import React            from 'react'
import axios            from 'axios'
import language_variant from "store/languages.jsx"
import Preloader        from 'components/preloader'
import authActions      from 'actions/authActions.js'


import 'css/error_boundary.scss'
import 'images/frontEnd_crash.jpg';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: false,
            errorInfo: false,
            visible: true,
            eventInfo: {
                class: '',
                text: '',
                tagName: '',
                type: ''
            }
        };
    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidMount() {
        this.mount = true;
    }
    componentWillUnmount() {
        this.mount = false;
        window.removeEventListener('click', this.listenner, false)
    }
    listenner = (e) => {
        if(!this.state.hasError && this.mount) {
            e = e || window.event;
            const target = e.target || e.srcElement,
                text = target.textContent || target.innerText,
                tagName = e.target.tagName,
                type = e.target.type;
            this.setState({
                eventInfo: {
                    class: target.getAttribute('class'),
                    text: text,
                    tagName: tagName,
                    dataName: target.getAttribute('data-name'),
                    type: type,
                    id: target.id,
                    screenX: e.screenX,
                    screenY: e.screenY,
                }
            });
        }
    }
    UNSAFE_componentWillMount() {
        window.addEventListener('click', this.listenner, false)
    }
    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        this.setState({
            error: error,
            errorInfo: info
        })
        console.log("%c COMPONENTDIDCATCH this.props.children", 'color: green; font-weight: bold;', this.props.children)
    }
    reloadPage() {
        document.location.reload(true);
    }
    sendInfoAboutError = () => {
        this.preloader.show();
        let { error, errorInfo, eventInfo } = this.state;
        let info = error.toString() + '\n' + errorInfo.componentStack + '\n';
        info += '*USER_LOGIN*: ' + localStorage.getItem('login') + '\n';
        info += '*LOCATION*: ' + location.href + '\n';
        info += '*LAST_CLICKED_DOM_EL* - ' + '\n';
        info += '*e.CLASS*: ' + eventInfo.class + '\n';
        info += '*e.TEXT*: ' + eventInfo.text + '\n';
        info += '*e.TAGNAME*: ' + eventInfo.tagName + '\n';
        info += '*e.DATA*-NAME: ' + eventInfo.dataName + '\n';
        info += '*e.ID*: ' + eventInfo.id + '\n';
        info += '*e.TYPE*: ' + eventInfo.type + '\n';
        info += '*e.screenX*: ' + eventInfo.screenX + '\n';
        info += '*e.screenY*: ' + eventInfo.screenY + '\n';
        info += '*APP_VERSION*: ' + APP_VERSION + '\n';
        // info += `*LOCAL_STORAGE*: ${JSON.stringify(localStorage)}`;
        setTimeout(() => requestToSlack(this.login, info, this.hideErrorInfo), 1000) //for user tranquility
    }
    hideErrorInfo = () => {
        console.log(`%c hideErrorInfo-`, "color: green; font-weight: 400; text-transform:uppercase",)
        this.setState({
            // hasError: false,
            message_sent: true
        }, () => {
            if(this.mount) this.preloader.hide();
        });//this.reloadPage

    }
    render() {
        let { visible, message_is_sent } = this.state;
        if(this.state.hasError) {
            // You can render any custom fallback UI
            return <div className = 'error_boundary' style = {{visibility: visible?'visible':'hidden'}}>
                <img src = 'images/frontEnd_crash.jpg' className = 'error_back'/>
                <div className = "error_info_container">
                    <h1>{language_variant.something_went_wrong}</h1>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                         <br />
                    </details>
                    <div>
                        <h3>
                            {
                                message_is_sent ? 
                                language_variant.message_sent :
                                language_variant.please_send_error
                            }
                        </h3>
                        <button className = 'primari_btn' name = 'sendInfo' onClick = {this.sendInfoAboutError}>{language_variant.send_info}</button>
                        <button className = 'primari_btn' name = 'reloadPage' onClick = {this.reloadPage}>{language_variant.reload_page}</button>
                        <button className = 'primari_btn' name = 'hideInfo' onClick = {this.hideErrorInfo}>{language_variant.hide_notif}</button>
                    </div>
                    <Preloader ref = { el => this.preloader = el }/>
                 </div>
            </div>
        }
        return this.props.children;
    }
}

export function sendMessageToChannel (loginOrPlaceInfo, info, callback) {
    info += 'BROWSER: ' + window.navigator.appVersion || window.navigator.userAgent + '\n';
    info += `loginOrPlaceInfo: + ${loginOrPlaceInfo} \n`;
    axios({
        url: "https://hooks.slack.com/services/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",
        method: 'POST',
        data: JSON.stringify({
            "icon_emoji": ":hugging_face:",
            "userName": loginOrPlaceInfo,
            "text": '*front end bug*' + '\n' + '```\n' + info + ' \n```'
        })
    }).then(() => {
        // authActions.setMessageFromAdmin(language_variant.message_sent)
        if (callback) callback();
    }).catch((e) => {
        authActions.setMessageFromAdmin(`Не вдалося надіслати повідомлення, причина: ${SL.tryStringify(eInfo.errorInfo)}`)
    })
}




function stringifyEl(domEl) {
    let whitelist = ["id", "tagName", "className", "childNodes"];

    function domToObj (domEl) {
        var obj = {};
        for (let i=0; i<whitelist.length; i++) {
            if (domEl[whitelist[i]] instanceof NodeList) {
                obj[whitelist[i]] = Array.from(domEl[whitelist[i]]);
            }
            else {
                obj[whitelist[i]] = domEl[whitelist[i]];
            }
        };
        return obj;
    }

    return JSON.stringify(domEl, function (name, value) {
        if (name === "") {
            return domToObj(value);
        }
        if (Array.isArray(this)) {
            if (typeof value === "object") {
                return domToObj(value);
            }
            return value;
        }
        if (whitelist.find(x => (x === name)))
            return value;
    })
}

