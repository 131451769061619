import React from 'react'
import {connect}                              from 'react-redux'
import language_variant from 'store/languages'
import {setNotifText} 	from 'actions/bugReport.js'
import PopUpWindow from 'oftenUsed/PopUpWindow.jsx'
function PopUpNotifcation(props) {
	let {text_notif} = props;
	if(!!text_notif){
		return 	<PopUpWindow 	head_text =		{language_variant.attention}
								openAlways = 	{true}
				                head_back = 	{{backgroundColor:"rgb(255, 77, 77)", color: 'white', fill: 'white'}}
				                onClose = 		{()=>setNotifText(null)}
				                popUp_size = 	{{maxWidth: '99%', width: '500px', height: '192px', height: 'max-content'}}>
			                <div className = "remove_container">
			                	<p className = 'confirm_text'>{text_notif}</p>
			                </div>
					</PopUpWindow>
	}else{
		return null
	}
}

export default connect(
    state => ({
        text_notif:     state.globalR.authorizationR.text_notif,
    })
)(PopUpNotifcation)