import axios            from 'axios'
import language_variant from "../store/languages.jsx"
import bugReport        from './bugReport.js'
import store            from 'store/store.js'
import infoStore        from './infoStore.js'
import eventAction      from './filtersAction.js'
import SL               from 'sl-library'
import helpFunc         from 'components/modules/controlPage/helpFunc.js'
import HintContrtol     from 'components/modules/UIHints/controlPageHint.jsx'
import ControlContext   from 'components/modules/controlPage/ControlContext.js'

const TransportApi = {
	getVehiclesInfo:            getVehiclesInfo,
	getVehiclesInfoMini:        getVehiclesInfoMini,
	getVehiclesSimpleList:		 getVehiclesSimpleList,
	getOneVehicleInfo:          getOneVehicleInfo,
	dispatchVehicleInfoToStore: dispatchVehicleInfoToStore,
	setStatusForButtonFilters:  setStatusForButtonFilters,
	getVehiclesInfoFull:        getVehiclesInfoFull,
	getVehicleSettings: 		getVehicleSettings,
	getVehiclesToDevices:		getVehiclesToDevices,
	getMiniListWithDevices:		getMiniListWithDevices,
	getMiniListWithDevices:		getMiniListWithDevices
}

export default TransportApi;

function getVehiclesSimpleList({callbackOnOK, callbackOnError}) {
	const requestData = {
			method:          'GET',
			baseURL:         infoStore().base_url,
			url:             infoStore().bonus_url_api + 'vehicle/mobile-state',
			dataType:        "JSON",
			headers:         { 'authorization': infoStore().token },
			withCredentials: true,
		}
		axios(requestData).then(answer => {
			if(callbackOnOK)callbackOnOK(answer.data)

			
			// HintContrtol.addVehicleForShowHint() //adding demo vehicle
		}).catch(serveranswer => {
			if(callbackOnError)callbackOnError(null);
			bugReport(serveranswer, requestData, "getVehiclesInfoMini")
		})
}

let full_vehicles_list_was_received = false;
let mini_state_was_received = false;
function getMiniListWithDevices(callbackForAll) {
	const promiseMiniList = new Promise((resolve, reject) => {
		getVehiclesInfoMini(resolve, reject);
	});
	const promiseDevicesList = new Promise((resolve, reject) => {
		getVehiclesToDevices(resolve, reject);
	});
	Promise.all([promiseMiniList, promiseDevicesList]).then(value => {
		const vehicleListMini = value[0];
		const vehiclesToDevicesList = value[1];
		let vehicleListMiniModified = null;
		let sortedArray = null;
		if(SL.arrayIsEmpty(vehicleListMini)){
			vehicleListMiniModified = Array.from(vehicleListMini, oneVehicle => { //crutch for create state DTO like in full state
				return {
							vehicleCounters: [],
							lastKnownTime: oneVehicle.vehicleData ? oneVehicle.vehicleData.timestamp : null,
							vehicleId: oneVehicle.id,
							...oneVehicle,
							...oneVehicle.vehicleData,
						}
			})
		}

		if(SL.arrayIsEmpty(vehiclesToDevicesList) && SL.arrayIsEmpty(vehicleListMiniModified)){
			vehicleListMiniModified.map(oneVehicle => {
				if(oneVehicle.type == 'VEHICLE'){
					const equalEl = vehiclesToDevicesList.find(el => el.vehicleId == oneVehicle.vehicleId);
					oneVehicle.currentDeviceMapping = {deviceSerial: equalEl.deviceId};
				}
			});
		}
		if(SL.arrayIsEmpty(vehicleListMiniModified)){
			sortedArray = helpFunc.sortVehicles(vehicleListMiniModified)
		}

		if(!full_vehicles_list_was_received){
			dispatchVehicleInfoToStore(sortedArray);
		}
		mini_state_was_received = true;
		if(callbackForAll) callbackForAll(sortedArray)
		// iFitsTestLogin(vehiclesInfo);

	}, reason => {
	  	console.warn('getMiniListWithDevices has problem',  reason)
	});

	
	// setStatusForButtonFilters(vehiclesInfo)

	

	// getVehiclesToDevices(addDevices)
}

function getVehiclesInfoMini(callbackOnOK, callbackOnError) {
	const requestData = {
			method:          'GET',
			baseURL:         infoStore().base_url,
			url:             infoStore().bonus_url_api + 'vehicle/mini-state',
			dataType:        "JSON",
			withCredentials: true,
			headers:         { 'authorization': infoStore().token },
		}
		axios(requestData).then(answer => {
			if(callbackOnOK)callbackOnOK(answer.data)

			
			// HintContrtol.addVehicleForShowHint() //adding demo vehicle
		}).catch(serveranswer => {
			if(callbackOnError)callbackOnError(null);
			bugReport(serveranswer, requestData, "getVehiclesInfoMini")
		})
}

function getVehiclesToDevices(callbackOnOK, callbackOnError) {
	const requestData = {
				method:          'GET',
				baseURL:         infoStore().base_url,
				url:             infoStore().bonus_url_api + 'vehicles-to-devices',
				dataType:        "JSON",
				withCredentials: true,
				headers:         { 'authorization': infoStore().token },
			}
			axios(requestData).then(answer => {
				let vehiclesInfo = answer.data;
				callbackOnOK ? callbackOnOK(vehiclesInfo) : null
			}).catch(serveranswer => {
				if(callbackOnError)callbackOnError(null);
				bugReport(serveranswer, requestData, "getVehiclesToDevices")
			})
}

function getVehiclesInfoFull(callback) {
	let requestData = {
		method:          'GET',
		baseURL:         infoStore().base_url,
		url:             infoStore().bonus_url_api + 'vehicle/state',
		dataType:        "JSON",
		withCredentials: true,
		headers:         { 'authorization': infoStore().token },
	}
	axios(requestData).then(answer => {
		full_vehicles_list_was_received = true;
		let vehiclesInfo = answer.data;
		if(mini_state_was_received){
			updateDependentElements(vehiclesInfo);
		}else{
			dispatchVehicleInfoToStore(helpFunc.sortVehicles(vehiclesInfo));
			ControlContext.hidePreloader();
			setTimeout(()=>ControlContext.initSetNDStatusTimer(), 1000);
		}
		
		callback ? callback(vehiclesInfo) : null
	}).catch(serveranswer => bugReport(serveranswer, requestData, "getVehiclesInfoFull"))
}

function updateDependentElements(vehiclesInfo) {
	setStatusForButtonFilters(vehiclesInfo)
	vehiclesInfo.forEach(vehicleInfo => {
		moveDeviceOnToFirstLevelCRUTCH(vehicleInfo)
		ControlContext.updateFromFullState(vehicleInfo)
	})
	ControlContext.sortVehiclesOnGetFullState();
	ControlContext.updateInfoGraphic(vehiclesInfo);
	ControlContext.filterVehiclesOnGetFullState();
	ControlContext.hidePreloader();
	ControlContext.full_vehiclesList_is_recieved = true;
	setTimeout(()=>ControlContext.initSetNDStatusTimer(), 1000);

	// if(DEVELOPMENT) console.trace('UPDATEDEPENDENTELEMENTS completed')
	return vehiclesInfo
}


function getVehiclesInfo(callback) {//it's duplicate getVehiclesInfoFull (it's was different endpoint). remove it later
	let requestData = {
		method:          'GET',
		baseURL:         infoStore().base_url,
		url:             infoStore().bonus_url_api + 'vehicle/state',
		dataType:        "JSON",
		withCredentials: true,
		headers:         { 'authorization': infoStore().token },
	}
	axios(requestData).then(answer => {
		let vehiclesInfo = answer.data;
		updateDependentElements(vehiclesInfo)
		callback ? callback(vehiclesInfo) : null;
		//iFitsTestLogin(vehiclesInfo); was joke for developer team, but now no team(,  joke not actual more
		//adding demo vehicle (this was need for hints drawing, we decided to abandon them because. hard to maintain)
		}).catch(serveranswer => bugReport(serveranswer, requestData, "getVehiclesInfo"))
}



function moveDeviceOnToFirstLevelCRUTCH(vehicleInfo) {//on get message by websocket this value will be at first level ()
	if(vehicleInfo.currentDeviceMapping && vehicleInfo.currentDeviceMapping.deviceSettingsDto){
		vehicleInfo.deviceOn = vehicleInfo.currentDeviceMapping.deviceSettingsDto.deviceOn
	}
}

function setStatusForButtonFilters(vehiclesInfo) {
	if(SL.arrayIsEmpty(vehiclesInfo)) {
		vehiclesInfo.forEach(key => {
			for(let event in key.eventsCountPerDay) {
				if(key.eventsCountPerDay[event] > 0) {
					eventAction.updateStatusFilter(event, {
						showEvents: true,
						disabled: false,
						hidden: false,
					})
				}
			}
		})
	}
}

function dispatchVehicleInfoToStore(vehiclesInfo) {
	store.dispatch({
		type:            	'GET_transport',
		setVehiclesInfo: 	vehiclesInfo
	})
}

function getOneVehicleInfo(vehicleId, callback) {
	let requestData = {
		method:   'GET',
		baseURL:  infoStore().base_url,
		url:      infoStore().bonus_url_api + 'vehicle/' + vehicleId + '/state',
		dataType: "JSON",
		headers:  { 'authorization': infoStore().token },
	}
	axios(requestData).then(answer =>
	                        getVehicleSettings(vehicleId, (settings) => {
	                        	answer.data.settings = settings;
	                        	callback(answer.data)
	                        }) )

	.catch(serveranswer => bugReport(serveranswer, requestData, "getOneVehicleInfo"))
}

function getVehicleSettings(vehicleId, callback) {
	let requestData = {
		method:   'GET',
		baseURL:  infoStore().base_url,
		url:      infoStore().bonus_url_api + 'vehicle/' + vehicleId + '/settings',
		dataType: "JSON",
		headers:  { 'authorization': infoStore().token },
		params: { locale: SL.doUperCase(infoStore().language) }
	}
	axios(requestData).then(answer => callback(answer.data))
	.catch(serveranswer => bugReport(serveranswer, requestData, "getOneVehicleInfo"))
}

function consoleSpecificVehicle(vehiclesList, vehicleId) {
	if(SL.arrayIsEmpty(vehiclesList) && typeof SL.isNumeral(vehicleId)) {
		vehiclesList.find(compareFunc)
	}

	function compareFunc(vehicle) {
		if(vehicle.vehicleId == vehicleId) {
		}
	}
}

function iFitsTestLogin(vehiclesInfo) {
	if(infoStore().login == "test") { //creating demo view of vehicle
		vehiclesInfo.forEach((key) => {
			if(key.vehicleId == 2) {
				// key.fixed = true;
				key.imagesrc = './images/batman.jpg'
			}
			if(key.vehicleId == 82 || key.vehicleId == 2) {
				// key.fixed = true;
			}
		})
		// setEventsTOExample()
	}
	return vehiclesInfo
}
