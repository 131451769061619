import React, { Component } from 'react';
import L  from "leaflet"
import _  from "lodash"
import geoRequest                                    from 'actions/geoRequest.js';

import 'leaflet/dist/leaflet.css'
import './customMapStyles.scss'

const visicomApiKey = '688720a1b11c64de9e9ee0546e62cd58';
export const googleApiKey = 'AIzaSyAtvLWQRKn-krgnNQBFx3anEdYPwe_7v9g';

function getLanguage() {//dublicate func))
    const lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : 'ru';
    return lang == 'ua' ? 'uk' : lang
}
// LeafLet:  L.tileLayer('http://tiles.mq.ua/{z}/{x}/{y}.png', {
//             attribution: 'LeafLet',
//             tileName: 'LeafLet',
//             layerID: 'LeafLet'
//         }),
export function TileLayers(){
    return  {
                roadmap: L.tileLayer(`https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=${getLanguage()}`,
                            {
                            maxZoom: 20,
                            subdomains:['mt0','mt1','mt2','mt3'],
                            attribution: 'Google map',
                            tileName: 'Google map',
                            layerID: 'roadmap',
                        }),

                hybrid: L.tileLayer(`https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&hl=${getLanguage()}`,
                            {
                            maxZoom: 20,
                            subdomains:['mt0','mt1','mt2','mt3'],
                            attribution: 'Google map',
                            tileName: 'Google map hybrid',
                            layerID: 'hybrid',
                            language: getLanguage(),
                            // apiKey:   googleApiKey
                        }),
                Visicom: L.tileLayer('https://tms1.visicom.ua/2.0.0/planet3/base/{z}/{x}/{y}.png?origin=nw'+'&key' + visicomApiKey, {
                            attribution: 'Дані карт © АТ «<a href="https://api.visicom.ua/">Візіком</a>»',
                            tileName: 'Visicom map',
                            layerID: 'Visicom',
                            language: getLanguage()
                        }),
                /*Luxena: L.tileLayer('https://tiles.mq.ua/{z}/{x}/{y}.png', {
                            attribution: 'Luxena',
                            tileName: 'Luxena',
                            layerID: 'Luxena',
                            language: getLanguage()
                        }),*/
                OSM_World:  L.tileLayer('https://tiles.elsyton.com/{z}/{x}/{y}.png', {
                                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                                tileName: 'OpenStreetMap',
                                layerID: 'OSM_World',
                                language: getLanguage()
                            }),
                OSM_World_2021: L.tileLayer('https://maps-osm.elsyton.com/hot/{z}/{x}/{y}.png', {
                                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                                tileName: 'OSM Globus',
                                layerID: 'OSM_World',
                                language: getLanguage()
                            }),
                OSM_2024: L.tileLayer('https://maps-osm-temp.elsyton.com/hot/{z}/{x}/{y}.png', {
                                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                                tileName: 'OpenStreetMap',
                                layerID: 'OSM_2024'
                        }),    
            }
}

export function getDefMapType() {
    if(localStorage.getItem('mapType') !== 'Luxena'){
        return localStorage.getItem('mapType') || 'hybrid'
    }else{
        return 'hybrid'
    }
}

export function saveMapTypeToStore(mapType) {
    localStorage.setItem('mapType', mapType)
}

export function getMapTypeFromStore() {
    return getDefMapType() == 'Visicom' ? 'OSM_World' : getDefMapType(); //crutch because have problems with Visicom acces
}

function crutch_disable_default_actions_on_click_to_linkA_in_popUp() {//don't know is a library bug or some conflict
    window.addEventListener('click', e => {
        if(e.target) {
            try{
                const el = e.target;
                const attr = el.getAttribute('aria-label');
                const parrentAttr = el.parentElement.getAttribute('aria-label');
                if(attr == "Close popup" || parrentAttr == "Close popup") e.preventDefault()
            }catch(err){
                console.trace(`%c crutch LF map err`, "color: #708090; font-weight: 500; text-transform:uppercase", err)
            }
           
        }
    })
}

export default class LFMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMapCreated: false,
        }
        this.initialState = this.state;
        this.updateMapSize = _.debounce(this.updateMapSize, 250);
    }
    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("resize", this.updateMapSize);
        geoRequest.saveMapToStore(null);
        console.log(`%c componentWillUnmount-`, "color: green; font-weight: 400; text-transform:uppercase", )
    }
    updateMapSize = () => {
        if(this.mounted && this.map) this.map.invalidateSize();
    }
    componentDidMount() {
         //crutch for close popup button (don't know why, but element work like a link)
        const {id} = this.props;
        this.mounted = true;
        crutch_disable_default_actions_on_click_to_linkA_in_popUp();
        const {lat, lng, zoom} = this.props;
        this.map = L.map(id ? id : 'lf_map', {
            center: [lat, lng],
            zoom: zoom ? zoom : 7,
            zoomControl: false,
            editable: true,
            measureControl: true,
            preferCanvas: true,
        })
        L.control.zoom({ position: 'bottomright' }).addTo(this.map);
        const defaultMapType = !TileLayers()[getDefMapType()] ? 'Luxena' : getDefMapType();
        const selectedLayer = TileLayers()[defaultMapType];
        saveMapTypeToStore(defaultMapType);//some styles depended (markers label, tooltips, geozones opacity)
        this.map.addLayer(selectedLayer);
        geoRequest.saveMapToStore(this.map);
       
        this.setState({ isMapCreated: true }, () => this.props.onMapCreated(this.map) );
        window.addEventListener("resize", this.updateMapSize);
        // window.mapObject = this.map;
    }
    render() {
        const style = Object.assign({
            width: this.props.width,
            height: this.props.height
        }, this.props.style);
        const { isMapCreated } = this.state;
        const {id} = this.props;
        return <div style = {style} className = {this.props.className} id = {id ? id : 'lf_map'}>
                    {
                        !isMapCreated ?
                        <img src = '/images/ring-alt.gif' width='80' height='80' className='preloader'/> :
                        null
                    }
                </div>
    }
}


