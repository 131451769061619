import _                from "lodash"
import transportInfo    from "actions/transportInfo.js";
import helpFunc         from "./helpFunc.js";
import SL               from "sl-library";
import VehiclesList     from 'store/VehicleContext.js'
import infoStore        from 'actions/infoStore.js'
import language_variant from "store/languages.jsx"
import authActions 		from "actions/authActions.js"



export const ControlContext = class ControlContext extends VehiclesList{
	constructor(context) {
		super(context)
		// Object.assign(this, VehiclesList)
		// debounce
		// throttle
		this.overedMarker = this._overedMarker_;
		this.hoveredMarker = this._hoveredMarker_;
		// this.overedMarker =   _.throttle(this._overedMarker_, 200, { 'trailing': true } );
		// this.hoveredMarker = _.throttle(this._hoveredMarker_, 200, { 'trailing': true });
		this.full_vehiclesList_is_recieved = false;
		window.CC = this;
	}
	getThis() {
		return this
	}
	addAccesToComponent(componentName, functionForUpdateState, functionForGetState) {
		this[componentName] = {
			setState: functionForUpdateState,
			set state(val) {
				this._state_ = val;
			},
			get state() {
				return this._state_ ?
					this._state_() :
					'access to state unavailable, check ' + componentName + 'addAccesState'
			}
		}
		this[componentName].state = functionForGetState;
	}
	removeComponent(componentName) {
		delete this[componentName];
	}
	updateListOnFixIt(vehicleId) { //move to worker
		const { displayedTransport } = this.ContentControlPage.state;
		const indexOfVehicle = displayedTransport.findIndex(key => key.vehicleId == vehicleId);
		const newStatusFixed = !displayedTransport[indexOfVehicle].fixed;
		displayedTransport[indexOfVehicle].fixed = newStatusFixed;
		this.ContentControlPage.setState({
			displayedTransport: SL.copyOfObject(helpFunc.sortingArrayByFixed(displayedTransport))
		});
		this.vehiclesList[vehicleId].setState({ fixed: newStatusFixed });
	}
	callFixVehicle(vehicleId, status) {
		const callBack = (vehicleDTO) => this.updateListOnFixIt(vehicleId);
		transportInfo.setFixedForVehicle(vehicleId, status, callBack);
	}
	updatesFromWebSocket(newState) {
		const { vehicleId, deviceId, name } = newState;
		let vehicleIdByDeviceId;
		/*if(vehicleId == 39187){
			console.log("%c UPDATESFROMWEBSOCKET", coCSS, newState)
		}*/
		if(!vehicleId){
			vehicleIdByDeviceId = this.getVehicleIdByDeviceId(deviceId);
			newState.vehicleId = vehicleIdByDeviceId;
		}
		if(SL.ifNoKey(newState, 'antStatus')){
			newState.name = 'NEWANTSTATUS'
		}
		switch (name) {
			case "vehicleLocation":
				this.onUpdateVehicleLocation(newState);
				break;
			case "LockStateForDevice":
				this.updateLockStateStatus(newState)
				break;
			case "SOS_ALARM":
				this.onGetEventSosAlarm(vehicleId);
				break;
			case "SIGNALING_ALARM":
				this.onGetEventSignalingAlarm(vehicleId);
				break;
			case "onUpdateVehicleCounter":
				this.onUpdateVehicleCounter(newState);
				break;
			case 'NEWANTSTATUS':
				this.onGetNewAntStatus(newState);
				break;
			case 'ErrorSetLock':
				vehicleIdByDeviceId = this.getVehicleIdByDeviceId(deviceId)
				newState.vehicleId = vehicleIdByDeviceId;
				this.showMessageAboutErrorSetLock(newState)
				break;
			case 'deviceStatus':
				this.onUpdateDeviceStatus(newState)
				break;
			case 'changedVehicleList':
				console.warn('CHANGEDVEHICLELIST page will be reloaded', newState )
				if(newState.message == 'Data mapping was created' 
				   		|| newState.message == 'Data mapping was updated'
				   			|| newState.message == 'Vehicle was deleted'){
					// authActions.setMessageFromAdmin('Сп');
					setTimeout(()=>window.location.reload(), 4000);
				}
				
				break;
			// case "disableLockedAlarm"://fake names I call it's from other component
			// 	this.onDisableLockedAlarm(newState);
			// 	break;
			// case "disableSosAlarm"://fake names I call it's from other component
			// 	this.onDisableSosAlarm(newState);
			// 	break;
			default:
				// this.onGetNewAntStatus(newState);
				break;
		}
		setTimeout(()=>this.updateInfoGraphic(this.getStoreState()), 500); //crutch for waiting store update, need refactoring later...) or call this function after every update vehicle in list
	}
	get itsRouteDetail(){
		return window.location.hash.includes('routeDetail')
	}
	get itsControlPage(){
		return window.location.hash.includes('control')
	}
	callBackAfterUpdateStateInList(vehicleId){
		this.updateInfoWindow(vehicleId);
		this.updateMarkerStatus(vehicleId);
	}
	onUpdateDeviceStatus(newState){
		const {vehicleId} = newState;
		this.quietUpdateVehicleInStore({
			vehicleId: vehicleId,
			deviceStatus: newState.deviceStatus
		});
		if(!this.itsRouteDetail && this.checkVehicleList(vehicleId) ) {
			this.vehiclesList[vehicleId].setState(
			                                      	{ deviceStatus: newState.deviceStatus},
			                                      	() => this.callBackAfterUpdateStateInList(vehicleId)
			                                      )
		}
		if(this.itsRouteDetail && this.checkVehicleList(vehicleId)){
			setTimeout(()=>this.updateMarkerStatus(vehicleId), 500);
		}
	}
	showMessageAboutErrorSetLock(newState){
		const {lockState, vehicleId} = newState;
		const text = lockState?language_variant.error_on_set_lock:language_variant.error_on_set_unlock;
		if(this.checkVehicleList(vehicleId)) this.vehiclesList[vehicleId].setState({ errorSetLock: text })
		setTimeout(()=> {
			if(this.checkVehicleList(vehicleId)) this.vehiclesList[vehicleId].setState({ errorSetLock: null });
		}, 10000)
		// this.quietUpdateVehicleInStore(text, "errorSetLock");
		// {"deviceId":73573,"lockStateTime":"2021-04-07T09:11:50.829Z","lockState":true,"name":"ErrorSetLock"}
	}
	checkVehicleList(vehicleId){
		return this.vehiclesList && this.vehiclesList[vehicleId]
	}
	updateLockStateStatus(newState){
		const {deviceId, vehicleId} = newState;
		if(this.checkVehicleList(vehicleId)){
			this.vehiclesList[vehicleId].setState({ lockStateDto: newState }, () => this.callBackAfterUpdateStateInList(vehicleId));
		}
		this.quietUpdateVehicleInStore(newState, 'lockStateDto');
	}
	updateFromFullState(vehicleInfo){
		const { vehicleId, vehiclesDataGpsDataDto} = vehicleInfo;
		if(this.checkVehicleList(vehicleId)){
			this.vehiclesList[vehicleId].setState({...vehicleInfo}, () => {
				this.updateMarkerLocation({ ...vehiclesDataGpsDataDto, vehicleId: vehicleId, azimuth:  vehicleInfo.azimuth});
				this.callBackAfterUpdateStateInList(vehicleId);
			})
		}
		this.quietUpdateVehicleInStore(vehicleInfo);
		
	}
	initSetNDStatusTimer(){
		// alert('get from store need')
		const setNDStatus = () => {
			const list_in_store = this.getStoreState();
			for(let index in list_in_store){
				const vehicleDTOInStore  = list_in_store[index];
				const { lastKnownTime, vehicleId, deviceStatus } = vehicleDTOInStore;
				const nc_status = new Date() - new Date(lastKnownTime) > 620_000 && deviceStatus == "CONNECTED";
				if(this.checkVehicleList(vehicleId)) this.vehiclesList[vehicleId].setState({nc_status});
				this.quietUpdateVehicleInStore({vehicleId, nc_status});
			}
		};
		setNDStatus();
		if(!this.setNDStatusTimer_is_active) {
			setInterval(setNDStatus, 60_000);//60_000
		}
		this.setNDStatusTimer_is_active = true; 

		
	}
	get sortVehiclesOnGetFullState(){//may be it's need rewrite for mobX
		return this._sortVehiclesOnGetFullState_ ? this._sortVehiclesOnGetFullState_ : () => null
	}
	set sortVehiclesOnGetFullState(func){
		this._sortVehiclesOnGetFullState_ = func;
	}
	get filterVehiclesOnGetFullState(){//may be it's need rewrite for mobX registered from ContentControlPage component
		return this._filterVehiclesOnGetFullState_ ? this._filterVehiclesOnGetFullState_ : () => null
	}
	set filterVehiclesOnGetFullState(func){//from ContentControlPage component
		this._filterVehiclesOnGetFullState_ = func;
	}
	set updateInfoGraphic(func){
		this._updateInfoGraphic_ = func;
	}
	get updateInfoGraphic(){
		return this._updateInfoGraphic_ ? this._updateInfoGraphic_ : () => null//may be it's bad decision, but convenient
	}
	set onSelectFromInfoGraphic(func){
		this._onSelectFromInfoGraphic_ = func
	}
	get onSelectFromInfoGraphic(){
		return this._onSelectFromInfoGraphic_ ? this._onSelectFromInfoGraphic_ : () => null//may be it's bad decision, but convenient
	}
	updateVehicleNameInStore(newNameDto){
		this.quietUpdateVehicleInStore(newNameDto)
	}
	onGetNewAntStatus(newData) {
		const { vehicleId, antStatus } = newData;
		if(antStatus) {
			antStatus.vehicleId = vehicleId;
			if(this.checkVehicleList(vehicleId)){
				this.vehiclesList[vehicleId].setState({ antStatus: antStatus }, this.callBackAfterUpdateStateInList(vehicleId))

			}
			this.quietUpdateVehicleInStore(antStatus, "antStatus");
		} else {
			// console.log("%c хз шо с этим делать. Покажу тут", coCSS, newData)
		}
	}
	onUpdateVehicleLocation(newData) {
		const { vehiclesDataGpsDataDto, vehicleId } = newData;
		delete newData.name
		newData.lastKnownTime = newData.timestamp;
		this.quietUpdateVehicleInStore(newData);
		// if(vehicleId == 38042)console.log("%c ONUPDATEVEHICLELOCATION", coCSS, newData, this, this.checkVehicleList(vehicleId), this.vehiclesList)
		if(!this.itsRouteDetail && this.checkVehicleList(vehicleId) ) {
			this.vehiclesList[vehicleId].setState({...newData }, () => {
				this.updateMarkerLocation({vehicleId: vehicleId, ...vehiclesDataGpsDataDto, azimuth:  newData.azimuth });
				this.callBackAfterUpdateStateInList(vehicleId);
			})
		}else{
			if(this.itsRouteDetail && this.checkVehicleList(vehicleId)){
				this.updateMarkerLocation({vehicleId: vehicleId, ...vehiclesDataGpsDataDto, azimuth:  newData.azimuth });
				this.updateInfoWindow(vehicleId);
			}
		}
	}
	onGetEventSosAlarm(vehicleId) {
		const vehicleDTOInStore = this.getVehicleDtoFromStore(vehicleId)

		if(this.checkVehicleList(vehicleId) ) {
			this.vehiclesList[vehicleId].setState({ currentDeviceMapping: updateDTO(vehicleDTOInStore) }, () => this.callBackAfterUpdateStateInList(vehicleId));
		}
		vehicleDTOInStore ?
		this.quietUpdateVehicleInStore(updateDTO(vehicleDTOInStore), 'currentDeviceMapping') :
		null

		function updateDTO(vehicleDTOInStore) {
			const { currentDeviceMapping } = vehicleDTOInStore;
			SL.ifNoKey(currentDeviceMapping, "alarm", true); // set true to key alarm
			SL.ifNoKey(currentDeviceMapping, "alarmTimestamp", new Date());
			currentDeviceMapping.vehicleId = vehicleId;
			return SL.copyOfObject(currentDeviceMapping)
		}
	}
	onGetEventSignalingAlarm(vehicleId) {
		const vehicleDTOInStore = this.getVehicleDtoFromStore(vehicleId)
		if(this.checkVehicleList(vehicleId) ) {
			const { currentDeviceMapping } = this.vehiclesList[vehicleId].state;
			SL.ifNoKey(currentDeviceMapping, "lockedAlarm", true);
			this.vehiclesList[vehicleId].setState({ currentDeviceMapping: updateDTO(vehicleDTOInStore)} , () => this.callBackAfterUpdateStateInList(vehicleId));
		}
		vehicleDTOInStore ?
		this.quietUpdateVehicleInStore(updateDTO(vehicleDTOInStore), 'currentDeviceMapping') :
		null

		function updateDTO(vehicleDTOInStore) {
			const { currentDeviceMapping } = vehicleDTOInStore;
			SL.ifNoKey(currentDeviceMapping, "lockedAlarm", true);
			currentDeviceMapping.vehicleId = vehicleId;
			return SL.copyOfObject(currentDeviceMapping)
		}
	}
	onUpdateVehicleCounter(newData) {
		const { vehicleId, vehicleCounterDto } = newData;
		if(this.checkVehicleList(vehicleId) ) {
			const { vehicleCounters } = this.vehiclesList[vehicleId].state;
			const indexCounter = vehicleCounters.findIndex(counter => vehicleCounterDto.id == counter.id);
			vehicleCounters[indexCounter] = vehicleCounterDto;
			this.vehiclesList[vehicleId].setState({ vehicleCounters: vehicleCounters }, () => this.callBackAfterUpdateStateInList(vehicleId));
		}
	}
	onDisableLockedAlarm(newData) {
		const { vehicleId } = newData;
		const vehicleDTOInStore = this.getVehicleDtoFromStore(vehicleId)
		if(this.checkVehicleList(vehicleId) ) {
			this.vehiclesList[vehicleId].setState({
				currentDeviceMapping: updateDTO(vehicleDTOInStore),
				alarm: false,
			}, () => this.callBackAfterUpdateStateInList(vehicleId));
		}
		if(vehicleDTOInStore){
			this.quietUpdateVehicleInStore(updateDTO(vehicleDTOInStore), "currentDeviceMapping");
		}
		function updateDTO(vehicleDTOInStore) {
			const { currentDeviceMapping, vehicleId } = vehicleDTOInStore;
			SL.ifNoKey(currentDeviceMapping, "lockedAlarm", false);
			currentDeviceMapping.vehicleId = vehicleId;
			return SL.copyOfObject(currentDeviceMapping)
		}
	}
	onDisableSosAlarm(newData) {
		const { vehicleId } = newData;
		const vehicleDTOInStore = this.getVehicleDtoFromStore(vehicleId)
		if(this.checkVehicleList(vehicleId) ) {
			this.vehiclesList[vehicleId].setState({
				currentDeviceMapping: updateDTO(vehicleDTOInStore),
				alarm: false,
			}, () => this.callBackAfterUpdateStateInList(vehicleId));
		}
		if(vehicleDTOInStore){
			this.quietUpdateVehicleInStore(updateDTO(vehicleDTOInStore), "currentDeviceMapping");
		}
		function updateDTO(vehicleDTOInStore) {
			const { currentDeviceMapping, vehicleId } = vehicleDTOInStore;
			SL.ifNoKey(currentDeviceMapping, "alarm", false);
			SL.ifNoKey(currentDeviceMapping, "alarmTimestamp", new Date())
			currentDeviceMapping.vehicleId = vehicleId;
			return SL.copyOfObject(currentDeviceMapping)
		}
	}
	onGetSimpleEvent(newData) {
		const { name, vehicleId } = newData;
		if(this.vehiclesList[vehicleId]) {
			this.vehiclesList[vehicleId].setState({ eventsCountPerDay: updateDTO(this.vehiclesList[vehicleId].state) }, () => this.callBackAfterUpdateStateInList(vehicleId));
		}
		const vehicleDTOInStore = this.getVehicleDtoFromStore(vehicleId)
		if(vehicleDTOInStore){
			this.quietUpdateVehicleInStore({ ...updateDTO(vehicleDTOInStore), vehicleId: vehicleId }, "eventsCountPerDay");
		}
		function updateDTO(vehicleDto) {
			// console.log("%c vehicleDto", coCSS, vehicleDto)
			let { eventsCountPerDay } = vehicleDto;
			eventsCountPerDay = eventsCountPerDay?eventsCountPerDay:{};
			eventsCountPerDay[name] = addOne(eventsCountPerDay[name]);
			return SL.copyOfObject(eventsCountPerDay)
			function addOne(countOfEvents) {
				return countOfEvents ? countOfEvents + 1 : 1;
			}
		}
	}
	getStoreState(){
		return infoStore('globalR.controlR.vehiclesInfo')
	}
	quietUpdateVehicleInStore(newData, keyName) { //quiet update store may don't work
		// console.log("%c QUIETUPDATEVEHICLEINSTORE storeState", coCSS, storeState, newData, keyName)
		const { vehicleId } = newData;
		const storeState = this.getStoreState()
		if(storeState){
			const indexInStore = this.getIndexInStore(vehicleId);
			if(!keyName && storeState[indexInStore]){
				storeState[indexInStore] = Object.assign(storeState[indexInStore], newData);
			}else{
				SL.ifNoKey(storeState[indexInStore], keyName, newData);
			}
			return storeState[indexInStore]
		}
	}
	getVehicleIdByDeviceId(deviceId){
		const storeState = infoStore('globalR.controlR.vehiclesInfo');
		const vehicleDto = storeState ? storeState.find(el => SL.ifNoKey(el.currentDeviceMapping, 'deviceSerial') == deviceId):null;
		return vehicleDto ? vehicleDto.vehicleId : null
	}
	getVehicleDtoFromStore(vehicleId){
		const storeState = infoStore('globalR.controlR.vehiclesInfo');
		if(storeState && storeState[this.getIndexInStore(vehicleId)]){
			return storeState[this.getIndexInStore(vehicleId)]
		}else{
			return null
		}
	}
	getIndexInStore(vehicleId){
		const storeState = infoStore('globalR.controlR.vehiclesInfo');
		return  SL.arrayIsEmpty(storeState) ?
				storeState.findIndex(el => el.vehicleId == vehicleId ) :
				console.warn('trying get index vehicle in store, but store is empty')
	}
	setLabelStatus(labelStatus) {
		localStorage.setItem('labelForAllEnabled', labelStatus)
	}
	get labelForAllEnabled() {
		return SL.getBooleanFromLocalStorage('labelForAllEnabled')
	}
	scrollVehicleIntoView(vehicleId) {
		if(!this.itsRouteDetail){
			let obj = document.querySelectorAll(`[data-value="${vehicleId}"]`)
			if(!!obj[0]) {
				let vehicleDomEl = obj[0];
				if(!SL.isVisibleY(vehicleDomEl)){
					vehicleDomEl.scrollIntoView({block: "center", behavior: "smooth"});
				}
				vehicleDomEl.style.cssText = `background-color: rgba(255, 255, 255, 0.3); box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.33)`
				setTimeout(()=>{
					vehicleDomEl.style = null;
				}, 3000)

			}
		}
	}
	get full_vehiclesList_is_recieved(){
		return this._full_vehiclesList_is_recieved_
	}
	set full_vehiclesList_is_recieved(value){
		return this._full_vehiclesList_is_recieved_ = value
	}
	get hidePreloader(){
		return 	this._hide_preloader_ ?
				this._hide_preloader_ :
				() => this.itsControlPage ? console.warn('logic trying hide preloader but access to it not available') : null;
	}
	set hidePreloader(func){
		return this._hide_preloader_ = func;
	}
}

const ControlContextLogic = new ControlContext();
export default ControlContextLogic