import React, { Fragment, useLayoutEffect, useRef, useState }  from 'react';
import { renderToString }                    from 'react-dom/server'
import moment                                from 'moment'
import infoStore                             from 'actions/infoStore.js'
import store                                 from 'store/store.js'
import SL                                    from 'sl-library'
import { Link }                              from 'react-router-dom';
import language_variant                      from 'store/languages.jsx'
import helpFunc                              from "components/modules/controlPage/helpFunc.js"
import SeparPremission,
    { permissionsFoRequst }                  from "oftenUsed/SeparPremission.jsx"
import {
    SpeedInfoForInfoWindow,
    FuelLevel,
    Inmidcourse,
    IgnitionInfoForInfoWindow,
    AntennaIcon
}                                            from 'components/modules/controlPage/VehicleStatus.jsx'

import {
    antennaIcon,
    securityIcon,
    sosIcon,
    icon_inmidcourseMoving,
    icon_inmidcourseParking,
    iconRouteDetailLink,
    linkIcon,
    closeIcon
}                                            from 'images/simple_svg_icons.jsx'


export function stringRoutePointInfoWindow(pointInfo) {

    const infoWindowContent =   <div    className = "task_detail_info_window"
                                        style =     {{zIndex: 55}}
                                        id =        {'info_'+ pointInfo.id}>
                                        <div id = "bodyContent">
                                            <p className = "point_name"> {pointInfo.name} </p>
                                            {
                                                pointInfo.actualArrivalTime ?
                                                <p className="flex_container">
                                                    <span className = 'name_of_list_item'>Время прибытия: </span>
                                                    <span className="grey">{moment(pointInfo.actualArrivalTime).format('DD.MM.YYYY - HH.mm')}</span>
                                                </p> :
                                                null
                                            }
                                            {
                                                pointInfo.requiredArrivalTimeFrom ?
                                                <p className="flex_container">
                                                    <span className = 'name_of_list_item'>Требуемое время прибытия: </span>
                                                    <span className="grey">{moment(pointInfo.requiredArrivalTimeFrom).format('DD.MM.YYYY - HH.mm')}</span>
                                                </p> :
                                                null
                                            }
                                            {
                                                pointInfo.actualDepartureTime ?
                                                <p className="flex_container">
                                                    <span className = 'name_of_list_item'>Время отбытия: </span>
                                                    <span className="grey">{moment(pointInfo.actualDepartureTime).format('DD.MM.YYYY - HH.mm')}</span>
                                                </p> :
                                                null
                                            }
                                            {
                                                pointInfo.actualDepartureTime ?
                                                <p className="flex_container">
                                                    <span className = 'name_of_list_item'>Требуемое время отбытия: </span>
                                                    <span className="grey">{moment(pointInfo.actualDepartureTime).format('DD.MM.YYYY - HH.mm')}</span>
                                                </p>:
                                                null
                                            }
                                        </div>
                                        {
                                            pointInfo.actuatePresenceTime ?
                                            <p className="flex_container">
                                                <span className = 'name_of_list_item'>Время проведенное в точке: </span>
                                                <span classwName="grey">{moment.utc(pointInfo.actuatePresenceTime*1000).format('HH:mm:ss')}</span>
                                            </p> :
                                            null
                                        }


                                </div>
    return renderToString(infoWindowContent)
}

export function stringGeozoneInfoWindow(name, square, info, editFunc){
    const zoneTypes = infoStore().zoneTypes;
    const user_permission = infoStore().user_permission;
    const infoWindowContent =   <div    className = "geozone_info_window info_window"
                                        style = {{zIndex: 55}}
                                        id = {'info_'+ info.id}>
                                        <p className="info_window_geozone_name">
                                            <span className="transport_number"> <i className = 'id_geozone'>[{info.id}]</i> {name}   </span>
                                        </p>
                                        <div id="bodyContent" >
                                            <p className="flex_container geoinfo_line">
                                                <span>
                                                    {language_variant.square }:
                                                </span>
                                                <span className="grey">
                                                    {square}
                                                    {language_variant.hectares}
                                                </span>
                                            </p>
                                            <p className="flex_container geoinfo_line">
                                                <span>
                                                    {language_variant.type}:
                                                </span>
                                                <span className="grey">
                                                    {getZoneTypeName(zoneTypes, info.zoneTypeId)}
                                                </span>
                                            </p>
                                            <div className="clear"></div>
                                        </div>
                                        {
                                            user_permission && user_permission.editZones ?
                                            <EditBtn info = {info}/>:
                                            null
                                        }
                                </div>

    return renderToString (infoWindowContent)
}

export function stringCarInfoWindow(props){
    const { id, newData, src, status} = props;
    const { antStatus, lockStateDto, fixed, imagesrc, eventsCountPerDay } = props;
    const {
        vehiclesDataGpsDataDto,
        timestamp,
        lastKnownTime,
        imgStyle,
        deviceSettingsDto,
        multiSensorValues,
        realSensorValues,
        logicalInputStatuses,
        licensePlate,
        name,
        vehicleId,
        currentDeviceMapping,
        trailerActionEvent,
        connectedRfidCard
    } = props;
    const lat = SL.ifNoKey(vehiclesDataGpsDataDto, 'latitude');
    const lng = SL.ifNoKey(vehiclesDataGpsDataDto, 'longitude');
    const lastDataDate = timestamp || lastKnownTime;
    const content = <div className = 'car_info_window'>
                        <button className = 'btn_horizontal_window_view' id = 'btn_horizontal_window_view' title = 'set horizontal view'>
                            <div><i></i><i></i></div>
                            <div><i></i><i></i><i></i></div>
                            <div><i></i></div>
                        </button>
                        <p className="auto_info">
                            <span   className="transport_name"
                                    style = { { paddingRight: !licensePlate?0:1.5 + 'rem' } }>
                                    {name}
                            </span>
                            <span   className = "transport_number">
                                {licensePlate}
                            </span>

                            {
                                !!src?
                                <img    className = "driverAva"
                                        src =   {src}
                                        style = {"visibility:" + imgStyle}/>:
                                null
                             }
                        </p>
                        <div id="bodyContent">
                            <p><b>{language_variant.tracker}</b> : {currentDeviceMapping ? currentDeviceMapping.deviceSerial : '--'}</p>
                            {/*<p className="current_status"> <Inmidcourse    {...props}/> </p>*/}
                            <IgnitionInfoForInfoWindow    ignitionOn =  {SL.ifNoKey(vehiclesDataGpsDataDto, 'ignitionOn')}/>
                            <p ><SpeedInfoForInfoWindow  {...vehiclesDataGpsDataDto}/></p>
                            {/*<p ><b>Lat:</b> {SL.isNumeral(lat) ? lat : '--'} </p>
                            <p ><b>Lng:</b> {SL.isNumeral(lng) ? lng : '--'}</p>*/}
                            {
                                helpFunc.latLngIsNotVeracity(vehiclesDataGpsDataDto) ?
                                <p><b>{language_variant.message_about_gps_veracity}</b></p> :
                                null
                            }
                            <LastDataTimeText lastDataDate = {lastDataDate}/>
                            <AntennaStatus status = {antStatus} vehicleId = {id}/>
                            <SosIcon   status =    {SL.ifNoKey(deviceSettingsDto, 'alarm')}
                                       timeStamp = {SL.ifNoKey(deviceSettingsDto, 'alarmTimestamp')}
                                       vehicleId = {id} />
                            <Security   lockState = {SL.ifNoKey(lockStateDto, 'lockState')}
                                        lockStateTime = {SL.ifNoKey(lockStateDto, 'lockStateTime')}
                                        vehicleId =     {id} />
                            <SecurityAlarm  lockedAlarm =   {SL.ifNoKey(currentDeviceMapping, 'lockedAlarm')}
                                            lockStateTime = {SL.ifNoKey(lockStateDto, 'lockStateTime')}
                                            vehicleId =     {id} />
                            <VehicleSensors  multiSensorValues = {multiSensorValues}
                                            logicalInputStatuses = {logicalInputStatuses}
                                                realSensorValues = {realSensorValues}/>
                            <MountedTrailers trailerActionEvent = {trailerActionEvent} />
                            <ConnectedRfidCard connectedRfidCard = {connectedRfidCard} />
                        </div>
                        <div className="info_window_buttons_block">
                            <LinkToRoute name = {name} licensePlate = {licensePlate} vehicleId = {vehicleId}/>
                            <LinkToCoordinatesForDefWindow vehiclesDataGpsDataDto = {vehiclesDataGpsDataDto} />
                        </div>
                    </div>;
    return renderToString(content)
}

function TrailerInfoWindow(props) {
    const { trailerActionEvent, vehiclesDataGpsDataDto, vehicleId } = props;
    if(typeof trailerActionEvent !== 'object') {
        console.warn('TRAILERINFOWINDOW trailerActionEvent is not valid - ', trailerActionEvent)
        return language_variant.no_data;
    }
    const {
        id,
        idSerial,
        name,
        registerTime,
        vehicleLicensePlate,
        vehicleName,
        width,
        type,
    } = trailerActionEvent;
    const connectedStatus = type == 'TRAILER_CONNECTED' ? language_variant.connected : language_variant.disconnected;
    return  <div className = 'car_info_window'>
                <div className="auto_info">
                    <span   className="transport_name">
                            {name}
                    </span>
                    <p><b>{language_variant.status}</b> : { connectedStatus }</p>
                    <UpdateTrailerStatusTime {...trailerActionEvent} shortView = {true}/>
                    <p><b>{/*{language_variant.tracker}*/}ID</b> :  {idSerial} </p>
                    {
                        type == 'TRAILER_CONNECTED' ?
                        <p><b>{language_variant.vehicle}</b> :  {vehicleName} : {vehicleLicensePlate} </p> :
                        null
                    }
                </div>
                <div id = "bodyContent">
                </div>
                <div className="info_window_buttons_block">
                    <LinkToRoute name = {name} vehicleId = {vehicleId}/>
                    <LinkToCoordinatesForDefWindow vehiclesDataGpsDataDto = {vehiclesDataGpsDataDto} />
                </div>
            </div>
}



export function stringTrailerInfoWindow(props){
        return renderToString(<TrailerInfoWindow {...props}/>)
}

export function customizeInfoWindow() {
    let length = document.querySelectorAll(".gm-style-iw").length;
    let windowContainer = document.querySelectorAll(".gm-style-iw")[length - 1]; //container infowindow
    if(!!windowContainer) {
        windowContainer.borderColor = 'red';
        windowContainer.style.borderRadius = '2px';
        windowContainer.style.boxShadow = "0 -2px 10px 0 rgba(0, 0, 0, 0.2)";
        windowContainer.firstChild.style.width = "100%";
        windowContainer.firstChild.style.maxWidth = "100%";
        windowContainer.firstChild.style.height = "auto";
        windowContainer.style.maxHeight = "fit-content";
        windowContainer.firstChild.style.maxHeight = "fit-content";
        let iwCloseBtn = windowContainer.childNodes[1];
        iwCloseBtn.className = 'btn_close_infowindow';
    }
}

function EditBtn(props) {
    const { info, onClick } = props;
    return  <button className = 'secondary_btn_small'
                    name =      'editGeo'
                    onClick =   {onClick}
                    value =     {info.id}  >
                {  language_variant.edit }
            </button>
}

function getZoneTypeName(zoneTypesList, zoneTypeId) {
    if(SL.arrayIsEmpty(zoneTypesList)){
        const zoneType = zoneTypesList.find(oneZoneType => oneZoneType.id == zoneTypeId)
        return zoneType.localName
    }else{
        return zoneTypeId
    }
}

function MountedTrailers(props) {
    const {trailerActionEvent} = props;
    const widthText = () => <p>
                                <b>{language_variant.width}</b>:
                                <i className = 'grey'>{trailerActionEvent.width}</i> {language_variant.m}
                            </p>
    return  trailerActionEvent ?
            <div key = {trailerActionEvent.idSerial} className = 'trailer_info_in_popup'>
                <h4>ID</h4>
                <b className = 'trailer_info_title'>{language_variant.trailer}: {trailerActionEvent.idSerial}</b>
                <b>{trailerActionEvent.name}</b><br/>
                <UpdateTrailerStatusTime {...trailerActionEvent} shortView = {true}/>
                { trailerActionEvent.width ? widthText() : null }
            </div> :
            null
}

function ConnectedRfidCard(props) {
    const {connectedRfidCard} = props;
    return  connectedRfidCard ?
            <p key = {connectedRfidCard.number}>
                <b>{language_variant.card}: </b> {connectedRfidCard.driverName ? connectedRfidCard.driverName : connectedRfidCard.number}<br/>
            </p> :
            null
}
//хочу добавить
/*<div className = 'window_content_block'>
    иконка прицел - следить за авто
    иконка показать маршрут
    иконка сменить карту
    иконка копировать координаты
    иконка последние уведомления по авто
    иконка заметки (кстати в список транспорта добавить нужно)
</div>*/
export function HorizontalCarWindow(props) {
    const { newData } = props;
    const { antStatus, lockStateDto, fixed, imagesrc, eventsCountPerDay } = props;
    const {
        vehiclesDataGpsDataDto,
        timestamp,
        lastKnownTime,
        imgStyle,
        deviceSettingsDto,
        multiSensorValues,
        realSensorValues,
        logicalInputStatuses,
        licensePlate,
        name,
        vehicleId,
        currentDeviceMapping,
        trailerActionEvent,
        connectedRfidCard,
        type
    } = props;
    const lat = SL.ifNoKey(vehiclesDataGpsDataDto, 'latitude');
    const lng = SL.ifNoKey(vehiclesDataGpsDataDto, 'longitude');
    const lastDataDate = timestamp || lastKnownTime;
    const totalArray = [
        ...getSF.getRealSensorsValues(realSensorValues),
        ...getSF.getMultiSensorValues(multiSensorValues),
        ...getSF.getLogicalInputsStatuses(logicalInputStatuses)
    ];
    const groupedSensors = createParts6Length(totalArray);
    const ManageBtns =  <div className="manage_btns_container">
                            <button className = 'btn_close' onClick={props.onClose} title = 'close window'>
                                {closeIcon('close_icon')}
                            </button>
                            <button className = 'btn_def_window_view' onClick={props.setDefaultView} title = 'set default view'>
                                <i></i><i></i><i></i>
                                <i className = 'like_marker_icon'></i>
                            </button>
                            <LinkToRoute name = {name} vehicleId = {vehicleId}/>
                            <LinkToCoordinates vehiclesDataGpsDataDto = {vehiclesDataGpsDataDto} />
                        </div>
    if(type == 'TRAILER'){
        return <div className = 'horizontal_info_window'>
                    { ManageBtns }
                    <div className = 'window_content_block' >
                        <TrailerInfoWindow {...props}/>
                    </div>
                </div>
    }else{
        return  <div className = 'horizontal_info_window'>
                    { ManageBtns }
                    <div className = 'window_content_block' >
                        <h4>INFO</h4>
                        <p className = 'vehicle_name_in_window' title = 'vehicle name and license number'><b>{name}</b> {licensePlate}</p>
                        <p title = 'vehicle ID'><b>ID:</b> {vehicleId}</p>
                        <p title = 'tracker serial number' ><b>{language_variant.tracker}:</b> {currentDeviceMapping ? currentDeviceMapping.deviceSerial : '--'}</p>
                    </div>
                    <div className = 'window_content_block current_status_in_window'>
                        <h4>GPS</h4>
                        <LastDataTimeText lastDataDate = {lastDataDate} shortView = {true}/>
                        <IgnitionInfoForInfoWindow    ignitionOn =  {SL.ifNoKey(vehiclesDataGpsDataDto, 'ignitionOn')} colors_enabled = {true}/>
                        <p ><SpeedInfoForInfoWindow  {...vehiclesDataGpsDataDto}/></p>
                        <p title = 'latitude' className = 'lat_lng_line'><b>Lat:</b> <span className = 'lat_lng_span' title = {lat}>{SL.isNumeral(lat) ? lat : '--'}</span> </p>
                        <p title = 'longitude' className = 'lat_lng_line'><b>Lng:</b> <span className = 'lat_lng_span' title = {lng}>{SL.isNumeral(lng) ? lng : '--'}</span></p>
                        {
                            helpFunc.latLngIsNotVeracity(vehiclesDataGpsDataDto) ?
                            <p><b>{language_variant.message_about_gps_veracity}</b></p> :
                            null
                        }

                        <AntennaStatusHorizontalWindow status = {antStatus} vehicleId = {vehicleId} />
                        <SosIcon   status =    {SL.ifNoKey(deviceSettingsDto, 'alarm')}
                                   timeStamp = {SL.ifNoKey(deviceSettingsDto, 'alarmTimestamp')}
                                   vehicleId = {vehicleId} />
                        <Security   lockState = {SL.ifNoKey(lockStateDto, 'lockState')}
                                    lockStateTime = {SL.ifNoKey(lockStateDto, 'lockStateTime')}
                                    vehicleId =     {vehicleId} />
                        <SecurityAlarm  lockedAlarm =   {SL.ifNoKey(currentDeviceMapping, 'lockedAlarm')}
                                        lockStateTime = {SL.ifNoKey(lockStateDto, 'lockStateTime')}
                                        vehicleId =     {vehicleId} />
                    </div>
                    {
                        trailerActionEvent ||  connectedRfidCard?
                        <div className = 'window_content_block'>
                            <MountedTrailers trailerActionEvent = {trailerActionEvent} />
                            <ConnectedRfidCard connectedRfidCard = {connectedRfidCard} />
                        </div> :
                        null
                    }
                    {
                        SL.arrayIsEmpty(groupedSensors) ?
                        <div className = 'window_content_block'>
                            <h4>{language_variant.sensors}</h4>
                            <div className = 'sensors_block_in_horizontal_window'>
                                {
                                    groupedSensors.map((sensorGroup, index) => (
                                        sensorGroup.length ?
                                        <div  key = {index} className = 'one_sensor_group_in_horizontal_window'>
                                            {sensorGroup.map(el => el)}
                                        </div>
                                        :
                                        null)
                                    )
                                }
                            </div>
                        </div> :
                        null
                    }

                   {/* <div className = 'window_content_block'>
                        <div className="info_window_buttons_block">

                        </div>
                    </div>*/}
                    {/*<div className = 'window_content_block'>
                        иконка прицел - следить за авто
                        иконка показать маршрут
                        иконка сменить карту
                        иконка копировать координаты
                        иконка последние уведомления по авто
                        иконка заметки (кстати в список транспорта добавить нужно)
                    </div>*/}
                </div>
    }

}


export const listennerInstructions = {

    get btnCopyEl() {
        return document.getElementById(this.btnId);
    },
    get btnViewEl(){
        return document.getElementById(this.btnViewElId)
    },
    btnId: 'get_google_link',
    btnViewElId: 'btn_horizontal_window_view',
    eventName: 'click',
    get vehiclesDataGpsDataDto() { //defined on add listener
        return this._vehiclesDataGps_
    },
    set vehiclesDataGpsDataDto(value) {
        this._vehiclesDataGps_ = value
    },
    get funcOnCopy() {
        return e => {
            e.stopImmediatePropagation();
            const getDiv = () => document.getElementById('copy_notif_text'); //tooltip div
            SL.setValueToBoofer(createLinkToGoogleMaps(this.vehiclesDataGpsDataDto));
            getDiv().textContent = language_variant.coordinates_copied;
            // setTimeout(() => getDiv() ? getDiv().classList.remove('show_text_copyed') : null, 1000);
        }
    },
    setListenerForChangeView(callback) {
         if(this.btnViewEl){
            this.btnViewEl.addEventListener('click', callback)
         }else{
            console.warn('SETLISTENERCHANGEVIEW el - ', this.btnViewEl)
         }
    },
    setListenerForCopyBtn(callback){
        if(this.btnCopyEl){
            this.btnCopyEl.addEventListener('click', callback)
         }else{
            console.warn('SETLISTENERFORCOPYBTN el - ', this.btnCopyEl)
         }
    }
}

function createLinkToGoogleMaps(latLng) {
    // http://maps.google.com/maps?q=24.197611,120.780512 //example
    return `https://maps.google.com/maps?q=${latLng.latitude},${latLng.longitude}`
}

const getSF = getSensors()

function VehicleSensors(props) {
    const { multiSensorValues, realSensorValues, logicalInputStatuses } = props;
    const {
        getRealSensorsValues,
        getMultiSensorValues,
        getLogicalInputsStatuses
    } = getSF;
    return  <Fragment>

                {getRealSensorsValues(realSensorValues)}
                {getMultiSensorValues(multiSensorValues)}
                {getLogicalInputsStatuses(logicalInputStatuses)}
            </Fragment>
}

function getSensors() {
        function getRealSensorsValues(realSensorValues) {
            return  SL.arrayIsEmpty(realSensorValues) ?
                    realSensorValues.map(key => { //key.visible ?
                        const sensorInfo = <p key = {key.sensorNumber}><b>{key.name}</b>: {SL.roundValue(key.value)}<i className = 'grey'>{key.units}</i></p>
                        return key.visible ? sensorInfo : null
                    }) :
                    []
        }
        function getMultiSensorValues(multiSensorValues) {
            return  SL.arrayIsEmpty(multiSensorValues) ?
                    multiSensorValues.map(key => {
                        const sensorInfo = <p key = {key.name}><b>{key.name}</b>: {SL.roundValue(key.value)}<i className = 'grey'>{key.units}</i></p>
                        return key.visible ? sensorInfo : null
                    }) :
                    []
        }
        function getLogicalInputsStatuses(logicalInputStatuses) {
            return  SL.arrayIsEmpty(logicalInputStatuses) ?
                    logicalInputStatuses.map(key => {
                        return <p key = {key.inputNumber}><b>{key.inputName}</b>: <i className = 'grey'>{key.statusName}</i></p>
                    }) :
                    []
        }
        return {
            getRealSensorsValues: getRealSensorsValues,
            getMultiSensorValues: getMultiSensorValues,
            getLogicalInputsStatuses: getLogicalInputsStatuses,
        }
}

function getRequiredInfo(props){
    let {state, src, vehicle} = props;
    console.log("%c state", coCSS, state)
    const deviceMappings = SL.ifNoKey(vehicle, 'deviceMappings')
    return {
                vehiclesDataGpsDataDto: SL.ifNoKey(state, 'vehiclesDataGpsDataDto'),
                imgStyle:               !!src?'visible':'hidden',
                deviceInfo:             SL.arrayIsEmpty(deviceMappings) ? deviceMappings[0] : null,
                timestamp:              SL.ifNoKey(state, 'timestamp') || SL.ifNoKey(state, 'lastKnownTime'),
                multiSensorValues:      SL.ifNoKey(state, 'multiSensorValues'),
                realSensorValues:       SL.ifNoKey(state, 'realSensorValues'),
                deviceSettingsDto:      SL.ifNoKey(state, 'deviceSettingsDto'),
                multiSensorValues:      SL.ifNoKey(state, 'multiSensorValues'),
                realSensorValues:       SL.ifNoKey(state, 'realSensorValues'),
    }
}

function getAntStatusText(status) {
    let content = '--';
    if (SL.ifNoKey(status, 'antConnected')) {
        content = crutchForNiceView(language_variant.antena_connected)
    }
    if (SL.ifNoKey(status, 'antConnected') == false) {
        content = crutchForNiceView(language_variant.antenna_was_disabled)
    }
    if (SL.ifNoKey(status, 'shortMode')) {
        content = crutchForNiceView(language_variant.DEVICE_SHORT_MODE_ON)
    }

    return content
}

function crutchForNiceView(string) {//too lazy to write a new translation in vocabulary
    const newString  = string.split(/[\t\s]/).map((str, index) =>   <Fragment key = {index}>
                                                                        {
                                                                            index == 0 ?
                                                                            <Fragment><b>{str}:</b></Fragment> :
                                                                            ` ${str}`
                                                                        }
                                                                    </Fragment>)
    return newString
}

function AntennaStatus(props){
    let {status, vehicleId} = props;
    return  <p>{getAntStatusText(status)} </p>
}

function AntennaStatusHorizontalWindow(props) {
    return <p>{getAntStatusText(props.status)} <AntennaIcon {...props}/></p>
}

function Security(props){
    let {vehicleId, lockState, lockStateTime, lockedAlarm} = props; //
    let content =   language_variant.stay_on_quard_from + ' ' + moment(lockStateTime).format('DD.MM.YYYY - HH:mm:ss');

    return  lockState ?
            <p className = 'security'>
                    {securityIcon('shield', false)} {content}
            </p> :
            null
}

function SecurityAlarm(props) {
    const {lockStateTime, lockedAlarm} = props;
    const content = language_variant.was_alarm_locked + ' ' + moment(lockStateTime).format('DD.MM.YYYY - HH:mm:ss');
    return  lockedAlarm ?
            <p className = 'security'>
                    {securityIcon('shield', lockedAlarm)} {content}
            </p> :
            null
}

function SosIcon(props){
    let {timeStamp, status, vehicleId}  = props;
    let content  = language_variant.alarmDrawdown + ' ' + moment(timeStamp).format('DD.MM.YYYY - HH:mm:ss');
    return  !!status
            ?<div className = 'sosIcon'>
                                {sosIcon('SOS_ALARM')} {content}
            </div>
            :null
}

function LinkToRoute(props) {
    let {name, licensePlate, vehicleId} = props;
        name = !!name?name:'%20';
        licensePlate = !!licensePlate?licensePlate:'%20';
    return  <a  href={`/#/routeDetail/${vehicleId}/${name}/${licensePlate}`}
                className = "info_window_btn_route_link" >
                {iconRouteDetailLink}
                <div className = 'tooltip_on_top'>
                    {language_variant.showroute}
                </div>
            </a>
            /*{language_variant.showroute}*/
}

function LinkToCoordinatesForDefWindow(props) {
    const {vehiclesDataGpsDataDto} = props;
    return  vehiclesDataGpsDataDto ?
            <Fragment>
                <button id = 'get_google_link'
                        className = "info_window_btn_copy_link">
                        {linkIcon}

                        <div id = 'copy_notif_text' className = 'tooltip_on_top'>
                            {language_variant.copy_coordinates}
                        </div>
                </button>
                {/*<div id = 'copy_notif_text' className = 'hiden_text_copyed show_text_copyed'>
                    {language_variant.link_copied_to_clipboard}
                </div>*/}
            </Fragment> :
            null
}

function LinkToCoordinates(props) {
    const {vehiclesDataGpsDataDto} = props;
    const [copied, setCopied] = useState(false);
    function onClickCopy() {
        SL.setValueToBoofer(createLinkToGoogleMaps(vehiclesDataGpsDataDto));
        setCopied(true)
    }
    return  vehiclesDataGpsDataDto ?
            <Fragment>
                <button id = 'get_google_link'
                        className = "info_window_btn_copy_link"
                        onClick = {onClickCopy}>
                        {linkIcon}

                        <div id = 'copy_notif_text' className = 'tooltip_on_top'>
                            {copied ? language_variant.link_copied_to_clipboard : language_variant.copy_coordinates}
                        </div>
                </button>
                {/*<div id = 'copy_notif_text' className = 'hiden_text_copyed show_text_copyed'>
                    {language_variant.link_copied_to_clipboard}
                </div>*/}
            </Fragment> :
            null
}

function LastDataTimeText(props) {
    const { lastDataDate, shortView }  = props;
    return  <DateView   label_text = {language_variant.TIME_OF_GETTING_LAST_DATA_DEVICE_LAST_DATA_COLUMN}
                        date = {lastDataDate}
                        shortView = {shortView} />
}

function UpdateTrailerStatusTime(props) {
    const { registerTime, shortView, connected_status, type }  = props;
    const label_text =  type == 'TRAILER_CONNECTED' ?
                        language_variant.connection_date :
                        language_variant.disconection_date;

    return  <DateView   label_text = { label_text }
                        date = {registerTime}
                        shortView = {shortView} />
}

function DateView(props) {
    const {label_text, date, shortView} = props;
    const date_text = date ?  moment(date).format('DD.MM.YYYY - HH:mm:ss') : '--';
    return  shortView ?
            <div className = 'with_tooltip date_view_in_window'>
                { date_text }
                <div className = 'tooltip_on_top'>
                    { label_text }
                </div>
            </div> :
            <p > <b>{label_text}</b>: { date_text } </p>
}

function createParts6Length(arr) {
    let groupedArray = [];
    const totalLength = arr.length;
    if(totalLength > 6){
        groupedArray.push(arr.splice(0, 7));
        groupedArray.push(arr);
    }else{
        groupedArray.push(arr)
    }
    return groupedArray
}
