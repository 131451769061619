import { createStore as _createStore, applyMiddleware }     from 'redux'
import baseReducers                                     from 'reducers'
import Registry                                         from './registry'
import registryMiddleware                               from './registry/middleware'
import {createLogger}                                   from 'redux-logger'

const logger = createLogger({
        predicate: (getState, action) => (
                                        process.env.NODE_ENV !== 'production' &&
                                        false && //disable logger
                                        action.type !== "NOTIF" &&
                                        action.type !== "HOVER" &&
                                        action.type !== "LOCATION" &&
                                        action.type !== "ZONE_TYPES" &&
                                        action.type !== "GEOFENCES_LIST" &&

                                        action.type !== "GET_NEWDATA" &&
                                        // action.type !== "GET_transport" &&
                                        action.type !== "DEFUELLING_START" &&
                                        action.type !== "FUELLING_START" &&
                                        action.type !== "SOS_ALARM" &&
                                        action.type !== "WEBSOCKET" &&
                                        // action.type !== 'UPDATE_TRANSPORT_LIST' &&
                                        action.type !== 'ONMESSAGE' &&
                                        action.type !== "GOTO" &&
                                        action.type !== "MOVEUP" &&
                                        // action.type !== "LANGUAGE_UPDATE" &&
                                        action.type !== "VEHICLE_GROUPS" &&
                                        action.type !== "VEHICLE_PARKING_END" &&
                                        action.type !== "COUNT" &&
                                        action.type !== "SET_REPORT_TYPES" &&
                                        action.type !== "VEHICLE_PARKING_START" &&
                                        action.type !== "VEHICLE_SENSOR_CONTROL" &&
                                        action.type !== "VEHICLE_OVER_SPEED_START" &&
                                        action.type !== "SET_GEOFENCE" &&
                                        action.type !== "VEHICLE_STOP_END" &&
                                        action.type !== "VEHICLE_STOP_START")

});
// const logger = createLogger({
//         predicate: (getState, action) => (action.type !== "NOTIF" &&
//                                         action.type !== "HOVER" &&
//                                         action.type !== "LOCATION" &&
//                                         // action.type !== "GET_NEWDATA" &&
//                                         action.type !== "WEBSOCKET" &&
//                                         action.type !== "GET_NEWDATA" &&
//                                         action.type !== 'UPDATE_TRANSPORT_LIST' &&
//                                         action.type !== 'ONMESSAGE' &&
//                                         action.type !== "GOTO" &&
//                                         action.type !== "MOVEUP" &&
//                                         action.type !== "LANGUAGE_UPDATE" &&
//                                         action.type !== "COUNT" &&
//                                         action.type !== "SET_REPORT_TYPES" &&
//                                         action.type !== "VEHICLE_PARKING" &&
//                                         action.type !== "VEHICLE_SENSOR_CONTROL" &&
//                                         action.type !== "VEHICLE_OVER_SPEED_START" &&
//                                         action.type !== "SET_GEOFENCE" &&
//                                         action.type !== "VEHICLE_STOP_END" &&
//                                         action.type !== "VEHICLE_STOP_START")

// });

 function createStore(initialState = {}){
    const registry = new Registry(baseReducers)
    // let finalCreateStore = applyMiddleware(registryMiddleware(registry))
    let finalCreateStore = DEV_SERVER?applyMiddleware(registryMiddleware(registry), logger):applyMiddleware(registryMiddleware(registry))
    // if we have redux devtools installed hook into it.
    if(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        finalCreateStore = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(finalCreateStore)
    }
    const store = finalCreateStore(_createStore)(
        registry.initialReducers,
        initialState
    )
    registry.store = store
    return store
}
const store = createStore();
export default store
// export default createStore(reducer, /* preloadedState, */ composeEnhancers(
//   applyMiddleware(...middleware),
//   // other store enhancers if any
// ));


