import React                    from 'react'
import language_variant         from 'store/languages'
import ReactDOM                 from 'react-dom';
import SL               		from 'sl-library'
import { sendMessageToChannel } from 'components/ErrorBoundary.jsx'
import { close_icon }           from 'images/simple_svg_icons.jsx'
import 'css/pop_up.scss'

const modalRoot = document.getElementById('modal-root');

export default class PopUpWindow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: !!this.props.openAlways ? true : false,
		}
	}
	close = (e) => {
		const { onClose } = this.props;
		if(onClose) onClose()
		this.setState({ active: false })
	}
	reload = () => this.forceUpdate()
	open = () => this.setState({ active: true })
	render() {
		const { active } = this.state;
		const { popUp_size, dataName } = this.props;
		return active ?
			<Modal dataName = { dataName }>
					<div className = { this.props.class }>
						<div className = "pop_mask" onClick = { this.close }> </div>
						<div className = "pop_up" style = { popUp_size }>
							<div className = "pop_header" style = { this.props.head_back } >
							<span className = 'pop_up_header_text'>{ this.props.head_text} </span> {this.props.lower_case_text_head_text}
							<button className = "btn_close close"
									onClick =   { this.close } >
							  	{ close_icon }
							</button>
							</div>
							<div className = 'popup_content'>
							{ this.props.children }
							</div>
						</div>
					</div>
				</Modal> :
			null
	}
}


export class Modal extends React.Component {
	constructor(props) {
		super(props);
		this.el = document.createElement('div');
		this.el.setAttribute('data-modal', this.props.dataName);
		if(this.props.className) this.el.className = this.props.className;
	}
	componentDidMount() {
		// The portal element is inserted in the DOM tree after
		// the Modal's children are mounted, meaning that children
		// will be mounted on a detached DOM node. If a child
		// component requires to be attached to the DOM tree
		// immediately when mounted, for example to measure a
		// DOM node, or uses 'autoFocus' in a descendant, add
		// state to Modal and only render the children when Modal
		// is inserted in the DOM tree.
		modalRoot.appendChild(this.el);
		if(this.props.getChild) {
			this.props.getChild(this.el)
		}
	}
	componentWillUnmount() {
		try {
			if(document.body.contains(modalRoot) && document.body.contains(this.el)) modalRoot ? modalRoot.removeChild(this.el): console.warn('Modal modalRoot', modalRoot);
		} catch (e) {
			console.error(e)
			sendMessageToChannel("it's debug message(sent automatically) - MODAL removeChild", this.props.dataName + e.toString())
			alert(language_variant.something_went_wrong + ', ' + language_variant.we_already_work_with_fix)
		}
	}
	render() {
		return ReactDOM.createPortal(
			this.props.children,
			this.el,
		);
	}
}
