import store 		from 'store/store.js'
import infoStore 	from './infoStore.js'
import {ifNoKey} 	from 'sl-library'
const eventAction  = {
	updateStatusFilter: updateStatusFilter,
}
export default eventAction;

function updateStatusFilter(type, settingsFilter, showEvents) {
	let filterName = getSettingsName(type);
	store.dispatch({
		type: 						type,
		UpdateFilterSettings: 	settingsFilter,
		setShowEvents: 			calcShowEvents(showEvents, filterName)
	})
}
function getSettingsName(type){
	switch(type){
		case 'VEHICLE_PARKING_START':
			return 'settingsParkingFilter'
		break;
		case 'FUELLING_START':
			return 'settingsFuellingFilter'
		break;
		case 'DEFUELLING_START':
			return 'settingsDefuelingFilter'
		break;
		case 'VEHICLE_OVER_SPEED_START':
			return 'settingsOverspeedFilter'
		break;
		default:
			return 'coincided_nothing_vs_type'
	}
}
function calcShowEvents(showEvents, filterName){
	//if events absent fiter is disabled, but on first event in vehicle list we enable filter
	if(showEvents == undefined && !!filterName){
		let filterSettings = infoStore('globalR.eventsR.'+filterName);
		let filterIsDisabled = ifNoKey(filterSettings, 'disabled');
		if(filterIsDisabled){
			showEvents = true;
		}else{
			let show_filterName = filterName.replace('settings', 'show');
			let currentStatusShowEvents = infoStore('globalR.eventsR.'+show_filterName)
			showEvents = currentStatusShowEvents;
		}
	}
	return showEvents
}