import store                           from 'store/store.js'
import {ifNoKey, arrayIsEmpty, copyOfObject}    from 'sl-library'
import infoStore                       from './infoStore.js'
import _                            from 'lodash'
const WebsocketActions = {
	saveWebSocket: 	    saveWebSocket,
	clearWebSocket: 	clearWebSocket,
   checkWebSocket:     checkWebSocket,
}

window.WebsocketActions = WebsocketActions;
export default WebsocketActions;


function saveWebSocket(socket) {
   window.webSocket = socket;
}

function checkWebSocket(socket) {
   return window.webSocket
}
function clearWebSocket(socket){
    window.clearIntervalsReconect ? window.clearIntervalsReconect() : null;
    window.webSocket?window.webSocket.close():null;
    window.webSocket = null;
}









