const trailerInfoWindow = {
    status: 'Статус',
    connected: 'підключений',
    disconnected: 'відключений',
    connection_date: 'Дата підключення',
    disconection_date: 'Дата відключення',
};

const routeWords = {
    ...trailerInfoWindow,
    copy_coordinates:                          'Скопіювати координати',
    coordinates_copied:                        'Координати скопійовано',

    vehicle:                                   'Авто',
    current_counter_value:                     'Лічильник зараз',
    text_about_validation_counter_values:      'Початкове значення має бути меншим за поріг спрацьовування',
    status_will_update_after:                  "Статус оновиться після виходу пристрою на зв'язок",

    fromDateL:                                  'c',
    toDateL:                                    'по',
    or_create_new:                             'або створіть нову',

    disabled_receiving_data:                   'Для трекера вимкнено прийом даних',
    triggers_are_absent:                       'Тригери відсутні',
    total_distance:                            'Загальна відстань',
    message_about_gps_veracity:                "Координати GPS  не є актуальними!",
    ON:                                        'ON',
    OFF:                                       'OFF',
    enter_route_name:                          'Введіть назву маршруту',
    task_routes:                               'Маршрути завдань',
    are_youre_sure_want_remove_route:          'Ви впевнені, що хочете видалити маршрут?',
    shedule_route:                             'Розклад маршруту',
    pass_order:                                'Порядок проходження',
    regarding_activation:                      'Відносно активації',
    relative_to_day:                           'Відносно доби',
    absolute_time:                             'Абсолютний час',
    consistently:                              'Послідовно',
    arbitrarily:                               'Довільно',
    with_passes:                               'З перепустками',
    active_tasks:                              'Активні завдання',
    complete_the_task:                         'Завершити завдання?',
    details:                                   'Деталі',
    cancel_job:                                'Скасувати завдання',
    latest_data:                               'Останні дані',
    completed_tasks:                           'Завершені завдання',
    job_detail:                                'Деталі завдання',
    quest_start:                               'Початок завдання',
    quest_end:                                 'Кінець завдання',
    activate_task:                             'Активувати завдання',
    sheduled_for:                              'Заплановано на',
    sheduled:                                  'Заплановано',
    sheduled_job:                              'Заплановані завдання',
    start_task:                                'Почати виконання завдання?',
    list_of_sheduled_jobs_is_empty:            'список запланованих завдань порожній',
    route_detail:                              'Деталі маршруту',
    route_name:                                'Назва маршруту',
    path_order_route_points:                   'Порядок проходження точок маршруту',
    update_route:                              'Оновити маршрут',
    save_route:                                'Зберегти маршрут',
    complete:                                  'Закінчити',
    remove_route_point:                        'Видалити точку маршруту',
    show_detail:                               'Показати подробиці',
    hide_detail:                               'Приховати подробиці',
    permissible_deviation_from_arrival_time:   'Припустиме відхилення від часу прибуття',
    permissible_deviation_from_departure_time: 'Припустиме відхилення від часу відправлення',
    save_point:                                'Зберегти точку',
    location_not_selected:                     'Місце не вибрано',
    are_you_sure_want_delete_job:              'Ви впевнені, що хочете видалити завдання?',
    select_performer:                          'Вибрати виконавця',
    performer:                                 'Виконавець(чи)',
    no_one_is_doing_the_job:                   'Завдання ніхто не виконує',
    vehicles_that_parfom_task:                 'Авто які виконують завдання',
    vehicles_with_task:                        'Авто із завданням',
    available_vehicles:                        'Доступні авто',
    one_off:                                   'одноразове',
    daily:                                     'щоденне',
    Sunday:                                    'Неділя',
    Monday:                                    'Понеділок',
    Понеділок:                                 'Вівторок',
    Wednesday:                                 'Середа',
    Thursday:                                  'Четвер',
    Friday:                                    `П'ятниця`,
    Saturday:                                  'Субота',
    editing_task:                              'Редагування завдання',
    create_task:                               'Створення завдання',
    name_of_task:                              'Назва завдання',
    task_type:                                 'Тип завдання',
    task_description:                          'Опис завдання',
    update_task:                               'Оновити завдання',
    save_task:                                 'Зберегти завдання',
    task_start_date:                           'Дата початку завдання',
    task_end_date:                             'Дата кінця завдання',
    tasks_days:                                'Дні завдання',
    select_every_day:                          'Вибрати щодня',
    enter_route_name:                          'Введіть назву маршрут'
};

const globalVocabulary = {
    sensors:                                            "Датчики",

    DETAILED_SPEEDING:                                  'Детально',
    land_cadastre:                                      'Кадастр геометрія ділянок',
    land_cadastre_index:                                'Кадастр індексна мапа',
    attention:                                          'Увага',
    you_donT_have_enough_rights_to_do:                  'Бракує прав, щоб виконати це!',

    logic_inputs:   'Логічні входи',
    by_number:      'По номеру',
    multisensor:    'Мультисенсор',
    cabinet:                                    'Кабінет',

    standart_view: 'Стандартний режим',
    for_print: 'Для друку',
    power_restored_text:                                                 'Підключення бортмережі відновлено',
    data_not_available:                                                  'Дані недоступні',
    new_notifications_absent:                                            'Немає нових повідомленнь',
    clear_blocking_date:                                                 'Очистити дату блокування',
    add:                                                                 'Додати',
    update_blocking_date:                                                'Оновити дату блокування',
    blocking_date:                                                       'Дата блокування',
    blocking:                                                            'Блокування',
    blocked:                                                             'Заблоковано',
    infographic:                                                         'Інфографіка',
    complete_vehicles_list:                                              'Повний список транспорту',
    brief_vehicles_list:                                                 'Короткий список транспорту',
    sorting:                                                             'Сортування',
    by_name:                                                             'За назвою',
    by_license_plate:                                                    'За номером авто',
    by_traker_number:                                                    'За номером трекера',
    vehicle_whith_parking:                                               'Авто з парковками',
    vehicle_whith_fuelling:                                              'Авто із заправками',
    vehicle_whith_defuelling:                                            'Авто з можливим зливом палива',
    vehicle_whith_speeding:                                              'Авто з перевищеннями',
    vehicle_online:                                                      'Авто онлайн',
    vehicle_offline:                                                     'Авто офлайн',
    show_only:                                                           'Показати тільки',
    interface_and_application:                                           'Інтерфейс та додаток',
    player:                                                              'Програвач',
    no_groups:                                                           'нема груп',
    on_calculation:                                                      'Включити розрахунки',
    off_calculation:                                                     'Вимкнути розрахунки',
    trailer_registration:                                                'Робота причепа',
    RFID_registration:                                                   'Робота RFID карти',
    card:                                                                'Карта',
    RFID_cards:                                                          'RFID карти',
    additionalColumns:                                                   'Додаткові стовпці',
    message_to_many:                                                     'Ви кілька разів поспіль ввели неправильний пароль. очікуйте ..',
    weak:                                                                'слабкий',
    okay:                                                                'нормальний',
    good:                                                                'хороший',
    strong:                                                              'сильний',
    restore_pass:                                                        'Відновити пароль',
    remembered_you_pass:                                                 'Згадали пароль?',
    entered_passwords_dont_match:                                        'Введені паролі не збігаються',
    password_update_failed:                                              'Оновити пароль не вийшло',
    password_recovery:                                                   'Відновлення паролю',
    zone_width_must_be:                                                  'Ширина зони повинна бути більше 0м',
    not_set_IN:                                                          'Не призначений логічний вхід "Причіп" для роботи звіту',
    FROM:                                                                'з',
    radius:                                                              'Радiус',
    editVehicles:                                                        'Редагувати авто',
    seeTasks:                                                            'Бачити завдання',
    select_zone_type:                                                    'Виберіть тип зони',
    error_on_set_unlock:                                                 'При знятті з охорони сталася помилка',
    error_on_set_lock:                                                   'При постановці на охорону сталася помилка',
    rotation_in_hour:                                                    'о/г',
    'r/min':                                                             'о/хв',
    rotation_crankshaft_amount:                                          'Кількість обертів колінвала',
    crankshaft_amount_rotation:                                          'Кількість обертів колінвала',
    link_to_report:                                                      'Посилання на звіт',
    enter_gmail_email_for_export:                                        'Введіть email Gmail аккаунта для експорту файлу',
    recieved:                                                            'Отримано',
    total:                                                               'Усього',
    data_processing_completed:                                           'Обробка даних завершена',
    request_processing_in_progress:                                      'Йде обробка запиту',
    geofence_color:                                                      'Колір геозони',
    zone_width_in_m:                                                     'Ширина зони в метрах',
    move_to_group:                                                       'Перемістити до групи',
    notes_name:                                                          'Назва замітки',
    notes_text:                                                          'Текст замітки',
    zone_types:                                                          'Типи зон',
    select_zone_types:                                                   'Виберіть типи зон',
    filter_by_zone_types:                                                'Фільтр по типам зон',
    create_geophence_from_route:                                         'Створити геозону з маршруту',
    speeding:                                                            'Перевищення швидкості',
    connection_restored:                                                 'З’єднання відновлено',
    try_recovery_connection:                                             "Пробуємо відновити з'єднання. Перевірте інтернет з'єднання",
    waypoints:                                                          'Точки маршруту',
    INCLUDE_FIRST_SHIFT:                                                 'Перша змiна',
    INCLUDE_SECOND_SHIFT:                                                'Друга змiна',
    start_of_period:                                                     'Початок перiоду',
    end_of_period:                                                       'Кiнець перiоду',
    get_data:                                                            'Отримати данi',
    offName:                                                             'Вимкнутий',
    onName:                                                              'Увiмкнутий',
    notifyDeviceInControl:                                               'Спрацьовування логічних входів',
    name_new:                                                            'Назва',
    width:                                                               'Ширина',
    filter_notifications:                                                'Фільтрувати повідомлення',
    to_new:                                                              'До',
    from_new:                                                            'Вiд',
    same_fuel_data_time:                                                 'Cклеювати заправки менш',
    notes_by_vehicle:                                                    'Нотатки по авто',
    create_geozone:                                                      'Створити геозону',
    update_geozone:                                                      'Оновити геозону',
    creating_geozone_from_route:                                         'Створення геозон на основі маршруту',
    save_geozone:                                                        'Зберегти геозону',
    along_the_track:                                                     'Уздовж треку',
    squares_from_route:                                                  'Площі з маршруту',
    name_for_geozone:                                                    'Назва для геозони',
    fill_name_of_geozone:                                                'Заповнiть назву геозони',
    rename:                                                              'Перейменувати',
    add_to_group:                                                        'Додати в групу',
    parameters:                                                          'Параметри',
    variables:                                                           'Змінні',
    set_control_zone:                                                    'Встановити контрольну зону',
    get_route:                                                           'Запросити маршрут',
    import:                                                              'Імпортувати',
    select_archive_zip_kmz:                                              'Виберіть архів zip/kmz або kml файл',
    problems_when_generation_report:                                     'Під час створення звіту виникли проблеми',
    for_period:                                                          'За перiод',
    password_was_updated:                                                'Пароль було оновлено',
    save_password:                                                       'Зберегти пароль',
    from_beginning_of_month:                                             'З початку мicяця',
    edit_rfid_cards:                                                   'Редагувати RFID карти',
    user_can:                                                            'Пользователь может',
    edit_user:                                                           'Редагування користувача',
    create_user:                                                         'Створення користувача',
    enter_user_login:                                                    'Введiть логiн користувача',
    users_management_head:                                               'Керування користувачами',
    from:                                                                'з',
    count_lines:                                                         'Кiлькicть строк',
    trailer_type:                                                        'Тип причепу',
    transmit_period_night_in_seconds:                                    'Період передачі нічний в С',
    transmit_period_in_seconds:                                          'Період передачі денний в С',
    trailers_width:                                                      'Ширина причепа в метрах',
    serial_number_of_trailer:                                            'Серійний номер причепа',
    name_of_trailer:                                                     'Назва причепу',
    editing_trailer:                                                     'Редагування причепа',
    adding_trailer:                                                      'Додавання причепа',
    trailers_managment:                                                  'Керування причепами',
    enter_name_type_or_serial:                                           "Введіть ім'я причепа, серійний номер або тип",
    get_fuel_data:                                                       'Бачити дані про паливо',
    see_charts:                                                          'Бачити графіки',
    switch_device_outputs_state:                                         'Перемикання керуючого входу',
    set_lock_mode:                                                       'Встановити режим охорони',
    unset_alarm_flag:                                                    'Зняття флагу тривоги',
    edit_zones:                                                          'Редагувати зони та звiти',
    seeRoutes:                                                           'Бачити маршрути та причепи',
    see_reports:                                                         'Бачити звіти',
    see_current_vehicle_position:                                        'Бачити поточний стан машин',
    save_user_rights:                                                    'Зберегти права користувача',
    no_data_allowed_for_user:                                            'Немає даних про дозволенi права для користувача',
    prohibited_vehicles_of_user:                                         'Заборонений транспорт користувача',
    allowed_vehicles_of_user:                                            'Дозволений транспорт користувача',
    trailers:                                                            'Причепи',
    instruction_for_setup:                                               'Iнструкція для встановлення',
    open_telegram:                                                       'Вiдкрити телеграм',
    vehicleId:                                                           'ID Авто',
    limit_sapmle_time:                                                   'Обмежити час вибірки',
    message_for_user:                                                    'Повiдомлення користувачу',
    save_description:                                                    'Зберегти опис',
    save_message:                                                        'Зберегти повiдомлення',
    allowed_vehicles:                                                    'Дозволений транспорт',
    prohibited_vehicles:                                                 'Заборонений транспорт',
    description:                                                         'Опис',
    confirm_password:                                                    'Пiдтвердження паролю',
    users_management:                                                    'Користувачi',
    select:                                                              'Обрати',
    selected:                                                            'Обрано ',
    import:                                                              'Iмпорт',
    smoothed_data:                                                       'Згладжені данi',
    no_data_or_equipment_width:                                          'Немає даних за вибраний період або не встановлена ширина навісного обладнання',
    all_zone_types:                                                      'Всi типи',
    type:                                                                'Тип',
    comment_text:                                                        'Текст коментаря',
    comments_for:                                                        'Коментарi для ',
    are_your_sure_want_del_comment:                                      'Ви впевнені, що хочете видалити коментар?',
    edit_comment:                                                        'Редагування коментаря ',
    create_comment:                                                      'Створення коментаря',
    add_geofence:                                                        'Додати геозону',
    withOutGroups:                                                       'Без груп',
    raw_data:                                                            "RAW данi",
    detect_parking_time_hint:                                            'Тайм-аут розмежування зупинки / парковки з увімкненим запалюванням ',
    detect_parking_time:                                                 'Парковка з увімкненим запалюванням, через',
    nothing_selected:                                                    ' - нічого не вибрано',
    filter:                                                              "Фільтр",
    all_groups:                                                          'Всi групи',
    tracker:                                                             'Трекер',
    in_system:                                                           'У системi',
    request_was_send_answer_in_notif:                                    'Запит був відправлений, відповідь на запит прийде в повідомлення (правий верхній кут, дзвіночок)',
    ussd_send:                                                           'Отправка ussd',
    arming:                                              "Постановка на охорону",
    remove_notif_about_triggering:                                       'Прибрати повідомлення про тривогу',
    only_from_line_all:                                                  'Тільки з рядка "Всього"',
    all_data:                                                            'Усi данi',
    clear_selected:                                                      'Очиcтити обране',
    Fuel_sensor_is_not_install:                                          'Датчик палива не встановлений',
    ignitionOn:                                                          "Запалювання",
    stopVehicle:                                                         'Зупинка',
    period1_hint:                                                        'Період передачі при русі транспорту',
    period2_hint:                                                        'Період передачі при стоянці транспорту',
    ATTACHED_EQUIPMENT_WIDTH_hint:                                       "Ширина навісного обладнання на транспорті, для розрахунку площі обробки.",
    maximum_speed_hint:                                                  "Швидкість транспорту, вище якої коливання палива однозначно не розглядаються як заправки",
    maximum_duration_hint:                                               "Параметр для усереднення сплесків палива",
    minimal_stop_time_hint:                                              "Максимальний час для визначення при зупинці - стрибок палива або заправка",
    maximum_allowed_speed_hint:                                          "Для інформування про перевищення швидкості в повідомленні і звітах",
    m:                                                                   "м",
    vehicle_width:                                                       "Ширина авто",
    ATTACHED_EQUIPMENT_WIDTH:                                            "Ширина навiсного обладнання",
    distance_in_zone_no_less:                                            "Пробіг у зоні не менше, км",
    login:                                                               'Логін',
    DAILY_TRANSPORT_ACCOUNT:                                             'Обліковий по транспорту',
    Yes:                                                                 'Так',
    No:                                                                  'Нi',
    server_temporarily_unavailable:                                      'Сервер тимчасово недоступний',
    admin_panel:                                                         'Адмiн панель',
    take_off_notif_about_sos:                                            'Прибрати повідомлення про SOS',
    isNotifySosSignal:                                                   'Сигнал SOS',
    hide_geofences:                                                      'Сховати геозони',
    show_geofence:                                                       'Показати геозони',
    labels:                                                              'Назви',
    STOPS_REPORT:                                                        "По зупинкам",
    SUMMARY_FUEL_REPORT:                                                 "По рiвню палива",
    DAILY_FUEL_REPORT:                                                   "По рiвню палива по дням",
    PARKING_WITH_IGNITION_ON_DURATION_REPORT:                            "Час зупинки з увiмкненим запалюванням",
    ZONE_SQUARES_REPORT:                                                 "Площi зон",
    ZONE_SPEEDING_REPORT:                                                "Перевищення швидкостi по типа зон",
    SPEEDING_REPORT:                                                     "Перевищення швидкостi",
    MILEAGE_BY_ZONE_TYPES_REPORT:                                        "Кiлометраж по типам зон",
    MOVEMENT_BY_DAY_REPORT:                                              'Рух по дням',
    MILEAGE_BY_ZONES_REPORT:                                             'Кілометраж по зонам',
    DRIVERS_WORK_WITH_VEHICLES:                                          'Робота водіїв на транспортних засобах',
    TRAILERS_WORK_WITH_VEHICLES:                                         'Робота причепів на транспортних засобах',
    TIME_OF_GETTING_LAST_DATA:                                           'Час отримання останніх даних',
    DEVICES_ACTIVITY:                                                    'Активність пристроїв',
    ZONE_PASSAGE:                                                        'Проїзд зон',
    DEVICES_IN_ZONES_NUMBER:                                             'Кiлькicть пристроїв у зонах',
    STOPS_IN_ZONES:                                                      'Зупинки у зонах',
    STOPS_OUT_OF_ZONES:                                                  'Зупинки поза зонами',
    USSD_REPORT:                                                         'USSD звіт',
    WORK_TIME_STOPS_IN_ZONES_OUT_OF_ZONES_TIME:                          'Робочій час та стоянки у зонах і поза зонами',
    DAILY_FLOW_METER_REPORT:                                             'Вимірювач витрати палива по дням',
    CHANGING_LOGICAL_INPUTS:                                             'Зміни логічних вводів',
    USER_CONNECTION_HISTORY:                                             'Історія підключень користувачів',
    LOCK_EVENT_REPORT:                                                   'Режим охорони',
    LIVE_REPORT:                                                         'Живий звіт',
    ACCELEROMETER_REPORT:                                                'Спрацювання датчика прискорення',
    PROCESSED_AREA_REPORT:                                               'Площа обробки',
    licensePlate:                                                        'Номер авто',
    absentDataFromSomeSensors:                                           "Вiдсутнi данi вiд деяких датчиків",
    openInNewTab:                                                        "Вiдкрити посилання у новiй вкладцi",
    cantSaveForThisAccount:                                              'Не виходi зберегти звiт для цього акаунту',
    saveToGoogleDisk:                                                    "Зберегти на гугл диск",
    notifySensorControl:                                                 "Повiдомлення контролю сенсорів",
    isNotifyLogicalInputEngineHoursCounter:                              "Лічільники мотогодин по логічному входу",
    isNotifyEntryZone:                                                   "В'їзд в контрольну зону",
    isNotifyExitZone:                                                    "Виїзд з контрольної зони",
    during:                                                              ' протягом ',
    counter_events_term_over:                                            'Кiлькicть подій - "Перевищення температури"',
    counter_events_speed_over:                                           'Кiлькicть подій - "Перевищення швидкостi"',
    counter_events_voltage:                                              'Кiлькicть подій - "Стрибок напруги"',
    counter_events_fuelling:                                             'Кiлькicть подій - "Заправка"',
    counter_events_defuelling:                                           'Кiлькicть подій - "Можливий злив палива',
    counter_events_parking:                                              'Кiлькicть подій - "Парковка"',
    no_coordinates:                                                      "Координати пристрою вiдсутнi",
    status_was_updated:                                                  "Оновлено",
    antena_connected:                                                    "Антена ON",
    antena_info_absent:                                                  "Нема даних про статус антени",
    antena_info_absent_check_device:                                     "Нема даних по стану антени, перевiрте присутність пристрою на авто",
    antenna_was_disabled:                                                "Антена OFF",
    credibility:                                                         "Достовірність",
    table_SIGNALING_ALARM:                                               "Тривога у режимi охорони!",
    SOS_ALARM:                                                           "ТРИВОГА!",
    on_full:                                                             "Увiмкнено",
    off_full:                                                            "Вимкнено",
    POWER_RESTORED:                                                      "Живлення вiдновлено",
    DEVICE_ANT_ON:                                                       "Антена ON",
    DEVICE_ANT_OFF:                                                      "Антена OFF",
    DEVICE_SHORT_MODE_ON:                                                "Антена закорочена",
    DEVICE_SHORT_MODE_OFF:                                               'Антена увiмкнена, не замкнута',
    passed:                                                              'пройшло',
    stop:                                                                'Стоп',
    start:                                                               'Старт',
    POWER_RESTORED_START:                                                "Живлення зникло",
    POWER_RESTORED_END:                                                  "Живлення вiдновлено",
    EXIT_CONTROL_ZONE:                                                   "Виїзд з контрольної зони",
    ENTRY_CONTROL_ZONE:                                                  "В'їзд до контрольної зони",
    EngineHours_by_ignite:                                               'Мотогодини за запалюванням',
    LOGICAL_INPUT_ENGINE_HOURS_COUNTER:                                  'Мотогодини за логічним входом',
    MOTOHOURS_CAN:                                                       'Мотогодини CAN',
    enable_table:                                                        'Увiмкнути вигляд таблицi',
    enable_chart:                                                        'Увiмкнути вигляд графiка',
    pure_data:                                                           'Чистi данi',
    calculation:                                                         'Розрахунки',
    driver:                                                              'Водiй',
    undefined:                                                           'Невiдомо',
    fuel_consumed:                                                       'Cпожито палива',
    fuel_consumed_on_hundred:                                            'На 100 км',
    fuel_consumption_in_hour:                                            'За годину',
    pure:                                                                'чисте',
    parking_duration:                                                    'Тривалість стоянки ',
    parking_duration_table:                                                    'Стоянка ',
    fuelled:                                                             'Залито палива ',
    defuelled:                                                           'Злито палива ',
    antenna_was_disabled:                                                'Антена була вимкнена - ',
    antenna_was_short_mode:                                              'Антену було закорочено  - ',
    today:                                                               "Cьогодні",
    yesterday:                                                           "Вчора",
    last_seven_day:                                                      "Останнi сiм днiв",
    set_own:                                                             "Задати свій...",
    saved:                                                               'Збережений',
    fuel_sensor_not_installed:                                           'Датчик рiвню палива не вставлено',
    PROCESSED_AREA_REPORT:                                               'Площа обработки',
    'km/h':                                                              'км/г',
    ACCELEROMETER_REPORT:                                                "Спрацювання датчика прискорення",
    LIVE_REPORT:                                                         "Живий звiт",
    managing_keys:                                                       'Управлiння ключами',
    get_link_for_Report:                                                 'Отримати посилання для звiту',
    LOCK_EVENT_REPORT:                                                   "Режим охорони",
    CHANGING_LOGICAL_INPUTS:                                             "Змiна логiчних входiв",
    USER_CONNECTION_HISTORY:                                             "Iсторiя пiдключень користувачiв",
    LONGER_THEN:                                                         "Тривалість бiльше нiж (у сек.)",
    GEO_CODING:                                                          "Геокодування",
    HUNDRED_MILES:                                                       "Розрахувати расход на 100 миль",
    DAILY_FLOW_METER_REPORT:                                             "Расходомiр палива по дням",
    TASK_START:                                                          "Початок завдання",
    TASK_END:                                                            "Кінець завдання",
    FUELLING:                                                      "Заправка палива",
    FUELLING_START:                                                      "Заправка палива",
    FUELLING_END:                                                        "Заправка палива",
    DEFUELLING:                                                    "Злив палива",
    DEFUELLING_START:                                                    "Злив палива",
    DEFUELLING_END:                                                      "Злив палива",
    CAUTION_EVENTS:                                                      "Важливі події",
    INFO_EVENTS:                                                         "Інформаційні події",
    VEHICLE_OVER_SPEED:                                            "Перевищення швидкостi",
    VEHICLE_OVER_SPEED_START:                                            "Перевищення швидкостi",
    VEHICLE_OVER_SPEED_END:                                              "Перевищення швидкостi",
    VEHICLE_PARKING:                                               "Парковка з увiмкненим запалюванням",
    VEHICLE_PARKING_START:                                               "Парковка з увiмкненим запалюванням",
    VEHICLE_PARKING_END:                                                 "Парковка з увiмкненим запалюванням",
    WORK_TIME_STOPS_IN_ZONES_OUT_OF_ZONES_TIME:                          'Робочiй час та стоянки у зонах та поза зонами',
    vehicleDidntMoveDonthaveCoordinatesForGeozone:                       "Авто не рухалось, нема координат для геозони",
    onThisVehicleSensorAbsent:                                           'На цьому авто датчик видсутнiй',
    someProblem:                                                         'Маємо проблему',
    ZONE_PASSAGE:                                                        'Проїзд зон',
    DEVICES_IN_ZONES_NUMBER:                                             'Кількість пристроїв в зонах',
    STOPS_IN_ZONES:                                                      'Зупинки в зонах',
    STOPS_OUT_OF_ZONES:                                                  'Зупинки поза зонами',
    hide_notif:                                                          'Сховати це повідомлення',
    reload_page:                                                         'Перезавантажити сторiнку',
    send_info:                                                           'Вiдправити информацiю',
    please_send_error:                                                   'Будь ласка, вiдправте повідомлення про помилку й спопробуйте перезавантажити сторiнку',
    something_went_wrong:                                                'Щось пiшло не так',
    we_already_work_with_fix:                                            'ми вже працюємо над цим',
    notif_about_enter:                                                   "Повідомити про в'їзд в зону",
    notif_about_exit:                                                    'Повідомити про виїзд із зони',
    control_in_zones:                                                    'Контроль у зонах',
    enter_template_name:                                                 'Введiть назву шаблону',
    task:                                                                'завдання',
    minimal_fuelling_volume:                                             "Мiнiмальний Об`єм заправки",
    minimal_defuelling_volume:                                           "Мiнiмальний Об`єм зливу",
    rename_zone_type:                                                    "Перейменування типа зон",
    add_zone_type:                                                       "Додати тип зони",
    enterZonTypeName:                                                    "Введить им'я типа",
    save_zoneType:                                                       "Зберегти тип зони",
    add_type:                                                            "Додати тип",
    handling:                                                            "Керування",
    control_of_sensor:                                                   "Контроль датчиків",
    DEVICES_ACTIVITY:                                                    "Активність приладiв",
    TIME_OF_GETTING_LAST_DATA:                                           "Час отримання останніх даних",
    TRAILERS_WORK_WITH_VEHICLES:                                         "Работа прицепів по машинам",
    DRIVERS_WORK_WITH_VEHICLES:                                          "Робота водіїв по машинам",
    DAILY_TRAFFIC_COLUMN_START_MOTION:                                   "Початок руху",
    DAILY_TRAFFIC_COLUMN_END_MOTION:                                     "Кінець руху",
    DAILY_TRAFFIC_COLUMN_MILEAGE:                                        "Загальний пробіг",
    DAILY_TRAFFIC_COLUMN_TIME_IN_WAY:                                    "Час у дорозі",
    DAILY_TRAFFIC_COLUMN_NO_GPS_DATA:                                    "Геодані відсутні",
    DAILY_TRAFFIC_COLUMN_NO_POWER:                                       "Не було живлення",
    DAILY_TRAFFIC_COLUMN_MAXIMAL_SPEED:                                  "Максимальна швидкість",
    DAILY_FUEL_COLUMN_CONSUMPTION_AMOUNT:                                "Кількість спожитого палива",
    DAILY_TRAFFIC_COLUMN_AVERAGE_SPEED:                                  "Середня швидкість",
    ZONE_REPORT_COLUMN_MILEAGE_IN_ZONES:                                 "Пробіг в зонах",
    ZONE_REPORT_COLUMN_FUELED_CONSUMED_IN_ZONES:                         "Витрачено палива в зонах",
    ZONE_REPORT_COLUMN_MILEAGE_IN_ZONE_TYPES:                            "Пробіг по типам зони",
    ZONE_REPORT_COLUMN_FUELED_CONSUMED_IN_ZONE_TYPES:                    "Кількість спожитого палива по типам зони",
    SPEEDING_AMOUNT_REPORT_COLUMN:                                       "Кількість перевищення швидкості",
    ZONE_REPORT_COLUMN_STOPS_IN_ZONES_DURATION:                          "Тривалість зупинки в зоні",
    DRIVERS_WORK_SERIAL_ID_COLUMN:                                       "Серійний номер брелка",
    DRIVERS_WORK_START_COLUMN:                                           "Час початку роботи",
    DRIVERS_WORK_STOP_COLUMN:                                            "Час закінчення роботи",
    DRIVERS_WORK_DURATION_COLUMN:                                        "Час роботи водія",
    TRAILERS_WORK_DURATION_COLUMN:                                       "Час роботи причепа",
    DEVICE_ACTIVITY_LOGICAL_INPUT_1:                                     "ДАНІ ПО ЛОГІЧНОМУ ВХОДУ 1",
    DEVICE_ACTIVITY_LOGICAL_INPUT_2:                                     "ДАНІ ПО ЛОГІЧНОМУ ВХОДУ 2",
    DEVICE_ACTIVITY_LOGICAL_INPUT_3:                                     "ДАНІ ПО ЛОГІЧНОМУ ВХОДУ 3",
    DEVICE_ACTIVITY_LOGICAL_INPUT_4:                                     "ДАНІ ПО ЛОГІЧНОМУ ВХОДУ 4",
    DEVICE_ACTIVITY_LOGICAL_INPUT_5:                                     "ДАНІ ПО ЛОГІЧНОМУ ВХОДУ 5",
    DEVICE_ACTIVITY_LOGICAL_INPUT_6:                                     "ДАНІ ПО ЛОГІЧНОМУ ВХОДУ 6",
    DEVICE_ACTIVITY_LOGICAL_INPUT_7:                                     "ДАНІ ПО ЛОГІЧНОМУ ВХОДУ 7",
    DEVICE_ACTIVITY_LOGICAL_INPUT_8:                                     "ДАНІ ПО ЛОГІЧНОМУ ВХОДУ 8",
    MOVEMENT_BY_DAY_REPORT:                                              "Рух по дням",
    MILEAGE_BY_ZONES_REPORT:                                             "Кiлометраж по зонам",
    digitalInputs:                                                       "Цифровi входи",
    fuel_level:                                                          "Рiвень палива",
    tank:                                                                "Бак",
    UssdRequestForDevice:                                                "Вiдповiдь на ussd запит:",
    send:                                                                "Надiслати",
    login_placeholder:                                                   "Логiн",
    password_placeholder:                                                "Пароль",
    errorauthorization:                                                  "Помилка у логiнi або паролi",
    enterToSystem:                                                       "Вхiд до системи",
    interfaceLanguage:                                                   "Мова интерфейсу",
    profile:                                                             "Профiль",
    button_send:                                                         "Увiйти",
    control:                                                             "Контроль",
    tasks:                                                               "Завдання",
    report:                                                              "Звiт",
    personal:                                                            "Співробітники",
    help:                                                                "Допомога",
    placeholder_search:                                                  "Пошук ...",
    today:                                                               "Сьогоднi",
    show:                                                                "Вiдображати:",
    group:                                                               "Група",
    groups:                                                              "Групи",
    allTransport:                                                        "Весь транспорт",
    transport_search_placeholder:                                        "Номер чи марка транспорту",
    errorconnection:                                                     "Проблеми з'єднання з сервером...",
    status_inmidcourse:                                                  "В дорозi",
    parking:                                                             "Cтоїть",
    on:                                                                  "Увiмкн",
    off:                                                                 "Вимкн",
    kmH:                                                                 "км/г",
    min:                                                                 "хв",
    engine:                                                              "Запалювання",
    speed:                                                               "Швидкicть",
    showroute:                                                           "Показати маршрут",
    givetask:                                                            "Дати завдання",
    MARKER_ADMIN_WORDS:                                                  "____________________________________",
    clients:                                                             "Клієнти",
    users:                                                               "Користувачі",
    devices:                                                             "Пристрої",
    admins:                                                              "Адміністратори",
    sensors_type:                                                        "Типи датчиків",
    exit:                                                                "Вихiд",
    clients_list:                                                        "Список клi",
    admin_list:                                                          "Список адмiнiстраторiв",
    transports:                                                          "Транспорт",
    nodata:                                                              "Дані відсутні",
    name_group:                                                          "Введiть назву групи",
    groupEdit:                                                           "Редагування групи",
    createGroup:                                                         "Створити групу",
    cancel:                                                              "Відмінити",
    remove_group:                                                        "Видалити групу",
    save:                                                                "Зберегти",
    selectVehicles:                                                      "Оберiть транспортнi засоби",
    select_vehicle:                                                      "Оберiть транспортний засiб",
    give_task:                                                           "Дати завдання",
    active:                                                              "Активнi",
    planed:                                                              "Запланованi",
    competed:                                                            "Завершенi",
    draft:                                                               "Чорнетки",
    templates:                                                           "Шаблони",
    template:                                                            "Шаблон",
    suitable_for_assignments:                                            "Пiдходить для задач, що повторюються",
    geozones_and_addresses:                                              "Адреси та геозони",
    apply:                                                               "Використати",
    anytime:                                                             "любий час",
    task_type:                                                           "Тип завдання",
    route:                                                               "Маршрут",
    date_and_time:                                                       "Дата та час",
    beginning_way:                                                       "Початок шляху",
    no_data:                                                             "Данi вiдсутнi ",
    may_be_trouble_internet:                                             "Можливо виникли проблеми з пiдключенням до iнтернету. Спробуйте перезавантажити сторiнку",
    choose_vehicle:                                                      "Оберiть ТЗ",
    one_time_assignment:                                                 "One time assignment",
    addPoint:                                                            "Додати мicце",
    enter_adress_or_geofence:                                            "Введiть адресу або геозону",
    date_and_time:                                                       "Дата та час",
    yesterday:                                                           "Вчора",
    last_seven_day:                                                      "Останнi сiм днiв",
    set_own:                                                             "Встановити свiй...",
    fuel:                                                                "Паливо",
    voltage:                                                             "Напруга",
    speed:                                                               "Швидкiсть",
    input:                                                               "Ввiд",
    support_contacts:                                                    "Служба пiдтримки:", //"Є питання? Служба пiдтримки:",
    stay_in_course:                                                      "GPS МОНІТОРИНГ | КОНТРОЛЬ ПАЛИВА | ЕФЕКТИВНИЙ БІЗНЕС",
    notifications:                                                       "Повiдомлення",
    password:                                                            "Пароль",
    recieved_sms_notif:                                                  "Отримувати SMS повiдомлення",
    recieved_email_notif:                                                "Отримувати повiдомлення на пошту",
    recieved_telegram_notif:                                             "Отримувати повiдомлення в телеграм",
    adress:                                                              "адреса",
    name:                                                                "Iм'я",
    setting:                                                             "Налаштування",
    mobile_phone_number:                                                 "Номер мобiльного телефону",
    user_name:                                                           "Iм'я користувача",
    current_pass:                                                        "Поточний пароль",
    new_pass:                                                            "Новий пароль",
    change:                                                              "Змiнити",
    here_you_can_change_pass:                                            "Тут ви можете змiнити ваш пароль для входу до системи.",
    in_system:                                                           "Всерединi системи",
    on_email:                                                            "На пошту",
    sms_notif:                                                           "SMS-повiдомлення",
    telegram_notif:                                                      "Telegram",
    signal:                                                              "Сигнал",
    more:                                                                "більше",
    fueling:                                                             "Заправки",
    maybe_discharge:                                                     "Можливi зливи палива",
    consumption_average:                                                 "Споживання палива на 100км вище середнього",
    lack_signal:                                                         "Вiдсутнiсть сигналу від авто",
    event_in_way:                                                        "Подiї на маршрутi",
    stop_vs_engine_on:                                                   "Зупинки з увiмкненим двигуном",
    over_speed:                                                          "Перевищення швидкостi",
    dangerous_temper:                                                    "Небезпечне пiдвищення температури двигуна",
    dangerous_voltage:                                                   "Небезпечне пiдвищення напруги у борт-мережi",
    start_task:                                                          "Початок завдання",
    end_task:                                                            "Завершення завдання",
    deviation_route:                                                     "Відхилення від маршруту",
    choose_events_what_you_want_receive_on_email:                        "Оберiть події, про якi бажаєте отримувати повідомлення свою електронну адресу.",
    to_receive:                                                          "Щоб отримувати повідомлення на свій номер телефону, будь ласка, ",
    indicate_your_number:                                                "вкажіть свій номер ",
    and_allow:                                                           "і дозвольте SMS повідомлення",
    settings:                                                            "Налаштування",
    show_all:                                                            "Показати усе ",
    tuning_notif:                                                        "Налаштування повiдомлень",
    select_event_if_you_want_receive:                                    "Оберiть події, про якi бажаєте отримувати повідомлення",
    add_photo:                                                           "Додати фото",
    change_photo:                                                        "Змiнити фото",
    profile_vehicle:                                                     "Профiль транспортного засобу",
    parameters:                                                          "Параметри",
    counters:                                                            "Лiчильники",
    current_level_fuel:                                                  "Поточний рiвень палива",
    rate_of_fuel:                                                        "Норма витрати палива (на 100км)",
    l:                                                                   "л",
    L:                                                                   "Л",
    voltage_board_network:                                               "Напруга борт-мережi",
    current_voltage:                                                     "Поточна напруга",
    normal_voltage:                                                      "Нормальна напруга",
    v:                                                                   "В",
    motor_temperature:                                                   "Температура двигуна",
    current_temperature:                                                 "Поточна температура",
    normal_temperature:                                                  "Нормальна температура",
    other_parameters:                                                    "Iншi параметри",
    mileage:                                                             "Пробiг",
    km:                                                                  "км",
    carrying:                                                            "Грузопiд'ємнiсть",
    tons:                                                                "тонн",
    fuel_tank_volume:                                                    "Об'єм баку",
    change_of_oil:                                                       "Замiна масла",
    disable_counter:                                                     "Вимкнути лiчильник",
    enable_counter:                                                      "Увiмкнути лiчильник",
    reset_counter:                                                       "Скинути лiчильник",
    next_maintenance:                                                    "Наступне ТО",
    change_rubber:                                                       "Замiна резини",
    here_will_be_employee:                                               "Тут буде вiдображатися профiль спiвробiтника.",
    please_select_employe:                                               "Бужь ласка, оберiть спiвробiтника зi списку злiва або",
    add_new_one:                                                         "додайте нового",
    contacts:                                                            "Контакти",
    work_time:                                                           "Робочiй час",
    vehicles:                                                            "Транспортнi засоби",
    violations:                                                          "Порушення",
    h:                                                                   "г",
    date:                                                                "дата",
    timestamp:                                                           "дата",
    location:                                                            "Мiсцеположення",
    new_employee:                                                        "Новий спiвробiтник",
    photo_helps:                                                         "Фото допоможе вам швидко знайти потрiбну людину",
    enter_first_name:                                                    "Введить iм`я користувача",
    enter_last_name:                                                     "Введить прiзвище користувача",
    first_name:                                                          "Iм`я",
    last_name:                                                           "Прiзвище",
    add_another_phone:                                                   "Додати ще один номер телефону",
    create_employee:                                                     "Створити спiвробiтника",
    add_employee:                                                        "Додати спiвробiтника",
    are_you_sure:                                                        "Ви впевненi, що хочете видалити ",
    this_employee:                                                       "цього працiвника",
    remove:                                                              "Видалити",
    deleting:                                                            "Видалення",
    edit_employee_profile:                                               "Редагування профiлю спiвробiтника",
    update_data:                                                         "Оновоти данi",
    report_type:                                                         "Тип звiту",
    here_will_be_report:                                                 "Тут буде вiдображено ваш звiт.",
    create_report_on_sidebar:                                            "Створiть звiт, використовуючi меню злiва. В процесi роботи ви зможете оновлювати параметри звiту в реальному часi",
    create_own_report:                                                   "Створити свiй звiт",
    name_licenceplate_or_group:                                          "Назва, номер авто чи група ТЗ",
    update_report:                                                       "Оновити звiт",
    start_date_must_be_early:                                            "Дата початку має бути ранiше дати кiнця",
    report_parameters:                                                   "Параметри звiту",
    display_order:                                                       "Порядок вiдображення",
    choose_enter_parameters:                                             "Введіть назву та виберіть параметри, які ви хочете включити у вашi звіти",
    report_name:                                                         "Назва звiту",
    start_motion:                                                        "Початок руху",
    end_motion:                                                          "Кiнец руху",
    time_in_way:                                                         "Час у дорозi",
    mileage:                                                             "Пробiг ",
    mileage_counter:                                                     "Лiчильник пробiгу",
    max_speed:                                                           "Максимальна швидкiсть",
    average_speed:                                                       "Середня швидкiсть ",
    spent_fuel:                                                          "Потрачено палива",
    average_spent_fuel:                                                  "Среднi витрати палива на 100 км",
    average_fuel_consumption_per_hour:                                   "Среднi витрати палива за 1 годину",
    time_parking:                                                        "Час зупинки",
    time_parking_ignition_on:                                            "Час зупинки з уключеним запалюванням",
    time_parking_ignition_off:                                           "Час стоянки с виключеним запалюванням",
    time_stop_in_geo:                                                    "Час стоянки в геозонi",
    time_of_entry_in_geo:                                                "Час в`їзду до геозонi",
    time_of_exit_in_geo:                                                 "Час виїзду з геозони",
    spent_fuel_in_geo:                                                   "Витрачено палива в геозонi",
    fuel_consumtion_in_geo:                                              "Витрати палива в геозонi",
    distance_about_prev_entering:                                        "Вiдстань вiд минулого в`їзду",
    distance_traveled:                                                   "Пройдений шлях",
    geofence:                                                            "Геозони",
    stop_parking:                                                        "Зупинки/Стоянки",
    check_order_display:                                                 "Перевiрте порядок вiдображення параметрiв в табличцi и помiняйте колонки мicцями, якщо потрiбно.",
    over_cursor_of_interest:                                             "Наведiть курсор на цiкавлячу вас колонку та перетягнiть її в iнше мiсце",
    create_report:                                                       "Створити звiт",
    pull_to_move:                                                        "Тягнiть для перемiщення",
    your_report_was_created:                                             "Ваш звiт було створено!",
    now_you_can_choose:                                                  "Теперь ви можете обрати його у полi  ",
    type_of_report:                                                      "“Тип звiту”",
    save_pdf:                                                            "Зберегти в PDF",
    print:                                                               "Друк",
    save_xlsx:                                                           "Зберегти в XLSX",
    save_csv:                                                            "Зберегти в CSV",
    no_data_for_period:                                                  "Немає данних за обраний перiод",
    try_other_period:                                                    "Спробуйте обрати iншi дати.",
    whole_period:                                                        "For the whole period",
    period:                                                              "Перiод",
    time_in_point:                                                       "Час у точках",
    optional:                                                            "опцiйно",
    repeatings:                                                          "Повторюючийся",
    date_of_startTask:                                                   (window.outerWidth >= 375) ? "Дата початку завдання" : "Дата початку",
    no:                                                                  "немає",
    before:                                                              "до",
    task_created_succesfull:                                             "Завдання створено успiшно",
    orienting_resource_consumption:                                      "Орieнтовнi витрати ресурсiв",
    without_taking_in_geo:                                               "без обліку роботи в геозонах та стоянки на точках",
    duration_of_route:                                                   "Довжина маршруту",
    fuel_consumption:                                                    "Витрати палива",
    duration:                                                            "Тривалiсть",
    edit:                                                                "Редагувати",
    adresses:                                                            "Адреса",
    geofences:                                                           "Геозони",
    search_by_addresses:                                                 "Пошук по адресам",
    search_by_geofences:                                                 "Пошук по геозонам",
    you_dont_have_saved_addresses:                                       "У вас доки ще немає збережених адрес.",
    add_often_used_addres:                                               "Додавайте часто використовуванi адреси, за допомогою меню праворуч.",
    you_dont_have_saved_geofences:                                       "У вас доки ще немає збережених геозон.",
    add_geofences_by:                                                    "Додавайте геозони, за допомогою меню праворуч.",
    add_address:                                                         "Додати адресу",
    add_geofence:                                                        "Додати геозону",
    new_addres:                                                          "Нова адреса",
    new_geo:                                                             "Нова геозона",
    enter_address:                                                       "Введiть адресу",
    denotation:                                                          'Назва',
    this_address:                                                        'цю адресу',
    this_geofence:                                                       'цю геозону',
    addres_or_object:                                                    "Можно знайти адресу або об'єкт",
    circle:                                                              "Окружнiсть",
    polygon:                                                             "Багатокутник",
    color:                                                               "Колiр",
    default:                                                             "Звичайна",
    city:                                                                "Мiсто",
    cornfield:                                                           "Поле",
    type_geofence:                                                       "Тип геозони",
    search_by_templates:                                                 "Пошук по шаблонами",
    you_dont_have_saved_templates:                                       "У вас немає збережених шаблонiв",
    add_templates:                                                       'Додайте шаблон використвуючi кнопку "Дати завдання"',
    hide:                                                                "Сховати",
    show:                                                                "Показати",
    points:                                                              {
    one:                                                                 "точку",
    i:                                                                   "точки",
    ek:                                                                  "точок",
    },
    decline_min:                                                         {
    one:                                                                 "мiн.",
    i:                                                                   "мiн.",
    ek:                                                                  "мiн.",
    },
    decline_hour:                                                        {
    one:                                                                 "година",
    i:                                                                   "годин",
    ek:                                                                  'година',
    },
    copying:                                                             "Копiювання",
    sure_copy_template:                                                  "Ви дiйсно бажаєте скопiювати цей шаблон?",
    copy:                                                                'Копiювати',
    repeat:                                                              "Повторення",
    every_day:                                                           "Кожного дня",
    every_week:                                                          "Кожну недiлю",
    every_month:                                                         "Кожного мiсяця",
    on_weekdays:                                                         "В буднi днi",
    update_name:                                                         "Оновити назву",
    no_active_tasks:                                                     "Hема активних завдань.",
    no_planned_tasks:                                                    "Hема запланованих завдань.",
    no_completed_tasks:                                                  "Hема закiнченних завдань.",
    create_task_for_vehicles:                                            "Створюйте завдання для зручного контролю своїх транспортних засобiв.",
    template_was_created:                                                "Шаблон успiшно створено!",
    template_was_edited:                                                 "Шаблон успiшно вiдредаговано!",
    column_is_required:                                                  "Стовбець є обов'язковим i не може бути видаленим чi перемiщеним",
    transport_search:                                                    "пошук траспорт",
    for_your_request_nodata:                                             "По вашому запиту немає даних",
    choose_geofence_or:                                                  'Оберiть геозону або',
    create_new:                                                          'Створiть нову',
    forgot_pass:                                                         "Забули пароль?",
    help:                                                                "Допомога",
    nothing_choosed:                                                     "Нiчого не обрано",
    date_of_violation:                                                   "Дата порушення",
    enter_address:                                                       "Введiть адресу",
    description_of_violation:                                            "Опис порушення",
    save_violation:                                                      "Зберегти порушення",
    adding_violation:                                                    "Додавання порушення",
    been_blocked:                                                        "Ваш акаунт заблоковано",
    find_reason:                                                         "Звертайтесь:",
    or_email:                                                            "або за email:",
    introduced:                                                          "Ознайомлений",
    noGpsPeriod:                                                         "немає gps даних",
    noPowerPeriod:                                                       "немає живлення",
    "daily-traffic":                                                     "Рух по дням",
    "logical-inputs":                                                    "Логiчнi виводи",
    "stops-report":                                                      "Звiт по зупинкам",
    save_and_contiue:                                                    "Зберегти та продовжити",
    allotted_show_place:                                                 "Видiлену область буде вiдображено на мicцi аватарки",
    "daily-fuel-level":                                                  "Рiвень палива по дням",
    "daily_consumption_hundred_km":                                      "Витрати пального на 100 км",
    "daily_fuel_consumption":                                            "Витрачено палива",
    "fuel_level_end":                                                    "Рiвень палива на кiнець доби",
    "fuel_level_start":                                                  "Рiвень палива на початок доби",
    "daily_ignition_on_duration":                                        "Час з увiмкненим запалюванням",
    "daily_consumption_hour":                                            "Витрати палива за годину",
    "fuelled_at_day":                                                    "Всього заправлено",
    "de_fuelled_at_day":                                                 "Всього можливо злито",
    "mileage_at_day":                                                    "Видстань за добу",
    "summary_de_fuelled":                                                "Можливо злито палива",
    "summary_fuel_spent":                                                "Загальна кiлькiсть витраченого палива",
    "summary_fuelled":                                                   "Загальна кiлькiсть залитого палива",
    "summary_mileage":                                                   "Загальна пройдена вiдстань",
    "ignition_on_duration":                                              "Загальний час з увiмкненим запалюванням",
    delta:                                                               "Длительность",
    dist:                                                                "Дистанцiя вiд минулої зупинки",
    time_begin:                                                          "початок",
    time_end:                                                            "кiнец",
    arrival_time:                                                        "Час прибуття",
    departure_time:                                                      "Час вiдправлення",
    complete_prev_step:                                                  "Заповнiть попереднiй етап",
    fuel_property_speed:                                                 "Максимальна швидкість руху при визначенні зливів і заправок",
    fuel_property_min_volume:                                            "Мінімальний обсяг заправки і зливу",
    fuel_property_duration:                                              "Пропускати скачки менш ніж секунд",
    fuel_property_tank_volume:                                           "об`єм бака",
    fuel_property_allowed_fluctuation:                                   "Допустимі коливання палива (на перший час поки так)",
    fuel_property_minimal_mileage:                                       "мінімальна відстань проїзду (поки так)",
    fuel_property_minimal_stop_time:                                     "мінімальний час зупинки (при якому це не буде вважатися подією стоянки)",
    fuel_property_average_fuel_consumption:                              "середнє споживання палива",
    no_valid_name:                                                       'Дозволяються кириличнi та латинськi символи, не менш нiж два',
    validationResult:                                                    'Заповнiть усi поля позначенi *',
    no_valid_telegram:                                                   'Ваш логiн не пройшов перевiрку',
    no_valid_email:                                                      'Ваша поштова адреса не пройшла перевiрку',
    no_valid_phone_number:                                               'Номер має скадатися з 12 цифр (мiжнародний формат), наприклад +380582458919',
    textTelegramSettings:                                                "Щоб отримувати повідомлення на свiй телеграм вкажіть його логiн та дозволье повідомлення.",
    youCanhere:                                                          "Можете зробити це тут.",
    choose_notif:                                                        "Оберiть повідомлення якi хочете отримувати",
    no_events:                                                           "немає подiй",
    edit_geo:                                                            'Редагування геозони',
    admonition:                                                          "Попередження",
    not_complete_edit:                                                   "Ви не завершили редагування геозони",
    continue:                                                            'Продовжити',
    resume_edit:                                                         'Видновити редагування',
    "zones-passage":                                                     'Перетин ТС зон',
    Parking:                                                             "Парковка",
    "zones-device-count":                                                'Кiлькicть приладiв у зонах',
    "zones-passage":                                                     'Перетин транспортним засобом зон',
    "stops-in-zones":                                                    'Зупинки у зонах',
    "stops-without-zones":                                               'Зупинки не в зонах',
    "zones-stops-and-work":                                              'Час работи i зупинки в зонах та не в зонах',
    "mileage-by-zone-types":                                             'Пробiг по типам зон',
    prev_cur_zone_entry_duration:                                        'Час поза зонами',
    zone_entry_time:                                                     "Час в'їзду до зони",
    zone_exit_time:                                                      'Час виїзду з зони',
    zone_name:                                                           "Им'я зони",
    "exit-out-of-zones":                                                 "Виїзд iз зон",
    movement_start:                                                      'Початок руху',
    movement_stop:                                                       'Кiнець руху',
    time_of_working:                                                     'Час роботи',
    time_of_stops_in_zones:                                              'Час зупинок у зонах',
    time_of_stops_without_zones:                                         'Час зупинок не в зонах',
    serial_numbers:                                                      'Серiйнi номери',
    summary_duration_out_zones:                                          'Общiй час у зонах',
    summary_mileage_out_zones:                                           'Общiй пробiг поза за зонами',
    daily_mileage:                                                       'Добовий пробiг',
    daily_zone_type_fuel_consumption:                                    'Добовi витрати палива по типам зон',
    daily_zone_type_mileage:                                             'Добовий пробiг по типам зон',
    zone_type:                                                           'Тип зони',
    vehicle_name:                                                        "Iм'я машини",
    zone_exits_number:                                                   'Кiлькicть виходiв iз зон',
    zones_vehicle_was_in:                                                'Зони де був транспорт',
    vehicle_zone_exit_count:                                             'Кiлькicть зо по транспортним засобам',
    days_number:                                                         'Кiлькicть днiв без виїзду',
    zones_with_vehicles:                                                 'Зони з транспортом',
    error_autorization:                                                  'Будь ласка перевiрте свої логiн/пароль',
    stop_address:                                                        "Адреса зупинки",
    longitude:                                                           "Довгота",
    latitude:                                                            "Широта",
    zone_titles:                                                         "Назва зон",
    zone_ids:                                                            "Id зон",
    sound_signal:                                                        "Звук сигналу",
    didnt_have_vehicle:                                                  "У вас немає транспортних засобiв",
    youDidntsetOldPass:                                                  "Ви не вказали старий пароль",
    passwords_do_not_match:                                              "Паролi не спiвпадають",
    password_must_be:                                                    "Пароль повинен містити мінімум 3 цифри 5 букв, в загальному 8 символів",
    newPassMustBe:                                                       "Новий пароль повинен мінімум 3 цифри 5 букв, в загальному 8 символів",
    yourPassWasUpdated:                                                  "Ваш пароль успiшно оновлено",
    checkYourOldPass:                                                    "Перевiрте старий пароль",
    horizontal_orientation_unavailable:                                       "Горизонтальна орієнтація недоступна",
    available_options:                                                   "Доступнi опцiї",
    "daily-fuel":                                                        "Звiт по паливу",
    "zone-report":                                                       "Звiт по зонам",
    no_gps_data:                                                         "GPS Відсутній",
    liters:                                                              "Л",
    maximal_speed:                                                       "Максимальна швидкість",
    no_power:                                                            "Немає джерела живлення",
    consumption_amount:                                                  "Кількість споживання палива",
    fuelling_amount:                                                     "Кількість заправленого палива",
    consumption_per_hundred_km:                                          "Споживання на 100 км",
    stops_in_zones_duration:                                             "Тривалiсть зупинки в зонi",
    level_at_start:                                                      "Рiвень палива на начало",
    level_at_end:                                                        "Рiвень палива на кiнець",
    defuelling_amount:                                                   "Злив палива",
    stops_without_zones_duration:                                        "Длительность остановок за пределами зон",
    fueling:                                                             "Заправка",
    defueling:                                                           "Можливо злив",
    new_group:                                                           'Нова група',
    you_dont_have_group:                                                 'У вас поки що немає груп.',
    create_group_for_comfort:                                            'Створiть групи транспортних засобiв для больш комфортного користування системою.',
    asign_to_other_transport:                                            "Назначити iншому транспорту",
    make_template:                                                       "Зробити шаблоном",
    print:                                                               "Роздрукувати",
    next:                                                                "Далі",
    cancel:                                                              "Відміна",
    last_time_data:                                                      "Остан. данi",
    failed:                                                              "Проваленi",
    no_failed_tasks:                                                     "Немає провалений завдань",
    after:                                                               'Пiсля',
    number_device:                                                       "Номер пристрою",
    date_setup:                                                          "Дата встановлення",
    date_disas:                                                          "Дата зняття",
    square:                                                              "Площа",
    hectares:                                                            "Га",
    road:                                                                "Шлях",
    enter_dates:                                                         "Введення дат",
    maximum_speed:                                                       "Максимальна швидкість руху для визначення заправок/зливів",
    "m/s":                                                               'М/С',
    maximum_amount:                                                      'Максимальна рiзниця',
    liter:                                                               'Л',
    maximum_duration:                                                    'Максимальний час повернення палива при стрибках',
    s:                                                                   'С',
    tank_volume:                                                         "Об'єм баку",
    fuel_allowable_fluctuation:                                          'Коливання палива',
    minimal_mileage:                                                     'Мiнiмальна вiдстань',
    minimal_stop_time:                                                   'Максимальний час стрибка палива короткочасної зупинки',
    average_fuel_consumption:                                            'Середнi витрати палива на 100км',
    maximum_allowed_speed:                                               "Максимальна дозволена швидкість",
    period1:                                                             'Передача з увiмкнутим запалюванням',
    period2:                                                             'Передача з вимкнутим запалюванням',
    "mileage-by-zones":                                                  'Километраж по зонам',
    over_speed:                                                          "Перевищення швидкостi",
    over_speed_by_zone_type:                                             "Перевищення швидкостi по типам зон",
    area_zones:                                                          "Площi зон",
    ignition_on_parking_time:                                            "Час стоянки з увiмкненим запалюванням",
    "fuel-level":                                                        "Рiвень палива",
    DAILY_FUEL_COLUMN_LEVEL_AT_START:                                    "Рівень на початок",
    FUEL_CONSUMPTION_PER_HOUR:                                           "Витрати за годину",
    DAILY_IGNITION_ON_DURATION:                                          'Час увімкненного запалювання',
    DAILY_FUEL_COLUMN_DEFUELLING_AMOUNT:                                 'Час можливого зливу',
    DAILY_FUEL_COLUMN_LEVEL_AT_END:                                      'Рівень на кінець',
    DAILY_TRAFFIC_COLUMN_MILEAGE:                                        'Відстань',
    DAILY_FUEL_COLUMN_CONSUMPTION_AMOUNT:                                'Об\'ем витраченого палива',
    DAILY_FUEL_COLUMN_CONSUMPTION_PER_HUNDRED_KM:                        'Витрати на 100 км',
    DAILY_FUEL_COLUMN_FUELLING_AMOUNT:                                   'Витрати палива',
    ZONE_REPORT_COLUMN_FUELED_CONSUMED_IN_ZONES:                         'Витрати палива у зонах',
    ZONE_REPORT_COLUMN_MILEAGE_IN_ZONES:                                 'Відстань у зонах',
    ZONE_SQUARE_REPORT_COLUMN:                                           'Площа зони',
    PARKING_WITH_IGNITION_ON_DURATION_COLUMN:                            'Тривалість стоянки з увікмненим запалюванням',
    DAILY_TRAFFIC_COLUMN_TIME_IN_WAY:                                    'Час у дорозi',
    DAILY_TRAFFIC_COLUMN_START_MOTION:                                   'Початок руху',
    DAILY_TRAFFIC_COLUMN_MAXIMAL_SPEED:                                  'Максимальна швидкість',
    DAILY_TRAFFIC_COLUMN_NO_POWER:                                       'Немає живлення',
    DAILY_TRAFFIC_COLUMN_END_MOTION:                                     'Кінець руху',
    DAILY_TRAFFIC_COLUMN_NO_GPS_DATA:                                    "Немає GPS",
    SPEEDING_AMOUNT_REPORT_COLUMN:                                       'Кількість перевищень',
    SPEEDING_DURATION_REPORT_COLUMN:                                     'Тривалість перевищення',
    SPEEDING_TIME_WITH_SPEED_REPORT_COLUMN:                              'Час з перевищенням',
    ZONE_REPORT_SPEEDING_DURATION_COLUMN:                                'Тривалість перевищення',
    ZONE_REPORT_SPEEDING_TIME_WITH_SPEED_COLUMN:                         'Час з перевищенням',
    ZONE_REPORT_SPEEDING_AMOUNT_COLUMN:                                  'Кількість перевищень',
    TimeoutForDevice:                                                    'Авто пiд охороною',
    set_on_guard:                                                        "Поставити на охорону",
    disarm:                                                              "Зняти авто з охорони",
    guard_settings:                                                      "Охорона авто",
    stay_on_quard_from:                                                  "На охоронi з",
    DAILY_FUEL_COLUMN_CONSUMPTION_AMOUNT:                                "Кількість використаного палива",
    ZONE_REPORT_COLUMN_MILEAGE_IN_ZONES:                                 "Пробіг в зонах",
    DAILY_TRAFFIC_COLUMN_MILEAGE:                                        "Загальний пробіг",
    ZONE_REPORT_COLUMN_FUELED_CONSUMED_IN_ZONES:                         "Палива використано в зонах",
    ZONE_REPORT_COLUMN_MILEAGE_IN_ZONES:                                 "Пробіг у зонах",
    DAILY_TRAFFIC_COLUMN_MILEAGE:                                        "Загальний пробіг",
    ZONE_REPORT_COLUMN_FUELED_CONSUMED_IN_ZONES:                         "Палива використано у зонах",
    SPEEDING_AMOUNT_REPORT_COLUMN:                                       "Кількість перевищень швидкости",
    SPEEDING_DURATION_REPORT_COLUMN:                                     "Тривалість перевищення швидкості",
    SPEEDING_TIME_WITH_SPEED_REPORT_COLUMN:                              "Час з перевищенням швидкості",
    ZONE_REPORT_SPEEDING_AMOUNT_COLUMN:                                  "Кількість перевищень швидкості в зонах",
    ZONE_REPORT_SPEEDING_DURATION_COLUMN:                                "Тривалість перевищення швидкості у зонах",
    ZONE_REPORT_SPEEDING_TIME_WITH_SPEED_COLUMN:                         "Час з перевищенною швидкістю у зонах",
    ZONE_SQUARE_REPORT_COLUMN:                                           "Площі зон",
    PARKING_WITH_IGNITION_ON_DURATION_COLUMN:                            "Стоянка з увімкненим запалюванням",
    DAILY_FUEL_COLUMN_LEVEL_AT_START:                                    "Рівень палива на початок дня",
    DAILY_FUEL_COLUMN_FUELLING_AMOUNT:                                   "Кількість заправленого палива",
    DAILY_FUEL_COLUMN_DEFUELLING_AMOUNT:                                 "Кількість злитого палива",
    DAILY_TRAFFIC_COLUMN_MILEAGE:                                        "Пробіг",
    DAILY_FUEL_COLUMN_CONSUMPTION_AMOUNT:                                "Кількість використаного палива",
    DAILY_FUEL_COLUMN_CONSUMPTION_PER_HUNDRED_KM:                        "Витрати на сто кiлометрiв",
    DAILY_FUEL_COLUMN_LEVEL_AT_END:                                      "Рівень палива на кінець дня",
    FUEL_CONSUMPTION_PER_HOUR:                                           "Витрата палива за годину",
    DAILY_IGNITION_ON_DURATION:                                          "Час з увікмненим запалюванням",
    CONSTANT_TIME_WHEN_FUEL_LEVEL_CHANGED:                               'Час коли мінявся рівень палива',
    CONSTANT_FUEL_LEVEL_DIFFERENCE:                                      "На скільки змінився рівень палива",
    CONSTANT_FUEL_LEVEL_BEFORE_CHANGES:                                  "Рівень до змін",
    CONSTANT_MILEAGE_AFTER_PREVIOUS_CHANGE:                              "Пройдено від минулих змін",
    CONSTANT_FUEL_CONSUMED:                                              "Витрачено палива",
    CONSTANT_CONSUMPTION_PER_HUNDRED_KM:                                 "Витрати на 100 км",
    CONSTANT_STOPS_BEGIN_COLUMN:                                         "Початок зупинки",
    CONSTANT_STOPS_DURATION_COLUMN:                                      "Тривалість зупинки",
    CONSTANT_STOPS_DISTANCE_FROM_PREVIOUS_COLUMN:                        "Відстань між зупинками",
    CONSTANT_STOPS_ADDRESS_COLUMN:                                       "Адреса зупинки",
    ZONE_REPORT_COLUMN_STOPS_IN_ZONES_DURATION:                          "Продолжительность остановок в зоне",
    CONSTANT_STOPS_CURRENT_ZONE:                                         "Обрана зона",
    CONSTANT_STOPS_SUMMARY_DISTANCE:                                     "Загальна відстань по всім зупинкам",
    DRIVERS_WORK_START_COLUMN:                                           "Час початку роботи",
    DRIVERS_WORK_STOP_COLUMN:                                            "Час кінця роботи",
    DRIVERS_WORK_DURATION_COLUMN:                                        "Час роботи водія",
    DRIVERS_WORK_SERIAL_ID_COLUMN:                                       "Серійний номер брелока",
    TRAILERS_WORK_DURATION_COLUMN:                                       "Час роботи прицепу",
    TRAILERS_WORK_SERIAL_ID_COLUMN:                                      "Серійний номер брелока",
    TRAILERS_WORK_START_COLUMN:                                          "Час початоку роботи",
    TRAILERS_WORK_STOP_COLUMN:                                           "Час кінця роботи",
    TIME_OF_GETTING_LAST_DATA_DEVICE_LAST_DATA_COLUMN:                   "Останні дані",
    TIME_OF_GETTING_LAST_DATA_DEVICE_ID_COLUMN:                          "Id пристрою",
    SIGNALING_ALARM:                                                     "Сигналiзацiя:",
    was_alarm_locked:                                                    "Вiдпрацювала сигналiзацiя, авто розблоковано",
    alarmDrawdown:                                                       "Сигнал sos спрацював",
    lockedAlarmTrigger:                                                  "Спрацювання сигналізації",
    noSignalInSecurity:                                                  "Вiдсутнiсть сигналу в режимi охорони",
    app_notif:                                                           "Повiдомлення на додаток",
    ignition_always_on:                                                  "Запалювання завжди увiмкнено",
    control_output:                                                      "Керуючий вивiд",
    no_data_parameters:                                                  "Немає даних по параметрам",
    inverse:                                                             "Iнверсiя",
    trailer:                                                             "Прицеп",
    deviceInNumber:                                                      "Номер ввода",
    notifyMileageCounter:                                                "Повiдомлення лiчильника по пробiгу",
    notifyWorkingDaysCounter:                                            "Повiдомлення лiчильника по дням",
    notifyEngineHoursCounter:                                            "Повiдомлення лiчильника по моточасам",
    isNotifyCanCounter:                                                  "Повiдомлення лiчильника моточасів по CAN",
    EngineHours:                                                         "Мотогодини",
    WorkingDays:                                                         "Лiчильник по днях",
    TEMPORAL:                                                            "З урахуванням часу",
    NOT_TEMPORAL:                                                        "Без урахування час",
    not_connected:                                                       "Не на зв'язку",
    movement:                                                            'Рух',
    parking:                                                             'Стоянка',
};

const routeDetailsPage = {
    volume:                     'об`єм',
    year:                       'рік',
    days:                       'добу',
    month:                      'міс',
    hour:                       'год',
    min:                        'мін',
    second:                     'секунд',
    consider_ignition:          'Враховувати запалювання',
    ignore_ignition:            'Не враховувати запалювання',
    haversin:                   'Хаверсін',
    filtered_fuel_events:       'Події паливного фільтра',
    do_not_smooth:              'Не згладжувати',
    do_smooth:                  'Згладжувати',
    show_pure_data:             'Показати чисті дані',
    hide_pure_data:             'Приховати чисті дані',
    show_raw_data:              'Показати RAW',
    hide_raw_data:              'Приховати RAW',
    ruler:                      'Лінійка',
    link_copied_to_clipboard:   'Посилання скопійоване в буфер обміну',
    validity:                   'Достовірність',
    Smoothed:                   'Згладжене',
    unfiltered:                 'Нефільтроване',
    unfiltered_data:            'Нефільтровані',
    only_valid_data:            'Лише валідні дані',
    table_view:                 'Режим таблиці',
    focus_on_vehicle_marker:    'Стежити за авто',
};

const transportProfile = {
    counter:           'Лічильник',

    create_counter:    'Створення лічильника',
    edit_counter:      'Редагування лічильника',
    counter_name:      'Назва лічильника',
    counter_type:      'Тип лічильника',
    start_value:       'Початкове значення',
    threshold:          'Поріг спрацьовування',
    left:              'Залишилось',
    created:           'Створено',

    are_you_sure_you_want_to_delete_the_trigger: 'Ви впевнені, що хочете видалити тригер?',
    trigger_creatting:                           'Створення тригера',
    trigger_editing:                             'Редагування тригера',
    trigger_name:                                'Назва',
    type:                                        'Тип',
    logic_input:                                 'Логічний вхід',
    condition:                                   'Умова',
    enabled:                                     'Ввімкнений',
    disabled:                                    'Вимкнений',
    available:                                   'Доступно',
    notification_text:                           'Текст повідомлення',
    more_than:                                   'More Than (Більш ніж)',
    less_than:                                   'Less Than (Меньш ніж)',
    quals:                                       'Quals (Відповідає)',
    remaining_to_work: 'До спрацювання',
    will_work_when_value: 'Спрацює при значенні датчика',
    will_work_when:         'Спрацює коли',

    creating_control_zone:  'Cтворення контрольної зони',
    editing_control_zone:   'Редагування зони',
    choose_zone:            'Виберіть зону',
};

const rfidCardsPage = {
    phone_number:                                                        'Номер телефону',
    enter_number_or_driver_name:                                         "Введіть номер або ім'я водія",
    add_card:                                                            'Додати карту',
    update_card:                                                         'Оновити карту',
    delete_card:                                                         'Видалити карту',
    add_new:                                                             'додайте нову',
    here_will_bew_card_info:                                             'Тут буде відображатися інформація про картку',
    please_select_card_or:                                               'Будь ласка, виберіть карту зі списку зліва або',
    driver_name:                                                         "Ім'я водія",
    are_you_sure_want_remove_card:                                       'Ви впевнені, що хочете видалити карту?',
    editing_rfid_card:                                                   'Редагування RFID карти',
    creating_rfid_card:                                                  'Створення RFID карти',
}

const infoGraphicControl = {
    CONNECTED: "На зв'язку",
    DISCONNECTED: 'Вимкнено',
    NO_DATA: 'Немає даних',
    NO_DEVICE: 'Немає пристрою',
    coordinates_absent: 'Координати відсутні',
    data_reception_disabled: 'Прийом даних вимкнено',
    parking_with_ignition_on: "Стоянка з увімкненим запалюванням",
    vehicle_like_auto: 'Авто',
};

const hints= {
    closePopup:                                                          "Закрийте вiкно",
    vehicle_stopped:                                                     "Транспортний засіб стоїть",
    selected_vehicle:                                                    "Транспортний засіб обрано",
    vehicle_moving:                                                      "Транспортний засіб рухається",
    vehicle_not_connected:                                               "Транспортний засіб не на зв'язку",
    marker_hint:                                                         "Маркер, що вказує положення авто та напрямок його руху.",
    clickOnMarker:                                                       "Клік по маркеру викликає вікно з інформацією по авто",
    event_what_happened_today:                                           "Події, що трапилися з авто сьогодні",
    on_hover_visible_counter:                                            "При наведенні на іконку видно кількість подій",
    parking_vs_engintOn:                                                 'Стоянки з включеним запалюванням більше 5 хвилин',
    fuelling:                                                            "Заправка палива",
    fuel_drain:                                                          "Можливий злив палива",
    vehicle_is_on_quard:                                                 "Авто поставлено на охорону",
    quard_is_triggered:                                                  "Спрацювала охорона",
    on_hover_visible_event_date:                                         "При наведенні з'явиться інформація про дату, коли відбулася подія",
    info_about_curent_status:                                            "Інформація про поточний стан",
    antena_is_ok:                                                        "З антеною все гаразд",
    end_education:                                                       "Закінчити навчання",
    antena_icons:                                                        "Інформація про те, коли були отримані останні дані",
    click_on_star:                                                       'Клік по зірочці дозволяє закріпити елемент у верхній частині списку, щоб його було легше знайти',
    click_on_one_of_list:                                                "Зробіть клік на авто зі списку (на порожнє місце)",
    desc_selected_vehicle:                                               "Тепер це авто в режимі 'вибрано', відбулося наступне:",
    if_vehicle_has_coordinates:                                          "якщо є координати пристрою - карта змістилася до маркера цього авто",
    if_veicle_move:                                                      "якщо авто рухається - то карта буде переміщатися вслід за ним",
    also_available_btns:                                                 "також стали доступні кнопки 'побудова маршруту' та 'постановка задачі'",
    click_by_this_btn_call_hint:                                         "Клік по цій кнопці викликає допомогу по елементам сторінки",
    navigation_by_vehicle_settings:                                      "Навігація по налаштуванням транспортного засобу для повідомлень і звітів",
    vehicleId_in_our_system:                                             "Id транспортного засобу в нашій системі",
    deviceId:                                                            "Серійний номер пристрою, який встановлено на транспортному засобі",
    settings_for_notif:                                                  "Базові параметри і періоди передачі",
    desc_counters:                                                       "Налаштування лічильників. Наприклад, ви можете встановити кількість пройдених мотогодин, при закінченні яких вам прийде повідомлення",
    desc_handling:                                                       "Постановка / зняття охорони, відправка USSD запитів",
    desc_digitalInputs:                                                  "Налаштування цифрових входів",
    desc_control_of_sensor:                                              "Налаштування і створення тригерів для повідомлень по показникам сенсорів. Наприклад, ви можете налаштувати наступну умову: якщо значення сенсору 'рівень палива' буде менше ніж 20, надіслати мені повідомлення наступного змісту ...",
    desc_control_in_zones:                                               "Управління повідомленнями про в'їзд/виїзд із зони",
    click_for_workWithCroup:                                             "Натисніть, щоб викликати меню роботи з групами",
    onclick_inList_left:                                                 "При кліці в списку транспорту залишиться тільки транспорт який належить до цієї групи",
    this_addNewGroup:                                                    "Натисніть, щоб створити нову групу",

    click_on_marker:                                                     'Зробіть клік на іконку, щоб приховати події, що вам не цікаві у списку транспорту. Якщо курсор має вигляд  "Відключено" -  в системі немає повідомлень цього типу',
    search_field:                                                        'Поле для пошуку транспорту зі списку',
    click_on_btn_alow:                                                   'Kлік по кнопці дозволяє приховати/показати список транспорту',
    click_btn_showRoute:                                                 'Клік по кнопці дозволяє побудувати маршрут транспортного засобу',
    click_btn_setTask:                                                   'Клік по кнопці дозволяє дати завдання транспортному засобу',
    click_nameOfVehicle:                                                 "Клікніть по iконцi, щоб викликати профіль транспортного засобу для його налаштування",
    here_you_can_look:                                                   "Тут ви можете подивитися статистику, а також налаштувати датчики на своєму авто",
    in_this_block:                                                       "В даному блоці відображаються події, які відбулися з авто за сьогоднішній день. Якщо навести курсор на значок, то можна побачити його назву",
    info_about_last_data:                                                "Інформація про те, коли останній раз приходили дані з авто",
    click_to_workWithCroup:                                              "Натисніть для роботи з групами транспорту",
    here_can_edit_name:                                                  "Тут ви можете редагувати назву групи.",
    vehicle_of_groupCheckbox:                                            "Транспорт, який належить до групи, позначений галочкою. Якщо клікнути на транспорт, він додасться до групи або виключиться з неї",
    search_by_transport:                                                 "Пошук по транспорту допоможе вам фільтрувати транспорт, який цікавить",
    button_instructions:                                                 'Клік по кнопці "Збереження" дозволяє зберегти зміни, що зроблені в групі; "Скасування" - скасує зміни; "Видалити групу" - видалить існуючу групу, при цьому транспорт з акаунту не видалиться',
    info_about_curent:                                                   "Інформація про поточний стан авто: запалювання, рівень палива та інше",
    button_view_ofMap:                                                   "Кнопки дозволяють вибрати зовнішній вигляд карти",
    marker_of_place:                                                     "Позначає місце розташування транспорту. Маркер синього кольору позначає, що ця машина активна. При наведенні на маркер з'являється підказка з назвою машини. Щоб побачити більше інформації, потрібно клікнути по маркеру",
    pressToChoose:                                                       'Натисніть, щоб вибрати тип звіту',
    also_you_can_call:                                                   "Також ви можете створити свій звіт, викликавши конструктор звітів",
    pressToChooseOnVehicle:                                              "Виберіть транспорт, за яким буде побудований звіт. Так само ви можете використовувати це поле для пошуку авто або групи",
    pressToChooseThisVehicle:                                            "Натисніть, щоб вибрати авто. Жирним шрифтом виділені авто, які вже обрані",
    forDeletePress_x:                                                    "Щоб видалити авто зі списку, потрібно натиснути на хрестик",
    date_start_of_period:                                                "Дату початку періоду ви можете ввести з клавіатури (дд.мм.рр), або натиснувши на іконку з календарем",
    timeReportFrom:                                                      "Час, з якого буде сформовано звіт, ви можете додати в поле у форматі 20:00 або вибрати зі списку",
    buttonWillActiveAfter:                                               "Кнопка стає активною, коли будуть заповнені всі необхідні поля. При натисканні на неї звіт оновлюється",
    buttonAlloswChangeSize:                                              "Клік по кнопці дозволяє змінювати розміри відображення звіту",
    buttonsForExport:                                                    "Кнопки для експорту в різні формати",
    drawNameOfReport:                                                    "Вкажіть назву для звіту - це обов'язкове поле",
    chooseFieldWhatWillInRep:                                            'Виберіть поля, які будуть відображатися в звіті',
    pressForSetting:                                                     'Натисніть, щоб перейти до налаштування порядку колонок',
    pinchMouse:                                                          'Затисніть повзунок миші та перетягуйте колонку в потрібне місце',
    pressForCreate:                                                      'Натисніть, щоб створити персональний звіт',
    holdMouseButton:                                                     'Затисніть повзунок миші та перетягуйте колонку в потрібне місце',
    clickForCreateCustomRep:                                             'Натисніть, щоб створити персональний звіт',
    clickAddEmployee:                                                    'Натисніть, щоб додати співробітника',
    listOfYourEmployee:                                                  'Список ваших співробітників',
    clickForChooseEmploye:                                               'Натисніть, щоб обрати співробітника',
    onMapMarkedCurrent:                                                  'На карті вказано поточне місце розташування співробітника',
    clickOnElement:                                                      'Клік по цьому елементу відкриє можливість відредагувати поточну інформацію',
    clickOnElementAlowDelete:                                            'Клік по елементу дозволить видалити співробітника',
    here_can_change_period:                                              'Тут можна змінити період, за який буде побудований маршрут з графіком',
    click_for_showTable:                                                 'По кліку на кнопку інформація про машину буде представлена у вигляді таблиці',
    sensrosButtons_block:                                                'Доступні на авто датчики: при наведенні на блок можна побачити їх назви',
    addGraph:                                                            'Натиснувши на кнопку, можна додати/відключити інформацію з датчику в графік',
    graph_triger:                                                        'Утримуючи затиснутою на тригерi ліву клавішу миші, можна дивитися дані в конкретній точці на графіку (тригер рухомий). Так само є управління стрілками вліво, вправо',
    here_valueSensors:                                                   'Показники датчиків в момент, що визначається положенням тригера',
    time_format:                                                         'Шкала часу в 24-годинному форматі',
    line_of_progress:                                                    'Лінія прогресу відповідає часовій шкалі та індексує виконання завдання. Так само на шкалі відображаються події, які відбулися з транспортом. Тривалість події підсвічена кольором іконки. Якщо зробити клiк по іконці, маркер авто переміститься до крапки на карті, де ця подія відбулася',

    marker_show_currentPlace:                                            "Маркер вказує положення авто та напрямок його руху",
    blue_color_route:                                                    "Синім кольором на карті - маршрут транспорту за вибраний час. Якщо клікнути на крапку маршруту, виділений рядок/тригер в таблиці переміщається на відповідну часову мітку. Це дозволить дізнатися час та іншу інформацію від авто в цьому місці.",
    zoom_instruction:                                                    "Цей елемент керування дозволяє масштабувати графік. Щоб змінити масштаб, можна використовувати повзунок, кнопки +/-, а також скрол миші на графіку.",
    startTask_instr:                                                     "Визначтеся з завданням. Якщо це завдання буде періодично повторюватися - зробіть та обирайте шаблон. Якщо завдання для одного разу - обирайте одноразове завдання",
    available_vehicle_instr:                                             "Якщо клікнути на цей елемент, з'явиться список доступного транспорту",
    button_for_name:                                                     "Натисніть на радіокнопку, щоб заповнити назву шаблону",
    name_is_required:                                                    "Назва є обов'язковим полем; заповніть, щоб перейти до наступного кроку",
    pressToNextStep:                                                     "Натисніть, щоб перейти до наступного етапу",
    control_points_instr:                                                "Маршрут може містити як контрольні крапки, так і геозони",
    clickForList:                                                        "Клік по елементу викликає список геозон",
    start_enterAdress_instr:                                             "Почніть вводити назву адреси, щоб з'явилися підказки. Далі натисніть Tab",
    forChooseAddres:                                                     "Щоб oбрати підказку використовуйте ↑ ↓; щоб oбрати адресу натисніть Enter; oбрану адресу буде відзначати на карті маркер",
    clickForCopy:                                                        "Натисніть, щоб скопіювати в буфер обміну",
    clickToClear:                                                        "Натисніть, щоб очистити",
    clickToAdd:                                                          "Натисніть, щоб додати ще одну адресу",
    clickToDelete:                                                       "Кнопка дозволяє видалити адресу з маршруту",
    afterClick_instr:                                                    "Після натискання кнопки відбувається побудова маршруту і перехід до наступного кроку. Зверніть увагу: для того, щоб побудувати маршрут, необхідно мінімум 2 крапки",
    understand:                                                          "Зрозуміло!",
    enterDate_instr:                                                     "Введіть дату початку завдання в форматі дд.мм.рік або виберіть з календаря. Щоб викликати календар, натисніть на іконку в кутку поля",
    listControlPoint:                                                    "Список контрольних крапок маршруту",
    timeCheckpoint:                                                      "Час, до якого транспортного засобу потрібно прийти в адрес призначення. Система буде відстежувати його під час виконання завдання. Ви можете ввести час в 24-годинному форматі (чч:мм) або вибрати його зі списку",
    ifThenYouCanChange:                                                  "Якщо не влаштовує оптимальний маршрут, що прокладений системою, - його можливо відредагувати, змінивши вибір дороги між крапками. Для цього затисніть ліву кнопку миші на ділянці дороги; далі шляхом перетягування змініть маршрут",
    clickToReturn:                                                       'Клік по кнопці дозволить повернуться на крок назад',
    clickToNextStep:                                                     'Натисніть, щоб перейти до наступного етапу',
    pressToSaveTemplate:                                                 "Натисніть, щоб зберегти шаблон",
    pressToDrawOnMap:                                                    "Натисніть на шаблон, щоб маршрут шаблону був відмальований на мапі",
    clickToView:                                                         "Натисніть, щоб переглянути повну інформацію про шаблон",
    clickToSetTask:                                                      "Натисніть, щоб поставити транспорту цей шаблон в задачу",
    clickToDeleteTemplete:                                               "Натисніть, щоб видалити шаблон",
    clickToCopyTemplate:                                                 "Натисніть, щоб скопіювати шаблон",
    clickToViwAllLIst:                                                   "Натисніть, щоб подивитися повний список крапок",
    clickToViewPointOnMap:                                               "Натисніть, щоб подивитися, де крапка знаходиться на мапі",
    clickToEdit:                                                         "Натисніть, щоб перейти до редагування",
    clictToGeoDraw:                                                      "Натисніть на назву, щоб геозона була відмальована на мапі",
    clickToDeleteElem:                                                   "Натисніть, щоб видалити цей елемент",
    enterAddresForMove:                                                  "Введіть адресу, щоб карта змістилася за зазначеною адресою",
    chooseTypeColor:                                                     "Виберіть тип кола та його колір. Далі кліком по мапі можна побудувати фігуру",
    whitePoints:                                                         "Білі крапки є елементами управління фігурою. Крапка по центру дозволяє змінювати її місце розташування; інші крапки - змінювати її розміри або форму. Кнопка зі стрілкою поруч з фігурою дозволяє скасувати останні змінення",
    hereCalcSquare:                                                      "Тут розраховується площа фігури в га",
    deleteBtnGeo:                                                        "Якщо ви десь помилилися та хочете почати спочатку, цією кнопкою можна видалити фігуру",
    fillNameGeo:                                                         "Заповніть назву геозони, за якою вона буде доступна для розшуку. Поле є обов'язковим",
    clickToSaveGeo:                                                      "Натисніть, щоб зберегти геозону",
};

const telegram_instructions = {
    send_message_to_telegrams_from_globus:                               'Відправлення повідомлень з "ГЛОБУС Pro" на Telegram',
    start_of_work:                                                       'Початок роботи:',
    search_bot_in_telegram:                                              "1. У Telegram шукаємо / додаємо в список наш бот",
    send_command_start:                                                  "2. Відправляємо йому команду",
    after_that_send_login_path:                                          "3. Після цього логіном в форматі", //userLogin userPassword
    work_with_telegram_started:                                          "Робота з Telegram-ботом почалася!",
    now_we_need_setup_messages_in_to_telegram:                           "4. Тепер необхідно налаштувати повідомлення, які будуть відправлятися в Telegram.",
    for_that:                                                            "Для цього:",
    move_to_profile:                                                     "- переходимо в Профіль -> Повідомлення -> Telegram",
    select_message_what_we_wont_receive:                                 "- вибираємо там повідомлення, які будуть відправлятися в Telegram",
    for_disable_messages_enter_command_exit:                             "5. Щоб перестати приймати повідомлення на конкретний Telegram - необхідно на ньому разлогініться. Для цього - відправляємо з нього команду",
    help_and_avalaible_command:                                          "6. Допомога і всі доступні команди: ",
    warning:                                                             "Важливо:",
    connect_bot_rulles_is_from_telegram:                                 "1. Правила підключення бота регулюються самим Telegram і ми не можемо їх змінювати.",
    you_can_clear_login_and_password_from_history:                       "2. Якщо Ви не хочете, щоб логін / пароль зберігався у Вашій історії Telegram (з безпеки) - видаліть його з історії відразу ж після вдалого логіна!",
    for_this:                                                            "Для цього:",
    press_to_message:                                                    "- натискаємо на повідомлення",
    select_in_menu_remove_for_me_and_for_all:                            '- в меню вибираємо "Delete For Me and all" / "Видалити для себе і для всіх"',
    message_will_remove_from_your_history:                               "- повідомлення зникає в історії",
    if_you_dont_remove_dont_worry_this_message_can_see_only_you:         "Навіть якщо не видалили - інші користувачі бота ваш логін / пароль в історії не бачать!",
    language_for_default_is_from_your_profile_in_system:                 "3. Мова повідомлень - за профілем клієнта. Щоб змінити - необхідно заповнити профіль.",
    you_can_connect_many_different_telegram_account_for_one_our_account: "4. Робота декількох різних Telegram з одним аккаунтом можлива, повідомлення будь-кому надійдуть однаково.",
    login_for_limited_user_is_the_same:                                  "5. Логін під обмеженим користувачем точно такий же, повідомлення - за списком доступних йому машин.",
};

const errorTexts = {
    something_went_wrong:                                                'Щось пішло не так',
    notify_support:                                                      'Повідомити підтримку',
    message_sent:                                                        'Повідомлення успішно відправлено',
    request_limit_exceeded:                                              "Перевищен ліміт на запити, спробуйте за хвилину",
    not_enough_data_to_build_geofence:                                   'Недостатньо даних для побудови геозони',
    only_file_types_allowed:                                             'Дозволені лише zip / kmz або kml файли',
    is_wrong:                                                            'помилковий',
    Account:                                                             'Аккаунт ',
    absent_in_gmail:                                                     ' в gmail відсутнiй',
    rfidCard_number:                                                     'Номер RFID карти ',
    already_exists:                                                      ' вже існує',
    expiry_date:                                                         'Дата закінчення: ',
    cant_be_in_the_past:                                                 ' не може бути в минулому',
    vehicle_with_id:                                                     'Транспортний засіб з id: ',
    belongs_to_the_group_already:                                        'вже належить до групи',
    does_not_belong_to_the_group:                                        'не належить до групи',
    No_required_parameter_GeoCoding:                                     "Відсутня обов'язковий параметр - гео кодинг",
    geoCoding_cant_be_null:                                              'Геокодінг не може бути null',
    equipmentWidth_cant_be_null:                                         'Ширина обладнання не може бути null',
    longerThen_cant_be_null:                                             'Довше чим не може бути null ',
    ZoneType_cant_be_null:                                               'Тип зони повинен бути зазначений ',
    not_exist:                                                           ' не існує',
    zone_type_with_id:                                                   'Тип зони з id ',
    Impossible_create_zone_from_square_with_coordinate:                  'Неможливо створити геозону, недостатньо координат',
    not_exist_device_in_with_id:                                         'Не існує пристрою з id: ',
    tankVolumeStart_cant_be_more_tankVolume:                             'Обсяг заправки не може бути більше ніж обсяг бака',
    fuel_volume_exceeded_tank_volume:                                    'Обсяг палива перевищує обсяг паливного бака',
    Cant_find_FUEL_LEVEL_BEFORE_EVENT:                                   'Неможливо знайти РІВЕНЬ ПАЛИВА ДО ПОДІЇ, тому що неможливо визначити початковий обсяг бака!',
    Rfid_card_can_not_be_empty:                                          'Карта RFID не може бути порожньою',
    Rfid_card_id_can_not_be_null:                                        'Ідентифікатор RFID-картки не може бути нульовим',
    No_actual_device_mapping_for_control_entity:                         "Трекер не встановлений на авто в запрошений час ",
    Current_vehicl_with_id:                                              'Поточне авто з id: ',
    does_not_have_current_device_mapping:                                'немає поточного зіставлення пристроїв',
    Vehicle_has_incorrect_width:                                         "Автомобіль має неправильну ширину",
    trailer_serial_allready_exist:                                       'Причіп з цим серійним номером вже є в системі',
    user_with_such_login_already_exists:                                 'Користувач з таким логіном вже існує',
    impossible_get_square_zone_from_coordinates:                         'Неможливо отримати квадратну зону з координат: точки <= 1',
    absent_data_for_report:                                              'Отсутствует информация для отчета',
    There_is_no_information_from_the_fuel_sensor:                        'Відсутня інформація з датчика палива',
    The_tracker_is_missing_on_the_car:                                   'Відсутній трекер на авто',
};

const lostedWords = {
    scheduled: 'scheduled',
    scheduled_job: 'scheduled_job',
    scheduled_for: 'scheduled_for',

    list_of_scheduled_jobs_is_empty: 'list_of_scheduled_jobs_is_empty',
    Tuesday: 'Вівторок'
};

const words = Object.assign({},
    globalVocabulary,
    telegram_instructions,
    hints,
    errorTexts,
    rfidCardsPage,
    infoGraphicControl,
    routeWords,
    routeDetailsPage,
    lostedWords,
    transportProfile,
);

export const subCatUA = {
    globalVocabulary,
    telegram_instructions,
    hints,
    errorTexts,
    rfidCardsPage,
    infoGraphicControl,
    routeWords,
    routeDetailsPage,
    lostedWords,
    transportProfile,
};

export default words
