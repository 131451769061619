import axios from 'axios'
import store from 'store/store.js'
import bugReport from './bugReport.js'
import infoStore from './infoStore.js'
import {ifNoKey, tryStringify, arrayIsEmpty, copyOfObject, removeElementFromList, isJsonString}   from 'sl-library'
import language_variant  																								  from "store/languages.jsx"

const geoRequest = {
	getGeophenceList:            getGeophenceList,
	getGeophenceListAll:         getGeophenceListAll,
	saveGeophence:               saveGeophence,
	deleteGeophence:             deleteGeophence,
	updateGeo:                   updateGeo,
	getGeoAlongRoute:            getGeoAlongRoute,
	getSquareFromRoute:          getSquareFromRoute,
	getListZoneTypes:            getListZoneTypes,
	createZoneType:              createZoneType,
	updateZoneType:              updateZoneType,
	deleteZoneType:              deleteZoneType,
	// addGeoZoneToList:         addGeoZoneToList,
	updateGeoZoneInList:         updateGeoZoneInList,
	saveGeofenceListToStore:     saveGeofenceListToStore,
	getGeophenceListFromStore:   getGeophenceListFromStore,
	setSelectedZoneTypesToStore: setSelectedZoneTypesToStore,
	saveGeofencesForViewToStore: saveGeofencesForViewToStore,
	updateGeofencesLists:        updateGeofencesLists,
	createGeofenceNote:          createGeofenceNote,
	updateGeofenceNote:          updateGeofenceNote,
	getGeofenceNotes:            getGeofenceNotes,
	deleteGeofenceNote:          deleteGeofenceNote,
	importZones:                 importZones,
	saveFullGeofenceListToStore: saveFullGeofenceListToStore,
	getTaskTypeId:               getTaskTypeId,
	getGeoZonesInfo:             getGeoZonesInfo,
	addFakeType:                 addFakeType,
	removeFakeType:              removeFakeType,
	getForbidenZoneTypesForUser: getForbidenZoneTypesForUser,
	saveMapToStore: 				  saveMapToStore,
}
export default geoRequest;

export function importZones(formData, callback) {
	let requestData = {
		method: 'PUT',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozone/kml",
		headers: {
			'Content-Type': 'multipart/form-data',
			'authorization': infoStore().token
		},
		withCredentials: true,
		data: formData
	};

	axios(requestData)
		.then(() => callback(formData))
		.catch(answer => bugReport(answer, requestData, 'importZones'));
}


function getGeoAlongRoute(vehicleId, dateFrom, dateTo, width, callback) {
	let requestData = {
		method: 'GET',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "track/geozone/along/" + vehicleId,
		dataType: "JSON",
		headers: { "Content-Type": "application/json", 'authorization': infoStore().token }, //заголовок
		withCredentials: true,
		params: {
			from: dateFrom,
			to: dateTo,
			width: width
		}
	};
	axios(requestData).then(function(answer) {
			console.log('getGeoAlongRoute', answer)
			if(!!callback) {
				callback(answer.data)
			} else console.log("%c getGeoAlongRoute: don't know what do with data, callback-", 'color: purlpe; font-weight: bold;', callback)
		})
		.catch((answer) => {
			if(ifNoKey(answer, "status") == 422) {
				store.dispatch({
					type: 'NOTIFINPOPUP',
					setTextNotif: language_variant.vehicleDidntMoveDonthaveCoordinatesForGeozone
				})
			} else {
				bugReport(answer, requestData, 'getGeoAlongRoute')
			}
		})
}

function getSquareFromRoute(vehicleId, path, callback) {
	let requestData = {
		method: 'POST',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "track/geozone/field/" + vehicleId,
		dataType: "JSON",
		headers: { "Content-Type": "application/json", 'authorization': infoStore().token }, //заголовок
		withCredentials: true,
		data: path
	};
	axios(requestData).then(function(answer) {
			console.log('getSquareFromRoute', answer)
			if(!!callback) {
				callback(answer.data)
			} else console.log("%c getSquareFromRoute: don't know what do with data, callback-", 'color: purlpe; font-weight: bold;', callback)
		})
		.catch((serveranswer) => {
			console.log("%c getGeoAlongRoute - serveranswer", 'color: red; font-weight: bold;', serveranswer)
			bugReport(serveranswer, requestData, 'getSquareFromRoute')
		})
}

function getGeophenceList(callback) { //with out zone types == 'task'
	let requestData = {
		method: 'GET', // "PUT",//,
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozones",
		dataType: "json", //формат
		headers: { "Content-Type": "application/json", 'authorization': infoStore().token }, //заголовок
		withCredentials: true,
		params: { login: infoStore().login },
	};
	axios(requestData).then(function(answer) {
		let data = answer.data;
		saveGeofenceListToStore(data);
		if(!arrayIsEmpty(getSavedSelectedZoneTypes())){
			saveGeofencesForViewToStore(data)
		}
		if(!!callback) {
			callback(data)
		}

	}).catch((serveranswer) => {
		bugReport(serveranswer, requestData, "getGeophenceList")
	})
}

function getGeophenceListAll(callback) {
	let requestData = {
		method: 'GET', // "PUT",//,
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozones/all",
		dataType: "json", //формат
		headers: { "Content-Type": "application/json", 'authorization': infoStore().token }, //заголовок
		withCredentials: true,
		params: { login: infoStore().login },
	};
	axios(requestData).then(function(answer) {
		let data = answer.data
		if(!!callback) {
			callback(data)
		}
		saveFullGeofenceListToStore(data)
	}).catch((serveranswer) => {
		bugReport(serveranswer, requestData, "getGeophenceList")
	})
}

function getGeophenceListFromStore() {
	return infoStore('globalR.tasksR.geofences')
}

function saveGeophence(geoInfo, callback) {
	if(typeof geoInfo == 'object') {
		geoInfo.owner_login = infoStore().login
	} else {
		console.log("%c saveGeophence: bad data for request, geoInfo", 'color: red; font-weight: bold;', geoInfo)
	}
	let requestData = {
		method: 'PUT',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozone",
		dataType: "JSON",
		headers: {
			"Content-Type": "application/json",
			'authorization': infoStore().token,
		},
		withCredentials: true,
		data: [geoInfo]
	};
	axios(requestData).then((answer) => {
		if(!!callback) {
			callback(answer.data[0])
		}
	}).catch((serveranswer) => {
		bugReport(serveranswer, requestData, "saveGeophence")
	})
}

function updateGeofencesLists(geofences_list, geofencesForView) {
	store.dispatch({
		type: 'UPDATE_GEOFENCES_LIST',
		setNewGeofences_list: geofences_list,
		setNewListForView: geofencesForView
	})
}

function updateGeoZoneInList(newGeozone, listGeozones) {
	if(arrayIsEmpty(listGeozones)) {
		const indexNewGeozone = listGeozones.findIndex(key => key.id == newGeozone.id);
		listGeozones[indexNewGeozone] = newGeozone;
	}
	return listGeozones
}

function deleteGeophence(id, callback) {
	let requestData = {
		method: 'DELETE',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozone",
		dataType: "json", //формат
		headers: {
			"Content-Type": "application/json",
			'authorization': infoStore().token
		}, //заголовок
		withCredentials: true,
		params: { id: id }
	};
	axios(requestData).then((answer) => {
		let listGeophence = infoStore('globalR.tasksR.geofences');
		store.dispatch({
			type: "SET_GEOFENCE",
			setGeofences: removeElementFromList(listGeophence, 'id', id)
		})
		if(callback) callback()
	}).catch((serveranswer) => {
		bugReport(serveranswer, requestData, "deleteGeophence")
	})
}

function updateGeo(data, callback) {
	// if(typeof data == 'object') data.owner_login = infoStore().login;
	let requestData = {
		method: 'POST',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozone",
		dataType: "JSON",
		headers: {
			"Content-Type": "application/json",
			'authorization': infoStore().token
		},
		withCredentials: true,
		data: data
	}
	axios(requestData).then((answer) => {
		let listGeophence = infoStore('globalR.tasksR.geofences')
		let data = answer.data;
		callback(data)
		if(arrayIsEmpty(listGeophence)) {
			let indexOfZone = listGeophence.findIndex(key => {
				if(key.id == data.id) {
					return key
				}
			})
			listGeophence[indexOfZone] = data
		}
		store.dispatch({
			type: "SET_GEOFENCE",
			setGeofences: listGeophence
		})
	}).catch((serveranswer) => {
		bugReport(serveranswer, requestData, "updateGeo")
	})
}

function getListZoneTypes(callback) {
	const lang = infoStore().language;
	let requestData = {
		method: 'GET',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozone/type",
		headers: {
			"Content-Type": "application/json",
			'authorization': infoStore().token
		},
		params: { locale: lang ? lang.toUpperCase() : lang }
	}
	axios(requestData).then(answer => {
			const zoneTypesList = answer.data;
		// getForbidenZoneTypesForUser(infoStore().login, answer => console.log(answer))
			setZoneTypesToStore(zoneTypesList)
			if(!!callback) callback(answer.data)
		}).catch(serveranswer => bugReport(serveranswer, requestData, "getListZoneTypes"))
}

function getForbidenZoneTypesForUser(login, callback, onError) {
	const requestData = {
		method: 'GET',
		dataType: "JSON",
		baseURL: infoStore().base_url,
		url: `/api/v2/geozones/forbidden/user/${login}`,
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
      .then(answer => callback(answer.data) )
		.catch(serveranswer => {
			onError(serveranswer)
			bugReport(serveranswer, requestData, "getForbidenZoneTypesForUser")
		})
}

function getGeoZonesInfo(callback) {
	let zonesList = null, zoneTypesList = null;
	const startDate = new Date();
	const waitingData = setInterval(()=> {
			if(zonesList && zoneTypesList){
				clearInterval(waitingData);
				callback(zonesList);
			}
			if(new Date().getTime() - startDate.getTime() > 300000){
				clearInterval(waitingData);
				console.error('Data for drawing geozones not received after 5 minutes. Waiting is aborted', zonesList, zoneTypesList)
			}
	}, 300)


	getListZoneTypes(answerList =>  zoneTypesList = answerList);
	getGeophenceList(answerList =>  zonesList = answerList);
}

function getTaskTypeId(callback) {
	const lang = infoStore().language;
	let requestData = {
		method: 'GET',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozone/type/all",
		headers: {
			"Content-Type": "application/json",
			'authorization': infoStore().token
		},
		params: { locale: lang ? lang.toUpperCase() : lang }
	}
	axios(requestData).then(answer => {
		const typesList = answer.data;
		const taskType = typesList.find(oneZoneType => oneZoneType.type == 'TASK');
		saveTaskTypeId(taskType.id)
		if(!!callback) callback(answer.data);
	}).catch(serveranswer => bugReport(serveranswer, requestData, "getListZoneTypes"))
}

function saveTaskTypeId(taskTypeId) {
	store.dispatch({
		type: 'SAVE_TASK_TYPE_ID',
		saveId: taskTypeId
	})
}
export function getSavedSelectedZoneTypes() {
	const login = localStorage.getItem('login');
	const selected = localStorage.getItem(login + '-selected_zone_types');
	return isJsonString(selected) ? JSON.parse(selected) : []
}

export function saveSelectedZoneTypes(selectedZoneTypes) {
	const login = localStorage.getItem('login');
	localStorage.setItem(login + '-selected_zone_types', JSON.stringify(selectedZoneTypes))
}

function setZoneTypesToStore(zoneTypesList) {
	const selectedZoneTypes = getSavedSelectedZoneTypes()
	store.dispatch({
		type: 'ZONE_TYPES',
		setZoneTypes: zoneTypesList,
		setSelectedZoneTypes: arrayIsEmpty(selectedZoneTypes) ? copyOfObject(selectedZoneTypes) : copyOfObject(zoneTypesList)
	})
}

function setSelectedZoneTypesToStore(zoneTypesList) {
	store.dispatch({
		type: 'SELECTED_ZONE_TYPES',
		setSelectedZoneTypes: copyOfObject(zoneTypesList)
	})
}

function saveGeofenceListToStore(geofencesList) {
	store.dispatch({
		type: "GEOFENCES_LIST",
		setGeofencesList: geofencesList
	})
}

function saveFullGeofenceListToStore(geofencesList) {
	store.dispatch({
		type: "GEOFENCES_LIST_FULL",
		setFullList: geofencesList
	})
}

function saveMapToStore(map) {
	store.dispatch({
		type: "SAVE_MAP_OBJECT",
		saveMap: 	map
	})
}

function saveGeofencesForViewToStore(geofencesListForView) {
	store.dispatch({
		type: 'GEOFENCES_FOR_VIEW',
		setListForView: copyOfObject(geofencesListForView)
	})
}

function createZoneType(typeName, callback) {
	let requestData = {
		method: 'PUT',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozone/type/user",
		headers: {
			"Content-Type": "application/json",
			'authorization': infoStore().token
		},
		params: { type: typeName }
	}
	axios(requestData).then(answer => {
		if(!!callback) callback(answer.data)
	}).catch(serveranswer => bugReport(serveranswer, requestData, "createZoneType"))
}

function updateZoneType(zoneTypeInfo, callback) {
	let requestData = {
		method: 'POST',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozone/type/user",
		headers: {
			"Content-Type": "application/json",
			'authorization': infoStore().token
		},
		data: zoneTypeInfo
	}
	axios(requestData).then(answer => {
		if(!!callback) callback(answer.data)
	}).catch(serveranswer => bugReport(serveranswer, requestData, "updateZoneType"))
}

function deleteZoneType(zoneTypeId, callback) {
	let requestData = {
		method: 'DELETE',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozone/type/user",
		headers: {
			"Content-Type": "application/json",
			'authorization': infoStore().token
		},
		params: { id: zoneTypeId }
	}
	axios(requestData).then(answer => {
		if(!!callback) callback(zoneTypeId)
	}).catch(serveranswer => bugReport(serveranswer, requestData, "deleteZoneType"))
}

function createGeofenceNote(requestBody, callback) {
	// {
	//     "comment": "tra-ta-ta",
	//     "geozoneId": 2001
	// }
	let requestData = {
		method: 'POST',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozone-comment",
		headers: {
			"Content-Type": "application/json",
			'authorization': infoStore().token
		},
		data: requestBody
	}
	axios(requestData)
		.then(answer => callback(answer.data))
		.catch(serveranswer => bugReport(serveranswer, requestData, "createGeofenceNote"))
}

function updateGeofenceNote(requestBody, callback) {
	// {
	//     "id": 1,
	//     "comment": "bla-bla-bla",
	//     "geozoneId": 2001
	// }
	let requestData = {
		method: 'PUT',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozone-comment",
		headers: {
			"Content-Type": "application/json",
			'authorization': infoStore().token
		},
		data: requestBody
	}
	axios(requestData)
		.then(answer => callback(answer.data))
		.catch(serveranswer => bugReport(serveranswer, requestData, "updateGeofenceNote"))
}

function getGeofenceNotes(geofenceId, callback) {
	let requestData = {
		method: 'GET',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozone-comment/geozone/" + geofenceId,
		headers: {
			"Content-Type": "application/json",
			'authorization': infoStore().token
		},
	}
	axios(requestData)
		.then(answer => callback(answer.data))
		.catch(serveranswer => bugReport(serveranswer, requestData, "getGeofenceNotes"))
}

function deleteGeofenceNote(geofenceCommentId, callback) {
	let requestData = {
		method: 'DELETE',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "geozone-comment",
		headers: {
			"Content-Type": "application/json",
			'authorization': infoStore().token
		},
		params: { id: geofenceCommentId }
	}
	axios(requestData)
		.then(answer => callback(answer.data))
		.catch(serveranswer => bugReport(serveranswer, requestData, "deleteGeofenceNote"))
}

function addFakeType(zoneTypes) {
	if(arrayIsEmpty(zoneTypes)) {
		const modifiedZoneTypes = copyOfObject(zoneTypes);
		const indexOfZoneType = zoneTypes.findIndex(zoneType => zoneType.id == 'fake_type_all');
		if(indexOfZoneType == -1) {
			modifiedZoneTypes.unshift({
				id: 'fake_type_all',
				type: 'fake_type_all',
				localName: language_variant.all_zone_types
			})
		}
		return modifiedZoneTypes
	} else return zoneTypes
}

function removeFakeType(zoneTypes) {
	if(arrayIsEmpty(zoneTypes)) {
		const copyOfList = copyOfObject(zoneTypes);
		const indexOfZoneType = copyOfList.findIndex(zoneType => zoneType.id == 'fake_type_all');
		if(indexOfZoneType == -1) {
			copyOfList.splice(indexOfZoneType, 1);
		}
		return copyOfList
	} else return zoneTypes
}
