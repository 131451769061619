// import language_variant 						from 'store/languages.jsx'

export default function reportR(state = {
		report_types:          undefined, //default_report_types,
		available_options:     undefined, //demo_available_options,
		report_created_pop_up: false,
		report_config:         undefined, //demo_config,
		report:                undefined, //demo_report,
		editing_report:        undefined,
		settings_from_route:   null,
		rfid_cards_list:       null,
		selected_vehicles:     [],
		is_full_size:          false

		}, action) {

		switch (action.type) {
			case 'SAVE_SELECTED_VEHICLE':
				return {
					...state,
					selected_vehicles: action.new_list
				};
			case 'SET_RFID_CARDS_LIST':
				return {
					...state,
					rfid_cards_list: action.saveCards,
				};
			case 'SET_INFO_FOR_FAST_REQUEST':
				return {
					...state,
					settings_from_route: action.saveSettings,
				};
			case 'SET_REPORT_TYPES':
				return {
					...state,
					report_types: action.setReport_types,
				};
			case 'SET_AVAILABLE_OPTION':
				return {
					...state,
					available_options: action.setAvailable_options,
				};
			case 'REPORT_WAS_CREATED':
				return {
					...state,
					report_created_pop_up: action.setReportCreate_pop_up,
				};
			case 'REPORT_CONFIG':
				return {
					...state,
					report_config: action.setReport_config,
				};
			case "REPORT":
				return {
					...state,
					report: action.setReport,
				};
			case "START_EDIT":
				return {
					...state,
					editing_report: action.setReport
				};
			case 'UPDATE_REPORT_PAGE_SIZE':
				return {
					...state,
					is_full_size: action.setNewSize,
				};
			default:
				return state;
		}
	}

reportR.reducer = 'reportR'

/*
let demo_available_options = [
								{type: 'way', 			name: 'start_motion'},
								{type: 'way', 			name: 'end_motion'},
								{type: 'way', 			name: 'time_in_way'},
								{type: 'way',   		name: 'mileage' },
								{type: 'way', 			name: 'max_speed'},
								{type: 'way',			name: 'average_speed'},
								{type: 'way', 			name: 'spent_fuel'},
								{type: 'way',			name: 'average_spent_fuel'},
								{type: 'stop/parking', 	name: 'time_parking'},
								{type: 'stop/parking', 	name: 'time_parking_ignition_on'},
								{type: 'stop/parking', 	name: 'time_parking_ignition_off'},
								{type: 'stop/parking', 	name: 'time_stop_in_geo'},
								{type: 'geo', 			name: 'time_of_entry_in_geo'},
								{type: 'geo', 			name: 'time_of_exit_in_geo'},
								{type: 'geo',			name: 'spent_fuel_in_geo'},
								{type: 'geo',			name: 'fuel_consumtion_in_geo'},
								{type: 'geo',			name: 'distance_about_prev_entering'}, //was created by design, but now don't use
						];
let demo_config	 =	[
						'timestamp',
						'start_motion',
						'end_motion',
						'time_in_way',
						'mileage',
						'max_speed',
						'average_speed',
						'spent_fuel',
						'average_spent_fuel',
						'time_parking',
						'time_parking_ignition_on',
						'time_parking_ignition_off',
					];
function simulateHundredDay(){
	let array = [];
	for(let i=1; i<1000; i++){
		array.push({
												timestamp: '2017-09-22T03:00:00Z',
												start_motion: 	'2017-09-22T03:00:00Z',
												end_motion: 	'2017-09-22T05:00:00Z',
												average_spent_fuel: {
																		value: 500+i,
																		units: 'units',
																	},
												max_speed: 	{
																value: 125+i,
																units: 'km/h',
															},
												average_speed: 		{
																		value: 15+i,
																		units: 'km/h',
																	},
											},)
	}
	return array
}
let demo_report  =  [
						{
							vehicle_name:'name of vehicle No Data',
							licensePlate: 'NU 1123B U',
							report_data: undefined //or null
						},
						{	vehicle_name:'name of vehicle 1',
							licensePlate: 'NU 1123B U',
							report_data: [
											{
												timestamp: '2017-09-22T03:00:00Z',
												start_motion: 	'2017-09-22T03:00:00Z',
												end_motion: 	'2017-09-22T04:23:15Z',
												average_spent_fuel: {
																		value: 500,
																		units: 'units',
																	},
												max_speed: 	{
																value: 125,
																units: 'km/h',
															},
												average_speed: 		{
																		value: 15,
																		units: 'km/h',
																	},
												mileage: 	{
																value: 500,
																units: 'km/h'
															}
											},
											{
												timestamp: '2017-09-23T03:00:00Z',
												start_motion: 	'2017-09-22T05:00:00Z',
												end_motion: 	'2017-09-22T18:00:00Z',
												average_spent_fuel: {
																		value: 7,
																		units: 'units',
																	},
												max_speed: 	{
																value: 13,
																units: 'km/h',
															},
												average_speed: 		{
																		value: 1,
																		units: 'km/h',
																	},
												mileage: 	{
																value: 500,
																units: 'km/h'
															}
											},
							]
						},
						{	vehicle_name:'name of vehicle 2',
							licensePlate: 'NU 1123B U',
							report_data: [
											{
												timestamp: '2017-09-22T03:00:00Z',
												start_motion: 	'2017-09-22T03:00:00Z',
												end_motion: 	'2017-09-22T05:00:00Z',
												average_spent_fuel: {
																		value: 500,
																		units: 'units',
																	},
												max_speed: 	{
																value: 125,
																units: 'km/h',
															},
												average_speed: 		{
																		value: 15,
																		units: 'km/h',
																	},
											},
											{
												timestamp: '2017-09-23T03:00:00Z',
												start_motion: 	'2017-09-22T03:00:00Z',
												end_motion: 	'2017-09-22T09:00:00Z',
												average_spent_fuel: {
																		value: 900,
																		units: 'units',
																	},
												max_speed: 	{
																value: 125,
																units: 'km/h',
															},
												average_speed: 		{
																		value: 15,
																		units: 'km/h',
																	},
											},
							]
						},
						{	vehicle_name:'Vehicle: Simulate hundreds day',
							licensePlate: 'NU 1123B U',
							report_data: simulateHundredDay()

						},
					];*/