import React from 'react';
import {noNull} from 'sl-library'
import language_variant from 'store/languages'
import "images/ring-alt.gif"

export default class Preloader extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			active: this.props.active,
		}
		this.mount = true;
	}
	hide = () =>{
		this.setState({active: false});
	}
	show = () =>{
		this.setState({active: true});
	}
	componentWillUnmount() {
		this.mount = false;
	}
	render(ReactElement, DOMElement, callback){
		return <img src='/images/ring-alt.gif' width='80' height='80'
		       		className='preloader'
		       		style={showPreloader(this.state.active)}/>
	}
}
function showPreloader(active){
	if(!active){
		return {
			opacity: 0,
			visibility: 'hidden',
			zIndex: -15,
		}
	}else{
		return {
			opacity: 1,
			visibility: 'visible',
			zIndex: 199999,
		}
	}
}