import React        from "react";
import { connect }  from 'react-redux'
import { ifNoKey }  from "sl-library"
import { getValue } from "reducers/authorization_reducer.js"


class SeparPermission extends React.Component{
	constructor(props) {
		super(props);
		const {
			alowed,
			kindUser,
			kindCapability,
			user_permission,
			type
		} = this.props;

		this.state = {
			show: checkAcces({
										alowedUserTypes: alowed,
										currentUserType: kindUser
									},
									{
										userPremission: user_permission,
										requiredPremissions: kindCapability
									}),
		}
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		const {
			alowed,
			kindUser,
			kindCapability,
			user_permission,
			type
		} = nextProps;
		this.setState({
			show: checkAcces({
										alowedUserTypes: alowed,
										currentUserType: kindUser
									},
									{
										userPremission: user_permission,
										requiredPremissions: kindCapability
									})
		});
	}
	UNSAFE_componentWillMount() {
		const {
			alowed,
			kindUser,
			kindCapability,
			user_permission,
			type
		} = this.props;

		if(type == 'page'){
			checkAcces({
				alowedUserTypes: alowed,
				currentUserType: kindUser
			},
			{
				userPremission: user_permission,
				requiredPremissions: kindCapability
			}) ? null : moveUserToPreviousPage();
		}
	}
	render() {
		const {children} = this.props;
		const {show} = this.state;
		const childElements = () => children ? React.cloneElement(this.props.children, {}) : null ;
		return show ? childElements() : null
	}
}

export default connect(
    state => ({
    	login: 				state.globalR.authorizationR.login,
		token: 				state.globalR.authorizationR.token,
		kindUser: 			state.globalR.authorizationR.kindUser,
		user_permission: 	state.globalR.authorizationR.user_permission,
    })
)(SeparPermission)

{/*<SeparPremission 	alowed = 			'SUPERADMIN, ADMIN, DEALER'
							kindCapability =    'editClients'>*/}

export function permissionsFoRequst(alowedUserTypes, requiredPremissions, request) {
	const kindUser = localStorage.getItem('kindUser');
	const capabilities = getValue(localStorage.getItem('user_permission'));
	checkAcces({
			alowedUserTypes: alowedUserTypes,
			currentUserType: kindUser
		},
		{
			userPremission: capabilities,
			requiredPremissions: requiredPremissions
		}) ? request() : null;
}


export function byKind(kind, alowed) {
	if(alowed.indexOf(kind) !== -1){
		return true
	}else{
		false
	}
}

function checkAcces(userInfo, permissions) {
	const {alowedUserTypes, currentUserType} = userInfo;
	const {userPremission, requiredPremissions} = permissions;
	let haveAcces = true;
	if(alowedUserTypes && !checkAccesByUserType(alowedUserTypes, currentUserType)){
		haveAcces  = false
	}
	if(!checkAccesByUserPremission(userPremission, requiredPremissions)){
		haveAcces  = false
	}
	return haveAcces
}

function checkAccesByUserPremission(userPremission, requiredPremissions) {
	let allowed = true;
	if(typeof requiredPremissions === 'string'){
		allowed = checkStatusPermission(requiredPremissions)
	}
	if(Array.isArray(requiredPremissions)){
		for(let i in requiredPremissions){
			allowed = checkStatusPermission(requiredPremissions[i]);
			if(!allowed){
				break;
			}
		}
	}
	return allowed

	function checkStatusPermission(requiredPremissions) {
		let statusAllowed = true;
		if(userPremission && !userPremission[requiredPremissions]){
			return false
		}else{
			return true
		}
		// for(let i in userPremission){
		// 	if(i == requiredPremissions){
		// 		statusAllowed = userPremission[i]
		// 	}
		// 	if(!statusAllowed){
		// 		break;
		// 	}
		// }
		// return statusAllowed
	}
}

function checkAccesByUserType(allowedUserTypes, currentUserType) {
	return allowedUserTypes.indexOf(currentUserType) == -1 ? false : true
}

function moveUserToPreviousPage() {
	setDeportaionUrl(undefined)
	window.history.back();
}