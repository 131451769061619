import React            from 'react'
import language_variant from 'store/languages'
import ClickOutside     from 'react-click-outside'
import { arrayIsEmpty } from "sl-library"

//props:
// list=[{id:'..', name: '..', value: '..'}],
//default_value = {id:'..', name: '..', value: '..'},
//class_name (sting) - class name of container
//class_on_active (sting) - class name of itemlist
//height (integer) - value in pixels
//return_value return result of select
export default class SimpleSelectList extends React.Component{
	constructor(props) {
		super(props);
		const { default_value, placeholder, list, selected_item } = this.props;
		this.defValue = { id: null, name: placeholder ? placeholder : '', value: null };
		this.defValue = default_value ? default_value : this.defValue;

		this.state = {
			list:          list,
			selected_item: selected_item ? selected_item : this.defValue,
			active:        false,
			warning:       false,
		}

	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			list:          nextProps.list,
			selected_item: nextProps.selected_item ? nextProps.selected_item : this.defValue,
		})
	}
	setWarning = () => this.setState({ warning: true });

	selectValue = (e) => {
		const { list } = this.state;
		const value = e.target.attributes.getNamedItem('value').value;
		const selected_item = list.find(key => value == key.value);
		this.setState({
			active: false,
			warning: false,
		})
		this.props.returnValue(selected_item)
	}
	render() {
		const { active, selected_item, list, warning } = this.state;
		const { class_name, class_on_active, listHeight, zIndex, disabled } = this.props;

		const height = listHeight ? listHeight : 150;
		const listStyle = { maxHeight: active ? height + 'px' : null };
		const listCssClass = active ? 'list list_open' : 'list';
		return(
				<ClickOutside 	className = 		{ class_name ? class_name : 'container_of_select_list' }
								style = 			{ zIndex ? {zIndex: zIndex, position: 'relative'} : null}
								onClickOutside =	{ () => this.setState({active: false}) }>
					<div className = 'simple_select_list'>
						<button name =      'currentItem'
								style =     { { boxShadow: !!warning ? 'box-shadow: 0 2px 8px 0 rgba(243, 135, 135, 0.33)' : null } }
								className = 'selected_item'
								disabled = 	{ disabled }
								onClick =   { () => this.setState({ active: true }) }>
								{ selected_item ? selected_item.name : null }
								{ !disabled ? <i className = 'triangle' style = { styleTriangle(active) }></i> : null 								}
						</button>
						<ul className = { listCssClass }
							style = 	{ listStyle }>
							<SelectItemsList 	listForShow =   { list }
												onSelect =      { this.selectValue }
												selectedValue = { selected_item.value } />
						</ul>
					</div>
				</ClickOutside>
			)
	}
}


function SelectItemsList(props){
	const {listForShow, onSelect, selectedValue} = props;
	return 	arrayIsEmpty(listForShow) ?
			listForShow.map(item => <OneSelectItem 	{...item}
			                						key = {item.value}
			                						onSelect = {onSelect}
			                						selectedValue = {selectedValue} />) :
			<li>{language_variant.no_data}</li>
}

function OneSelectItem(props){
	const styleSelected_item = props.value === props.selectedValue ? { fontWeight: 'bold' } : null;
	return <li 	key =     { props.id }
			 	value =   { props.value }
			 	style =   { styleSelected_item }
			 	onClick = { props.onSelect } >
				{props.name}
			</li>
}

export function styleTriangle(active) {
	return 	active?
			{
				borderLeft: '4px solid transparent',
			    borderRight: '4px solid transparent',
			    borderBottom: '4px solid black',
			    borderTop: 'none',
			}:
			{
				borderLeft: '4px solid transparent',
			    borderRight: '4px solid transparent',
			    borderBottom: 'none',
			    borderTop: '4px solid black',
			}
}