import React from "react";
// import ReactTooltip from 'react-tooltip'
import {Modal}  from './PopUpWindow.jsx'

const modalContainer = document.getElementById("modal-root");
class TooltipWrapper extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
		}
		this.initialState = this.state;
		this.tooltipOffset = 10;
		const defaultClasses = {
			top:    'tooltip_on_top',
			bottom: 'tooltip_on_bottom',
			left:   'tooltip_on_left',
			right:  'tooltip_on_right',
		};
		this.cssClasses = this.props.tooltipClasses ? this.props.tooltipClasses : defaultClasses;
		this.timeOutForShow = this.props.timeOutForShow ? this.props.timeOutForShow : 1000
	}
	onMouseOut = (e) => {
		this.setState({ active: false });
	}
	onMouseOver = (e) => {
		const elForTooltip = e.currentTarget;
		const setToolTipPosition = () => setTimeout(() => this.setTooltipPosition(elForTooltip), this.timeOutForShow);
		this.setState({ active: true }, setToolTipPosition);
	}
	setTooltipPosition = (elForTooltip) => {
		const { name, place, container } = this.props;
		const tooltipContainer = modalContainer.querySelector('div [data-modal=' + name + ']');
		if(tooltipContainer){
			tooltipContainer.className = this.cssClasses[place];
			const coordinates = this.getTooltipPosition(elForTooltip, tooltipContainer);
			tooltipContainer.className = this.cssClasses[coordinates.position];
			tooltipContainer.style.top = coordinates.top + "px";
			tooltipContainer.style.left = coordinates.left + "px";
		}
	}
	getTooltipPosition = (elForTooltip, tooltipContainer) => {
		const { place } = this.props;
		const placeInfo = elForTooltip.getBoundingClientRect();
		const tooltipInfo = tooltipContainer.getBoundingClientRect();
		let position;
		switch (place) {
			case 'left':
				position = leftPosition(placeInfo, tooltipInfo, this.tooltipOffset)
				return position.left + tooltipInfo.width > placeInfo.left ?
					rightPosition(placeInfo, tooltipInfo, this.tooltipOffset) :
					position
				break;
			case 'top':
				position = topPosition(placeInfo, tooltipInfo, this.tooltipOffset);
				return position.top + tooltipInfo.height > placeInfo.top ?
					bottomPosition(placeInfo, tooltipInfo, this.tooltipOffset) :
					position
				break;
			case 'right':
				position = rightPosition(placeInfo, tooltipInfo, this.tooltipOffset);
				return position.left > placeInfo.right ?
					leftPosition(placeInfo, tooltipInfo, this.tooltipOffset) :
					position
				break;
			case 'bottom':
				position = bottomPosition(placeInfo, tooltipInfo, this.tooltipOffset);
				return position.top <= placeInfo.bottom || position.top + tooltipInfo.height > window.innerHeight ?
					topPosition(placeInfo, tooltipInfo, this.tooltipOffset) :
					position;
				break;
			default:
				position = topPosition(placeInfo, tooltipInfo, this.tooltipOffset);
				return position.top + tooltipInfo.height > placeInfo.top ?
					bottomPosition(placeInfo, tooltipInfo, this.tooltipOffset) :
					position
				break;
		}
	}

	render(){
		const {active} = this.state;
		const {className} = this.props;
		return 	<div  	className =     {this.props.container}
						onMouseEnter = 	{this.onMouseOver}
						onMouseLeave = 	{this.onMouseOut}
						data-for =      {this.props.name}
	                  	data-class =    {this.props.className}>
	                  	{this.props.children}
	                  	{
	                  		active?  //|| this.props.name == '202parking' || this.props.name == '202parking'
	                  		<Modal dataName = {this.props.name} className = { 'tooltip_when_hidden' }>
	                  			{this.props.content}
	                  		</Modal> :
	                  		null
	                  	}

                </div>
	}

}

export default TooltipWrapper;

function minusCheck(value) {
	return value > 0 ? value : 0
}

function topPosition(placeInfo, tooltipInfo, offset) {
	return	{
				top: minusCheck(placeInfo.top - offset - tooltipInfo.height),
				left: minusCheck(placeInfo.left - (tooltipInfo.width / 2) + placeInfo.width),// (placeInfo.width / 2)
				position: 'top'
			}
}

function bottomPosition(placeInfo, tooltipInfo, offset) {
	return {
		top: placeInfo.bottom + offset,
		left: minusCheck(placeInfo.left + (placeInfo.width / 2) - (tooltipInfo.width / 2)),
		position: 'bottom'
	}
}

function leftPosition(placeInfo, tooltipInfo, offset) {
	return {
		top: placeInfo.top + placeInfo.height/2 - tooltipInfo.height/2 ,
		left: minusCheck(placeInfo.left - tooltipInfo.width - offset),
		position: 'left'
	}
}

function rightPosition(placeInfo, tooltipInfo, offset) {
	return {
		top: placeInfo.top + placeInfo.height/2 - tooltipInfo.height/2 ,
		left: placeInfo.right + offset,
		position: 'right'
	}
}


// class TooltipWrapper extends React.Component {
// 	constructor(props){
// 		super(props);
// 		this.state = {
// 			active: false,
// 		}
// 		this.initialState = this.state;

// 	}

// 	onMouseOver = () => {

// 	}
// 	onMouseHover = () => {

// 	}
// 	render(){
// 		return <div    	className =     {this.props.container}

// 	                    data-class =    {this.props.className}
// 	                    data-type = 	'info'
// 	                    data-tip =      {true}
// 	                    data-for =      {this.props.name}
// 	                    scrollHide = 	{false}
// 	                    data-place =    {this.props.place}
// 	                    data-delay-show = {this.props.delay?this.props.delay:0}
// 	                    data-effect =   'solid'>
// 					{this.props.children}


// 					<Modal dataName = {this.props.name}>
// 						<ReactTooltip id={this.props.name} getContent={[() => {return this.props.content}, 250]} />
// 					</Modal>
//                  </div>
// 	}
// }
