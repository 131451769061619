export default function eventsR(state = {
	settingsParkingFilter: {
		disabled: 	true,
		hidden: 	false,
	},
	showParkingFilter: false,
	settingsFuellingFilter: {
		disabled: 	true,
		hidden: 	false,
	},
	showFuellingFilter: false,
	settingsDefuelingFilter: {
		disabled: 	true,
		hidden: 	false,
	},
	showDefuelingFilter: false,
	settingsOverspeedFilter: {
		disabled: 	true,
		hidden: 	false,
	},
	showOverspeedFilter: false,
	settingsVoltageFilter: {
		disabled: 	true,
		hidden: 	true,
	},
	showVoltageFilter: false,
	settingsTemperatureFilter: {
		disabled: 	true,
		hidden: 	true,
	},
	showTemperatureFilter: false,
	notif_list: undefined,
	all_notif: [],
	count: undefined,
}, action) {
	switch (action.type) {
		case 'ALL_NOTIF':
			return {
				...state,
				all_notif: action.setAllNotif
			};
		case 'NOTIF':
			return {
				...state,
				notif_list: action.setNotif_list,
			};
		case 'COUNT':
			return {
				...state,
				count: action.setCount,
			};
		case 'VEHICLE_PARKING_START':
			return {
				...state,
				settingsParkingFilter: action.UpdateFilterSettings,
				showParkingFilter: action.setShowEvents,
			};
		case 'FUELLING_START':
			return {
				...state,
				settingsFuellingFilter: action.UpdateFilterSettings,
				showFuellingFilter: action.setShowEvents,
			};
		case 'DEFUELLING_START':
			return {
				...state,
				settingsDefuelingFilter: action.UpdateFilterSettings,
				showDefuelingFilter: action.setShowEvents,
			};
		case 'VEHICLE_OVER_SPEED_START':
			return {
				...state,
				settingsOverspeedFilter: action.UpdateFilterSettings,
				showOverspeedFilter: action.setShowEvents,
			};
		case 'IN_DEVELOP':
			return {
					...state,
					VoltageFilter: action.VoltageFilter,
					TemperatureFilter: action.TemperatureFilter,
			};
		default:
			return state;
	}
}
eventsR.reducer = 'eventsR'
