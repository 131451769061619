import { ifNoKey, arrayIsEmpty, copyOfObject, removeElementFromList } from 'sl-library'
import {getSavedSelectedZoneTypes, saveSelectedZoneTypes} from "actions/geoRequest.js"

export default function geoR(state = {
   zoneTypes: null,
   selectedZoneTypes: getSavedSelectedZoneTypes(),
   geofences_list: null,
   geofences_list_full: null,
   geofencesForView: [],
   taskTypeId: null,
   map:  null,
}, action) {
   switch (action.type) {
      case 'SAVE_MAP_OBJECT':
         return {
            ...state,
            map: action.saveMap,
         }; 
      case 'SAVE_TASK_TYPE_ID':
         return {
            ...state,
            taskTypeId: action.saveId,
         };
      case 'ZONE_TYPES':
         return {
            ...state,
            zoneTypes: action.setZoneTypes,
            selectedZoneTypes: action.setSelectedZoneTypes,
         };
      case 'ZONE_TYPES_LIST_UPDATE':
         return {
            ...state,
            zoneTypes: action.setZoneTypes,
         };
      case 'SELECTED_ZONE_TYPES':
         saveSelectedZoneTypes(action.setSelectedZoneTypes)
         return {
            ...state,
            selectedZoneTypes: action.setSelectedZoneTypes
         }
      case 'GEOFENCES_LIST':
         const selectedZoneTypes = getSavedSelectedZoneTypes();
         const listForView =  Array.isArray(selectedZoneTypes) && action.setGeofencesList ?
                              copyOfObject(action.setGeofencesList).filter(zone => selectedZoneTypes.find(zoneType => zone.zoneTypeId == zoneType.id)) :
                              copyOfObject(action.setGeofencesList);
         return {
            ...state,
            geofences_list: action.setGeofencesList,
            geofencesForView: listForView
         };
      case 'GEOFENCES_LIST_FULL':
         return   {
            ...state,
            geofences_list_full: action.setFullList
         };
      case 'GEOFENCES_FOR_VIEW':
         return {
            ...state,
            geofencesForView: action.setListForView
         };
      case 'UPDATE_GEOFENCES_LIST':
         return {
            ...state,
            geofences_list: action.setNewGeofences_list,
            geofencesForView: action.setNewListForView
         };
      default:
         return state;
   }
}



geoR.reducer = 'geoR'
