import { combineReducers }                      from 'redux'
import { reducersInjected, currentlyInjected }  from 'actions/registry'
class Registry {
    constructor(baseReducers) {
        this._reducers = baseReducers
    }

    injectReducers(reducers) {
        console.log("%c REGISTRY injectReducers, this, reducers - ", 'color: green; font-weight: bold;',  this, reducers)
        Object.assign(
            this._reducers,
            reducers.reduce((acc, reducer) => {
                acc[reducer.reducer] = reducer
                return acc
            }, {})
        )
        this.store.replaceReducer(combineReducers(this._reducers))
    }
    get initialReducers() {
        return combineReducers(this._reducers)
    }
};
export default Registry;
