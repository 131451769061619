import React, { Component, Fragment } from 'react';
import ClickOutside                   from 'react-click-outside'
import ReactSVG                       from 'react-svg'
import SL                             from "sl-library"
import eventAction                    from "actions/filtersAction.js"
import language_variant               from 'store/languages'
import SimpleSelectList               from "oftenUsed/SimpleSelectList.jsx"
import { getSortIcon }                from "images/simple_svg_icons.jsx"
import { iconV }                      from 'images/simple_svg_icons.jsx'

const SortingViewMethods  = BaseClass => class SortViewMethods extends BaseClass{
    setInitialState(){
        const {sortValue, sortDirection} = getSortValue()
        const { is_visible_default } = this.props;
        this.state = {
            selectedSort:   sortValue,
            sortDirection:  sortDirection,
            visible:        is_visible_default ? is_visible_default : false,
        }
        this.initialState = SL.copyOfObject(this.state);
    }
    getSortDirection = (value) => {
        let {selectedSort, sortDirection} = this.state;
        if(selectedSort == value){
            if(sortDirection == 'normal'){
                sortDirection = 'reverse'
            } else{
                sortDirection = 'normal'
            }
        }
        return sortDirection
    }
    setSorting = (e) => {
        const {selectedSort} = this.state;

        const value = e.target.getAttribute('data-value')
        const sortDirection = selectedSort !== value ? 'normal' : this.getSortDirection(value); //https://jr.elsyton.com/browse/G2B-3983
        saveSortToStorage({ sortValue: value, sortDirection: sortDirection })
        this.setState({
            selectedSort:   value,
            sortDirection:  sortDirection,
            visible:        false
        }, this.props.onSelectSort);
    }
    toogleVisibility = () => {
        const {visible} = this.state;
        this.setState({ visible: !visible });
    }
}

export default class SortVehiclesViewDesktop extends SortingViewMethods(Component) {
    constructor(props){
        super(props);
        this.setInitialState();
    }
    render() {
        const { visible, selectedSort, sortDirection } = this.state;
        return  <ClickOutside   className = 'sort_view'
                                onClickOutside = { () => this.setState({visible: false}) }>
                    <DesktopRenderContent   setSorting =    {this.setSorting}
                                            visible =       {visible}
                                            toogleVisibility = {() => this.setState({visible: !visible})}
                                            selectedSort =  {selectedSort}
                                            sortDirection = {sortDirection} />
                </ClickOutside>
    }
}

export class SortVehiclesViewMobile  extends SortingViewMethods(Component) {
    constructor(props){
        super(props);
        this.setInitialState();
    }
    setSorting = (e) => {
        const value = e.target.getAttribute('data-value')
        const sortDirection = this.getSortDirection(value);
        saveSortToStorage({ sortValue: value, sortDirection: sortDirection })
        this.setState({
            selectedSort:   value,
            sortDirection:  sortDirection,
        }, this.props.onSelectSort);
    }
    render() {
        return <SortListContainer   {...this.state}
                                    setSorting = {this.setSorting}
                                    is_mobile_view = {true} />
    }
}

function DesktopRenderContent(props) {
    const {visible, selectedSort, sortDirection} = props;
    const head_list = <p>{language_variant.sorting}</p>;
    return  <Fragment>
                <button className = 'sort_btn' onClick = { props.toogleVisibility }>
                    {getSortIcon(sortDirection)}
                </button>
                <SortListContainer {...props} head_list = {head_list}/>
            </ Fragment>
}


function SortListContainer(props) {
    const {selectedSort, setSorting, visible, is_mobile_view, head_list} = props;
    const sortItemList = [
        {name: 'byName',            text: language_variant.by_name},
        {name: 'byLisensePlate',    text: language_variant.by_license_plate},
        {name: 'byTrackerNumber',   text: language_variant.by_traker_number}
    ];
    return  visible ?
            <div className = 'sort_list_container'>
                { head_list ? head_list : null }
                <ul className = 'sort_list'>
                    {
                        sortItemList.map( key =>
                            <OneSortItem    key =            {key.name}
                                            data_value =     {key.name}
                                            onClick =        {props.setSorting}
                                            selectedSort =   {selectedSort}
                                            is_mobile_view = {is_mobile_view}
                                            sort_direction = {props.sortDirection}
                                            item_text =      {key.text}/>)
                    }
                </ul>
            </div> :
            null
}

function OneSortItem(props) {
    const is_selected = props.selectedSort == props.data_value;
    const style_li = is_selected ? {fontWeight: 'bold'} : null;
    return  <li onClick =       {props.onClick}
                data-value =    {props.data_value}
                style =         {style_li}>
               <IconSelected is_selected = {is_selected}  is_mobile_view = {props.is_mobile_view}/>
               {props.item_text}
               <DirectionIndicator sort_direction = {props.sort_direction} is_selected = {is_selected} is_mobile_view = {props.is_mobile_view}/>
            </li>
}

function IconSelected(props) {
    return  props.is_mobile_view ? <i className="icon_V_in_sort_list"> { props.is_selected? iconV() : null } </i> : null
}

function DirectionIndicator(props){
    return  props.is_mobile_view ? <i className="icon_SortDirection_in_sort_list"> { props.is_selected ? getSortIcon(props.sort_direction) : null }</i> : null
}

export function saveSortToStorage(valueObj) {
    localStorage.setItem('sortValue', JSON.stringify(valueObj))
}

export function getSortValue() {
    const valueFromStorage = localStorage.getItem('sortValue');
    return SL.isJsonString(valueFromStorage) ? SL.isJsonString(valueFromStorage) : {sortValue: 'byTrackerNumber', sortDirection: 'normal'}
}

// By name
// By license plate
// By tracker number

// SortVehicles.jsx


