if(!window.isIE && !!window.StyleMedia){
    alert("We are sorry, but we don't support IE browsers, try other")
}

import React, { Suspense, Fragment, useEffect, Context, useState } from "react";
import ErrorBoundary                 from './ErrorBoundary.jsx'
import { Route, Switch }             from 'react-router-dom';
import ReactTooltip                  from 'react-tooltip'
import PopUpNotifcation              from './PopUpNotifcation.jsx'
import axios                         from 'axios'
import authActions                   from "actions/authActions.js"
import "css/main.scss";



let authorization_reducer = import('reducers/authorization_reducer.js'),
controlPage_reducer =       import('reducers/controlPage_reducer.js'),
routeDetail_reducer =       import('reducers/routeDetail_reducer.js'),
tasks_reducer =             import('reducers/tasks_reducer.js'),
settings_reducer =          import('reducers/settings_reducer.js'),
events_reducer =            import('reducers/events_reducer.js'),
report_reducer =            import('reducers/report_reducer.js');


const Helmet = React.lazy(()               => import('react-helmet'));
const Form = React.lazy(()                 => import('./pages/form'));
const Control = React.lazy(()              => import('./pages/controlPage.jsx'));
const Tasks = React.lazy(()                => import('./pages/tasksPage')) //disabled uncompleted
const NewTasks = React.lazy(()             => import('./pages/newTask')) //disabled uncompleted
const NewTasksClone = NewTasks; //disabled uncompleted
const RouteDetailPage = React.lazy(()      => import('./pages/routeDetailPage'))
const UserProfile = React.lazy(()          => import('./pages/userProfilePage'));
// const Employees = React.lazy(()         => import('./pages/employeesPage')); //disabled uncompleted
const ReportsPage = React.lazy(()          => import('./pages/reportsPage'));
const CustomReport = React.lazy(()         => import('./pages/createCustomReport'));
const AllNotificationsPage = React.lazy(() => import('./pages/allNotificationsPage'));
const UserNotification = React.lazy(()     => import("./UserNotification.jsx"));
const Navigation = React.lazy(()           => import("./modules/Navigation.jsx"));
const NotFound = React.lazy(()             => import('./pages/notFound'));
const AuthFromAdminPanel = React.lazy(()   => import('./pages/AuthFromAdminPanel'));
const RestorePasswordPage = React.lazy(()  => import('./pages/RestorePasswordPage'));
const AppDoorMan = React.lazy(()           => import('./AppDoorMan.jsx'))
const GeofenceAndAdresses = React.lazy(()  => import('./modules/GeofenceAndAdresses/AddressesGeofences.jsx'));
const PageForLoginDemo = React.lazy(()     => import('./pages/PageForLoginDemo.jsx'));
const UsersManagment = React.lazy(()       => import('./pages/UsersManagement.jsx'));
const TrailersPage = React.lazy(()         => import('./pages/trailersPage.jsx'));
const TaskDetail = React.lazy(()           => import('./pages/TaskDetailPage.jsx'));
const RfidCardsPage = React.lazy(()        => import('./pages/rfidCardsPage.jsx'));
const VersionAppView = React.lazy(() => import('./VersionAppView.jsx'))



/* now hidden but addresses library was planned
const GeofenceAndAdresses = React.lazy(()  => import('./modules/GeofenceAndAdresses/AddressesGeofences.jsx'));*/

// const AprilPopUp = React.lazy(()        => import ('./modules/AprilPopUp.jsx'))

export const GlobalContext = React.createContext({
    get screenWidth(){
        return window.innerWidth
    },
    get screenHeight(){
      return window.innerHeight
    },
    onSizeUpate: null,//set from subscribed components


});

GlobalContext.displayName = 'GlobalContext';

const All_Tasks = () => (
        <Switch>
            <Route  path = '/tasks/tasks_list'
                    component = { Tasks}         />
            <Route  path = '/tasks/routes_list'
                    component = { Tasks}         />
            <Route  path = '/tasks/active'
                    component = { Tasks}         />
            <Route  path = '/tasks/planned'
                    component = { Tasks}         />
            <Route  path = '/tasks/completed'
                    component = { Tasks}         />
        </Switch>
);
const ProfileSettings = () => (
    <Switch>
            <Route  path = '/profile/main'
                    component = { UserProfile}         />
            <Route  path = '/profile/password'
                    component = { UserProfile}         />
            <Route  path = '/profile/notifications'
                    component = { UserProfile}         />
            <Route  path = '/tasks/planned'
                    component = { UserProfile}         />
            <Route  path = '/tasks/completed'
                    component = { UserProfile}         />
    </Switch>
);
const All_Reports = () => (
    <Suspense fallback = { <ViewOnScriptLoading text = 'All_Reports'/> }>
        <Switch>
                    <Route  exact path = '/reports'
                            component = { ReportsPage}  />

                    <Route  path = '/reports/createCustomReport'
                            component = { CustomReport} />
        </Switch>
    </Suspense>
);

function ViewOnScriptLoading(props) {
    return <div>{props.text} { props.text ? 'Loading ...' : null }</div>
}

function App(props) {
    const [state, updateState] = useState({
        screenWidth:    window.innerWidth,
        screenHeight:   window.innerHeight
    });
    useEffect(() => {
        const handleInvalidToken = e => e.key === 'sid' && e.oldValue !== e.newValue ? window.location.reload() : null;
        const setSizes = _.throttle( e => updateState({ //need for re-rendering child components
            ...state,
            screenWidth:    window.innerWidth,
            screenHeight:   window.innerHeight
        }), 500);
        const onHashChange = e =>  updateState({
            ...state,
            hash: window.location.hash
        });

        window.addEventListener('storage', handleInvalidToken);
        window.addEventListener('resize', setSizes);
        window.addEventListener("hashchange", onHashChange, false);


        return function cleanup() {
            window.removeEventListener('storage', handleInvalidToken);
            window.removeEventListener('resize', setSizes);
            window.removeEventListener('hashchange', onHashChange);
        }

      }, [authActions.logOut])
    return  <Fragment>
            <GlobalContext.Provider value = { state }>
                <ErrorBoundary>
                    <Suspense fallback={<ViewOnScriptLoading text = ''/>}>
                        <AppDoorMan />
                    </Suspense>
                </ErrorBoundary>

               <ErrorBoundary>
                    <Suspense fallback = {<ViewOnScriptLoading text = ''/>}>
                        <Navigation />
                    </Suspense>
                    <Suspense fallback={<ViewOnScriptLoading text = ''/>}>
                        <Helmet titleTemplate='%s - Globus application'
                                titleAttributes={{itemprop: 'name', lang: 'en'}}>
                        </Helmet>
                    </Suspense>
                </ErrorBoundary>
                <ErrorBoundary>
                    <PopUpNotifcation />
                </ErrorBoundary>
                {/*<Suspense fallback={<ViewOnScriptLoading text = ''/>}>
                    <AprilPopUp />
                </Suspense>*/}
                <Suspense fallback={<ViewOnScriptLoading text = 'pages' />}>
                    <Switch>
                            <Route  exact
                                    path = '/'
                                    component = { Form } />
                            <Route  path = '/control'
                                    component = { Control } />
                            <Route  path = '/routeDetail/:vehicleId/:transport_name?/:license_plate?/:event_date?'
                                    component = { RouteDetailPage }/>
                            <Route  path = '/profile'
                                    component = { ProfileSettings }/>
                            <Route  path = '/rfid_cards'
                                    component = { RfidCardsPage }/>
                           {/* {  //from old concept
                                DEV_SERVER ?
                                <Route  path = '/employees'
                                        component = { Employees}  /> :
                                null
                            }*/}
                            <Route  path = '/tasks'
                                    component = { All_Tasks}  />
                            <Route  path = '/geozones_and_addresses/:geoId?'
                                    component = { GeofenceAndAdresses} />
                            {/*because submenu must be the same thing*/}
                            <Route  exact path = '/reports'
                                    component = { ReportsPage}  />
                            <Route  path = '/reports/createCustomReport'
                                    component = { CustomReport} />
                            <Route  path = '/notifications'
                                    component = { AllNotificationsPage} />
                            <Route  path = '/commingFromAdmin/:SID/:login/:vehicleId?/:vehicleName?/:licensePlate?'
                                    component = { AuthFromAdminPanel} />
                            <Route  path = '/change-password/:login/:SID'
                                    component = { RestorePasswordPage} />
                            <Route  path = '/demo_account'
                                    component = { PageForLoginDemo} />
                            <Route  path = '/users_management'
                                    component = { UsersManagment} />
                            <Route  path = '/trailers_management'
                                    component = { TrailersPage} />
                            <Route  path = '/task_detail/:taskStateId'
                                    component = { TaskDetail} />

                            <Route  path = "*"
                                    component = { NotFound} />

                    </Switch>
                </Suspense>
                <ErrorBoundary>
                    <Suspense fallback={<ViewOnScriptLoading text = ''/>}>
                        <UserNotification />
                    </Suspense>
                </ErrorBoundary>
                <ReactTooltip />
                <VersionAppView />
            </GlobalContext.Provider>
            </Fragment>
}


export default App;




//for include images in buildFolder, need find better decission

import "images/batman.jpg"
import "images/batman.ico"

