import React, { Component, Fragment, Context }  from 'react';
import helpFunc                                 from './helpFunc.js'
import {connect}                                from 'react-redux';
import SL                                       from 'sl-library'

function getSidebarStatus() {
   if(SL.isMobileView){
      return localStorage.getItem('sideBarStatus') ? localStorage.getItem('sideBarStatus') : 'shorted'
   }else{
      return localStorage.getItem('sideBarStatus') ? localStorage.getItem('sideBarStatus') : 'default'
   }
}

export const SideBarContext = React.createContext({
    map: null,
    get sideBarStatus(){
        return getSidebarStatus();
    }
});

class LeftSidebarResizer extends Component {
   constructor(props) {
      super(props);
      this.state = {
         sideBarStatus: getSidebarStatus(),
         simpleListViewEnabled: this.props.simpleListViewEnabled,
      }
      this.initialState = this.state;
   }
   static getDerivedStateFromProps(props, state) {
      if(props.simpleListViewEnabled !== state.simpleListViewEnabled) {
         //for simple list shorted is good width)
         const newSideBarStatus = props.simpleListViewEnabled ? 'shorted' : getSidebarStatus();

         return {
            simpleListViewEnabled: props.simpleListViewEnabled,
            sideBarStatus: newSideBarStatus,
         }
      } else {
         return null
      }
   }
   udpateViewStatus = () => {
      const { sideBarStatus } = this.state;
      const newSideBarStatus = !SL.isMobileView ? stepOrder(sideBarStatus) : toogleSidebarStatus(sideBarStatus);
      this.setState({
         sideBarStatus: newSideBarStatus
      }, () => {
         localStorage.setItem('sideBarStatus', newSideBarStatus)
         // if(SL.isMobileView){//
         //    let sideBar_X_position;
         //    if(newSideBarStatus == 'shorted') {
         //       sideBar_X_position = - window.innerWidth + 10;
         //    } else {
         //       sideBar_X_position = 0;
         //    }
         //    // this.contentSidebar.style.left = `${sideBar_X_position}px`;
         // }
      });

   }

   componentDidUpdate(prevProps, prevState) {
      updateMapWidth(this.sideBarWidth)
   }
   componentWillUnmount() {
      this.mounted = false;
   }
   setDefaultView = () => this.mounted ? this.setState({ sideBarStatus: 'default' }) : null
   componentDidMount() {
      this.mounted = true;
      // window.setDefaultViewForSidebar = this.setDefaultView
      if(SL.isMobileView) {
         this.addListennersOnMobile();
         updateMapWidth(this.sideBarWidth)
      } else {
         updateMapWidth(this.sideBarWidth)
      }
   }
   addListennersOnMobile(){
      window.addEventListener('touchstart', this.saveTouchStartX);
      window.addEventListener('touchend', this.onTouchEndCalculations);
      this.contentSidebar.addEventListener('touchmove', this.saveTouchSidebarStatus );
   }

   saveTouchStartX = e => this.touchStartX = e.changedTouches[0].pageX
   saveTouchSidebarStatus = eOrValue => this.touch_is_on_sidebar = eOrValue
   onTouchEndCalculations = e => {
      this.touchEndX = e.changedTouches[0].pageX;
      const start_end_diff = this.touchEndX - this.touchStartX;
      let side_bar_left_pos;
      const updateSidebarPosition = () => this.contentSidebar.style.left = side_bar_left_pos + 'px';
      if(this.touch_is_on_sidebar && Math.abs(start_end_diff) > 80) {
         if(this.touchEndX > this.touchStartX) {
            side_bar_left_pos = 0;
            this.setState({ sideBarStatus: 'shorted'}, updateSidebarPosition);
         } else {
            side_bar_left_pos = -window.innerWidth;//10 - window.innerWidth;
            this.setState({ sideBarStatus: 'hidden'}, updateSidebarPosition);
         }


      }
      this.saveTouchSidebarStatus(false);
   }
   get sideBarWidth(){
      const { sideBarStatus } = this.state;
      const width_when_short_view = SL.isMobileView ? window.innerWidth : 350;
      const getWidthOnDefault = () => {
         const minWidth = 512,
            maxWidth = 853,
            halfScreen = window.innerWidth / 2;
         if(halfScreen <= maxWidth && halfScreen >= minWidth) return halfScreen
         if(halfScreen > maxWidth) return maxWidth;
         if(halfScreen < minWidth) return minWidth;
      };
      if(sideBarStatus == 'default') return getWidthOnDefault();
      if(sideBarStatus == 'shorted') return width_when_short_view;
      if(sideBarStatus == 'hidden') return 0
   }
   get styles() {
      const { sideBarStatus } = this.state;
      const containerStyle = { left: '0px' };
      const triggerStyle = {}
      if(sideBarStatus == 'hidden') {
         containerStyle.width = `${this.sideBarWidth}px`;
         containerStyle.left = '-7px';
         triggerStyle.filter = 'invert(1)';
         if(!SL.isMobileView) {
            triggerStyle.right = '-34px';
            triggerStyle.width = '30px';
            triggerStyle.borderLeft = '3px solid #1d2329';
         }



      }
      if(sideBarStatus == 'default') containerStyle.width = `${this.sideBarWidth}px`;
      if(sideBarStatus == 'shorted') {
         containerStyle.width = `${this.sideBarWidth}px`
         containerStyle.left = '0px';

      }

      if(SL.isMobileView) {
         containerStyle.top = '70px';
         if(sideBarStatus == 'hidden') {
            containerStyle.left = `-${window.innerWidth}px`;
         }
      }

      return {
         containerStyle,
         triggerStyle
      }
   }

	render() {
        const { sideBarStatus, simpleListViewEnabled } = this.state;
        const shorted_class = sideBarStatus == 'shorted' ? 'short_view' : '';
        const hidden_class = sideBarStatus == 'hidden' ? 'hidden_view' : '';
        return <div  className = {`left_sidebar_container ${shorted_class} ${hidden_class}`}
                     style =     { this.styles.containerStyle }
                     ref =       { (contentSidebar) => this.contentSidebar = contentSidebar } >
                     <div  className =    "trigger_triangle"
                           style =        { this.styles.triggerStyle }
                           onClick =      { this.udpateViewStatus }>
                           <Symbol sideBarStatus = { sideBarStatus }/>
                     </div>
                     <div className = "left_sidebar" id = "left_sidebar">
                         {this.props.children}
                     </div>
                </div>

	}
}

export default connect(
    state => ({
        simpleListViewEnabled:   state.globalR.controlR.simpleListViewEnabled,
    })
)(LeftSidebarResizer)

function updateMapWidth(sideBarWidth) {
   const mapEl = document.getElementById('map_wrapper');
   mapEl.style.width = `${window.innerWidth - sideBarWidth}px`;
   setTimeout(() => SideBarContext.map ? SideBarContext.map.invalidateSize() : null, 400);
}


function stepOrder(currentStep) {
   if(currentStep == 'default') {
      return 'shorted'
   }
   if(currentStep == 'shorted') {
      return 'hidden'
   }
   if(currentStep == 'hidden') {
      return 'default'
   }
}

function toogleSidebarStatus(currentStatus) {
   if(currentStatus == 'shorted' || currentStatus == 'default') {
       return 'hidden'
   }else{
      return 'shorted'
   }

}

function Symbol(props) {
   let { sideBarStatus } = props;
   let symbol;
   if(sideBarStatus == 'default') symbol = 'triangle_left';
   if(sideBarStatus == 'hidden') symbol = 'triangle_right';
   if(sideBarStatus == 'shorted') symbol = 'triangle_left';
   return   SL.isMobileView ?
            <Fragment>
              <i className = 'triangle_right'></i>
              <i className = 'triangle_left'></i>
            </Fragment> :
      <i className = {symbol}/>;
}




