import React, {Fragment}            from 'react';
import language_variant             from 'store/languages'
import moment                       from 'moment';
import {
    nd_icon,
    antennaIcon,
    securityIcon,
    sosIcon,
    icon_inmidcourseMoving,
    icon_inmidcourseParking,
    noGpsIcon,
    infoAboutVehicleIcon,
    powerRestored,
    speedMeterIcon,
    fuelTankIcon,
    // keyIcon,
    keyIcon1,
    keyIcon2,
    keyIcon3,
    keyIcon4,
    data_reception_disabled_icon,
} from 'images/simple_svg_icons.jsx'

import ReactTooltip                 from 'react-tooltip'
import SL                           from 'sl-library'
import TooltipWrapper               from 'oftenUsed/TooltipWrapper.jsx'
import helpFunc                     from './helpFunc.js'
import {SideBarContext}             from './LeftSidebarResizer.jsx'
import ControlContext               from "./ControlContext.js"
import SeparPremission              from "oftenUsed/SeparPremission.jsx"

export {FuelLevel, Security, SosIcon, SpeedInfoForInfoWindow, IgnitionInfoForInfoWindow, Inmidcourse, AntennaIcon}
export default class VehicleStatus extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            antStatus:          this.props.antStatus,
            lastKnownTime:      SL.ifNoKey(this.props.vehicle_state, 'lastKnownTime'),
            vehicle_state:      this.props.vehicle_state,
            lockStateDto:       this.props.lockStateDto,
            deviceSettingsDto:  SL.ifNoKey(this.props.currentDeviceMapping, 'deviceSettingsDto'),
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        let deviceSettingsDto = SL.ifNoKey(nextProps.currentDeviceMapping, 'deviceSettingsDto');
        this.setState({
            antStatus:          nextProps.antStatus,
            lastKnownTime:      SL.ifNoKey(nextProps.vehicle_state, 'lastKnownTime'),
            vehicle_state:      nextProps.vehicle_state,
            lockStateDto:       nextProps.lockStateDto,
            deviceSettingsDto:  deviceSettingsDto,
        });
        // const {multiSensorValues, realSensorValues} = ControlContext.compareSensorsVSSensorValues(
        //                                                                                       vehicle_state.multiSensorValues,
        //                                                                                       vehicle_state.realSensorValues,
        //                                                                                       vehicleId)
    }
    render(){
        let {vehicle_state, lockStateDto, deviceSettingsDto, antStatus, lastKnownTime} = this.state;
        let {vehicleId, showStatus} = this.props;
        if(vehicle_state && showStatus){
            return  <SideBarContext.Consumer>
                        {
                            (sideBarContext) => <div    className = 'status'
                                                        data-value = {vehicleId}>
                                                        <CurrentStatus  vehicle_state = {vehicle_state}
                                                                        parrentProps = {this.props}
                                                                        vehicleId = {vehicleId}/>

                                                        <div className="update_data">
                                                            <SosIcon    status =    {SL.ifNoKey(deviceSettingsDto, 'alarm')}
                                                                        timeStamp = {SL.ifNoKey(deviceSettingsDto, 'alarmTimestamp')}
                                                                        vehicleId = {vehicleId} />

                                                            <Security   lockStateTime = {SL.ifNoKey(lockStateDto, 'lockStateTime')}
                                                                        lockState = {SL.ifNoKey(lockStateDto, 'lockState')}
                                                                        vehicleId =     {vehicleId} />
                                                            <SecurityAlarm  lockedAlarm =   {SL.ifNoKey(deviceSettingsDto, 'lockedAlarm')}
                                                                            lockStateTime = {SL.ifNoKey(lockStateDto, 'lockStateTime')}
                                                                            vehicleId =     {vehicleId} />
                                                            <StatusNoGps {...vehicle_state}/>
                                                            <StatusPowerRestored {...vehicle_state}/>
                                                            <SygnalInfo antStatus = {antStatus}
                                                                        vehicleId = {vehicleId}
                                                                        shortedView = {sideBarContext.sideBarStatus == 'shorted'}
                                                                        lastKnownTime = {lastKnownTime} />
                                                        </div>
                                                </div>
                        }
                    </SideBarContext.Consumer>

        }else {
            return  showStatus ?
                    <div className="status" data-value = {vehicleId}>
                        <p className="current_status">{language_variant.no_data}</p>
                    </div> :
                    null
        }
    }
}


//{language_variant.tank}{tankNumber}
function Security(props){
    let {vehicleId, lockState, lockStateTime} = props;
    let content =   language_variant.stay_on_quard_from + ' ' + moment(lockStateTime).format('DD.MM.YYYY - HH:mm:ss');
    return  lockState ?
            <TooltipWrapper    className =         'antenna_tooltip'
                                container =         'event security'
                                name =              {'security' + vehicleId}
                                place =             'bottom'
                                content =           {content}>
                    {securityIcon('shield', false)}
            </TooltipWrapper> :
            null
}

function SecurityAlarm(props) {
    const {lockStateTime, lockedAlarm, vehicleId} = props;
    const content = language_variant.was_alarm_locked + ' ' + moment(lockStateTime).format('DD.MM.YYYY - HH:mm:ss');
    return  lockedAlarm ?
            <TooltipWrapper className =         'antenna_tooltip'
                            container =         'event security'
                            name =              {'security_alarm' + vehicleId}
                            place =             'bottom'
                            content =           {content}>
                    {securityIcon('shield', lockedAlarm)}
            </TooltipWrapper> :
            null
}

function SosIcon(props){
    let {timeStamp, status, vehicleId}  = props;
    let content  = language_variant.alarmDrawdown + ' ' + moment(timeStamp).format('DD.MM.YYYY - HH:mm:ss');
    return  !!status
            ?<TooltipWrapper    className =         'antenna_tooltip'
                                container =         'event SOS_ALARM'
                                name =              {'SOS' + vehicleId}
                                place =             'bottom'
                                content =           {content}>
                                {sosIcon('SOS_ALARM')}
            </TooltipWrapper>
            :null
}

function IgnitionInfo(props){
    let {ignitionOn, vehicleId} = props;
    const textTooltip = ignitionOn
                        ?(language_variant.engine + ' ' + language_variant.ON)
                        :(language_variant.engine + ' ' + language_variant.OFF)
        if(ignitionOn !== undefined){
            return  <TooltipWrapper className =         'antenna_tooltip'
                                    container =         'one_block_on_transport'
                                    name =              {'engine_status-' + vehicleId}
                                    place =             'top'
                                    content =           { textTooltip}>
                        <span className='dot'></span>
                        {keyIcon3(ignitionOn)}
                    </TooltipWrapper>


        }else{
            return null
        }
    {/*<span className = 'one_block_on_transport'>
        <span className='dot'></span>
        {keyIcon3(ignitionOn)}
        {
            !!ignitionOn
            ?(language_variant.engine + ' ' + language_variant.on)
            :(language_variant.engine + ' ' + language_variant.off)
        }
    </span>*/}
}

function IgnitionInfoForInfoWindow(props) {
    const {ignitionOn, colors_enabled} = props;
    const text_on = colors_enabled ? <span style = {{color: 'green'}}>{language_variant.ON}</span> : language_variant.ON; 
    const text_off = colors_enabled ? <span style = {{color:'darkgrey'}}>{language_variant.OFF}</span> : language_variant.OFF;
    return  typeof ignitionOn == 'boolean' ?
            <p className = 'one_block_on_transport'>
                   {
                        ignitionOn
                        ?<Fragment><b>{language_variant.engine}:</b>{text_on}</Fragment>
                        :<Fragment><b>{language_variant.engine}:</b>{text_off}</Fragment>
                    }
            </p> :
            null
}

function Inmidcourse(props) {
    let {vehicleId, vehiclesDataGpsDataDto, deviceStatus, currentDeviceMapping} = props;
    const deviceOn = SL.ifNoKey(currentDeviceMapping, 'deviceOn')
    // console.log("%c vehiclesDataGpsDataDto", 'color: green; font-weight: bold;',  vehiclesDataGpsDataDto, props)
    let ignitionOn, speed;
    if(vehiclesDataGpsDataDto){
        ignitionOn = vehiclesDataGpsDataDto.ignitionOn;
        speed = vehiclesDataGpsDataDto.speed;
    }
    const statusMarker = helpFunc.getVehicleMarkerStatus(props); //no_signal normal move
    const textTooltip = deviceOn ?
                        getTooltipTextForInMidCourese(statusMarker) :
                        language_variant.disabled_receiving_data;

    const iconByMoveStatus = () =>  statusMarker == 'move' ?
                                    icon_inmidcourseMoving(statusMarker) :
                                    icon_inmidcourseParking(null, statusMarker);

    const icon = deviceOn === false ? data_reception_disabled_icon() : iconByMoveStatus();
    return  <TooltipWrapper className =         'antenna_tooltip'
                            container =         'one_block_on_transport'
                            name =              {'in_mid_course-' + vehicleId}
                            place =             'top'
                            content =           { textTooltip}>
                { icon }
            </TooltipWrapper>
}

function getTooltipTextForInMidCourese(statusMarker) {
    switch (statusMarker) {
           case 'no_signal':
               return  language_variant.not_connected
                   return
           case 'move':
               return language_variant.movement
                   return
           case 'normal':
               return language_variant.parking
                   return
           default:
               return language_variant.no_data
       }
}

function SygnalInfo(props) {
    const { antStatus, vehicleId, lastKnownTime, shortedView} = props;
    return  <Fragment>
                <div className = 'visible_on_shorted_view'>
                    <AntennaIcon status = {antStatus} vehicleId = {vehicleId}/>
                </div>
                <div className = 'event antena hidden_on_shorted line-last_date_of_send_data'>
                    <AntennaIcon status = {antStatus} vehicleId = {vehicleId}/>
                    <span className = "grey"> {language_variant.last_time_data}: </span>
                    <span className = 'last_date_data_send_info'>
                        {lastKnownTime ? moment(lastKnownTime).format("DD.MM.YYYY - HH:mm:ss") : language_variant.no_data}{/*moment.utc(this.state.lastKnownTime, "YYYYMMDD, HH:mm:ss a").fromNow()*/}
                    </span>
                </div>
            </Fragment>
}

function NCStatusView(props) {
    const {vehicleId} = props;
    const textTooltip = language_variant.no_data;;
    return  <TooltipWrapper className =         'antenna_tooltip'
                            container =         'one_block_on_transport'
                            name =              {'in_mid_course-' + vehicleId}
                            place =             'top'
                            content =           { textTooltip}>
                 {nd_icon}
            </TooltipWrapper>
}

export const tooltipClasses = {
    top: 'antenna_tooltip_top antenna_tooltip_top_shorted_status',
    bottom: 'antenna_tooltip_bottom antenna_tooltip_bottom_shorted_status',
    left: 'antenna_tooltip_left antenna_tooltip_left_shorted_status',
    right: 'antenna_tooltip_right antenna_tooltip_right_shorted_status',
}

function AntennaIcon(props){
    let {status, vehicleId} = props;
    const content = <TextStatusAboutAntenna status = { status }  />
    const antennaHaveProblem = !SL.ifNoKey(status, 'antConnected') || SL.ifNoKey(status, 'shortMode');
    return  <TooltipWrapper tooltipClasses = { tooltipClasses }
                            container = 'event antena'
                            name =      { 'antennaFor' + vehicleId }
                            place =     'bottom'
                            content =   { content } >
                    {antennaIcon(antennaHaveProblem, SL.ifNoKey(status, 'shortMode'))}
            </TooltipWrapper>
}

export function TextStatusAboutAntenna(props) {
    let {status, isStatusForWindow} = props;
    const textUpdateStatus = !isStatusForWindow && status ?
                             <p >
                                {
                                    SL.ifNoKey(status,'updateTime') ?
                                    language_variant.status_was_updated + ': ' + moment(status.updateTime).format('DD.MM.YYYY - HH:mm:ss') :
                                    language_variant.antena_info_absent
                                }
                             </p> :
                             null;
    return  !!status
            ?<div >
                <p className = 'one_block_on_transport'>
                    { SL.ifNoKey(status, 'antConnected') ? language_variant.antena_connected : language_variant.antenna_was_disabled } 
                </p> 
                { SL.ifNoKey(status, 'shortMode') ? <p><b>{ language_variant.DEVICE_SHORT_MODE_ON } </b></p> : '' } 
                {textUpdateStatus}
            </div>
            :<div>
               <p>{language_variant.antena_info_absent_check_device}</p>
            </div>;
}

function StatusNoGps(props) {
   const {vehiclesDataGpsDataDto, vehicleId} = props;

   return   helpFunc.getNoGpsStatus(vehiclesDataGpsDataDto) ?
            <TooltipWrapper tooltipClasses = { tooltipClasses }
                            container =      'event icon_no_gps'
                            name =           {'no_location_'+vehicleId}
                            place =          'bottom'
                            content =        {language_variant.no_coordinates}>
                            {noGpsIcon('icon_no_location')}
            </TooltipWrapper>:
            null
}

function StatusPowerRestored(props) {
    const {vehiclesDataInputsEventDto, vehicleId} = props;
    return  vehiclesDataInputsEventDto && vehiclesDataInputsEventDto.powerRestored ?
            <TooltipWrapper tooltipClasses = { tooltipClasses }
                            container =      'event icon_no_gps'
                            name = {'powerRestored_'+vehicleId}
                            place = 'bottom'
                            content =   {language_variant.power_restored_text}>
                {powerRestored('icon_no_location')}
            </TooltipWrapper>:
            null
}

function CurrentStatus(props) {
    let {vehicle_state, vehicleId, parrentProps, nc_status} = props;
    let allContent =   <p className = 'current_status hidden_on_shorted'></p>
    return  <Fragment>
                {
                    vehicle_state.nc_status ?
                    <NCStatusView vehicleId = {vehicleId}/> :
                    <Inmidcourse    {...vehicle_state} vehicleId = {vehicleId}/>
                }
                <IgnitionInfo   ignitionOn = {SL.ifNoKey(vehicle_state.vehiclesDataGpsDataDto, 'ignitionOn')}
                                vehicleId = {vehicleId}/>
                <span className = 'dot'></span>
                <SpeedInfoInList {...vehicle_state.vehiclesDataGpsDataDto}/>
                <FuelLevel      multiSensorValues = {vehicle_state.multiSensorValues}
                                realSensorValues =  {vehicle_state.realSensorValues}
                                {...vehicle_state}
                                {...parrentProps}
                                vehicleId = {vehicleId}/>
                {allContent}
            </Fragment>
}

function FuelLevel(props){
    let {multiSensorValues, realSensorValues, vehicleId} = props;
    // let allSensorsPresent = ControlContext.compareSensorsVSSensorValues(multiSensorValues, realSensorValues, vehicleId)
    let allSensorsPresent = checkFuelSensorsValue(realSensorValues);
    let fuelLevel = [], vahicleHaveMultisensors;
    if(!allSensorsPresent){
        fuelLevel.push(<span className = 'one_block_on_transport' key='some_sensor_not_work'>
                            <span className = 'dot'> · </span>
                            <span>{language_variant.absentDataFromSomeSensors}</span>
                        </span>)
    }
    if(SL.arrayIsEmpty(multiSensorValues)){
        multiSensorValues.find((key,index)=>{
            if(key.sensorType == "FUEL_LEVEL" ){ //&& key.visible
                vahicleHaveMultisensors = true;
                let value = SL.roundValue(key.value, 10);
                fuelLevel.push( <span key={'fuelLevell'+index} className = 'one_block_on_transport'>
                                    <span className = 'hidden_on_shorted'><span className = 'dot'> · </span>{key.name}: </span>
                                    <span className = 'visible_on_shorted_view'>{fuelTankIcon}</span>
                                    <span className='status_value'>{value} </span>
                                    <span className='grey' style={{padding: '0px'}}> {language_variant.l}</span>
                                </span>)
            }
        })
    }//{language_variant.fuel_level}
    if(SL.arrayIsEmpty(realSensorValues) && !vahicleHaveMultisensors){
        let tankNumber = 0;
        realSensorValues.forEach(key=>{
            if(key.sensorType == 'FUEL_LEVEL' ){ //&& key.visible
                tankNumber += 1;
                let value = SL.roundValue(key.value, 10);
                fuelLevel.push(<span key={tankNumber} className = 'one_block_on_transport'>

                                    <span className = 'hidden_on_shorted'><span className = 'dot'> · </span>{key.name}: </span>
                                    <span className = 'visible_on_shorted_view'>{fuelTankIcon}</span>
                                    <span className='status_value'>{value}</span>
                                    <span className='grey' style={{padding: '0px'}}> {language_variant.l}</span>
                                </span>)
            }
        })
    }
    return  fuelLevel.map(key=>key)
}

function SpeedInfoInList(props) {
    let {ignitionOn, speed} = props;
    const style =   { padding: '0px', };
    return  SL.isNumeral(speed) && speed > 0 ?
            <span className = 'one_block_on_transport'>
                <span className = 'hidden_on_shorted'>{language_variant.speed}</span>
                <span className = 'visible_on_shorted_view'>{speedMeterIcon}</span>
                <span className = 'dot'></span>{getSpeedInKmH(speed)}
                <span className='grey ' style = {style}> {language_variant.kmH}</span>
            </span> :
            null
}

function getSpeedInKmH(speed) {
    return Math.round(speed*3.6*100)/100
}

function SpeedInfoForInfoWindow(props){
    let {ignitionOn, speed} = props;
    const style = { padding: '0px', };
    return  <span>
                <b>{language_variant.speed}: </b>
                { SL.isNumeral(speed) ? <span className = 'dot'>{getSpeedInKmH(speed)}</span> : '--' }
                <span className='grey ' style = {style}> {SL.isNumeral(speed) ? language_variant.kmH : '' }</span>
            </span>
}

//  <TooltipWrapper    tooltipClasses =    { tooltipClasses }
//                     container =         'event antena visible_on_shorted_view'
//                     name =              {'status_content' + vehicleId}
//                     place =             'bottom'
//                     content =           {allContent}>
//     {infoAboutVehicleIcon('info_icon')}
// </TooltipWrapper>

function checkFuelSensorsValue(realSensorValues){
    const numberForCompare = [27, 28, 32, 33, 34, 35]; //this is numbers for fuel sensor
    if(SL.arrayIsEmpty(realSensorValues)){
        const fuelSensors = getCountOfFuelSensors(realSensorValues, numberForCompare);
        return  fuelSensors.length > 1 ?
                fuelSensors.every(oneSensor => oneSensor.value > 0) :  // we look for values equal 0 or absent
                true
    }
    else return true
}

function getCountOfFuelSensors(realSensorValues, numberForCompare){
    let countOfFuelSensors = [];
    for(let i in realSensorValues){
        if(numberForCompare.includes(realSensorValues[i].sensorNumber)){
            countOfFuelSensors.push(realSensorValues[i])
        }
    }
    return countOfFuelSensors
}

