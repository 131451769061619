import store 						from 'store/store.js'
import bugReport 					from './bugReport'
import infoStore 					from './infoStore.js'
import axios 						from 'axios'
import SL 							from 'sl-library'
import TransportApi 				from 'actions/transportApi.js'

const transportInfo = {
	setFixedForVehicle:               setFixedForVehicle,
	updateTransportPeriod:            updateTransportPeriod,
	updateTransportSettings:          updateTransportSettings,
	updateSignalization:              updateSignalization,
	disableAlarm:                     disableAlarm,
	disableSos:                       disableSos,
	updateControlOutput:              updateControlOutput,
	updateIgnition:                   updateIgnition,
	getDigitalInputs:                 getDigitalInputs,
	updateDigitalInputs:              updateDigitalInputs,
	getValuesDigitalInputs:           getValuesDigitalInputs,
	saveCounter:                      saveCounter,
	updateCounter:                    updateCounter,
	deleteCounter:                    deleteCounter,
	saveSensorControl:                saveSensorControl,
	updateSensorControl:              updateSensorControl,
	deleteSensorControl:              deleteSensorControl,
	getListOfZoneConrols:             getListOfZoneConrols,
	saveZoneControl:                  saveZoneControl,
	updateZoneConrol:                 updateZoneConrol,
	deleteZoneConrol:                 deleteZoneConrol,
	createGroup:                      createGroup,
	getGroupsList:                    getGroupsList,
	updateGroupName:                  updateGroupName,
	deleteGroup:                      deleteGroup,
	addVehiclesToGroup:               addVehiclesToGroup,
	removeVehiclesFromGroup:          removeVehiclesFromGroup,
	updateOnAddGroupInVehicleList:    updateOnAddGroupInVehicleList,
	updateOnRemoveGroupInVehicleList: updateOnRemoveGroupInVehicleList,
	getVehicleNotes:                  getVehicleNotes,
	createVehicleNote:                createVehicleNote,
	updateVehicleNote:                updateVehicleNote,
	deleteVehicleNote:                deleteVehicleNote,
	updateNameVSLicensePlate:         updateNameVSLicensePlate,
	updateTrailerName:                updateTrailerName,
	saveDeviceInControl: 			  saveDeviceInControl,
	deleteDeviceInControl: 			  deleteDeviceInControl,
	updateDeviceInControl: 			  updateDeviceInControl,
	updateOnMoveVehiclesToGroup: 	  updateOnMoveVehiclesToGroup,
	moveVehicleToGroup: 		      moveVehicleToGroup,
	getTransportinfo: 				  getTransportinfo,
	resetCounter:					  resetCounter,
}

export default transportInfo;
/*function getRealSensors(vehicleId){
	let dataForRequest = {
		baseURL: 			infoStore().base_url,
		url: 				infoStore().bonus_url_api + "/vehicle/real-sensor",
		dataType: 			"JSON",
		method: 			'POST',
		withCredentials: 	true,
		headers: 			{'authorization': infoStore().token},
		data: 				controlSensorInfo
	}
}*/
function getVehicleNotes(vehicleId, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/note',
		dataType: "JSON",
		method: 'GET',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: {vehicleId: vehicleId}
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'getVehicleNotes'))
}

function createVehicleNote(noteInfo, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/note',
		dataType: "JSON",
		method: 'PUT',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: noteInfo
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'createVehicleNote'))
}

function updateVehicleNote(noteInfo, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/note/'+noteInfo.id,
		dataType: "JSON",
		method: 'POST',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: noteInfo
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'updateVehicleNote'))
}

function deleteVehicleNote(vehicleId, noteId, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/note/'+noteId,
		method: 'DELETE',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: {vehicleId: vehicleId}
	}
	axios(dataForRequest)
	.then( answer => callback(answer.data) )
	.catch( answer => bugReport(answer, dataForRequest, 'deleteVehicleNote'))
}

function setFixedForVehicle(vehicleId, fixedValue, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'user/control-entity/property',
		dataType: "JSON",
		method: 'POST',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: {
			id: vehicleId,
			fixed: fixedValue,
		}
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'setFixedForVehicle'))
}

function createGroup(groupName, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/group',
		method: 'PUT',
		withCredentials: true,
		headers: {
			'authorization': infoStore().token,
			'Content-Type': 'application/json'
		},
		responseType: 'stream',
		data: groupName
	}

	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'createGroups'))
}

function getGroupsList(callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/group',
		dataType: "JSON",
		method: 'GET',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
	}
	axios(dataForRequest).then((answer) => {
		if(callback) callback(answer.data)
		store.dispatch({
			type: 'VEHICLE_GROUPS',
			setVehicleGroups: answer.data
		})
	}).catch((answer) => bugReport(answer, dataForRequest, 'getGroupsList'))
}

function updateGroupName(groupId, newGroupName, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/group/' + groupId,
		dataType: "JSON",
		method: 'POST',
		withCredentials: true,
		headers: {
			'authorization': infoStore().token,
			'Content-Type': 'application/json'
		},
		data: newGroupName
	}

	axios(dataForRequest).then((answer) => {
		const name = answer.data.name.slice(1, -1);
		callback({id: answer.data.id, name})
	}).catch((answer) => bugReport(answer, dataForRequest, 'updateGroupName'))
}

function deleteGroup(groupId, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/group/' + groupId,
		dataType: "JSON",
		method: 'DELETE',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'deleteGroup'))
}

function addVehiclesToGroup(groupId, vehicleList, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/group/mapping/' + groupId,
		dataType: "JSON",
		method: 'PUT',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: vehicleList
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'addVehiclesToGroup'))
}

function removeVehiclesFromGroup(groupId, vehicleList, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/group/mapping/' + groupId,
		dataType: "JSON",
		method: 'DELETE',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: vehicleList
	}
	axios(dataForRequest).then((answer) => {
		callback?callback(answer.data):null
	}).catch((answer) => bugReport(answer, dataForRequest, 'removeVehiclesFromGroup'))
}

function saveSensorControl(vehicleId, controlSensorInfo, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/' + vehicleId + '/vehicle-sensor-control',
		dataType: "JSON",
		method: 'POST',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: controlSensorInfo
	}
	axios(dataForRequest).then((answer) => callback(answer.data) )
	.catch((answer) => bugReport(answer, dataForRequest, 'saveSensorControl'))
}

function saveDeviceInControl(controlSensorInfo, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/vehicle-device-in-control',
		dataType: "JSON",
		method: 'POST',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: controlSensorInfo
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'saveSensorControl'))
}

function updateSensorControl(vehicleId, sensorControlId, controlSensorInfo, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/' + vehicleId + '/vehicle-sensor-control/' + sensorControlId,
		dataType: "JSON",
		method: 'PUT',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: controlSensorInfo
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'updateSensorControl'))
}

function updateDeviceInControl(controlSensorInfo, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/vehicle-device-in-control',
		dataType: "JSON",
		method: 'PUT',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: controlSensorInfo
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'updateDeviceInControl'))
}

function deleteSensorControl(vehicleId, sensorControlId, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/' + vehicleId + '/vehicle-sensor-control/' + sensorControlId,
		method: 'DELETE',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
	}
	axios(dataForRequest).then((answer) => {
		callback(sensorControlId)
	}).catch((answer) => bugReport(answer, dataForRequest, 'deleteSensorControl'))
}

function deleteDeviceInControl(vehicleId, sensorControlId, callback) {
	let dataForRequest = {
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_api + 'vehicle/' + vehicleId + '/vehicle-device-in-control/' + sensorControlId,
			method: 'DELETE',
			withCredentials: true,
			headers: { 'authorization': infoStore().token },
		}
		axios(dataForRequest).then((answer) => callback(sensorControlId))
		.catch((answer) => bugReport(answer, dataForRequest, 'deleteDeviceInControl'))
}

function saveCounter(vehicleId, counterInfo, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/' + vehicleId + '/vehicle-counter',
		dataType: "JSON",
		method: 'POST',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: counterInfo
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'saveCounter'))
}

function updateCounter(vehicleId, counterInfo, counterId, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/' + vehicleId + '/vehicle-counter/' + counterId,
		dataType: "JSON",
		method: 'PUT',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: counterInfo
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'updateCounter'))
}

function deleteCounter(vehicleId, counterId, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/' + vehicleId + '/vehicle-counter/' + counterId,
		dataType: "JSON",
		method: 'DELETE',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
	}
	axios(dataForRequest).then((answer) => {
		callback(counterId)
	}).catch((answer) => bugReport(answer, dataForRequest, 'deleteCounter'))
}

function resetCounter(vehicleId, counterInfo, callback) {
	const {id} = counterInfo;
	let dataForRequest = {
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_api + `vehicle/${vehicleId}/vehicle-counter/reset/${id}`,
			dataType: "JSON",
			method: 'PUT',
			withCredentials: true,
			headers: { 'authorization': infoStore().token },
			// params: { counterReset: true, },
			data: counterInfo
		}
		axios(dataForRequest).then( answer => callback(answer.data))
		.catch((answer) => bugReport(answer, dataForRequest, 'resetCounter'))
}

function getTransportinfo(id, callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle' + '/' + id,
		dataType: "json",
		method: 'GET',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		// headers: {'x-globus-sid': token},
	}
	axios(requestData)
		.then(function answer(answer) {
			const vehicleDto = answer.data;
			TransportApi.getVehicleSettings(id, (settings) => {
            	vehicleDto.settings = settings;
            	if(!!callback) {
            		callback(answer.data)
            	} else {
            		store.dispatch({
            			type: 'SET_VEHICLE_INFO',
            			setVehicle_info: vehicleDto
            		})
            	}
            })

		})
		.catch(answer => bugReport(answer, requestData, 'getTransportinfo'));
}

function getTransportSettings(vehicleId) {
	let requestData = {
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_api + `vehicle/ + ${id}`,
			dataType: "json",
			method: 'GET',
			withCredentials: true,
			headers: { 'authorization': infoStore().token },
			// headers: {'x-globus-sid': token},
		}
		axios(requestData)
			.then((answer) => {
				if(!!callback) {
					callback(answer.data)
				}
			})
			.catch(answer => bugReport(answer, requestData, 'getTransportSettings'));
}

function updateTransportPeriod(id, periods, callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/' + id + '/period',
		dataType: "json",
		method: 'POST',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: {
			period1: periods.period1,
			period2: periods.period2,
		}
		// headers: {'x-globus-sid': token},
	};
	axios(requestData)
		.then(function newAnswer(answer) {
			if(!!callback) {
				callback(answer.data)
			} else {
				console.log("%c else not defined, updateTransportInfo - answer", 'color: orange; font-weight: bold;', answer)
			}
		})
		.catch(answer => bugReport(answer, requestData, 'updateTransportInfo'));
}

function updateTransportSettings(id, data, callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/' + id + '/settings',
		dataType: "json",
		method: 'PUT',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: data,
		// headers: {'x-globus-sid': token},
	}
	axios(requestData)
		.then(function answer(answer) {
			if(!!callback) {
				callback(answer.data)
			} else {
				console.log("%c else not defined, updateTransportInfo - answer", 'color: orange; font-weight: bold;', answer)
			}
		})
		.catch(answer => bugReport(answer, requestData, 'updateTransportSettings'));
}

function updateSignalization(deviceSerial, status, callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/device/' + deviceSerial + '/commands/set-lock-state',
		dataType: "json",
		method: 'POST',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: { lockState: status },
	}
	axios(requestData)
		.then(function answer(answer) {
			if(!!callback) {
				callback(answer.data)
			} else {
				console.log("%c else not defined, updateSignalization - answer", 'color: orange; font-weight: bold;', answer)
			}
		})
		.catch(answer => bugReport(answer, requestData, 'updateSignalization'));
}

function disableSos(vehicleId, callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/' + vehicleId + '/reset-alarm',
		dataType: "json",
		method: 'PUT',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: { alarm: false },
	};
	axios(requestData)
		.then(function answer(answer) {
			if(!!callback) {
				callback(answer.data)
			} else {
				console.log("%c else not defined, disableSos - answer", 'color: orange; font-weight: bold;', answer)
			}
		})
		.catch(answer => bugReport(answer, requestData, 'disableSos'));
}

function disableAlarm(vehicleId, callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/' + vehicleId + '/reset-locked-alarm',
		dataType: "json",
		method: 'PUT',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: { lockedAlarm: false },
	};
	axios(requestData)
		.then(function answer(answer) {
			if(!!callback) {
				callback(answer.data)
			} else {
				console.log("%c else not defined, disableAlarm - answer", 'color: orange; font-weight: bold;', answer)
			}
		})
		.catch(answer => bugReport(answer, requestData, 'disableAlarm'));
}

function updateControlOutput(deviceSerial, status, callback, name) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/device/' + deviceSerial + '/commands/out-vals', //infoStore().bonus_url_device +
		dataType: "string",
		method: 'PUT',
		headers: { 'authorization': infoStore().token },
		data: {
			[name]: status
		}
	}
	axios(requestData)
		.then(function answer(answer) {
			console.log("%c updateControlOutput answer", 'color: green; font-weight: bold;', answer)
			if(!!callback) {
				callback(answer.data, name)
			}
		})
		.catch(function(answer) {
			if(!!callback) {
				callback(answer.data, name)
			}
			bugReport(answer, requestData, 'updateControlOutput')
		});
}

function updateIgnition(deviceSerial, status, callback, name) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'vehicle/device/' + deviceSerial + '/commands/always-ignition-on',
		dataType: "string",
		method: 'GET',
		headers: { 'authorization': infoStore().token },
		params: { alwaysIgnitionOn: status }
	}
	axios(requestData)
		.then(function answer(answer) {
			if(!!callback) {
				callback(answer.data, name)
			}
		})
		.catch(answer => bugReport(answer, requestData, 'updateIgnition'));
}

function getDigitalInputs(deviceSerial, callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'device/' + deviceSerial + '/in', // +
		dataType: "JSON",
		method: 'GET',
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
		.then(function answer(answer) {
			if(!!callback) {
				callback(answer.data)
			}
		})
		.catch(answer => bugReport(answer, requestData, 'getDigitalInputs'));
}

function updateDigitalInputs(deviceSerial, deviceInNumber, deviceInNewData, callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'device/' + deviceSerial + '/in/' + deviceInNumber, // +
		dataType: "JSON",
		method: 'POST',
		headers: { 'authorization': infoStore().token },
		data: deviceInNewData,
	}
	axios(requestData)
		.then(answer => {
			if(!!callback) {
				callback(answer.data)
			}
		})
		.catch(answer => bugReport(answer, requestData, 'updateDigitalInputs'));
}

function getValuesDigitalInputs(deviceSerial, callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'device/' + deviceSerial + '/in/data', // +
		dataType: "JSON",
		method: 'GET',
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
		.then(answer => {
			if(!!callback) callback(answer.data)
		})
		.catch(answer => bugReport(answer, requestData, 'getValuesDigitalInputs'));
}

function getListOfZoneConrols(vehicleId, callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'geozone/vehicle/' + vehicleId, // +
		dataType: "JSON",
		method: 'GET',
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
		.then(answer => {
			if(!!callback) callback(answer.data)
		})
		.catch(answer => bugReport(answer, requestData, 'getListOfZoneConrols'));
}

function saveZoneControl(zoneControlInfo, callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'geozone/vehicle/', // +
		dataType: "JSON",
		method: 'PUT',
		headers: { 'authorization': infoStore().token },
		data: zoneControlInfo
	}
	axios(requestData)
		.then(answer => {
			if(!!callback) callback(answer.data)
		})
		.catch(answer => bugReport(answer, requestData, 'saveZoneConrol'));
}

function updateZoneConrol(zoneControlInfo, callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'geozone/vehicle/', // +
		dataType: "JSON",
		method: 'POST',
		headers: { 'authorization': infoStore().token },
		data: zoneControlInfo
	}
	axios(requestData)
		.then(answer => {
			if(!!callback) callback(answer.data)
		})
		.catch(answer => bugReport(answer, requestData, 'updateZoneConrol'));
}

function deleteZoneConrol(zoneControId, vehicleId, callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'geozone/' + zoneControId + '/vehicle/' + vehicleId, //
		dataType: "JSON",
		method: 'DELETE',
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
		.then(answer => {
			if(!!callback) callback(answer.data)
		})
		.catch(answer => bugReport(answer, requestData, 'deleteZoneConrol'));
}

function moveVehicleToGroup(vehicleId, groupIdForMapping, callback) {
	let requestData = {
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_api + 'vehicle/' + vehicleId + '/group/' + groupIdForMapping, //
			dataType: "JSON",
			method: 'PUT',
			headers: { 'authorization': infoStore().token },
		}
		axios(requestData)
			.then(answer => {
				updateOnMoveVehiclesToGroup(groupIdForMapping, vehicleId, callback)
				// if(!!callback) callback(answer.data)
			})
			.catch(answer => bugReport(answer, requestData, 'moveVehicleToGroup'));
}

function updateOnMoveVehiclesToGroup(groupId, vehicleId, callback) {
	const transport_list = infoStore('globalR.controlR.vehiclesInfo');
	const vehicleIndex = transport_list.findIndex(vehicle => vehicle.vehicleId == vehicleId);
	transport_list[vehicleIndex].groups = [{id: groupId}];
	TransportApi.dispatchVehicleInfoToStore(SL.copyOfObject(transport_list))
	callback()
}

function updateOnAddGroupInVehicleList(listOfVehiclesInGroup, newGroup) {
	const transport_list = infoStore('globalR.controlR.vehiclesInfo');
	if(SL.arrayIsEmpty(transport_list) && SL.arrayIsEmpty(listOfVehiclesInGroup)) {
		listOfVehiclesInGroup.forEach(addGroupToVehicle)
	}

	function addGroupToVehicle(group) {
		let indexOfVehicle = findVehicleIndex(transport_list, group);
		if(SL.isNumeral(indexOfVehicle) && indexOfVehicle !== -1) {
			transport_list[indexOfVehicle].groups.push(newGroup)
		}
	}
	TransportApi.dispatchVehicleInfoToStore(SL.copyOfObject(transport_list))
}

function updateOnRemoveGroupInVehicleList(listOfVehiclesForRemove) {
	let transport_list = infoStore('globalR.controlR.vehiclesInfo');
	if(SL.arrayIsEmpty(transport_list) && SL.arrayIsEmpty(listOfVehiclesForRemove)) {
		let groupId = listOfVehiclesForRemove[0].groupId;
		listOfVehiclesForRemove.forEach(group => {
			let indexOfVehicle = findVehicleIndex(transport_list, group);
			if(SL.isNumeral(indexOfVehicle) && indexOfVehicle !== -1) {
				let indexGroupForRemove = transport_list[indexOfVehicle].groups.findIndex(vehicleGroup => {
					if(vehicleGroup.id == groupId) {
						return vehicleGroup
					}
				})
				if(indexGroupForRemove !== -1) {
					transport_list[indexOfVehicle].groups.splice(indexGroupForRemove, 1)
				}
			}
		})
	}
}

function findVehicleIndex(transport_list, groupInfo) {
	const condition = (oneVehicle) => oneVehicle.vehicleId == groupInfo.vehicleId ? oneVehicle : null
	return transport_list.findIndex(condition)
}

function updateNameVSLicensePlate(infoForUpdate, callback) {
	const { vehicleId, name, licensePlate } = infoForUpdate;
	infoForUpdate = SL.copyOfObject(infoForUpdate)
	const params = infoForUpdate; //{ name: name, licensePlate: licensePlate, width: width }
	delete params.vehicleId;
	delete params.initialName;
	delete params.initialLicensePlate;
	let requestData = {
		baseURL:  infoStore().base_url,
		url:      infoStore().bonus_url_api + 'vehicle/' + vehicleId, //
		dataType: "JSON",
		method:   'POST',
		headers:  { 'authorization': infoStore().token },
		params:   params
	}
	axios(requestData)
		.then(answer => callback(answer.data) )
		.catch(answer => bugReport(answer, requestData, 'updateNameVSLicensePlate'));
}

function updateTrailerName(infoForUpdate, callback) {
	const { vehicleId, name} = infoForUpdate;
		const params = {name: name}; //{ name: name, licensePlate: licensePlate, width: width }
		let requestData = {
			baseURL:  infoStore().base_url,
			url:      infoStore().bonus_url_api + 'trailer/' + vehicleId, //
			dataType: "JSON",
			method:   'POST',
			headers:  { 'authorization': infoStore().token },
			params:   params
		}
		axios(requestData)
			.then(answer => callback(answer.data) )
			.catch(answer => bugReport(answer, requestData, 'updateNameVSLicensePlate'));
}

