import language_variant from 'store/languages'
import 'images/batman.jpg'
const demoVehicle = {
		name: language_variant.vehicle_name,
		owner: "test",
		licensePlate: language_variant.licensePlate,
		imagesrc: "./images/batman.jpg",
		vehicleId: 'vehicleForTest',
		owner: "test",
		timestamp: "2019-10-25T12:41:59Z",
		fixed: true,
		type: "VEHICLE",
		groups: [
		],
		description: "",
		period1: 32,
		period2: 90,
		minimalPeriod1: 30,
		minimalPeriod2: 90,
		returnMinimalPeriod: true,
		currentDeviceMapping: {
			"deviceSettingsDto": {
				"id": 11434,
				"alarm": true,
				"alarmTimestamp": "2019-11-12T17:50:04.146Z",
				"lockedAlarm": true,
				"alwaysIgnitionOn": false,
				"deviceOn": true,
				"outValsDto": {
					"firstOutVal": false,
					"secondOutVal": false,
					"thirdOutVal": false,
					"fourthOutVal": false,
					"fifthOutVal": false,
					"sixthOutVal": false,
					"seventhOutVal": false,
					"eighthOutVal": false,
					"allValsFalse": true
				}
			},
			"deviceSerial": 70016,
			"from": "2019-04-09T17:54:48Z",
			"to": null,
			"id": 41626
		},
		vehicleCounters: [
			{
				"id": 17,
				"counterName": "Счетчик Ы",
				"counterType": "MILEAGE_COUNTER",
				"counterTime": "2019-02-28T09:39:11.303Z",
				"counterState": true,
				"startFrom": 243.27116,
				"triggerAfter": 20,
				"endLeft": -223.27116,
				"logicalInputNumber": 0
			}
		],
		vehicleSensorControlDtoList: [
			{
				"id": 15,
				"vehicleId": 2,
				"timestamp": "2019-08-12T21:10:41.565Z",
				"sensorNum": 2,
				"sensorName": "Превышение скорости",
				"condition": "MoreThen",
				"thresholdValue": 50,
				"text": "превысил скорость"
			}
		],
		virtualSensors: [
			{
				"title": "Test",
				"fracDigits": 0,
				"units": "test",
				"vehicleId": 2,
				"visible": false,
				"fromZero": false,
				"virtualSensorId": 1147,
				"sensorType": "RAW_SENSOR"
			},
			{
				"title": "FUEL",
				"fracDigits": 11,
				"units": "1",
				"vehicleId": 2,
				"visible": false,
				"fromZero": false,
				"virtualSensorId": 1134,
				"sensorType": "FUEL_LEVEL"
			},
			{
				"title": "Test3",
				"fracDigits": 0,
				"units": "test",
				"vehicleId": 2,
				"visible": false,
				"fromZero": false,
				"virtualSensorId": 1149,
				"sensorType": "FUEL_LEVEL"
			},
			{
				"title": "температура",
				"fracDigits": 2,
				"units": "градусы",
				"vehicleId": 2,
				"visible": true,
				"fromZero": true,
				"virtualSensorId": 1106,
				"sensorType": "THERMAL"
			},
			{
				"title": "Test",
				"fracDigits": 0,
				"units": "0",
				"vehicleId": 2,
				"visible": false,
				"fromZero": false,
				"virtualSensorId": 1150,
				"sensorType": "FUEL_LEVEL"
			},
			{
				"title": "FUEL",
				"fracDigits": 11,
				"units": "1",
				"vehicleId": 2,
				"visible": false,
				"fromZero": false,
				"virtualSensorId": 1135,
				"sensorType": "FUEL_LEVEL"
			},
			{
				"title": "tets",
				"fracDigits": 0,
				"units": "1",
				"vehicleId": 2,
				"visible": false,
				"fromZero": false,
				"virtualSensorId": 1148,
				"sensorType": "FUEL_CONSUMPTION"
			},
			{
				"title": "Вольтметр",
				"fracDigits": 2,
				"units": "В",
				"vehicleId": 2,
				"visible": true,
				"fromZero": true,
				"virtualSensorId": 1105,
				"sensorType": "VOLTAGE"
			},
			{
				"title": "количество спутников GPS",
				"fracDigits": 0,
				"units": "",
				"vehicleId": 2,
				"visible": true,
				"fromZero": true,
				"virtualSensorId": 1107,
				"sensorType": "RAW_SENSOR"
			}
		],
		multiSensors: [],
		realSensorSockets: [
			{
				"id": 1024,
				"name": "asd",
				"virtualSensorId": 1134,
				"vehicleId": 2,
				"realSensors": [
					{
						"realSensorId": 6684,
						"sensorNumber": 123,
						"position": 0,
						"vehicleToDeviceMappingId": 41626,
						"vehicleId": 2,
						"reverse": false,
						"realSensorSocketId": 1024,
						"from": "2019-04-09T17:54:48Z",
						"to": null,
						"virtualSensorId": 1134,
						"current": true
					}
				]
			}
		],
		sensorCalibrationMappings: [
			{
				"id": 1022,
				"sensorCalibrationId": 4,
				"sensorCalibrationName": "Вольтметр globus7",
				"from": "2019-04-09T17:54:48Z",
				"to": null,
				"current": true
			}
		],
		antStatus: {
			"updateTime": "2019-10-25T09:45:53.259431Z",
			"antConnected": true,
			"shortMode": true
		},
		lockStateDto: {
			"deviceId": 70016,
			"lockStateTime": "2019-10-24T13:58:40.672Z",
			"lockState": true,
			"name": "LockStateForDevice"
		},
		eventsCountPerDay: {
			ENTRY_CONTROL_ZONE: 4,
			EXIT_CONTROL_ZONE: 4,
			VEHICLE_PARKING_START: 3,
			FUELLING_START: 2,
			DEFUELLING_START: 3,
			VEHICLE_OVER_SPEED_START: 12,
			SOS_ALARM: 1,
		},

		vehiclesDataGpsDataDto: {
			"latitude": 0,
			"longitude": 0,
			"speed": 0,
			"validity": 0,
			"ignitionOn": false
		},
		vehiclesDataInputsEventDto: {
			"valuesChangedMask": 0,
			"values": 16,
			"powerRestored": false,
			"lockedAlarm": false,
			"alarm": false
		},
		inputsValues: 16,
		multiSensorValues: [
			{
				id: 131,
				name: "Бак 1 + Бак 2",
				units: "Л",
				value: 104.56056285582699,
				sensorType: "FUEL_LEVEL"
			}
		],
		realSensorValues: [],
		settings: [
			{
				"name": "maximum_speed",
				"unit": "km/h",
				"value": "10.0"
			},
			{
				"name": "minimal_fuelling_volume",
				"unit": "liter",
				"value": "10.0"
			},
			{
				"name": "minimal_defuelling_volume",
				"unit": "liter",
				"value": "10.0"
			},
			{
				"name": "maximum_duration",
				"unit": "s",
				"value": "300"
			},
			{
				"name": "minimal_stop_time",
				"unit": "s",
				"value": "10"
			},
			{
				"name": "average_fuel_consumption",
				"unit": "liter",
				"value": "10.0"
			},
			{
				"name": "maximum_allowed_speed",
				"unit": "km/h",
				"value": "118.8"
			}
		]
};
export default demoVehicle;

