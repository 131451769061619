const trailerInfoWindow = {
    status: 'Статус',
    connected: 'подключен',
    disconnected: 'отключен',
    connection_date: 'Дата подключения',
    disconection_date: 'Дата отключения',
};

const routeWords = {
    ...trailerInfoWindow,
    coordinates_copied:                        'Координаты скопированы',
    copy_coordinates:                          'Скопировать координаты',
    vehicle:                                   'Авто',
    current_counter_value:                     'Счетчик сейчас',
    text_about_validation_counter_values:      'Начальное значение должно быть меньше порога срабатывания',
    status_will_update_after:                  'Статус обновится после выхода устройства на связь',
    parameters:                                'Параметры',
    or_create_new:                             'или создайте новую',
    disabled_receiving_data:                   'Отключен прием данных для трекера',
    triggers_are_absent:                       'Триггеры отсутствуют',
    total_distance:                            'Общее расстояние',
    message_about_gps_veracity:                "Координаты GPS  не актуальны!",
    ON:                                        'ON',
    OFF:                                       'OFF',
    enter_route_name:                          'Введите название маршрута',
    task_routes:                               'Маршруты заданий',
    are_youre_sure_want_remove_route:          'Вы уверены, что хотите удалить маршрут?',
    shedule_route:                             'Расписание маршрута',
    pass_order:                                'Порядок прохождения',
    regarding_activation:                      'Относительно активации',
    relative_to_day:                           'Относительно суток',
    absolute_time:                             'Абсолютное время',
    consistently:                              'Последовательно',
    arbitrarily:                               'Произвольно',
    with_passes:                               'С пропусками',
    active_tasks:                              'Активные задания',
    complete_the_task:                         'Завершить задание?',
    details:                                   'Детали',
    cancel_job:                                'Отменить задание',
    latest_data:                               'Последние данные',
    completed_tasks:                           'Завершенные задания',
    job_detail:                                'Детали задания',
    quest_start:                               'Начало задания',
    quest_end:                                 'Конец задания',
    activate_task:                             'Активировать задание',
    sheduled_for:                              'Запланировано на',
    sheduled:                                  'Запланировано',
    sheduled_job:                              'Запланированные задания',
    start_task:                                'Начать выполнение задания?',
    list_of_sheduled_jobs_is_empty:            'список запланированных заданий пуст',
    route_detail:                              'Детали маршрута',
    route_name:                                'Название маршрута',
    path_order_route_points:                   'Порядок прохождения точек маршрута',
    update_route:                              'Обновить маршрут',
    save_route:                                'Сохранить маршрут',
    complete:                                  'Закончить',
    remove_route_point:                        'Удалить точку маршрута',
    show_detail:                               'Показать подробности',
    hide_detail:                               'Скрыть подробности',
    permissible_deviation_from_arrival_time:   'Допустимое отклонение от времени прибытия',
    permissible_deviation_from_departure_time: 'Допустимое отклонение от времени отправления',
    save_point:                                'Сохранить точку',
    location_not_selected:                     'Место не выбрано',
    are_you_sure_want_delete_job:              'Вы уверены, что хотите удалить задание?',
    select_performer:                          'Выбрать исполнителя',
    performer:                                 'Исполнитель(ли)',
    no_one_is_doing_the_job:                   'Задание никто не выполняет',
    vehicles_that_parfom_task:                 'Авто которые выполняют задание',
    vehicles_with_task:                        'Авто с заданием',
    available_vehicles:                        'Доступные авто',
    one_off:                                   'одноразовое',
    daily:                                     'ежедневное',
    Sunday:                                    'Воскресенье',
    Monday:                                    'Понедельник',
    Tuesday:                                   'Вторник',
    Wednesday:                                 'Среда',
    Thursday:                                  'Четверг',
    Friday:                                    'Пятница',
    Saturday:                                  'Суббота',
    editing_task:                              'Редактирование задания',
    create_task:                               'Создание задания',
    name_of_task:                              'Название задания',
    task_type:                                 'Тип задания',
    task_description:                          'Описание задания',
    update_task:                               'Обновить задание',
    save_task:                                 'Сохранить задание',
    task_start_date:                           'Дата начала задания',
    task_end_date:                             'Дата конца задания',
    tasks_days:                                'Дни задания',
    select_every_day:                          'Выбрать каждый день',
    enter_route_name:                          'Введите название маршрут'
};

const globalVocabulary = {
    sensors:                                            'Датчики',
    DETAILED_SPEEDING:                                  'Подробно',
    land_cadastre:                                      'Кадастр геометрия участков',
    land_cadastre_index:                                'Кадастр индексная карта',
    attention:                                          'Внимание',
    you_donT_have_enough_rights_to_do:                  'Не хватает прав, чтоб выполнить это',
    logic_inputs:                                       'Логические входы',
    by_number:                                          'По номеру',
    multisensor:                                        'Мультисенсор',
    fromDateL:                                  'c',
    toDateL:                                    'по',
    cabinet:                                    'Кабинет',
    standart_view: 'Стандартный режим',
    for_print: 'Для печати',
    power_restored_text:                               'Подключение бортсети восстановлено',
    data_not_available:                                'Данные недоступны',
    new_notifications_absent:                          'Новых уведомлений нет',
    clear_blocking_date:                               'Очистить дату блокировки',
    add:                                               'Добавить',
    update_blocking_date:                              'Обновить дату блокировки',
    blocking_date:                                     'Дата блокировки',
    blocking:                                          'Блокировка',
    blocked:                                           'Заблокирован',
    infographic:                                       'Инфографика',
    brief_vehicles_list:                               'Краткий список транспорта',
    sorting:                                           'Сортировка',
    by_name:                                           'По названию',
    by_license_plate:                                  'По номеру авто',
    by_traker_number:                                  'По номеру трекера',
    vehicle_whith_parking:                             'Авто с парковками',
    vehicle_whith_fuelling:                            'Авто с заправками',
    vehicle_whith_defuelling:                          'Авто с возможными сливом топлива',
    vehicle_whith_speeding:                            'Авто с превышениями',
    vehicle_online:                                    'Авто онлайн',
    vehicle_offline:                                   'Авто офлайн',
    show_only:                                         'Показать только',
    interface_and_application:                         'Интерфейс и приложение',
    player:                                            'Плеер',
    no_groups:                                         'нет групп',
    trailer_registration:                              'Работа прицепа',
    RFID_registration:                                 'Работа RFID карты',
    card:                                              'Карта',
    RFID_cards:                                        'RFID карты',
    additionalColumns:                                 'Дополнительные столбцы',
    message_to_many:                                   'Вы несколько раз подряд ввели неправильный пароль. ожидайте..',
    weak:                                              'слабый',
    okay:                                              'нормальный',
    good:                                              'хороший',
    strong:                                            'сильный',
    restore_pass:                                      'Восстановить пароль',
    remembered_you_pass:                               'Вспомнили пароль?',
    entered_passwords_dont_match:                      'Введенные пароли не совпадают',
    password_update_failed:                            'Обновить пароль не вышло',
    password_recovery:                                 'Восстановление пароля',
    zone_width_must_be:                                'Ширина зоны должна быть больше 0м',
    not_set_IN:                                        'Не назначен логический вход "Прицеп" для работы отчета',
    FROM:                                              'из',
    radius:                                            'Радиус',
    editVehicles:                                      'Редактировать авто',
    seeTasks:                                          'Видеть задания',
    select_zone_type:                                  'Выберите тип зоны',
    error_on_set_unlock:                               'При снятии с охраны произошла ошибка',
    error_on_set_lock:                                 'При постановке на охрану произошла ошибка',
    rotation_in_hour:                                  'о/ч',
    'r/min':                                           'о/мин',
    crankshaft_amount_rotation:                        'Количество вращений коленвала',
    rotation_crankshaft_amount:                        'Количество вращений коленвала',
    link_to_report:                                    'Ссылка на отчёт',
    enter_gmail_email_for_export:                      'Введите email Gmail аккаунта для экспорта файла',
    recieved:                                          'Получено',
    total:                                             'Всего',
    data_processing_completed:                         'Обработка данных завершена',
    request_processing_in_progress:                    'Идет обработка запроса',
    geofence_color:                                    'Цвет геозоны',
    zone_width_in_m:                                   'Ширина зоны в метрах',
    move_to_group:                                     'Переместить в группу',
    notes_name:                                        'Название заметки',
    notes_text:                                        'Текст заметки',
    zone_types:                                        'Типы зон',
    select_zone_types:                                 'Выберите типы зон',
    filter_by_zone_types:                              'Фильтр по типам зон',
    create_geophence_from_route:                       'Создать геозону из маршрута',
    speeding:                                          'Превышения скорости',
    connection_restored:                               'Соединение восстановлено',
    connection_recovered:                              'Соединение восстановлено',
    try_recovery_connection:                           'Пробуем восстановить соединение...   Проверьте интернет соединение',
    waypoints:                                         'Точки маршрута',
    INCLUDE_FIRST_SHIFT:                               'Первая смена',
    INCLUDE_SECOND_SHIFT:                              'Вторая смена',
    start_of_period:                                   'Начало периода',
    end_of_period:                                     'Конец периода',
    get_data:                                          'Получить данные',
    offName:                                           'Выключенный',
    onName:                                            'Включенный',
    notifyDeviceInControl:                             'Срабатывание логических входов',
    name_new:                                          'Название',
    width:                                             'Ширина',
    filter_notifications:                              'Фильтровать уведомления',
    to_new:                                            'До',
    from_new:                                          'От',
    same_fuel_data_time:                               'Cклеивать заправки менее',
    create_geozone:                                    'Создать геозону',
    update_geozone:                                    'Обновить геозону',
    creating_geozone_from_route:                       'Создание геозоны на основе маршрута',
    save_geozone:                                      'Сохранить геозону',
    along_the_track:                                   'Вдоль трека',
    squares_from_route:                                'Площади из маршрута',
    name_for_geozone:                                  'Название для геозоны',
    fill_name_of_geozone:                              'Заполните название геозоны',
    rename:                                            'Переименовать',
    add_to_group:                                      'Добавить в группу',
    variables:                                         'Переменные',
    set_control_zone:                                  'Установить  контрольную зону',
    get_route:                                         'Запросить маршрут',
    import:                                            'Импортировать',
    select_archive_zip_kmz:                            'Выберите архив zip/kmz или kml файл',
    problems_when_generation_report:                   'При генерации отчёта возникли проблемы',
    for_period:                                        'За период',
    password_was_updated:                              'Пароль был обновлён',
    save_password:                                     'Сохранить пароль',
    from_beginning_of_month:                           'С начала месяца',
    edit_rfid_cards:                                 'Редактировать RFID карты',
    user_can:                                          'Пользователь может',
    edit_user:                                         'Редактирование пользователя',
    create_user:                                       'Создание пользователя',
    enter_user_login:                                  'Введите логин пользователя',
    users_management_head:                             'Управление пользователями',
    from:                                              'из',
    count_lines:                                       'Количество строк',
    trailer_type:                                      'Тип прицепа',
    transmit_period_night_in_seconds:                  'Период передачи ночной в С',
    transmit_period_in_seconds:                        'Период передачи дневной в С',
    trailers_width:                                    'Ширина прицепа в метрах',
    serial_number_of_trailer:                          'Серийный номер прицепа',
    name_of_trailer:                                   'Название прицепа',
    editing_trailer:                                   'Редактирование прицепа',
    adding_trailer:                                    'Добавление прицепа',
    trailers_managment:                                'Управление прицепами',
    enter_name_type_or_serial:                         'Введите имя прицепа, серийный номер или тип',
    get_fuel_data:                                     'Видеть данные о топливе',
    see_charts:                                        'Видеть графики',
    switch_device_outputs_state:                       'Переключение управляющего входа',
    set_lock_mode:                                     'Установить режим охраны',
    unset_alarm_flag:                                  'Снятие флага тревоги',
    edit_zones:                                        'Редактировать зоны и отчеты',
    seeRoutes:                                         'Видеть маршруты и прицепы',
    see_reports:                                       'Видеть отчёты',
    see_current_vehicle_position:                      'Видеть текущее положение машин',
    save_user_rights:                                  'Сохранить права пользователя',
    no_data_allowed_for_user:                          'Нет данных разрешенных прав для пользователя',
    prohibited_vehicles_of_user:                       'Запрещенный транспорт пользователя',
    allowed_vehicles_of_user:                          'Разрешенный транспорт пользователя',
    trailers:                                          'Прицепы',
    instruction_for_setup:                             'Инструкция для установки',
    open_telegram:                                     'Открыть телеграм',
    vehicleId:                                         'ID Авто',
    limit_sapmle_time:                                 'Ограничить время выборки',
    message_for_user:                                  'Сообщение пользователю',
    save_description:                                  'Сохранить описание',
    save_message:                                      'Сохранить сообщение',
    allowed_vehicles:                                  'Разрешенный транспорт',
    prohibited_vehicles:                               'Запрещенный транспорт',
    description:                                       'Описание',
    confirm_password:                                  'Подтверждение пароля',
    users_management:                                  'Пользователи',
    select:                                            'Выбрать',
    selected:                                          'Выбран',
    import:                                            'Импорт',
    smoothed_data:                                     'Сглаженные данные',
    no_data_or_equipment_width:                        'Нет данных за выбранный период либо не установлена ширина навесного оборудования',
    all_zone_types:                                    'Все типы',
    type:                                              'Тип',
    comment_text:                                      'Текст комментария',
    comments_for:                                      'Комментарии для ',
    are_your_sure_want_del_comment:                    'Вы уверены, что хотите удалить комментарий?',
    edit_comment:                                      'Редактирование коментария ',
    create_comment:                                    'Создание коментария',
    add_geofence:                                      'Добавить геозону',
    withOutGroups:                                     'Без групп',
    raw_data:                                          "RAW данные",
    detect_parking_time_hint:                          'Время разграничения остановки/парковки со включенным зажиганием',
    detect_parking_time:                               'Парковка со включенным зажиганием, через',
    nothing_selected:                                  ' - ничего не выбрано',
    filter:                                            "Фильтр",
    all_groups:                                        'Все группы',
    tracker:                                           'Трекер',
    in_system:                                         'В системе',
    request_was_send_answer_in_notif:                  'Запрос был отправлен, ответ на запрос придет в уведомления (правый верхний угол, колокольчик)',
    ussd_send:                                         'Отправка ussd',
    arming:                            'Постановка на охрану',
    remove_notif_about_triggering:                     'Убрать уведомление про тревогу',
    only_from_line_all:                                'Только со строки "Всего"',
    all_data:                                          'Все данные',
    clear_selected:                                    'Очистить выбранное',
    Fuel_sensor_is_not_install:                        'Датчик топлива не установлен',
    stopVehicle:                                       'Остановка',
    period1_hint:                                      'Период передачи при движении транспорта',
    period2_hint:                                      'Период передачи при стоянке транспорта',
    ATTACHED_EQUIPMENT_WIDTH_hint:                     "Ширина навесного оборудования на транспорте, для расчета площади обработки.",
    maximum_speed_hint:                                "Скорость транспорта, выше которой колебания топлива однозначно не рассматриваются как заправки",
    maximum_duration_hint:                             "Параметр для усреднения всплесков топлива",
    minimal_stop_time_hint:                            "Максимальное время для определения при остановке - скачок топлива или заправка",
    maximum_allowed_speed_hint:                        "Для информирования о превышении скорости в сообщении и отчетах",
    m:                                                 "м",
    vehicle_width:                                     "Ширина авто",
    ATTACHED_EQUIPMENT_WIDTH:                          "Ширина навесного оборудования",
    distance_in_zone_no_less:                          "Пробег в зоне не менее, км",
    login:                                             'логин',
    DAILY_TRANSPORT_ACCOUNT:                           'Учетный по транспорту',
    Yes:                                               'Да',
    No:                                                'Нет',
    server_temporarily_unavailable:                    'Сервер временно недоступен',
    admin_panel:                                       'Админ панель',
    take_off_notif_about_sos:                          'Убрать уведомление про сигнал SOS',
    isNotifySosSignal:                                 'Cигнал SOS',
    hide_geofences:                                    'Скрыть геозоны',
    show_geofence:                                     'Показать геозоны',
    labels:                                            'Названия',
    notes_by_vehicle:                                  'Заметки по авто',
    licensePlate:                                      'Номер авто',
    absentDataFromSomeSensors:                         "Нет данных с некоторых датчиков",
    openInNewTab:                                      "Открыть ссылку в новой вкладке",
    cantSaveForThisAccount:                            'Не выходит сохранить отчёт для этого аккаунта',
    saveToGoogleDisk:                                  "Сохранить на гугл диск",
    notifySensorControl:                               "Уведомления контроля датчиков",
    isNotifyLogicalInputEngineHoursCounter:            "Счетчики моточасов по логическому входу",
    isNotifyEntryZone:                                 "Въезд в контрольную зону",
    isNotifyExitZone:                                  "Выезд из контрольной зоны",
    during:                                            ' в течении ',
    counter_events_term_over:                          'Количество событий - "Превышение температуры"',
    counter_events_speed_over:                         'Количество событий - "Превышение скорости"',
    counter_events_voltage:                            'Количество событий - "Cкачёк питания"',
    counter_events_fuelling:                           'Количество событий - "Заправка"',
    counter_events_defuelling:                         'Количество событий - "Возможный слив топлива"',
    counter_events_parking:                            'Количество событий - "Парковка"',
    no_coordinates:                                    "Координаты устройства отсутсвуют",
    status_was_updated:                                "Обновлено",
    antena_connected:                                  "Антенна ON",
    antena_info_absent:                                "Нет данных о статусе антенны",
    antenna_was_disabled:                              "Антена OFF",
    antena_info_absent_check_device:                   "Нет данных по состоянию антенны, убедитесь в наличии устройства на авто",
    credibility:                                       "Достоверность",
    table_SIGNALING_ALARM:                             "Тревога у режимi охорони!",
    SOS_ALARM:                                         "ТРЕВОГА!",
    on_full:                                           "Включено",
    off_full:                                          "Выключено",

    passed:                                            'прошло',
    stop:                                              'Стоп',
    start:                                             'Старт',

    EXIT_CONTROL_ZONE:                                 "Выезд из контрольной зоны",
    ENTRY_CONTROL_ZONE:                                "Въезд в контрольную зону",
    EngineHours_by_ignite:                             'Моточасы по зажиганию',
    LOGICAL_INPUT_ENGINE_HOURS_COUNTER:                'Моточасы по логическому входу',
    MOTOHOURS_CAN:                                     'Моточасы по CAN',
    enable_table:                                      'Включить вид таблицы',
    enable_chart:                                      'Включить вид графика',
    pure_data:                                         'Чистые данные',
    on_calculation:                                    'Включить расчёты',
    off_calculation:                                   'Выключить расчёты',
    calculation:                                       'Расчёты',
    driver:                                            'Водитель',
    undefined:                                         'Неизвестно',
    fuel_consumed:                                     'Использовано топлива',
    fuel_consumed_on_hundred:                          'На 100 км',
    fuel_consumption_in_hour:                          'В час',
    pure:                                              'чистое',
    parking_duration:                                  'Длительность стоянки ',
    parking_duration_table:                                  'Стоянка ',
    fuelled:                                           'Залито топлива ',
    defuelled:                                         'Слито топлива ',
    antenna_was_disabled:                              'Антена была выключена - ',
    antenna_was_short_mode:                            'Антена была закорочена  - ',
    saved:                                             'Сохраненный',
    fuel_sensor_not_installed:                         'Датчик топлива не установлен',
    "PROCESSED_AREA_REPORT":                           'Площадь обработки',
    'km/h':                                            'км/ч',
    ACCELEROMETER_REPORT:                              "Срабатывание датчика ускорения",
    LIVE_REPORT:                                       "Живой отчет",
    managing_keys:                                     'Управление ключами',
    get_link_for_Report:                               'Получить ссылку для отчета',
    LOCK_EVENT_REPORT:                                 "Режим охраны",
    CHANGING_LOGICAL_INPUTS:                           "Изменение логических входов",
    USER_CONNECTION_HISTORY:                           "История подключений пользователей",
    LONGER_THEN:                                       "Длительность больше чем (в сек.)",
    GEO_CODING:                                        "Геокодирование",
    HUNDRED_MILES:                                     "Расчитать расход на 100 миль",
    DAILY_FLOW_METER_REPORT:                           "Расходомер топлива по дням",
    WORK_TIME_STOPS_IN_ZONES_OUT_OF_ZONES_TIME:        'Время работы и стоянки в зонах и вне зон',
    USSD_REPORT:                                       'USSD отчёт',
    vehicleDidntMoveDonthaveCoordinatesForGeozone:     "Авто не двигалось, нет координат для геозоны",
    onThisVehicleSensorAbsent:                         'На этом авто нет датчика',
    someProblem:                                       'Eсть проблема',
    ZONE_PASSAGE:                                      'Проезд зон',
    DEVICES_IN_ZONES_NUMBER:                           'Количество устройств в зонах',
    STOPS_IN_ZONES:                                    'Остановки в зонах',
    STOPS_OUT_OF_ZONES:                                'Остановки вне зон',
    hide_notif:                                        'Спрятать это уведомление',
    reload_page:                                       'Перезагрузить страницу',
    send_info:                                         'Отправить информацию',
    please_send_error:                                 'Пожалуйста, отправьте сообщение об ошибке и попробуйте перезагрузить страницу',
    something_went_wrong:                              'Что-то пошло не так',
    we_already_work_with_fix:                          'мы уже работаем над этим',
    notif_about_enter:                                 'Уведомить о въезде в зону',
    notif_about_exit:                                  'Уведомить о выезде из зоны',
    control_in_zones:                                  'Контроль в зонах',
    enter_template_name:                               'Введите название шаблона',
    task:                                              'задание',
    minimal_fuelling_volume:                           'Минимальный объем заправки',
    minimal_defuelling_volume:                         'Минимальный объем слива',
    rename_zone_type:                                  "Переименование типа зон",
    add_zone_type:                                     "Добавить тип зоны",
    enterZonTypeName:                                  "Введите имя типа",
    save_zoneType:                                     "Сохранить тип зоны",
    add_type:                                          "Добавить тип",
    handling:                                          "Управление",
    control_of_sensor:                                 "Контроль датчиков",
    DEVICES_ACTIVITY:                                  "Активность устройств",
    TIME_OF_GETTING_LAST_DATA:                         "Время получения последних данных",
    TRAILERS_WORK_WITH_VEHICLES:                       "Работа прицепов по машинам",
    DRIVERS_WORK_WITH_VEHICLES:                        "Работа водителей по машинам",
    STOPS_REPORT:                                      "По остановкам",
    SUMMARY_FUEL_REPORT:                               "По уровню топлива",
    DAILY_FUEL_REPORT:                                 "По уровню топлива по дням",
    PARKING_WITH_IGNITION_ON_DURATION_REPORT:          "Время стоянки с включенным зажиганием",
    ZONE_SQUARES_REPORT:                               "Площади зон",
    ZONE_SPEEDING_REPORT:                              "Превышения скорости по типам зон",
    SPEEDING_REPORT:                                   "Превышение скорости",
    MILEAGE_BY_ZONE_TYPES_REPORT:                      "Километраж по типам зон",
    DAILY_TRAFFIC_COLUMN_START_MOTION:                 "Начало движения",
    DAILY_TRAFFIC_COLUMN_END_MOTION:                   "Конец движения",
    DAILY_TRAFFIC_COLUMN_MILEAGE:                      "Общий пробег",
    DAILY_TRAFFIC_COLUMN_TIME_IN_WAY:                  "Время в пути",
    DAILY_TRAFFIC_COLUMN_NO_GPS_DATA:                  "Геодданые отсутствуют",
    DAILY_TRAFFIC_COLUMN_NO_POWER:                     "Не было питания",
    DAILY_TRAFFIC_COLUMN_MAXIMAL_SPEED:                "Максимальная скорость",
    DAILY_FUEL_COLUMN_CONSUMPTION_AMOUNT:              "Количество потребленного топлива",
    DAILY_TRAFFIC_COLUMN_AVERAGE_SPEED:                "Средняя скорость за день",
    ZONE_REPORT_COLUMN_MILEAGE_IN_ZONES:               "Пробег в зонах",
    ZONE_REPORT_COLUMN_FUELED_CONSUMED_IN_ZONES:       "Потреблено топливо в зонах",
    ZONE_REPORT_COLUMN_MILEAGE_IN_ZONE_TYPES:          "Пробег по типам зоны",
    ZONE_REPORT_COLUMN_FUELED_CONSUMED_IN_ZONE_TYPES:  "Количество потребленного топлива по типам зоны",
    SPEEDING_AMOUNT_REPORT_COLUMN:                     "Количество превышений скорости",
    DRIVERS_WORK_SERIAL_ID_COLUMN:                     "Серийный номер брелка",
    DRIVERS_WORK_START_COLUMN:                         "Время начала работы",
    DRIVERS_WORK_STOP_COLUMN:                          "Время окончания работы",
    DRIVERS_WORK_DURATION_COLUMN:                      "Время работы водителя",
    TRAILERS_WORK_DURATION_COLUMN:                     "Время работы прицепа",
    DEVICE_ACTIVITY_LOGICAL_INPUT_1:                   "Данные по логическому входу 2",
    DEVICE_ACTIVITY_LOGICAL_INPUT_3:                   "Данные по логическому входу 3",
    DEVICE_ACTIVITY_LOGICAL_INPUT_4:                   "Данные по логическому входу 4",
    DEVICE_ACTIVITY_LOGICAL_INPUT_5:                   "Данные по логическому входу 5",
    DEVICE_ACTIVITY_LOGICAL_INPUT_6:                   "Данные по логическому входу 6",
    DEVICE_ACTIVITY_LOGICAL_INPUT_7:                   "Данные по логическому входу 7",
    DEVICE_ACTIVITY_LOGICAL_INPUT_8:                   "Данные по логическому входу 8",
    MOVEMENT_BY_DAY_REPORT:                            "Движение по дням",
    MILEAGE_BY_ZONES_REPORT:                           "Километраж по зонам",
    digitalInputs:                                     "Цифровые входы",
    ignitionOn:                                        "Зажигание",
    fuel_level:                                        "Уровень топлива",
    tank:                                              "Бак",
    UssdRequestForDevice:                              "Ответ на ussd запрос:",
    send:                                              "Отправить",
    login_placeholder:                                 "Логин",
    password_placeholder:                              "Пароль",
    errorauthorization:                                "Неправильный логин или пароль",
    enterToSystem:                                     "Вход в систему",
    interfaceLanguage:                                 "Язык интерфейса",
    profile:                                           "Профиль",
    button_send:                                       "Войти",
    control:                                           "Контроль",
    tasks:                                             "Задания",
    report:                                            "Отчет",
    personal:                                          "Сотрудники",
    help:                                              "Помощь",
    placeholder_search:                                "Поиск ...",
    today:                                             "Сегодня:",
    show:                                              "Отображать:",
    group:                                             "Группа:",
    groups:                                            "Группы",
    allTransport:                                      "Весь транспорт",
    transport_search_placeholder:                      "Номер или марка транспорта",
    errorconnection:                                   "Проблемы подключения к серверу...",
    status_inmidcourse:                                "В пути",
    parking:                                           "стоит",
    on:                                                "Вкл",
    off:                                               "Выкл",
    kmH:                                               "км/ч",
    min:                                               "мин",
    engine:                                            "Зажигание",
    speed:                                             "Скорость",
    showroute:                                         "Показать маршрут",
    givetask:                                          "Дать задание",
    MARKER_ADMIN_WORDS:                                "____________________________________",
    clients:                                           "Клиенты",
    users:                                             "Пользователи",
    devices:                                           "Устройства",
    admins:                                            "Администраторы",
    sensors_type:                                      "Типы датчиков",
    exit:                                              "Выход",
    list:                                              "Список",
    clients_list:                                      "Список клиентов",
    admin_list:                                        "Список администраторов",
    transports:                                        "Транспорт",
    nodata:                                            "Нет данных",
    name_group:                                        "Введите название группы",
    groupEdit:                                         "Редактирование группы",
    createGroup:                                       "Создать группу",
    cancel:                                            "Отмена",
    remove_group:                                      "Удалить группу",
    save:                                              "Сохранить",
    selectVehicles:                                    "Выберите транспортные средства",
    select_vehicle:                                    "Выберите транспортное средство",
    give_task:                                         "Дать задание",
    active:                                            "Активные",
    planed:                                            "Запланированные",
    competed:                                          "Завершенные",
    draft:                                             "Черновики",
    templates:                                         "Шаблоны",
    template:                                          "Шаблон",
    suitable_for_assignments:                          "Подходит для заданий, которые периодически повторяются",
    geozones_and_addresses:                            "Адреса и геозоны",
    apply:                                             "Применить",
    anytime:                                           "любое время",
    task_type:                                         "Тип задачи",
    route:                                             "Маршрут",
    date_and_time:                                     "Дата и время",
    beginning_way:                                     "Начало пути",
    no_data:                                           "Нет данных",
    may_be_trouble_internet:                           "Возможно у вас проблемы с интернет соединением. Попробуйте перезагрузить страницу",
    choose_vehicle:                                    "Выберите ТС",
    one_time_assignment:                               "Одноразовое задание",
    addPoint:                                          "Добавить точку",
    enter_adress_or_geofence:                          "Введите адрес или геозону",
    date_and_time:                                     "Дата и время",
    today:                                             "Cегодня",
    yesterday:                                         "Вчера",
    last_seven_day:                                    "Последние семь дней",
    set_own:                                           "Задать свой...",
    fuel:                                              "Топливо",
    voltage:                                           "Напряжение",
    speed:                                             "Скорость",
    input:                                             "Ввод",
    support_contacts:                                  "Служба поддержки:",//"Есть вопрос? Служба поддержки:",
    stay_in_course:                                    "GPS МОНИТОРИНГ | КОНТРОЛЬ ТОПЛИВА | ЭФФЕКТИВНЫЙ БИЗНЕС",
    notifications:                                     "Уведомления",
    password:                                          "Пароль",
    recieved_sms_notif:                                "Получать SMS уведомления",
    recieved_email_notif:                              "Получать уведомления на почту",
    recieved_telegram_notif:                           "Получать уведомления в телеграм",
    adress:                                            "адрес",
    name:                                              "Имя",
    setting:                                           "Настройка",
    user_name:                                         "Имя пользователя",
    current_pass:                                      "Текущий пароль",
    new_pass:                                          "Новый пароль",
    change:                                            "Изменить",
    here_you_can_change_pass:                          "Здесь вы можете изменить ваш пароль для входа в систему.",
    in_system:                                         "Внутри системы",
    on_email:                                          "На почту",
    sms_notif:                                         "SMS-уведомления",
    telegram_notif:                                    "Telegram",
    signal:                                            "Сигнал",
    more:                                              "более",
    fueling:                                           "Заправки",
    maybe_discharge:                                   "Возможные сливы топлива",
    consumption_average:                               "Расход топлива на 100км выше среднего",
    lack_signal:                                       "Отсутствие сигнала от авто",
    event_in_way:                                      "События в пути",
    stop_vs_engine_on:                                 "Стоянки с включенным двигателем",
    over_speed:                                        "Превышение скорости",
    dangerous_temper:                                  "Опасное повышение температуры двигателя",
    dangerous_voltage:                                 "Опасное повышение напряжения бортсети",
    start_task:                                        "Начало задания",
    end_task:                                          "Завершение задания",
    deviation_route:                                   "Отклонение от маршрута",
    choose_events_what_you_want_receive_on_email:      "Выберите события, если хотите получать уведомления о них на свой электронный адрес.",
    to_receive:                                        "Чтобы получать уведомления на ваш номер телефона, пожалуйста, ",
    indicate_your_number:                              "укажите свой номер ",
    and_allow:                                         "и разрешите SMS уведомления",
    settings:                                          "Настройки",
    show_all:                                          "Показать все ",
    tuning_notif:                                      "Настройка уведомлений",
    select_event_if_you_want_receive:                  "Выберите те события, о которых вы хотите получать уведомления",
    add_photo:                                         "Добавить фото",
    change_photo:                                      "Изменить фото",
    profile_vehicle:                                   "Профиль транспортного средства",
    parameters:                                        "Параметры",
    counters:                                          "Счетчики",
    current_level_fuel:                                "Текущий уровень топлива",
    rate_of_fuel:                                      "Норма расхода топлива (на 100км)",
    l:                                                 "л",
    L:                                                 "Л",
    voltage_board_network:                             "Напряжение бортсети",
    current_voltage:                                   "Текущее напряжение",
    normal_voltage:                                    "Нормальное напряжение",
    v:                                                 "В",
    motor_temperature:                                 "Температура двигателя",
    current_temperature:                               "Текущая температура",
    normal_temperature:                                "Нормальная температура",
    other_parameters:                                  "Другие параметры",
    km:                                                "км",
    carrying:                                          "Грузоподъемность",
    tons:                                              "тонн",
    fuel_tank_volume:                                  "Объем бака",
    change_of_oil:                                     "Замена масла",
    disable_counter:                                   "Отключить счетчик",
    enable_counter:                                    "Включить счетчик",
    reset_counter:                                     "Сброcить счетчик",
    next_maintenance:                                  "Следующее ТО",
    change_rubber:                                     "Замена резины",
    here_will_be_employee:                             "Здесь будет отображаться профиль сотрудника.",
    please_select_employe:                             "Пожалуйста, выберите сотрудника из списка слева или",
    add_new_one:                                       "добавьте нового",
    contacts:                                          "Контакты",
    work_time:                                         "Рабочее время",
    vehicles:                                          "Транспортные средства",
    violations:                                        "Нарушения",
    h:                                                 "ч",
    date:                                              "дата",
    timestamp:                                         "дата",
    location:                                          "местоположение",
    new_employee:                                      "Новый сотрудник",
    photo_helps:                                       "Фото поможет Вам быстро отыскать нужного человека",
    enter_first_name:                                  "Введите имя пользователя",
    enter_last_name:                                   "Введите фамилию пользователя",
    first_name:                                        "Имя",
    last_name:                                         "Фамилия",
    add_another_phone:                                 "Добавить еще один номер",
    mobile_phone_number:                               "Номер мобильного телефона",
    create_employee:                                   "Создать сотрудника",
    add_employee:                                      "Добавить сотрудника",
    are_you_sure:                                      "Вы уверены, что хотите удалить ",
    this_employee:                                     "этого работника",
    remove:                                            "Удалить",
    deleting:                                          "удаление",
    edit_employee_profile:                             "Редактирование профиля cотрудника",
    update_data:                                       "Обновить данные",
    report_type:                                       "Тип отчета",
    here_will_be_report:                               "Здесь будет отображаться ваш отчет.",
    create_report_on_sidebar:                          "Создайте отчет, используя меню слева. В процессе работы вы сможете обновлять параметры отчета в реальном времени.",
    create_own_report:                                 "Создать свой отчет",
    name_licenceplate_or_group:                        "Название, номер авто или группа ТС",
    update_report:                                     "Обновить отчёт",
    start_date_must_be_early:                          "Дата начала должна быть раньше даты окончания",
    report_parameters:                                 "Параметры отчета",
    display_order:                                     "Порядок отображения",
    choose_enter_parameters:                           "Введите название и выберите параметры, которые вы хотите включить в ваш отчет",
    report_name:                                       "Название отчета",
    start_motion:                                      "Начало движения",
    end_motion:                                        "Конец пути",
    time_in_way:                                       "Время в пути",
    mileage:                                           "Пробег ",
    mileage_counter:                                   "Счетчик пробега",
    max_speed:                                         "Максимальная скорость",
    average_speed:                                     "Средняя скорость",
    spent_fuel:                                        "Потрачено топлива",
    average_spent_fuel:                                "Средний расход топлива на 100 км",
    average_fuel_consumption_per_hour:                 "Средний расход топлива за 1 час",
    time_parking:                                      "Время остановки",
    time_parking_ignition_on:                          "Время стоянки с включенным зажиганием",
    time_parking_ignition_off:                         "Время стоянки с выключенным зажиганием",
    time_stop_in_geo:                                  "Время стоянки в геозоне",
    time_of_entry_in_geo:                              "Время въезда в геозону",
    time_of_exit_in_geo:                               "Время выезда из геозоны",
    spent_fuel_in_geo:                                 "Потрачено топлива в геозоне",
    fuel_consumtion_in_geo:                            "Расход топлива в геозоне",
    distance_about_prev_entering:                      "Расстояние от предыдущего въезда",
    distance_traveled:                                 "Пройденный путь",
    geofence:                                          "Геозоны",
    stop_parking:                                      "Остановки/Стоянки",
    check_order_display:                               "Проверьте порядок отображения параметров в таблице и поменяйти колонки местами, если нужно.",
    over_cursor_of_interest:                           "Наведите курсор на интересующую вас колонку и перетяните ее в другое место.",
    create_report:                                     "Создать отчет",
    pull_to_move:                                      "Тяните для перемещения",
    your_report_was_created:                           "Ваш отчет успешно создан!",
    now_you_can_choose:                                "Теперь вы можете выбрать его в поле ",
    type_of_report:                                    "“Тип отчета”",
    save_pdf:                                          "Сохранить в PDF",
    print:                                             "Печать",
    save_xlsx:                                         "Сохранить в XLSX",
    save_csv:                                          "Сохранить в CSV",
    no_data_for_period:                                "Hет данных за выбранный период",
    try_other_period:                                  "Попробуйте выбрать другие даты.",
    whole_period:                                      "За весь период",
    period:                                            "Период",
    time_in_point:                                     "Время в точках",
    optional:                                          "опционально",
    repeatings:                                        "Повторяющееся",
    date_of_startTask:                                 (window.outerWidth >= 375) ? "Дата начала задания" : "Дата начала",
    no:                                                "нет",
    before:                                            "до",
    task_created_succesfull:                           "Задание успешно создано!",
    orienting_resource_consumption:                    "Ориентировочный расход ресурсов",
    without_taking_in_geo:                             "без учета работы в геозонах и стоянки на точках",
    duration_of_route:                                 "Протяженность маршрута",
    fuel_consumption:                                  "Расход топлива",
    duration:                                          "Продолжительность",
    edit:                                              "Редактировать",
    adresses:                                          "Адреса",
    geofences:                                         "Геозоны",
    search_by_addresses:                               "Поиск по адрессам",
    search_by_geofences:                               "Поиск по геозонам",
    you_dont_have_saved_addresses:                     "У вас пока нет сохраненных адресов.",
    add_often_used_addres:                             "Добавляйте часто используемые адреса, используя меню справа.",
    you_dont_have_saved_geofences:                     "У вас пока нет сохраненных геозон.",
    add_geofences_by:                                  "Добавляйте геозоны, используя меню справа.",
    add_address:                                       "Добавить адрес",
    add_geofence:                                      "Добавить геозону",
    new_addres:                                        "Новый адрес",
    new_geo:                                           "Новая геозона",
    enter_address:                                     "Введите адрес",
    denotation:                                        'Название',
    this_address:                                      'этот адрес',
    this_geofence:                                     'эту геозону',
    addres_or_object:                                  'Можно найти объект/адрес',
    circle:                                            "Окружность",
    polygon:                                           "Многоугольник",
    color:                                             "Цвет",
    default:                                           "Обычная",
    city:                                              "Город",
    cornfield:                                         "Поле",
    type_geofence:                                     "Тип геозоны",
    search_by_templates:                               "Поиск по шаблонам",
    you_dont_have_saved_templates:                     "У вас нет сохраненных шаблонов",
    add_templates:                                     'Добавьте шаблон используя кнопку "Дать задание"',
    hide:                                              "Скрыть",
    show:                                              "Показать",
    points:                                            {
    one:                                               "точку",
    i:                                                 "точки",
    ek:                                                "точек",
    },
    decline_min:                                       {
    one:                                               "мин.",
    i:                                                 "мин.",
    ek:                                                "мин.",
    },
    decline_hour:                                      {
    one:                                               "час",
    i:                                                 "часa",
    ek:                                                'часов',
    },
    copying:                                           "Копирование",
    sure_copy_template:                                "Вы действительно хотите скопировать этот шаблон?",
    copy:                                              'Копировать',
    repeat:                                            "Повтор",
    every_day:                                         "Каждый день",
    every_week:                                        "Каждую неделю",
    every_month:                                       "Каждый месяц",
    on_weekdays:                                       "По будням",
    update_name:                                       "Обновить название",
    no_active_tasks:                                   "Hет активных заданий.",
    no_planned_tasks:                                  "Hет запланированных заданий.",
    no_completed_tasks:                                "Нет завершенных заданий.",
    create_task_for_vehicles:                          "Создавайте задания для удобного контроля своих транспортных средств.",
    template_was_created:                              "Шаблон успешно создан!",
    template_was_edited:                               "Шаблон успешно отредактирован!",
    column_is_required:                                "Колонка является обязательной и не может быть удалена или пемещена",
    transport_search:                                  "поиск транспорта",
    for_your_request_nodata:                           "По вашему запросу нет данных",
    choose_geofence_or:                                'Выберите геозону или',
    create_new:                                        'Создайте новую',
    forgot_pass:                                       "Забыли пароль?",
    help:                                              "Помощь",
    nothing_choosed:                                   "Ничего не выбрано",
    date_of_violation:                                 "Дата нарушения",
    enter_address:                                     "Введите адрес",
    description_of_violation:                          "Описание нарушения",
    save_violation:                                    "Сохранить нарушение",
    adding_violation:                                  "Добавление нарушения",
    adding_violation:                                  "Добавление нарушения",
    been_blocked:                                      "Ваш аккаунт заблокирован",
    find_reason:                                       "Для уточнения:",
    or_email:                                          "или по email:",
    introduced:                                        "Ознакомлен",
    noGpsPeriod:                                       "нет gps данных",
    noPowerPeriod:                                     "нет питания",
    "daily-traffic":                                   "Движение по дням",
    "logical-inputs":                                  'Логические выводы ',
    "stops-report":                                    "Отчёт по остановкам",
    save_and_contiue:                                  "Сохранить и продолжить",
    allotted_show_place:                               "Выделенная область будет показыватся на месте аватарки",
    "daily-fuel-level":                                "Уровень топлива по дням",
    "daily_consumption_hundred_km":                    "Дневные траты топлива на 100 км",
    "daily_fuel_consumption":                          "Суточная расход топлива",
    "fuelled_at_day":                                  "Всего заправлено",
    "de_fuelled_at_day":                               "Всего возможно слито",
    "fuel_level_end":                                  "Уровень топлива в конце суток",
    "fuel_level_start":                                "Уровень топлива на начало суток",
    "daily_ignition_on_duration":                      "Время с включенным зажиганием",
    "daily_consumption_hour":                          "Расход топлива за час",
    "mileage_at_day":                                  "Расстояние за сутки",
    "summary_de_fuelled":                              "Вероятное количество слитого топлива",
    "summary_fuel_spent":                              "Всего израсходаваного топлива",
    "summary_fuelled":                                 "Всего заправлено топлива",
    "summary_mileage":                                 "Общее пройденое растояние",
    "ignition_on_duration":                            "Общее время с включенным зажиганием",
    delta:                                             "длительность",
    dist:                                              "дистанция от предыдущей остановки",
    time_begin:                                        "начало",
    time_end:                                          "конец",
    arrival_time:                                      "Время прибытия",
    departure_time:                                    "Время отбытия",
    complete_prev_step:                                "Заполните предыдущий этап",
    fuel_property_speed:                               "Максимальная скорость движения при определении сливов и заправок",
    fuel_property_min_volume:                          "Минимальный объем заправки и слива",
    fuel_property_duration:                            "Пропускать скачки менее чем секунд",
    fuel_property_tank_volume:                         "обьем бака",
    fuel_property_allowed_fluctuation:                 "допустимые колебания топлива (на первое время пока так)",
    fuel_property_minimal_mileage:                     "минимальное расстояние проезда (пока так)",
    fuel_property_minimal_stop_time:                   "минимальное время остановки (при котором это не будет считаться собитыем стоянки)",
    fuel_property_average_fuel_consumption:            "среднее потребление топлива",
    no_valid_name:                                     'Допускаются кириллические и латинские символы, не менее двух символов',
    validationResult:                                  'Заполните все поля помеченные *',
    no_valid_telegram:                                 'Ваш логин не прошел проверку',
    no_valid_email:                                    'Ваш почтовый адрес не прошел проверку',
    no_valid_phone_number:                             'Номер должен содержать 12 цифр (международный формат), например +780582458919',
    textTelegramSettings:                              "Для получения уведомлений укажите свой логин для телеграм и разрешите уведомления.",
    youCanhere:                                        "Можете сделать это тут",
    choose_notif:                                      "Выберите уведомления которые хотите получать из списка ниже",
    no_events:                                         "нет событий",
    edit_geo:                                          'Редктирование геозоны',
    admonition:                                        "Предупреждение",
    not_complete_edit:                                 "Вы не завершили редактирование геозоны",
    continue:                                          'Продолжить',
    resume_edit:                                       'Возобновить редактирование',
    Parking:                                           "Парковка",
    "zones-passage":                                   'Пересечение ТС зон',
    "zones-device-count":                              'Количество устройств в зонах',
    "zones-passage":                                   'Пересечения транспортным средством зон',
    "stops-in-zones":                                  'Остановки в зонах',
    "stops-without-zones":                             'Остановки вне зон',
    "zones-stops-and-work":                            'Время работы и стоянки в зонах и вне зон',
    "mileage-by-zone-types":                           'Километраж по типам зон',
    prev_cur_zone_entry_duration:                      'Время между зонами',
    zone_entry_time:                                   'Время въезда в зону',
    zone_exit_time:                                    'Время выезда из зоны',
    zone_name:                                         'Имя зоны',
    "exit-out-of-zones":                               "Выезд из зон",
    movement_start:                                    'Начало движения',
    movement_stop:                                     'Конец движения',
    time_of_working:                                   'Время работы',
    time_of_stops_in_zones:                            'Время остановок в зонах',
    time_of_stops_without_zones:                       'Время остановок вне зон',
    serial_numbers:                                    'Серийные номера',
    summary_duration_out_zones:                        'Общее время в зонах',
    summary_mileage_out_zones:                         'Общий пробег за пределами зон',
    daily_mileage:                                     'Суточный пробег',
    daily_zone_type_fuel_consumption:                  'Суточный расход топлива по типам зон',
    daily_zone_type_mileage:                           'Суточный пробег по типам зон',
    zone_type:                                         'Тип зоны',
    vehicle_name:                                      'Имя транспорта',
    zone_exits_number:                                 'Количество выходов из зон',
    zones_vehicle_was_in:                              'Зоны где был транспорт',
    vehicle_zone_exit_count:                           'Количество зон по транспортным средствам',
    days_number:                                       'Количество дней без выезда',
    zones_with_vehicles:                               'Зоны с транспортом',
    error_autorization:                                'Пожлайста проверьте свои логин/пароль',
    stop_address:                                      "Адрес остановки",
    longitude:                                         "Долгота",
    latitude:                                          "Широта",
    zone_titles:                                       "Названия зон",
    zone_ids:                                          "Id зон",
    sound_signal:                                      "Звук сигнала",
    didnt_have_vehicle:                                "У вас нет транспортных средств",
    youDidntsetOldPass:                                "Вы не указали старый пароль",
    passwords_do_not_match:                            "Пароли не совпадают",
    password_must_be:                                  "Пароль должен содержать минимум 3 цифры 5 букв, в общем 8 символов",
    newPassMustBe:                                     "Новый пароль должен содержать минимум 3 цифры 5 букв, в общем 8 символов",
    yourPassWasUpdated:                                "Ваш пароль успешно обновлен",
    checkYourOldPass:                                  "Проверьте старый пароль",
    horizontal_orientation_unavailable:                     "Горизонтальная ориентация недоступна",
    available_options:                                 "Доступные опции",
    "daily-fuel":                                      "Отчет по топливу",
    "zone-report":                                     "Отчет по зонам",
    no_gps_data:                                       "GPS Отсутствует",
    liters:                                            "Л",
    maximal_speed:                                     "Максимальная скорость",
    no_power:                                          "Нет питания",
    consumption_amount:                                "Расход топлива",
    fuelling_amount:                                   "Количество заправленого топлива",
    consumption_per_hundred_km:                        "Потребление на 100 км",
    stops_in_zones_duration:                           "Длительность остановок",
    level_at_start:                                    "Уровень топлива на начало",
    level_at_end:                                      "Уровень на конец",
    defuelling_amount:                                 "Слив топлива",
    stops_without_zones_duration:                      "Длительность остановок за пределами зон",
    fueling:                                           "Заправка",
    defueling:                                         "Возможный слив",
    new_group:                                         'Новая группа',
    you_dont_have_group:                               'У вас пока нет групп.',
    create_group_for_comfort:                          'Создайте группы транспортных средств для более удобного пользования системой.',
    asign_to_other_transport:                          "Назначить другому транспорту",
    make_template:                                     "Сделать шаблоном",
    print:                                             "Распечатать",
    next:                                              "Далее",
    cancel:                                            "Отмена",
    last_time_data:                                    "Посл. данные",
    failed:                                            "Проваленные",
    no_failed_tasks:                                   "Нет проваленых задач",
    after:                                             'После',
    number_device:                                     "Номер устройства",
    date_setup:                                        "Дата установки",
    date_disas:                                        "Дата снятия",
    square:                                            "Площадь",
    hectares:                                          "Га",
    road:                                              "Путь",
    enter_dates:                                       "Ввод дат",
    maximum_speed:                                     "Максимальная скорость для определения заправок/сливов",
    "m/s":                                             'М/С',
    maximum_amount:                                    'Максимальное количество',
    liter:                                             'Л',
    maximum_duration:                                  'Максимальное время возврата топлива при скачках',
    s:                                                 'С',
    tank_volume:                                       'Объем бака',
    fuel_allowable_fluctuation:                        'Колебания топлива',
    minimal_mileage:                                   'Минимальное расстояние',
    minimal_stop_time:                                 'Максимальное время прыжка топлива кратковременной остановки',
    average_fuel_consumption:                          'Средний расход топлива на 100 км',
    maximum_allowed_speed:                             "Максимальная разрешенная скорость",
    period1:                                           'Передача со включенным зажиганием',
    period2:                                           'Передача со выключенным зажиганием',
    "mileage-by-zones":                                'Километраж по зонам',
    over_speed:                                        "Превышение скорости",
    over_speed_by_zone_type:                           "Превышение скорости по типам зон",
    area_zones:                                        "Площади зон",
    ignition_on_parking_time:                          "Время стоянки с включенным зажиганием",
    "fuel-level":                                      "Уровень топлива",
    DAILY_FUEL_COLUMN_LEVEL_AT_START:                  "Уровень в начале",
    FUEL_CONSUMPTION_PER_HOUR:                         "Расход в час",
    DAILY_IGNITION_ON_DURATION:                        'Длительность включенного зажигания',
    DAILY_FUEL_COLUMN_DEFUELLING_AMOUNT:               'Длительность вероятного слива',
    DAILY_FUEL_COLUMN_LEVEL_AT_END:                    'Уровень вконце',
    DAILY_TRAFFIC_COLUMN_MILEAGE:                      'Расстояние',
    DAILY_FUEL_COLUMN_CONSUMPTION_AMOUNT:              'Объем потребленного топлива',
    DAILY_FUEL_COLUMN_CONSUMPTION_PER_HUNDRED_KM:      'Потребление на 100 км',
    DAILY_FUEL_COLUMN_FUELLING_AMOUNT:                 'Расход топлива',
    ZONE_REPORT_COLUMN_FUELED_CONSUMED_IN_ZONES:       'Расход топлива в зонах',
    ZONE_REPORT_COLUMN_MILEAGE_IN_ZONES:               'Километраж в зонах',
    ZONE_SQUARE_REPORT_COLUMN:                         'Площадь зоны',
    PARKING_WITH_IGNITION_ON_DURATION_COLUMN:          'Длительность стоянки с включенным зажиганием',
    DAILY_TRAFFIC_COLUMN_TIME_IN_WAY:                  'Время в пути',
    DAILY_TRAFFIC_COLUMN_START_MOTION:                 'Начало движения',
    DAILY_TRAFFIC_COLUMN_MAXIMAL_SPEED:                'Максимальная скорость',
    DAILY_TRAFFIC_COLUMN_NO_POWER:                     'Нет питания',
    DAILY_TRAFFIC_COLUMN_END_MOTION:                   'Конец движения',
    DAILY_TRAFFIC_COLUMN_NO_GPS_DATA:                  "Нет GPS",
    SPEEDING_AMOUNT_REPORT_COLUMN:                     'Количество превышений',
    SPEEDING_DURATION_REPORT_COLUMN:                   'Длительность превышения',
    SPEEDING_TIME_WITH_SPEED_REPORT_COLUMN:            'Время с превышением',
    ZONE_REPORT_SPEEDING_DURATION_COLUMN:              'Длительность превышения',
    ZONE_REPORT_SPEEDING_TIME_WITH_SPEED_COLUMN:       'Длительность превышения',
    ZONE_REPORT_SPEEDING_AMOUNT_COLUMN:                'Количество превышений',
    TimeoutForDevice:                                  'Авто под охранной',
    set_on_guard:                                      "Поставить на охрану",
    disarm:                                            "Снять авто с охраны",
    guard_settings:                                    "Охрана авто",
    stay_on_quard_from:                                "На охране с",
    DAILY_TRAFFIC_COLUMN_START_MOTION:                 "Начало движения",
    DAILY_TRAFFIC_COLUMN_END_MOTION:                   "Конец движения",
    DAILY_TRAFFIC_COLUMN_MILEAGE:                      "Пробег",
    DAILY_TRAFFIC_COLUMN_TIME_IN_WAY:                  "Время в пути",
    DAILY_TRAFFIC_COLUMN_NO_GPS_DATA:                  "Не было gps данных",
    DAILY_TRAFFIC_COLUMN_NO_POWER:                     "Не было питания",
    DAILY_TRAFFIC_COLUMN_MAXIMAL_SPEED:                "Максимальная скорость",
    DAILY_FUEL_COLUMN_CONSUMPTION_AMOUNT:              "Количество потребленного топлива",
    ZONE_REPORT_COLUMN_MILEAGE_IN_ZONES:               "Пробег в зонах",
    DAILY_TRAFFIC_COLUMN_MILEAGE:                      "Общий пробег",
    ZONE_REPORT_COLUMN_FUELED_CONSUMED_IN_ZONES:       "Потреблено топливо в зонах",
    ZONE_REPORT_COLUMN_MILEAGE_IN_ZONES:               "Пробег в зонах",
    DAILY_TRAFFIC_COLUMN_MILEAGE:                      "Общий пробег",
    ZONE_REPORT_COLUMN_FUELED_CONSUMED_IN_ZONES:       "Потреблено топлива в зонах",
    SPEEDING_AMOUNT_REPORT_COLUMN:                     "Количество превышений скорости",
    SPEEDING_DURATION_REPORT_COLUMN:                   "Продолжительность превышения скорости",
    SPEEDING_TIME_WITH_SPEED_REPORT_COLUMN:            "Время со скоростью превышения",
    ZONE_REPORT_SPEEDING_AMOUNT_COLUMN:                "Количество превышений скорости в зонах",
    ZONE_REPORT_SPEEDING_DURATION_COLUMN:              "Продолжительность превышения скорости в зонах",
    ZONE_REPORT_SPEEDING_TIME_WITH_SPEED_COLUMN:       "Время со скоростью превышения в зонах",
    ZONE_SQUARE_REPORT_COLUMN:                         "Площади зон",
    PARKING_WITH_IGNITION_ON_DURATION_COLUMN:          "Стоянка со включенным зажиганием",
    DAILY_FUEL_COLUMN_LEVEL_AT_START:                  "Уровень топлива в начале дня",
    DAILY_FUEL_COLUMN_FUELLING_AMOUNT:                 "Количество заправленного топлива",
    DAILY_FUEL_COLUMN_DEFUELLING_AMOUNT:               "Количество слитого топлива",
    DAILY_TRAFFIC_COLUMN_MILEAGE:                      "Пробег",
    DAILY_FUEL_COLUMN_CONSUMPTION_AMOUNT:              "Количество потребленного топлива",
    DAILY_FUEL_COLUMN_CONSUMPTION_PER_HUNDRED_KM:      "Расход на сто километров",
    DAILY_FUEL_COLUMN_LEVEL_AT_END:                    "Уровень топлива в конце дня",
    FUEL_CONSUMPTION_PER_HOUR:                         "Расход топлива за час",
    DAILY_IGNITION_ON_DURATION:                        "Время со включенным зажиганием",
    CONSTANT_TIME_WHEN_FUEL_LEVEL_CHANGED:             'Время когда менялся уровень',
    CONSTANT_FUEL_LEVEL_DIFFERENCE:                    "На сколько изменился уровень",
    CONSTANT_FUEL_LEVEL_BEFORE_CHANGES:                "Уровень до изменений",
    CONSTANT_MILEAGE_AFTER_PREVIOUS_CHANGE:            "Пройдено от прошлого изменения",
    CONSTANT_FUEL_CONSUMED:                            "Потрачено топлива",
    CONSTANT_CONSUMPTION_PER_HUNDRED_KM:               "Расход на 100 км",
    CONSTANT_STOPS_BEGIN_COLUMN:                       "Начало остановки",
    CONSTANT_STOPS_DURATION_COLUMN:                    "Длительность остановки",
    CONSTANT_STOPS_DISTANCE_FROM_PREVIOUS_COLUMN:      "Расстояние между остановками",
    CONSTANT_STOPS_ADDRESS_COLUMN:                     "Адрес остановки",
    CONSTANT_STOPS_CURRENT_ZONE:                       "Текущая зона",
    CONSTANT_STOPS_SUMMARY_DISTANCE:                   "Общая дистанция по всем остановкам",
    DRIVERS_WORK_START_COLUMN:                         "Время начала работы",
    DRIVERS_WORK_STOP_COLUMN:                          "Время окончания работы",
    DRIVERS_WORK_DURATION_COLUMN:                      "Время работы водителя",
    DRIVERS_WORK_SERIAL_ID_COLUMN:                     "Серийный номер брелка",
    TRAILERS_WORK_DURATION_COLUMN:                     "Время работы прицепа",
    TRAILERS_WORK_SERIAL_ID_COLUMN:                    "Серийный номер прицепа",
    TRAILERS_WORK_START_COLUMN:                        "Время начала работы",
    TRAILERS_WORK_STOP_COLUMN:                         "Время окончания работы",
    TIME_OF_GETTING_LAST_DATA_DEVICE_LAST_DATA_COLUMN: "Последние данные",
    TIME_OF_GETTING_LAST_DATA_DEVICE_ID_COLUMN:        "Id устройства",
    SIGNALING_ALARM:                                   "Сигнализация:",
    was_alarm_locked:                                  "Сработала сигнализация, авто разблокировано в",
    alarmDrawdown:                                     "Сигнал SOS сработал",
    lockedAlarmTrigger:                                "Сработка сигнализации",
    noSignalInSecurity:                                "Отсутствие сигнала в режиме охраны",
    app_notif:                                         "Уведомления в приложении",
    ignition_always_on:                                "Зажигание всегда включено",
    control_output:                                    "Управляющий вывод",
    no_data_parameters:                                "Нет данных по параметрам",
    inverse:                                           "Инверсия",
    trailer:                                           "Прицеп",
    deviceInNumber:                                    "Номер ввода",
    notifyMileageCounter:                              "Уведомления счетчика по пробегу",
    notifyWorkingDaysCounter:                          "Уведомления счетчика по дням",
    notifyEngineHoursCounter:                          "Уведомления счетчика по моточасам",
    isNotifyCanCounter:                                "Уведомления счетчика моточасов по CAN",
    EngineHours:                                       "Моточасы",
    WorkingDays:                                       "Счетчик по днях",
    TEMPORAL:                                          "C учетом времени",
    NOT_TEMPORAL:                                      "Без учета времени",
    ZONE_REPORT_COLUMN_FUELED_CONSUMED_IN_ZONE_TYPES:  'Топлива потребленно в типе зоны',
    ZONE_REPORT_COLUMN_STOPS_WITHOUT_ZONES_DURATION:   'Остановки вне зон',
    DAILY_TRAFFIC_COLUMN_AVERAGE_SPEED:                'Средняя скорость',
    ZONE_REPORT_COLUMN_MILEAGE_IN_ZONE_TYPES:          'Километраж по типам зон',
    ZONE_REPORT_COLUMN_STOPS_IN_ZONES_DURATION:        'Остановки в зонах',
    not_connected:                                     'Не на связи',
    movement:                                          'Движение',
    parking:                                           'Стоянка',
};

const routeDetailsPage = {
    volume:                   'объём',
    year:                     'год',
    days:                     'суток',
    month:                    'мес',
    hour:                     'ч',
    min:                      'мин',
    second:                   'секунд',
    consider_ignition:        'Учитывать зажигание',
    ignore_ignition:          'Не учитывать зажигание',
    haversin:                 'Хаверсин',
    filtered_fuel_events:     'События топливного фильтра',
    do_not_smooth:            'Не сглаживать',
    do_smooth:                'Сглаживать',
    show_pure_data:           'Показать чистые данные',
    hide_pure_data:           'Скрыть чистые данные',
    show_raw_data:            'Показать RAW',
    hide_raw_data:            'Скрыть RAW',
    ruler:                    'Линейка',
    link_copied_to_clipboard: 'Ссылка скопирована в буфер обмена',
    validity:                 'Достоверность',
    unfiltered:               'Нефильтрованое',
    unfiltered_data:          'Нефильтрованные',
    only_valid_data:          'Только валидные данные',
    table_view:               'Режим таблицы',
    focus_on_vehicle_marker:  'Cледить за авто',
};


const transportProfile = {
    counter:             'Счетчик',
    create_counter:      'Создание счетчика',
    edit_counter:        'Редактирование счетчика',
    counter_name:        'Название счетчика',
    counter_type:        'Тип счетчика',
    start_value:         'Начальное значение',
    threshold:           'Порог срабатывания',
    left:                'Осталось',
    counter_was_created: 'Счетчик создан',
    created:             'Создан',
    are_you_sure_you_want_to_delete_the_trigger: 'Вы уверены, что хотите удалить триггер?',
    trigger_creatting:                           'Cоздание триггера',
    trigger_editing:                             'Редактирование триггера',
    trigger_name:                                'Название',
    type:                                        'Тип',
    logic_input:                                 'Логический вход',
    condition:                                   'Условие',
    enabled:                                     'Включен',
    disabled:                                    'Выключен',
    available:                                   'Доступно',
    notification_text:                           'Текст уведомления',
    more_than:                                   'More Than (Более чем)',
    less_than:                                   'Less Than (Менее чем)',
    quals:                                       'Quals (Равно)',
    remaining_to_work: 'До срабатывания',
    will_work_when_value: 'Сработает при значении датчика',
    will_work_when:         'Сработает при',

    creating_control_zone:  'Cоздание контрольной зоны',
    editing_control_zone:   'Редактирование зоны',
    choose_zone:            'Выберите зону',
    Smoothed:               'Сглаженное',
};

const rfidCardsPage = {
    phone_number:                  'Номер телефона',
    enter_number_or_driver_name:   'Введите номер или имя водителя',
    add_card:                      'Добавить карту',
    update_card:                   'Обновить карту',
    delete_card:                   'Удалить карту',
    add_new:                       'добавьте новую',
    here_will_bew_card_info:       'Тут будет отображаться информация про карточку',
    please_select_card_or:         'Пожалуйста, выберите карту со списка слева или',
    driver_name:                   'Имя водителя',
    are_you_sure_want_remove_card: 'Вы уверены, что хотите удалить карту?',
    editing_rfid_card:             'Редактирование RFID карты',
    creating_rfid_card:            'Создание RFID карты',
};

const infoGraphicControl = {
    CONNECTED:          'На связи',
    DISCONNECTED:       'Отключено',
    NO_DATA:            'Нет данных',
    NO_DEVICE:          'Нет устройства',
    coordinates_absent: 'Координаты отсутсвуют',
    data_reception_disabled: 'Прием данных отключен',
    parking_with_ignition_on:          "Стоянка со включенным зажиганием",
    vehicle_like_auto: 'Авто',
};

const hintsVocabularyRU = {
    here_you_can_look:                                 "Тут вы можете смотреть статистику, а также настроить датчики на свое авто",
    not_enough_data_to_build_geofence:                 'Недостаточно данных для построения геозоны',
    is_wrong:                                          'не верный',
    closePopup:                                        "Закройте окно",
    vehicle_stopped:                                   "Транспортное средство стоит",
    selected_vehicle:                                  "Транспортное средство выбрано",
    vehicle_moving:                                    "Транспортное средство движется",
    vehicle_not_connected:                             "Транспортное средство не на связи",
    marker_hint:                                       "Маркер указывает положение авто и направление его движения.",
    clickOnMarker:                                     "Клик по маркеру вызывает окно с информацией по авто",
    event_what_happened_today:                         "События, которые произошли с авто за сегодня",
    on_hover_visible_counter:                          "При наведении на иконку видно количество событий",
    parking_vs_engintOn:                               "Стоянки с включенным зажиганием более 5 минут",
    fuelling:                                          "Заправка топлива",
    fuel_drain:                                        "Предполагаемый слив топлива",
    on_hover_visible_event_date:                       "При наведении видно время события",
    vehicle_is_on_quard:                               "Авто поставлено на охрану",
    quard_is_triggered:                                "Сработала охрана",
    on_hover_visible_event_date:                       "При наведении появится информация о дате, когда произошло событие",
    info_about_curent_status:                          "Информация о текущем состоянии",
    antena_is_ok:                                      "С антенной всё в порядке",
    end_education:                                     "Закончить обучение",
    antena_icons:                                      "Информация о том, когда были получены последние данные",
    click_on_star:                                     'Клик по звездочке позволяет закрепить элемент вверху списка, чтобы его было легче найти',
    click_on_one_of_list:                              "Кликните на пустое место авто из списка",
    desc_selected_vehicle:                             "Авто в режиме 'выбрано', произошло следующее:",
    if_vehicle_has_coordinates:                        "если есть координаты устройства - карта сместилась к маркеру этого авто",
    if_veicle_move:                                    "если авто двигается - карта перемещается за ним",
    also_available_btns:                               "стали доступны кнопки: 'построение маршрута' и 'постановка задачи'",
    click_by_this_btn_call_hint:                       "Клик по этой кнопке включает помощь по элементам страницы",
    navigation_by_vehicle_settings:                    "Навигация по настройкам транспортного средства для уведомлений и отчетов",
    vehicleId_in_our_system:                           "Id транспортного средства в системе",
    deviceId:                                          "Серийный номер трекера, который установлен на транспортном средстве",
    settings_for_notif:                                "Базовые параметры и периоды передачи",
    desc_counters:                                     "Настройки счетчиков. Например, вы можете установить количество пройденных моточасов, при прошествии которых придет уведомление",
    desc_handling:                                     "Постановка/снятие охраны, отправка USSD запросов",
    desc_digitalInputs:                                "Настройки цифровых входов",
    desc_control_of_sensor:                            "Настройки и создание триггеров для уведомлений по значениям датчиков. Например, вы можете настроить следующее условие: если значение датчика 'уровень топлива' опустится ниже 20, прислать мне уведомление",
    desc_control_in_zones:                             "Управление уведомлениями о въезде/выезде из зоны",
    click_for_workWithCroup:                           "Нажмите, чтобы вызвать меню работы с группами",
    onclick_inList_left:                               "При клике в списке транспорта останется только транспорт, который принадлежит к данной группе",
    this_addNewGroup:                                  "Нажмите, чтобы создать новую группу",


    click_on_marker:                                   'Кликните по иконке, чтобы скрыть события, которые не интересны в списке транспорта. Если курсор имеет вид "Отключен" - в системе нет уведомлений данного типа',
    search_field:                                      'Поле для поиска нужного транспорта из списка',
    click_on_btn_alow:                                 'Клик по кнопке позволяет скрыть/показать список транспорта',
    click_nameOfVehicle:                               "Кликните по названию авто, чтобы вызвать профиль транспортного средства с его настройками",
    click_btn_showRoute:                               'Клик по кнопке позволяет построить маршрут транспортного средства',
    click_btn_setTask:                                 'Клик по кнопке позволяет переименовать транспортное средство',

    in_this_block:                                     "В данном блоке отображаются события, которые произошли с авто за сегодняшний день. Если навести курсор на значок, высветится название авто",
    info_about_curent:                                 "Информация о текущем состоянии авто, зажигании, уровне топлива и т.д.",
    info_about_last_data:                              "Информация о том, когда последний раз приходили данные с авто",
    click_to_workWithCroup:                            "Нажмите для работы с группами транспорта",

    here_can_edit_name:                                "Тут вы можете отредактировать название группы.",
    vehicle_of_groupCheckbox:                          "Транспорт, который принадлежит к группе, помечен галочкой. При клике на транспорт он будет добавлен в группу (или исключен).",
    search_by_transport:                               "Поиск в этой графе поможет отфильтровать интересующий транспорт",
    button_instructions:                               'Клик по кнопке "Cохранить" позволяет сохранить изменения, сделанные в группе; "Отмена" - отменяет изменения; "Удалить группу" - удаляет существующую группу, при этом транспорт с аккаунта не удалится',
    button_view_ofMap:                                 "Кнопки позволяют выбрать внешний вид карты",
    marker_of_place:                                   "Обозначает местоположение транспорта. Маркер синего цвета обозначает, что эта машина активна. При наведении на маркер появится подсказка с названием машины. Чтобы увидеть больше информации, нужно кликнуть по маркеру",
    pressToChoose:                                     'Нажмите, чтобы выбрать тип отчёта',
    also_you_can_call:                                 "Также вы можете создать свой отчёт в конструкторе отчётов",
    pressToChooseOnVehicle:                            "Выберите транспорт, по которому будет создан отчёт. Также вы можете использовать это поле для поиска авто или группы",
    pressToChooseThisVehicle:                          "Нажмите, чтобы выбрать данное авто. Выбранные авто будут выделены жирным шрифтом",
    forDeletePress_x:                                  "Чтобы удалить авто из списка, нужно нажать на крестик",
    date_start_of_period:                              "Дату начала периода вы можете ввести с клавиатуры (дд.мм.гг), или нажав на иконку с календарем",
    timeReportFrom:                                    "Время, с которого сформируется отчет, можно указать в поле в формате 20:00 либо выбрать со списка",
    buttonWillActiveAfter:                             "Кнопка становится активной после того, как будут заполнены все необходимые поля. По клику на нее отчёт обновляется",
    buttonAlloswChangeSize:                            "Клик по кнопке позволяет менять размеры отображения отчета",
    buttonsForExport:                                  "Кнопки для экспорта в разные форматы",
    drawNameOfReport:                                  'Укажите название отчёта - это обязательное поле',
    chooseFieldWhatWillInRep:                          'Выберите поля, которые будут отображаться в отчёте',
    pressForSetting:                                   'Нажмите, чтобы перейти к настройке порядка колонок',
    pinchMouse:                                        'Зажмите указатель мыши и перетягивайте колонку в нужное место',
    pressForCreate:                                    'Нажмите, чтобы создать свой персональный отчёт',
    holdMouseButton:                                   'Зажмите указатель мыши и перетягивайте колонку в нужное место',
    clickForCreateCustomRep:                           'Нажмите чтобы создать свой персональный отчёт',
    clickAddEmployee:                                  'Нажмите, чтобы добавить сотрудника',
    listOfYourEmployee:                                'Список ваших сотрудников',
    clickForChooseEmploye:                             'Нажмите, чтобы выбрать сотрудника',
    onMapMarkedCurrent:                                'На карте отмечено текущее местоположение сотрудника',
    clickOnElement:                                    'Клик по этому элементу дает возможность отредактировать информацию',
    clickOnElementAlowSetTask:                         'Клик по этому элементу позволит дать задание сотруднику',
    clickOnElementAlowDelete:                          'Клик по элементу позволит удалить сотрудника',
    here_can_change_period:                            'Здесь можно сменить период, за который будет построен маршрут с графиком',
    click_for_showTable:                               'По клику на кнопку информация о машине будет представлена в виде таблицы',
    sensrosButtons_block:                              'Доступные на авто датчики: при наведении на блок можно увидеть их названия',
    addGraph:                                          'Нажав на кнопку, можно добавить/убрать информацию из графика',
    graph_triger:                                      'Удерживая зажатой левую кнопку мыши на триггере, можно смотреть данные в конкретной точке на графике (триггер подвижный). Также доступно управление стрелками влево/вправо',
    here_valueSensors:                                 'Показания датчиков в момент, который определяется положением триггера',
    time_format:                                       'Временная шкала в 24-часовом формате',
    line_of_progress:                                  'Линия прогресса соответствует временной шкале и индексирует выполнение задания. Также на ней отображаются события, которые произошли с транспортом. Длительность события подсвечена цветом иконки.  При клике по иконке маркер переместится к соответствующей точке на карте',
    marker_show_currentPlace:                          "Маркер автомобиля указывает положение авто и направление его движения",
    blue_color_route:                                  "Синим цветом на карте - маршрут транспорта за выбранное время. При клике на точку маршрута выделенная строка/триггер в таблице перемещается на соответсвующую временную метку. Это позволит узнать время и данные по авто в выбраной точке",
    zoom_instruction:                                  "Этот элемент управления позволяет масштабировать график. Для изменения масштаба можно использовать ползунок, кнопки +/- либо скролл мышь на графике. ",
    startTask_instr:                                   "Определитесь с задачей. Если она будет периодически повторяться - создайте шаблон и каждый раз используйте его. Если задача одноразовая - выбирайте одноразовое задание",
    available_vehicle_instr:                           "При клике на этот элемент появится список доступного транспорта",
    button_for_name:                                   "Нажмите на радиокнопку, чтобы заполнить название шаблона",
    name_is_required:                                  "Название является обязательным полем; заполните, чтобы перейти к следующему шагу",
    pressToNextStep:                                   "Нажмите, чтобы перейти к следующему этапу",
    control_points_instr:                              "Маршрут может содержать как контрольные точки, так и геозоны",
    clickForList:                                      "Клик по элементу вызывает список геозон",
    start_enterAdress_instr:                           "Начните вводить название адреса, чтобы появились подсказки. Затем нажмите Tab ",
    forChooseAddres:                                   "Чтобы выбрать подсказку, используйте ↑ ↓; чтобы выбрать адрес - нажмите Enter; выбранный адрес будет отмечен на карте маркером",
    clickForCopy:                                      "Нажмите, чтобы скопировать в буфер обмена",
    clickToClear:                                      "Нажмите, чтобы очистить",
    clickToAdd:                                        "Нажмите, чтобы добавить еще один адрес",
    clickToDelete:                                     "Кнопка позволяет удалить адрес из маршрута",
    afterClick_instr:                                  "После нажатия кнопки происходит построение маршрута и переход к следующему шагу. Обратите внимание: для того, чтобы построить маршрут, необходимо минимум 2 точки",
    understand:                                        "Понятно!",
    enterDate_instr:                                   "Введите дату начала задания в формате дд.мм.гг или выберите из календаря. Чтобы вызвать календарь, кликните по иконке в углу поля",
    listControlPoint:                                  "Список контрольных точек маршрута",
    timeCheckpoint:                                    "Время, до которого транспортному средству нужно попасть в адрес назначения. Система будет отслеживать его при выполнением задания. Вы можете ввести время в 24-часовом формате (чч:мм) или выбрать его из списка",
    ifThenYouCanChange:                                "Если вас не устраивает оптимальный маршрут, проложенный системой,- его можно отредактировать, изменив дорогу между точками. Для этого зажмите левую клавишу мыши на участке дороги; далее путем перетаскивания измените маршрут",
    clickToReturn:                                     'Клик по это кнопке позволит вернуться на шаг назад',
    clickToNextStep:                                   'Нажмите, чтобы перейти к следующему этапу',
    pressToSaveTemplate:                               "Нажмите, чтобы сохранить шаблон",
    pressToDrawOnMap:                                  "Нажмите на шаблон, чтобы его маршрут был отрисован на карте",
    clickToView:                                       "Нажмите, чтобы просмотреть полную информацию про шаблон",
    clickToSetTask:                                    "Нажмите, чтобы поставить транспорту этот шаблон в задачу",
    clickToDeleteTemplete:                             "Нажмите, чтобы удалить шаблон",
    clickToCopyTemplate:                               "Нажмите, чтобы скопировать шаблон",
    clickToViwAllLIst:                                 "Нажмите, чтобы посмотреть полный список точек",
    clickToViewPointOnMap:                             "Нажмите, чтобы посмотреть, где точка находится на карте",
    clickToEdit:                                       "Нажмите, чтобы перейти к редактированию",
    clictToGeoDraw:                                    "Нажмите на название, чтобы геозона была отрисована на карте",
    clickToDeleteElem:                                 "Нажмите, чтобы удалить этот элемент",
    enterAddresForMove:                                "Введите адрес, чтобы карта переместилась к нему",
    chooseTypeColor:                                   "Выберите тип окружности и ее цвет. Далее, кликнув на карте, вы сможете построить фигуру ",
    whitePoints:                                       "Белые точки являются элементами управления фигурой. Точка по центру фигуры позволяет менять её расположение; остальные - изменять ее размеры или форму. Кнопка со стрелкой позволяет отменить последние изменения ",
    hereCalcSquare:                                    "Здесь рассчитывается площадь фигуры в га",
    deleteBtnGeo:                                      "Если вы где-то ошиблись и хотите начать заново, этой кнопкой можно удалить фигуру",
    fillNameGeo:                                       "Заполните название геозоны, по которому по которому она будет доступна для поиска. Поле является обязательным",
    clickToSaveGeo:                                    "Нажмите, чтобы сохранить геозону",
};

const telegram_instructions = {
    send_message_to_telegrams_from_globus:             'Відправка повідомлень з "ГЛОБУС Pro" на Telegram,',
    start_of_work:                                     'Початок роботи:',
    search_bot_in_telegram:                            "1. У Telegram шукаємо / додаємо в список наш бот",
    send_command_start:                                                  "2. Отправляем ему команду",
    after_that_send_login_path:                                          "3. После этого логинимся в формате:",
    work_with_telegram_started:                                          "Работа с Telegram-ботом началась!",
    now_we_need_setup_messages_in_to_telegram:                           "4.  Теперь необходимо настроить сообщения, которые будут отправляться в Telegram.",
    for_that:                                                            "Для этого:",
    move_to_profile:                                                     "- переходим в Профиль -> Сообщения -> Telegram",
    select_message_what_we_wont_receive:                                 "- выбираем там сообщения, которые будут отправляться в Telegram",
    for_disable_messages_enter_command_exit:                             "5. Щоб перестати приймати повідомлення на конкретний Telegram - необхідно на ньому разлогініться. Для цього - відправляємо з нього команду",
    help_and_avalaible_command:                                          "6. Помощь и все доступные команды: ",
    warning:                                                             "Важно:",
    connect_bot_rulles_is_from_telegram:                                 "1. Правила подключения бота регулируются самим Telegram и мы не можем их менять.",
    you_can_clear_login_and_password_from_history:                       "2. Если Вы не хотите, чтобы логин/пароль сохранялся в Вашей истории Telegram (из безопасности) - удалите его из истории сразу же после успешного логина!",
    for_this:                                                            "Для этого:",
    press_to_message:                                                    "- нажимаем на сообщение",
    select_in_menu_remove_for_me_and_for_all:                            '- в меню выбираем "Delete For Me and all" / "Удалить для себя и для всех"',
    message_will_remove_from_your_history:                               "- сообщение исчезает в истории",
    if_you_dont_remove_dont_worry_this_message_can_see_only_you:         "Даже если не удалили - другие пользователи бота ваш логин/пароль в истории не видят!",
    language_for_default_is_from_your_profile_in_system:                 "3. Язык сообщений - по профилю клиента. Чтоб изменить его - необходимо заполнить профиль.",
    you_can_connect_many_different_telegram_account_for_one_our_account: "4. Работа нескольких разных телеграмов с одним аккаунтом возможна, сообщения всем будут приходить одинаково.",
    login_for_limited_user_is_the_same:                                  "5. Логин под ограниченным пользователем точно такой же, сообщения - по списку доступных ему машин.",
};
const errorTexts = {
    something_went_wrong:                               'Что-то пошло не так',
    notify_support:                                     'Уведомить поддержку',
    message_sent:                                       'Сообщение успешно отправлено',
    request_limit_exceeded:                             "Превышен лимит запросов, попробуйте через минуту",
    only_file_types_allowed:                            'Разрешены только zip/kmz или kml файлы',
    Account:                                            'Аккаунт ',
    absent_in_gmail:                                    ' в gmail отсутствует',
    rfidCard_number:                                    'Номер RFID карты ',
    already_exists:                                     ' уже существует',
    expiry_date:                                        'Дата окончания: ',
    cant_be_in_the_past:                                ' не может быть в прошлом',
    vehicle_with_id:                                    'Транспортное средство с id: ',
    belongs_to_the_group_already:                       'уже принадлежит к группе',
    does_not_belong_to_the_group:                       'не принадлежит к группе',
    No_required_parameter_GeoCoding:                    'Отсутствует обязательный параметр - гео кодинг',
    geoCoding_cant_be_null:                             'Геокодинг не может быть null',
    equipmentWidth_cant_be_null:                        'Ширина оборудования не может быть null',
    longerThen_cant_be_null:                            'Дольше чем не может быть null ',
    ZoneType_cant_be_null:                              'Тип зоны должен быть указан ',
    not_exist:                                          ' не существует',
    zone_type_with_id:                                  'Тип зоны с id ',
    Impossible_create_zone_from_square_with_coordinate: 'Невозможно создать геозону, недостаточно координат',
    not_exist_device_in_with_id:                        'Не существует устройства с id: ',
    tankVolumeStart_cant_be_more_tankVolume:            'Объем заправки не может быть больше чем объем бака',
    fuel_volume_exceeded_tank_volume:                   'Объем топлива превышает объем топливного бака',
    Cant_find_FUEL_LEVEL_BEFORE_EVENT:                  'Невозможно найти УРОВЕНЬ ТОПЛИВА ДО СОБЫТИЯ, потому что невозможно определить начальный объем бака!',
    Rfid_card_can_not_be_empty:                         'Карта RFID не может быть пустой',
    Rfid_card_id_can_not_be_null:                       'Идентификатор RFID-карты не может быть нулевым',
    No_actual_device_mapping_for_control_entity:        'Трекер не установлен на авто в запрашиваемый период ',
    Current_vehicl_with_id:                             'Текущее авто с id: ',
    does_not_have_current_device_mapping:               'нет текущего сопоставления устройств',
    Vehicle_has_incorrect_width:                        'Автомобиль имеет неправильную ширину',
    trailer_serial_allready_exist:                      'Прицеп с этим серийным номером уже есть в системе',
    user_with_such_login_already_exists:                'Пользователь с таким логином уже существует',
    impossible_get_square_zone_from_coordinates:        'Невозможно получить квадратную зону из координат: точки <= 1',
    absent_data_for_report:                             'Отсутствует информация для отчета',
    There_is_no_information_from_the_fuel_sensor:       'Отсутствует информация с датчика топлива',
    The_tracker_is_missing_on_the_car:                  'Отсутствует трекер на авто',
};

const lostedWords = {
    scheduled: 'scheduled',
    scheduled_job: 'scheduled_job',
    scheduled_for: 'scheduled_for',
    list_of_scheduled_jobs_is_empty: 'list_of_scheduled_jobs_is_empty',
    Tuesday: 'Вторник'
};

const events = {
    TASK_START:                                        "Начало задания",
    TASK_END:                                          "Конец задания",
    FUELLING:                                           "Заправка",
    FUELLING_START:                                    "Заправка",
    FUELLING_END:                                    "Заправка",
    DEFUELLING:                                         "Слив топлива",
    DEFUELLING_START:                                  "Слив топлива",
    DEFUELLING_END:                                  "Слив топлива",
    CAUTION_EVENTS:                                    "Важные события",
    INFO_EVENTS:                                       "Информационные события",
    VEHICLE_OVER_SPEED:                                 "Превышение скорости",
    VEHICLE_OVER_SPEED_START:                          "Превышение скорости",
    VEHICLE_OVER_SPEED_END:                            "Превышение скорости",
    VEHICLE_PARKING:                                    "Парковка с включенным зажиганием",
    VEHICLE_PARKING_START:                             "Парковка с включенным зажиганием",
    VEHICLE_PARKING_START:                             "Парковка с включенным зажиганием",
    VEHICLE_PARKING_END:                             "Парковка с включенным зажиганием",
    DEVICE_ANT_ON:                                     "Антенна ON",
    DEVICE_ANT_OFF:                                    "Антенна OFF",
    DEVICE_SHORT_MODE_ON:                              "Антенна подключена замкнута",
    DEVICE_SHORT_MODE_OFF:                             "Антенна подключена, не замкнута",
    POWER_RESTORED:                                    "Питание восстановлено",
    POWER_RESTORED_START:                              "Пропало питание",
    POWER_RESTORED_END:                                "Питание восстановлено",
}
const words = Object.assign(
    globalVocabulary,
    hintsVocabularyRU,
    telegram_instructions,
    errorTexts,
    rfidCardsPage,
    infoGraphicControl,
    routeWords,
    routeDetailsPage,
    lostedWords,
    transportProfile,
    events
)

export const subCatRU = {
    globalVocabulary ,
    hintsVocabularyRU,
    telegram_instructions,
    errorTexts,
    rfidCardsPage,
    infoGraphicControl,
    routeWords,
    routeDetailsPage,
    lostedWords,
    transportProfile,
    events,
}
export default words
