import React, { Fragment } from 'react'
import SL                  from 'sl-library'
import { getSortValue }    from "components/modules/filters/SortVehicles.jsx"
import _ 				   from "lodash"

const helpFunc = {
	listGroupsOfVehcile:         listGroupsOfVehcile,
	sortIt:                      sortIt,
	updateListOnFixIt:           updateListOnFixIt,
	doLowerCase:                 SL.doLowerCase,
	sortingArrayByFixed:         sortingArrayByFixed,
	notSignalIseMoreThenAllowed: notSignalIseMoreThenAllowed,
	getVehicleMarkerStatus:      getVehicleMarkerStatus,
	filterVehiclesOnSearch:      filterVehiclesOnSearch,
	filterVehiclesOnFilter:      filterVehiclesOnFilter,
	sortVehicles: 				 sortVehicles,
	getNoGpsStatus: 			 getNoGpsStatus,
	latLngIsNotVeracity: 		 latLngIsNotVeracity,
	filterVehiclesByGroup: 		 filterVehiclesByGroup,
	validityIs100:				 validityIs100,
	ignitionFilterStatus: 		 ignitionFilterStatus
}
export default helpFunc;
let sortValuePrev = null;
function sortVehicles(vehiclesList) {
	const sortObj = getSortValue();
	const {sortValue, sortDirection} = sortObj;
	let sortFunc;
	const getDeviceSerial = (vehicleDto) => vehicleDto.currentDeviceMapping ? vehicleDto.currentDeviceMapping.deviceSerial : 0;

	switch(sortValue){
		case 'byLisensePlate' :
			sortFunc = () => _.orderBy(vehiclesList, (el) => el.licensePlate ? el.licensePlate : '')
		break;
		case 'byName' :
			sortFunc = () => _.orderBy(vehiclesList, (el) => el.name ? el.name : '')
		break;
		case 'byTrackerNumber' :
			sortFunc = () => _.orderBy(vehiclesList, (el)=> getDeviceSerial(el))
		break;
		default :
			console.warn(`SORTVEHICLES unexpected sortValue - ${sortValue}, vehiclesList - `, vehiclesList);
			sortFunc = () => vehiclesList
		break;
	}
	const sortedArray = sortFunc();
	const arrayForView = sortDirection == 'reverse' && SL.arrayIsEmpty(sortedArray) ? sortedArray.reverse() : sortedArray;
	return  SL.copyOfObject(sortingArrayByFixed(arrayForView))
};

function filterVehiclesOnFilter(vehiclesList, filterName) {
	switch(filterName){
		case 'VEHICLE_PARKING_START' :
			return vehiclesList.filter(vehicle => SL.ifNoKey(vehicle.eventsCountPerDay, 'VEHICLE_PARKING_START'))
		break;
		case 'FUELLING_START' :
			return vehiclesList.filter(vehicle => SL.ifNoKey(vehicle.eventsCountPerDay, 'FUELLING_START'))
		break;
		case 'DEFUELLING_START' :
			return vehiclesList.filter(vehicle => SL.ifNoKey(vehicle.eventsCountPerDay, 'DEFUELLING_START'))
		break;
		case 'VEHICLE_OVER_SPEED_START' :
			return vehiclesList.filter(vehicle => SL.ifNoKey(vehicle.eventsCountPerDay, 'VEHICLE_OVER_SPEED_START'))
		break;
		case 'TERM' :
			return vehiclesList.filter(vehicle => SL.ifNoKey(vehicle.eventsCountPerDay, 'TERM'))
		break;
		case 'VOLTAGE' :
			return vehiclesList.filter(vehicle => SL.ifNoKey(vehicle.eventsCountPerDay, 'VOLTAGE'))
		break;
		case 'ONLINE' :
			return vehiclesList.filter(vehicle => SL.ifNoKey(vehicle, 'deviceStatus') == 'CONNECTED')
		break;
		case 'OFFLINE' :
			return vehiclesList.filter(vehicle => SL.ifNoKey(vehicle, 'deviceStatus') == 'NO_DATA' || SL.ifNoKey(vehicle, 'deviceStatus') == 'DISCONNECTED')
		break;
	}
}

export function filterVehiclesByGroup(transport_list, filteredGroups) {
    let displayedTransport = [];
    if(!filteredGroups){
        displayedTransport = transport_list;
        return displayedTransport
    }
    if(SL.arrayIsEmpty(filteredGroups) && filteredGroups.find(group => group.id  == 'all')) {
       displayedTransport = transport_list;
    }else{
        if(SL.arrayIsEmpty(transport_list)){
                displayedTransport = transport_list.filter(el => {
                    let vehicleAdded = false;
                    let vehicleInGroup = false;
                    for(let i in filteredGroups){
                        let groupId = filteredGroups[i].id;
                        if(groupId === 'withOutGroups'){
                            vehicleInGroup = !SL.arrayIsEmpty(el.groups)?true:false;
                        }else{
                            vehicleInGroup = SL.arrayIsEmpty(el.groups) ? el.groups.find(vehicleGroup => vehicleGroup.id == groupId) : false
                        }
                        if(vehicleInGroup){
                            vehicleAdded = true
                            break;
                        }
                    }
                    if(vehicleInGroup){
                        return el
                    }
                })
            }
        }

    return displayedTransport
}

function filterVehiclesOnSearch(inputValue, vehicleList, crutch) {
	const searchQuery = SL.removeSpaces(SL.doLowerCase(inputValue));
	let displayedTransport;
	if(inputValue == '`!online'){
		return vehicleList.filter(el => new Date() - new Date(el.lastKnownTime) <= 60000)//def period 30 second * 2
	}
	return SL.arrayIsEmpty(vehicleList) ?
		vehicleList.filter(el => {
			const name = SL.doLowerCase(el.name);
			const licensePlate = SL.doLowerCase(el.licensePlate);
			const vehicleId = SL.doLowerCase(el.vehicleId);
			const deviceId = SL.doLowerCase(SL.ifNoKey(el.currentDeviceMapping,'deviceSerial'));
			const searchValue = SL.removeSpaces(name + licensePlate + deviceId + vehicleId);
			return searchValue.indexOf(searchQuery) !== -1;
		}) :
		vehicleList

	function crutchFunc(vehicleDto) {
		return crutch ? vehicleDto.vehicle : vehicleDto
	}
}

function notSignalIseMoreThenAllowed(lastTimeOfSignal) {
	let currentTime = new Date();
	lastTimeOfSignal = new Date(lastTimeOfSignal);
	let timeOfLastSignal = SL.isValidDate(lastTimeOfSignal) ? new Date(lastTimeOfSignal) : null;
	if(timeOfLastSignal) {
		let different = currentTime.getTime() - timeOfLastSignal.getTime()
		if(different < 900000) { //15 minutes
			return false
		} else {
			return true
		}
	} else {
		return true
	}
}

function getVehicleMarkerStatus(vehicleState) {
	let vehicleStatus = 'no_signal';
	// deviceStatus  "CONNECTED", "DISCONNECTED", "NO_DATA"
	if(vehicleState) {
		vehicleStatus = 'normal';
		let { timestamp, vehiclesDataGpsDataDto, deviceStatus, deviceOn } = vehicleState;
		if(!deviceStatus || deviceStatus == "DISCONNECTED" || deviceStatus == "NO_DATA" || deviceOn === false) {
			vehicleStatus = 'no_signal';
		} else {
			if(vehiclesDataGpsDataDto && vehiclesDataGpsDataDto.speed > 0 && vehiclesDataGpsDataDto.ignitionOn) {
				vehicleStatus = 'move';
			}
		}
	}
	return vehicleStatus
}

function getNoGpsStatus(vehiclesDataGpsDataDto) {
	if(vehiclesDataGpsDataDto) {
		const 	lat = SL.ifNoKey(vehiclesDataGpsDataDto, 'latitude'),
        		lng = SL.ifNoKey(vehiclesDataGpsDataDto, 'longitude');
        if(!lat && !lng) return true;
		if(latLngIsNotVeracity(vehiclesDataGpsDataDto)) return true;
		return false
	}else{
		// console.warn(`%c vehiclesDataGpsDataDto`, "color: orange; font-weight: bold; text-transform:uppercase", vehiclesDataGpsDataDto)
	}
	return true  //if no data about GPS then show icon or other info about problem
}

function ignitionFilterStatus({ignitionOn, applyIgnitionStatus}) { // crutch instruction https://jr.elsyton.com/browse/G2B-4018 (if return true then add value to calculations)
	if(applyIgnitionStatus){
		return ignitionOn
	}else{
		return true 
	}
}

function latLngIsNotVeracity(vehiclesDataGpsDataDto) {
    const validity = SL.ifNoKey(vehiclesDataGpsDataDto, 'validity');
    if(SL.isNumeral(validity) && validity < 100) return true; //was 80
	if(!SL.isNumeral(validity)) return true;
	return false;
}

function validityIs100(vehiclesDataGpsDataDto) {
	const validity = SL.ifNoKey(vehiclesDataGpsDataDto, 'validity');
	const ignitionOn = SL.ifNoKey(vehiclesDataGpsDataDto, 'ignitionOn');
	if(SL.isNumeral(validity) && validity == 100) return true
	return false
}

function listGroupsOfVehcile(vehiclesList) {
	let groups = [];
	if(SL.arrayIsEmpty(vehiclesList)) {
		for(let el in vehiclesList) {
			if(!!SL.ifNoKey(vehiclesList[el], "group")) groups.push(SL.ifNoKey(vehiclesList[el], "group"))
		}
	}
	return groups
}

function sortIt(obj1, obj2, length) {
	let pos1 = (!!obj1.fixed) ? (obj1.fixed - length) : 1;
	let pos2 = (!!obj2.fixed) ? (obj2.fixed - length) : 1;
	return pos1 - pos2;
}

function sortingArrayByFixed(filteredArray) {
	if(SL.arrayIsEmpty(filteredArray)) {
		return filteredArray.sort((obj1, obj2) => sortIt(obj1, obj2, filteredArray.length))
	} else {
		return filteredArray
	}
}

function updateListOnFixIt(vehicleDTO) {
	let { id } = vehicleDTO;
	let displayedTransport = this.state.displayedTransport;
	let indexOfVehicle = displayedTransport.findIndex(key => {
		if(key.vehicleId == id) {
			return key
		}
	})
	displayedTransport[indexOfVehicle] = vehicleDTO;
	displayedTransport[indexOfVehicle].fixed = vehicleDTO.fixed;
	this.setState({
		displayedTransport: SL.copyOfObject(sortingArrayByFixed(displayedTransport))
	})
}



