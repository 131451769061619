import store 			from 'store/store.js'

export default function infoStore (path){//path must be without "state"
	let state = store.getState();
	if(!path){
		return {
			login: 				state.globalR.authorizationR.login,
			bonus_url_device: 	state.globalR.authorizationR.bonus_url_device,
			token: 				state.globalR.authorizationR.token,
			base_url: 			state.globalR.authorizationR.base_url,
			bonus_prefix: 		state.globalR.authorizationR.bonus_prefix,
			bonus_url_api: 		state.globalR.authorizationR.bonus_url_api,
			language: 			state.globalR.authorizationR.language,
			zoneTypes: 			state.globalR.geoR.zoneTypes,
			user_permission: 	state.globalR.authorizationR.user_permission,
			kindUser: 			state.globalR.authorizationR.kindUser,
			ownerLogin: 		state.globalR.authorizationR.ownerLogin
		}
	}else{
		if(typeof path == 'string'){
				let pathValue = path.split('.').reduce((obj, key) => {
					  return obj && obj[key];
				}, state);
				return pathValue
		}else{
			console.log("%c path must be sting like 'key.secondKey', your path:", 'color: red; font-weight: bold;',  path)
		}
	}
}