import store                                 from 'store/store.js'
import bugReport                             from './bugReport'
import axios                                 from 'axios'
import moment                                from "moment"
import language_variant, { setMomentLocale } from "store/languages"
import { default_report_types }              from 'reducers/report_reducer.js'
import ru                                    from 'moment/locale/ru.js'
import uk                                    from 'moment/locale/uk.js'
import infoStore                             from './infoStore.js'


const ProfileAction = {
	getProfile:         getProfile,
	languageUpdate:     languageUpdate,
	updateProfile:      updateProfile,
	deleteProfile:      deleteProfile,
	setProfileAvatar:   setProfileAvatar,
	updatePassword:     updatePassword,
	statusSound:        statusSound,
	disableHints:       disableHints,
	setProfileSettings: setProfileSettings,

}

export default ProfileAction

function updateFrontSettings(frontSettings) {
	const user_info = infoStore().user_info;
	console.log('updateFrontSettings, user_info',	user_info )
	updateProfile()
}

function updatePassword(data, callback, callbackOnError) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: '/api/v2/user/' + infoStore().login + '/password',
		dataType: "json",
		method: 'POST',
		headers: { 'authorization': infoStore().token },
		data: data
	}
	axios(requestData)
		.then(function answer(answer) {
			if(answer.data){
				callback(answer.data)
			}else{
				callbackOnError()
			}
		})
		.catch(function(serveranswer) {
			callbackOnError()
			if(serveranswer.status == 403 && serveranswer.message == "Access is forbidden") {
				if(!!callbackOnError) {
					callbackOnError()
				}
			} else {
				bugReport(serveranswer, requestData, "updatePassword")
			}
		});
}

function createProfile(data) {
	let requestData = {
		method: 'POST',
		baseURL: infoStore().base_url, //this was in the start
		url: infoStore().bonus_url_api + 'ns/profile',
		dataType: "json", //формат
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: data,
	}
	axios(requestData)
		.then((answer) => {
			console.log(answer.data)
			store.dispatch({
				type: "USER_INFO",
				setUserInfo: answer.data
			})
		})
		.catch((serveranswer) => bugReport(serveranswer, requestData, "createProfile"))
}
// 	createProfile({
//     "ownerLogin":"probeg",
//     "userName": "Vladimir",
//     "eMail": "lololo@usr.com",
//     "phoneNumber": "+380002536",
//     "interfaceLanguage": "ua",
//     "telegramLogin":"viskas"
// })
function getProfile(login) {
	let requestData = {
		method: 'GET',
		baseURL: infoStore().base_url, //this was in the start
		url: infoStore().bonus_url_api + 'ns/profile/' + login,
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
		.then((answer) => setProfileSettings(answer.data))
		.catch((serveranswer) => bugReport(serveranswer, requestData, "getProfile"))
}

function updateProfile(data, callback) {
	if(!data.frontSettings) data.frontSettings = '';//back send null, but on save get exception) 
	let requestData = {
		method: 'PUT',
		baseURL: infoStore().base_url, //this was in the start
		url: infoStore().bonus_url_api + 'ns/profile/update',
		dataType: "json", //формат
		withCredentials: true,
		data: data,
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
		.then((answer) => {
			if(!!callback) callback()
			setProfileSettings(answer.data)
		})
		.catch((serveranswer) => {
			bugReport(serveranswer, requestData, "updateProfile");
			if(!!callback) callback()
		})
}

function deleteProfile(id) {
	let requestData = {
		method: 'delete',
		baseURL: infoStore().base_url, //this was in the start
		url: infoStore().bonus_url_api + 'ns/profile/' + id,
		withCredentials: true,
		// dataType: "json",//формат
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
		.then((answer) => {
			console.log(answer.data)
		})
		.catch((serveranswer) => bugReport(serveranswer, requestData, "deleteProfile"))
	// }
}

function setProfileAvatar(id, blob, callback) {
	let formData = new FormData();
	formData.append('file', blob);
	let requestData = {
		method: 'POST',
		baseURL: infoStore().base_url, //this was in the start
		url: infoStore().bonus_url_api + 'ns/profile/' + id + '/avatar',
		withCredentials: true,
		// dataType: 	'multipart/form-data',//"json",//,//формат
		headers: {
			'Accept': 'application/json',
			'content-Type': 'multipart/form-data',
			// "Content-Encoding":"gzip"
			'authorization': infoStore().token
		},
		data: formData
	}
	axios(requestData)
		.then((answer) => {
			if(!!callback) {
				// if(!!answer.data){
				callback()
				// }
			}
		})
		.catch((serveranswer) => bugReport(serveranswer, requestData, 'setProfileAvatar'))
}

function setProfileSettings(profileData) {
	if(typeof profileData === 'object'){
		updateAvatarUrl(profileData)
		store.dispatch({
			type: "USER_INFO",
			setUserInfo: profileData
		})
		localStorage.setItem('profileSettings', JSON.stringify(profileData))
		languageUpdate(profileData.interfaceLanguage)
		statusSound(profileData.isNotifySound)
	}
}

function updateAvatarUrl(profileData) {
	if(profileData && profileData.avatar) {
		profileData.avatar = infoStore().base_url + infoStore('globalR.authorizationR.imagesPath') + profileData.avatar;
	}
}

function languageUpdate(langKey) {
	let nameMoment = langKey; //mistake in moment, ukraine language is uk
	let state = store.getState();
	language_variant.setLanguage(langKey);
	localStorage.setItem('lang', langKey)
	setMomentLocale()
	store.dispatch({
		type: 'LANGUAGE_UPDATE',
		setLanguage: langKey,
	})
};

function disableHints() {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "user/hints",
		method: 'POST',
		headers: {
			authorization: infoStore().token
		},
		params: {showHints: false}
	};
	axios(requestData)
	.then((answer) => console.log("%c DISABLEHINTS answer", coCSS, answer))
	.catch((answer) => bugReport(answer, requestData, 'disableHints'))
}

// function test() {
// 	let requestData = {
// 		baseURL: infoStore().base_url,
// 		url: infoStore().bonus_url_api + "user/hints",
// 		method: 'POST',
// 		headers: {
// 			// authorization: ''
// 		},
// 		params: {showHints: false}
// 	};
// 	axios(requestData)
// 	.then((answer) => console.log("%c DISABLEHINTS answer", coCSS, answer))
// 	.catch((answer) => bugReport(answer, requestData, 'disableHints'))
// }
// test()
function statusSound(value) {
	store.dispatch({
		type: "SOUND_NOTIF",
		setSatatus: value
	})
}



