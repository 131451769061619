import React, { Fragment } from "react";
import TooltipWrapper      from './TooltipWrapper.jsx'
import language_variant    from 'store/languages.jsx'
import "css/icons.scss";

//kill it in morning

let svgicons = {
	parkingI: parkingI,
	speedI: speedI,
	fuelI: fuelI,
	fuelDrainI: fuelDrainI,
	voltageI: voltageI,
	termI: termI,
};

class HoverOver extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			hover: false
		}
	}
	render() {
		let icon  = this.props.icon;
		let hover = this.state.hover;
		return !!icon
				?<div 	onMouseEnter = {()=>this.setState({hover: true})}
						onMouseLeave = {()=>this.setState({hover: false})}>
					{icon(hover)}
				</div>
				:null
	}
}

function parkingI(status){
	if(!status){
		return  <svg 	className="parking_icon"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 22 22">
					<path d='M4 20.5c-1.4 0-2.5-1.1-2.5-2.5V4c0-1.4 1.1-2.5 2.5-2.5h14c1.4 0 2.5 1.1 2.5 2.5v14c0 1.4-1.1 2.5-2.5 2.5H4z' fill="#FFF"/>
					<path d='M18 2c1.1 0 2 0.9 2 2v14c0 1.1-0.9 2-2 2H4c-1.1 0-2-0.9-2-2V4c0-1.1 0.9-2 2-2H18M18 1H4C2.3 1 1 2.3 1 4v14c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3V4C21 2.3 19.7 1 18 1L18 1z'/>
					<path d='M12 7h-2H8v6 2h2v-2h2c1.1 0 3-1.1 3-3S13.1 7 12 7zM12 11h-2V9h2c0 0 1 0.2 1 1S12 11 12 11z'/>
				</svg>
	}else{
		return <svg 	className="parking_icon"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 22 22"
						>
					<path d='' fill="#FFF"/>
					<path d='M12 9H10v2h2s1-0.2 1-1S12 9 12 9Z'/>
					<path d='M18 1H4A3 3 0 0 0 1 4V18a3 3 0 0 0 3 3H18a3 3 0 0 0 3-3V4A3 3 0 0 0 18 1ZM12 13H10v2H8V7h4a3.2 3.2 0 0 1 3 3A3.2 3.2 0 0 1 12 13Z'/>
				</svg>
	}
	return
}

function speedI(status){
	if(!status){
		return 	<svg 	className="speed_icon"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 22 22">
							<path d='M11 21.5C5.2 21.5.5 16.8.5 11S5.2.5 11 .5 21.5 5.2 21.5 11 16.8 21.5 11 21.5z' fill='#fff'/>
							<path d='M11 1c5.5 0 10 4.5 10 10s-4.5 10-10 10S1 16.5 1 11 5.5 1 11 1m0-1C4.9 0 0 4.9 0 11s4.9 11 11 11 11-4.9 11-11S17.1 0 11 0z' />
							<path d='M14.5 7C13.1 7 12 8.1 12 9.5v3c0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5v-3C17 8.1 15.9 7 14.5 7zm1.5 5.5c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5v-3c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v3z' />
							<path d='M10.4 8l.6-1H7l-1 4h1c.2-.6.8-1 1.4-1 .8 0 1.5.7 1.5 1.5v1c0 .8-.7 1.5-1.5 1.5S7 13.3 7 12.5V12H6v.5C6 13.9 7.1 15 8.5 15s2.5-1.1 2.5-2.5v-1C11 10.1 9.9 9 8.5 9c-.4 0-.7.1-1 .2L7.7 8h2.7z'/>
				</svg>
	}else{
		return <svg 	className="speed_icon"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 22 22">
							<path d='M14.5 8C13.7 8 13 8.7 13 9.5v3c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-3C16 8.7 15.3 8 14.5 8z' fill='#ff4d4d'/>
							<path d='M11 0C4.9 0 0 4.9 0 11s4.9 11 11 11 11-4.9 11-11S17.1 0 11 0zM8.5 9c1.4 0 2.5 1.1 2.5 2.5v1c0 1.4-1.1 2.5-2.5 2.5S6 13.9 6 12.5V12h1v0.5C7 13.3 7.7 14 8.5 14s1.5-0.7 1.5-1.5v-1c0-0.8-0.7-1.5-1.5-1.5 -0.7 0-1.2 0.4-1.4 1H6c0 0 0 0 0 0L7 7h4l-0.5 1H7.7L7.5 9.2C7.8 9.1 8.1 9 8.5 9zM17 12.5c0 1.4-1.1 2.5-2.5 2.5S12 13.9 12 12.5v-3C12 8.1 13.1 7 14.5 7S17 8.1 17 9.5V12.5z' />
							<path d='' />
							<path d=''/>
				</svg>
	}
}

function fuelI(status){
	if(!status){
		return <svg
						className="fuel_icon"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 22 22">
						<path d='M4 20.5c-1.4 0-2.5-1.1-2.5-2.5V4c0-1.4 1.1-2.5 2.5-2.5h14c1.4 0 2.5 1.1 2.5 2.5v14c0 1.4-1.1 2.5-2.5 2.5H4z' fill='#fff'/>
						<path d='M18 2c1.1 0 2 0.9 2 2v14c0 1.1-0.9 2-2 2H4c-1.1 0-2-0.9-2-2V4c0-1.1 0.9-2 2-2H18M18 1H4C2.3 1 1 2.3 1 4v14c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3V4C21 2.3 19.7 1 18 1L18 1z' fill='#ffb34a'/>
						<path d='M16 5h-4c-0.6 0-1 0.4-1 1v11h6V6C17 5.4 16.6 5 16 5zM16 11h-4V6h4V11z' fill='#ffb34a'/>
						<path d='M10 7L10 7M9.4 8H10V7H9l-4.4 4.9c-0.4 0.4-0.6 1-0.6 1.6C4 14.9 5.1 16 6.5 16S9 14.9 9 13.5V12h1v-1H8v1 1.5C8 14.3 7.3 15 6.5 15S5 14.3 5 13.5c0-0.4 0.1-0.7 0.4-1l3.6-4L9.4 8z' fill='#ffb34a'/>
					</svg>
	}else{
		return <svg
						className="fuel_icon"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 22 22">
						<path d='M4 20.5c-1.4 0-2.5-1.1-2.5-2.5V4c0-1.4 1.1-2.5 2.5-2.5h14c1.4 0 2.5 1.1 2.5 2.5v14c0 1.4-1.1 2.5-2.5 2.5H4z' fill='#ffb34a'/>
						<path d='M18 2c1.1 0 2 0.9 2 2v14c0 1.1-0.9 2-2 2H4c-1.1 0-2-0.9-2-2V4c0-1.1 0.9-2 2-2H18M18 1H4C2.3 1 1 2.3 1 4v14c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3V4C21 2.3 19.7 1 18 1L18 1z' fill='#ffb34a'/>
						<path d='M16 5h-4c-0.6 0-1 0.4-1 1v11h6V6C17 5.4 16.6 5 16 5zM16 11h-4V6h4V11z' fill='#fff'/>
						<path d='M10 7L10 7M9.4 8H10V7H9l-4.4 4.9c-0.4 0.4-0.6 1-0.6 1.6C4 14.9 5.1 16 6.5 16S9 14.9 9 13.5V12h1v-1H8v1 1.5C8 14.3 7.3 15 6.5 15S5 14.3 5 13.5c0-0.4 0.1-0.7 0.4-1l3.6-4L9.4 8z' fill='#fff'/>
					</svg>
	}
}

function notifI(status){
	let className = !!status?"show":"some";
	return <svg className={"icon_notification" + " " + className}
					 xmlns="http://www.w3.org/2000/svg"
					 aria-labelledby="title" >
				<title id="title">Уведомления</title>
				<path d= 'M16,10.3l0-4.1C16,3,13.5,0.1,10.2,0C6.8-0.1,4,2.6,4,6v4.3c0,0.4-0.2,0.9-0.5,1.2L2,13v1c0,0.6,0.4,1,1,1h14  c0.6,0,1-0.4,1-1v-1l-1.5-1.5C16.2,11.2,16,10.8,16,10.3z' fill="#FFF"/>
				<path d= 'M10,19c1.1,0,2-0.9,2-2H8C8,18.1,8.9,19,10,19z' fill="#FFF"/>
			</svg>
}

function fuelDrainI(status){
	if(!status){
		return 	<svg 	className="oil_icon"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 22 22">
						<path 	d={'M11 21.5C5.2 21.5 0.5 16.8 0.5 11 0.5 5.2 5.2 0.5 11 0.5S21.5 5.2 21.5 11C21.5 16.8 16.8 21.5 11 21.5z'}
								fill='white'/>
						<path 	d='M11 1c5.5 0 10 4.5 10 10s-4.5 10-10 10S1 16.5 1 11 5.5 1 11 1M11 0C4.9 0 0 4.9 0 11s4.9 11 11 11 11-4.9 11-11S17.1 0 11 0L11 0z'
								fill='#ff4d4d'/>
						<path d='M15 12c0 2.2-1.8 4-4 4s-4-1.8-4-4c0-2.1 2.6-5.4 3.6-6.6 0.2-0.2 0.6-0.2 0.8 0C12.4 6.6 15 9.9 15 12z'
								fill="#ff4d4d"/>

					</svg>

	}else{
		return 	<svg 	className="oil_icon"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 22 22">
						<path d='M11 0C4.9 0 0 4.9 0 11s4.9 11 11 11 11-4.9 11-11S17.1 0 11 0zM11 16c-2.2 0-4-1.8-4-4 0-2.1 2.6-5.4 3.6-6.6 0.2-0.2 0.6-0.2 0.8 0 1 1.2 3.6 4.5 3.6 6.6C15 14.2 13.2 16 11 16z'
								fill='#ff4d4d'/>
				</svg>

	}
}

function voltageI(status){
	if(!status){
		return 	<svg 	className="oil_icon"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 22 22">
					<path 	d='M11,21.5C5.2,21.5,0.5,16.8,0.5,11C0.5,5.2,5.2,0.5,11,0.5S21.5,5.2,21.5,11   C21.5,16.8,16.8,21.5,11,21.5z'
							fill='white' />
					<path 	d='M11,1c5.5,0,10,4.5,10,10s-4.5,10-10,10S1,16.5,1,11S5.5,1,11,1 M11,0C4.9,0,0,4.9,0,11s4.9,11,11,11s11-4.9,11-11   S17.1,0,11,0L11,0z'
							fill='#ff4d4d'/>
					<polygon xmlns="http://www.w3.org/2000/svg" points='9.2,5 7.1,11 10.2,11 8.1,17 15.1,11 12.4,11 15.1,5'
							fill='#ff4d4d'>
					</polygon>
				</svg>
	}else{
		return 	<svg 	className="oil_icon"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 22 22">
					<path 	d='M11,21.5C5.2,21.5,0.5,16.8,0.5,11C0.5,5.2,5.2,0.5,11,0.5S21.5,5.2,21.5,11   C21.5,16.8,16.8,21.5,11,21.5z'
							fill='#ff4d4d' />
					<path 	d='M11,1c5.5,0,10,4.5,10,10s-4.5,10-10,10S1,16.5,1,11S5.5,1,11,1 M11,0C4.9,0,0,4.9,0,11s4.9,11,11,11s11-4.9,11-11   S17.1,0,11,0L11,0z'
							fill='#ff4d4d'/>
					<polygon 	xmlns="http://www.w3.org/2000/svg" points='9.2,5 7.1,11 10.2,11 8.1,17 15.1,11 12.4,11 15.1,5'
								fill='white'>
					</polygon>
				</svg>
	}
}

function termI(status){
	if(!status){
		return <svg 	className="oil_icon"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 22 22">
						<path 	d='M11 21.5C5.2 21.5 0.5 16.8 0.5 11 0.5 5.2 5.2 0.5 11 0.5S21.5 5.2 21.5 11C21.5 16.8 16.8 21.5 11 21.5z'
								fill="white"/>
						<path 	d='M11 1c5.5 0 10 4.5 10 10s-4.5 10-10 10S1 16.5 1 11 5.5 1 11 1M11 0C4.9 0 0 4.9 0 11s4.9 11 11 11c6.1 0 11-4.9 11-11S17.1 0 11 0L11 0z'
								fill='#ff4d4d'/>
						<path 	d='M10.5 5C11.3 5 12 5.7 12 6.5v5.6 0.4l0.3 0.3c0.5 0.5 0.7 1.1 0.7 1.7 0 1.4-1.1 2.5-2.5 2.5S8 15.9 8 14.5c0-0.7 0.3-1.3 0.7-1.7L9 12.5v-0.4V6.5C9 5.7 9.7 5 10.5 5M10.5 4C9.1 4 8 5.1 8 6.5v5.6c-0.6 0.6-1 1.5-1 2.4 0 1.9 1.6 3.5 3.5 3.5s3.5-1.6 3.5-3.5c0-1-0.4-1.8-1-2.4V6.5C13 5.1 11.9 4 10.5 4L10.5 4z'
								fill='#ff4d4d'/>
						<rect 	xmlns="http://www.w3.org/2000/svg" x="14" y="10" width="3" height="1" fill='#ff4d4d'  />
						<rect 	xmlns="http://www.w3.org/2000/svg" x="14" y="8" width="2" height="1" fill='#ff4d4d' />
						<rect 	xmlns="http://www.w3.org/2000/svg" x="14" y="6" width="2" height="1" fill='#ff4d4d'  />
						<path 	d='M11 13.1V9h-1v4.1c-0.6 0.2-1 0.8-1 1.4 0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5C12 13.8 11.6 13.3 11 13.1z'
								fill='#ff4d4d'/>
					</svg>
	}else{
		return <svg 	className="oil_icon"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 22 22">
						<path 	d = 'M11 21.5C5.2 21.5 0.5 16.8 0.5 11 0.5 5.2 5.2 0.5 11 0.5S21.5 5.2 21.5 11C21.5 16.8 16.8 21.5 11 21.5z'
								fill = '#ff4d4d'/>
						<path 	d = 'M11,1c5.5,0,10,4.5,10,10s-4.5,10-10,10S1,16.5,1,11S5.5,1,11,1 M11,0C4.9,0,0,4.9,0,11s4.9,11,11,11c6.1,0,11-4.9,11-11   S17.1,0,11,0L11,0z'
								fill = '#ff4d4d'/>
						<path 	d = 'M10.5,5C11.3,5,12,5.7,12,6.5v5.6v0.4l0.3,0.3c0.5,0.5,0.7,1.1,0.7,1.7c0,1.4-1.1,2.5-2.5,2.5S8,15.9,8,14.5   c0-0.7,0.3-1.3,0.7-1.7L9,12.5v-0.4V6.5C9,5.7,9.7,5,10.5,5 M10.5,4C9.1,4,8,5.1,8,6.5v5.6c-0.6,0.6-1,1.5-1,2.4   c0,1.9,1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5c0-1-0.4-1.8-1-2.4V6.5C13,5.1,11.9,4,10.5,4L10.5,4z'
								fill = "white"/>
						<rect xmlns="http://www.w3.org/2000/svg" x="14" y="10" width="3" height="1" fill = "white"  />
						<rect xmlns="http://www.w3.org/2000/svg" x="14" y="8" width="2" height="1" fill = "white" />
						<rect xmlns="http://www.w3.org/2000/svg" x="14" y="6" width="2" height="1" fill = "white"  />
						<path d = 'M11,13.1V9h-1v4.1c-0.6,0.2-1,0.8-1,1.4c0,0.8,0.7,1.5,1.5,1.5  s1.5-0.7,1.5-1.5C12,13.8,11.6,13.3,11,13.1z'
							fill="#ff4d4d"/>
					</svg>

	}
}

function star(active, className, onClick){
	if(!active){
		return <svg 	className = 		{"star_icon" + " " +className}
						xmlns =				"http://www.w3.org/2000/svg"
						aria-labelledby = 	"title"
						onClick = 			{onClick}>
						<title id="title">Нажмите, чтобы закрепить вверху списка</title>
						<path d= 'M8.9,3l1.7,2.8c0.2,0.4,0.6,0.6,1,0.7L14.9,7l-2.3,2.4c-0.3,0.3-0.4,0.7-0.4,1.1l0.2,3.3l-2.7-1.3 c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.4,0-0.6,0.1l-2.7,1.3l0.2-3.3c0-0.4-0.1-0.8-0.4-1.1L3.1,7l3.2-0.6c0.4-0.1,0.8-0.3,1-0.7   L8.9,3 M8.9,1.4c-0.1,0-0.3,0.1-0.4,0.2L6.4,5.2C6.3,5.3,6.2,5.4,6.1,5.4L1.9,6.2C1.5,6.3,1.4,6.7,1.7,7l3,3.2 			c0.1,0.1,0.1,0.2,0.1,0.3l-0.2,4.2c0,0.3,0.2,0.5,0.4,0.5c0.1,0,0.1,0,0.2,0l3.6-1.7c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0l3.6,1.7  c0.1,0,0.1,0,0.2,0c0.2,0,0.5-0.2,0.4-0.5l-0.2-4.2c0-0.1,0-0.2,0.1-0.3L16.3,7c0.2-0.3,0.1-0.7-0.2-0.7l-4.3-0.8 	c-0.1,0-0.2-0.1-0.3-0.2L9.3,1.6C9.2,1.5,9.1,1.4,8.9,1.4L8.9,1.4z'/>
				</svg>
	}else{
		return <svg 	className = 		{"star_icon" + " " +className}
						xmlns =				"http://www.w3.org/2000/svg"
						aria-labelledby = 	"title"
						onClick = 			{onClick}>
						<title id="title">Нажмите, чтобы открепить</title>
						<path d= 'M13.2,10.1L16.3,7c0.2-0.3,0.1-0.7-0.2-0.7l-4.3-0.8c-0.1,0-0.2-0.1-0.3-0.2L9.3,1.7   c-0.2-0.3-0.6-0.3-0.7,0L6.4,5.3C6.4,5.4,6.3,5.4,6.1,5.5L1.9,6.2C1.6,6.3,1.5,6.7,1.7,7l3,3.2c0.1,0.1,0.1,0.2,0.1,0.3l-0.2,4.2   c0,0.3,0.3,0.6,0.6,0.4l3.6-1.7c0.1-0.1,0.3-0.1,0.4,0l3.6,1.7c0.3,0.1,0.6-0.1,0.6-0.4l-0.2-4.2C13.1,10.3,13.1,10.2,13.2,10.1z'/>
				</svg>

	}
}

function IconStar(props){
	let {status, callFix, transport_key} = props;
	let callFixation = (e) => {
		e.stopPropagation();
		callFix(transport_key, !status);
	}

	let className = (!!status) ? "show" : "transparent";
	return star(status, className, callFixation)
}

function IconParking(props){
	let {status, event_counter, vehicleId} = props;
	let hovered = false
	return 	(!!status && event_counter>0)
			?<TooltipWrapper 	container = 'event'
								name = {'parking'+vehicleId} place = 'top'
								delay = {1400}
								content = {language_variant.counter_events_parking}>
				<span className = 'counter_events parking_counter'>{event_counter}</span>
				<HoverOver icon = {parkingI}/>
			</TooltipWrapper>
			:null
}

function IconVoltage(props){
	let {status, event_counter, vehicleId} = props;
	return 	(!!status && event_counter>0)
			?<TooltipWrapper 	container = 'event'
								name = {'voltage'+vehicleId} place = 'top'
								delay = {1400}
								content = {language_variant.counter_events_voltage}>
				<span className = 'counter_events speed_counter'>{event_counter}</span>
				<HoverOver icon = {voltageI}/>
			</TooltipWrapper>
			:null
}

function IconFuelDrain(props){
	let {status, event_counter, vehicleId} = props;
	return 	(!!status && event_counter>0)
			?<TooltipWrapper 	container = 'event'
								name = {'fuelDrain'+vehicleId} place = 'top'
								delay = {1400}
								content = {language_variant.counter_events_defuelling}>
				<span className = 'counter_events defuelling_counter'>{event_counter}</span>
				<HoverOver icon = {fuelDrainI}/>
			</TooltipWrapper>
			:null
}

function IconFuel(props){
	let {status, event_counter, vehicleId} = props;
	return 	(!!status && event_counter>0)
			?<TooltipWrapper 	container = 'event'
								name = {'fuel'+vehicleId} place = 'top'
								delay = {1400}
								content = {language_variant.counter_events_fuelling}>
				<span className = 'counter_events fuel_counter'>{event_counter}</span>
				<HoverOver icon = {fuelI}/>
			</TooltipWrapper>
			:null
}

function IconSpeed(props){
	let {status, event_counter, vehicleId} = props;
	return 	(!!status && event_counter>0)
			?<TooltipWrapper 	container = 'event'
								name = {'speed'+vehicleId} place = 'top'
								delay = {1400}
								content = {language_variant.counter_events_speed_over}>
				<span className = 'counter_events speed_counter'>{event_counter}</span>
				<HoverOver icon = {speedI}/>
			</TooltipWrapper>
			:null
}

function IconTerm(props){
	let {status, event_counter} = props;
	return 	(!!status && event_counter>0)
			?<TooltipWrapper 	container = 'event'
								name = {'term'+vehicleId} place = 'top'
								delay = {1400}
								content = {language_variant.counter_events_term_over}>
				<span className = 'counter_events speed_counter'>{event_counter}</span>
				<HoverOver icon = {termI}/>
			</TooltipWrapper>
			:null
}

export {IconParking, IconVoltage, IconFuelDrain, IconFuel, IconSpeed, IconTerm, IconStar};
export default svgicons;

