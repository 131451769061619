const GoogleMapApiKey = 'AIzaSyAtvLWQRKn-krgnNQBFx3anEdYPwe_7v9g';
localStorage.setItem('APIKey', GoogleMapApiKey); // application gets the key from storage

const clientAppGA_script = () => {
	appendElement('script', { src: "https://www.googletagmanager.com/gtag/js?id=UA-26601474-7", async: true }, 'GA_place'); // async: true
	window.dataLayer = window.dataLayer || [];
	window.gtag = function() { dataLayer.push(arguments); }; // Ensure gtag is global
	gtag('js', new Date());
	gtag('config', 'UA-26601474-7');
};

// Uncomment the following line to enable Google Analytics in production
// if(PROD_SERVER) clientAppGA_script(); // tag required only for customers' analytics, they are only on the prod

function appendElement(elName, attributes, placeId) {
	const element = document.createElement(elName);
	for(let attributeName in attributes) {
		element.setAttribute(attributeName, attributes[attributeName]);
	}
	const where = placeId ? document.getElementById(placeId) : null;
	where ? where.appendChild(element) : document.body.appendChild(element);
}
