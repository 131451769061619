import React                        from 'react'
import language_variant 	 		from 'store/languages'
import { renderToString} 	 		from 'react-dom/server'
import infoStore                    from 'actions/infoStore'
import TransportApi                 from 'actions/transportApi.js'
import demoVehicle                  from 'demoData/demoVehicle.js'
import {copyOfObject} 			 	from 'sl-library'
import svgicons 	                from 'oftenUsed/svgicons.jsx';
import {
    antennaIcon,
    securityIcon,
    sosIcon,
    icon_inmidcourseMoving,
    icon_inmidcourseParking,
    noGpsIcon
} from 'images/simple_svg_icons.jsx'
const controlPageHint = function(nextButton, skipButton, enjoyHintInstance) {
	return {
		// descHelpBtn: function(onBeforeStart) {
		// 	let selector = '.btn_like_a';
		// 	let hintContent = <div className = 'hintContent'>{language_variant.click_by_this_btn_call_hint}</div>
		// 	return {
		// 		['select '+ selector]: renderToString(hintContent),
		// 		showNext: true,
		// 		showSkip: false,
		// 		// radius: 80,
		// 		nextButton,
		// 		skipButton,
		// 		onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
		// 	}
		// },
		// hintAboutMapMarker: function(onBeforeStart) {
		// 	let selector = '[src*="mapIcon"]';
		// 	let hintContent = 	<div className = 'hintContent'>
		// 							<div className='hintHeader'>{language_variant.marker_hint}</div>
		// 							<p className = 'p_vs_image'>
		// 								<img src = 'images/mapIcon/arrow_on_map_def.svg' height = '43'/> - {language_variant.vehicle_stopped}
		// 							</p>
		// 							<p className = 'p_vs_image'>
		// 								<img src = 'images/mapIcon/arrow_on_map.svg' height = '43'/> - {language_variant.selected_vehicle}
		// 							</p>
		// 							<p className = 'p_vs_image'>
		// 								<img src = 'images/mapIcon/arrow_on_map_move.svg' height = '43'/> - {language_variant.vehicle_moving}
		// 							</p>
		// 							<p className = 'p_vs_image'>
		// 								<img src = 'images/mapIcon/arrow_on_map_no_signal.svg' height = '43'/> - {language_variant.vehicle_not_connected}
		// 							</p>
		// 							<div className='hintFotter'>{language_variant.clickOnMarker}</div>
		// 						</div>
		// 	return {
		// 		['select '+ selector]: renderToString(hintContent),
		// 		showNext: true,
		// 		showSkip: false,
		// 		shape: 'circle',
		// 		timeout: 700,
		// 		radius: 80,
		// 		nextButton,
		// 		skipButton,
		// 		onBeforeStart: function(){
		// 			let icon = document.querySelector('[src*="mapIcon"]');
		// 			let id = icon.src.match(/[^#]+$$/gm)[0];
		// 			window.controlContextLogic.map.setZoom(5);
		// 			window.controlContextLogic.vehiclesList[id].moveMarkerToRigtTopCorner();
		// 			onBeforeStart?onBeforeStart(selector):null
		// 		}
		// 	}
		// },
		hintAboutRouteEvents: function(onBeforeStart){
			let selector = "[data-value='vehicleForTest'] .events";
			let hintContent = 	<div className = 'hintContent'>
									<div className='hintHeader'>
										{language_variant.event_what_happened_today}
										<span className='under_header'>({language_variant.on_hover_visible_counter})</span>
									</div>
									<p className = 'p_vs_image'>
										{svgicons.parkingI()} - {language_variant.parking_vs_engintOn}
									</p>
									<p className = 'p_vs_image'>
										{svgicons.speedI()} - {language_variant.over_speed}
									</p>
									<p className = 'p_vs_image'>
										{svgicons.fuelI()} - {language_variant.fuelling}
									</p>
									<p className = 'p_vs_image'>
										{svgicons.fuelDrainI()} - {language_variant.fuel_drain}
									</p>
									<p className = 'p_vs_image'>
										{svgicons.voltageI()} - {language_variant.dangerous_voltage}
									</p>
									<p className = 'p_vs_image'>
										{svgicons.termI()} - {language_variant.dangerous_temper}
									</p>
								</div>;
			return {
				selector: selector,
				description: renderToString(hintContent),
				top: -10,
				right: -5,
				bottom: -5,
				showNext: true,
				nextButton,
				showSkip: false,
				onBeforeStart: function(){
					onBeforeStart?onBeforeStart(selector):null
				}
			}
		},
		descInfoAboutVehicleTriggers_SmalIcons: function(onBeforeStart){
			let selector = "[data-value='vehicleForTest'] .update_data";
			let blinkDisable = true;
			let hintContent = 	<div className = 'hintContent'>
									<div className='hintHeader'>
										{language_variant.info_about_curent_status}
										<span className='under_header'>({language_variant.on_hover_visible_event_date})</span>
									</div>
									<p className = 'p_vs_image'>
										{securityIcon('shield', false)} - {language_variant.vehicle_is_on_quard}
									</p>
									<p className = 'p_vs_image'>
										{securityIcon('shield', true, blinkDisable)} - {language_variant.quard_is_triggered}
									</p>
									<p className = 'p_vs_image'>
										{noGpsIcon('icon_no_location')} - {language_variant.no_coordinates}
									</p>
									<p className = 'p_vs_image'>
										{sosIcon('SOS_ALARM')} - {language_variant.alarmDrawdown}
									</p>
									<p className = 'p_vs_image'>
										{antennaIcon(false)} - {language_variant.antena_is_ok}
									</p>
									<p className = 'p_vs_image'>
										{antennaIcon(true)} - {language_variant.antena_info_absent_check_device}
									</p>
								</div>;
			return {
				selector: selector,
				description: renderToString(hintContent),
				top: -10,
				right: -5,
				bottom: -5,
				showNext: true,
				nextButton,
				showSkip: false,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
			}
		},
		descAntennaIcon: function(onBeforeStart){
			let selector = ".one_auto:nth-child(2) .update_data .hidden_on_shorted";
			return {
				selector: selector,
				description: language_variant.antena_icons,
				showNext: true,
				showSkip: false,
				nextButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
			}
		},
		descBtnForFixationVehicle: function(onBeforeStart){
			let selector = ".one_auto:nth-child(2) .for_fix .star_icon";
			let hintContent = 	<div className = 'hintContent'>{language_variant.click_on_star}</div>;
			return {
				selector: selector,
				description: renderToString(hintContent),
				showNext: true,
				shape: 'circle',
				showSkip: false,
				radius: 25,
				nextButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
			}
		},
		descInfoAboutSelectedVehicle: function(onBeforeStart){
			let selector = ".one_auto:nth-child(2) .current_status";
			let hintContent = 	<div className = 'hintContent'>{language_variant.info_about_curent}</div>
			return {
				selector: selector,
				description: renderToString(hintContent),
				showNext: true,
				nextButton,
				showSkip: false,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
			}
		},
		actionClickOnVehicleInList: function(onBeforeStart){
			let selector = "li[data-value='vehicleForTest']";
			return {
				["click " + selector]: language_variant.click_on_one_of_list,
				description: language_variant.click_on_btn_alow,
				showSkip: false,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
			}
		},
		descEventsAfterVehicleSelect: function(onBeforeStart){
			let selector = "li[data-value='vehicleForTest']";
			let hintContent = 	<div className = 'hintContent'>
								<div className='hintHeader'>{language_variant.desc_selected_vehicle}</div>
									<p className = 'p_vs_image'>
										{language_variant.if_vehicle_has_coordinates};
									</p>
									<p className = 'p_vs_image'>
										{language_variant.if_veicle_move};
									</p>
							</div>
			return {
				selector: selector,
				description: renderToString(hintContent),
				showSkip: 	false,
				showNext: 	true,
				nextButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
			}
		},
		descTransportNameInList: function(onBeforeStart){
			let selector = ".btn_show_settings_container[data-for!='profile_btn_vehicleForTest']";
			return {
				["click " + selector]: language_variant.click_nameOfVehicle,
				showSkip: false,
				showNext: false,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
			}
		},
		descTransportProfile: function(onBeforeStart){
			let selector = "._header";
			let hintContent = <div className = 'hintContent'>
										<div className = 'hintHeader'>{language_variant.navigation_by_vehicle_settings}</div>
										<p className = 'p_vs_image'>ID - {language_variant.vehicleId_in_our_system}</p>
										<p className = 'p_vs_image'>DS - {language_variant.deviceId}</p>
									</div>
			return {
				selector: selector,
				// left: 300,
				description: renderToString(hintContent),
				showSkip: false,
				showNext: true,
				nextButton,
				onBeforeStart: function(){
					const callBeforeStart = () => onBeforeStart?onBeforeStart(selector):null;
					setTimeout(callBeforeStart, 700)
				}
			}
		},
		descNotes: function(onBeforeStart) {
			let selector = '#vecle_notes_btn';
			let hintContent = <div className = 'hintContent'>
										<p className = 'p_vs_image'>{language_variant.notes_by_vehicle}</p>
									</div>

			return {
				selector: selector,
				description: renderToString(hintContent),
				showSkip: false,
				showNext: true,
				nextButton,
				onBeforeStart: function(){
					onBeforeStart?onBeforeStart(selector):null}
			}
		},
		descParameters: function(onBeforeStart) {
			let selector = '.tabs[name="parameters"]';
			let hintContent = 	<div className = 'hintContent'>
									<p className = 'p_vs_image'>{language_variant.settings_for_notif}</p>
								</div>
			return {
				selector: selector,
				description: renderToString(hintContent),
				showSkip: false,
				showNext: true,
				nextButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null;}
			}
		},
		descСounters: function(onBeforeStart) {
			let selector = '.tabs[name="counters"]';
			let hintContent = 	<div className = 'hintContent'>
									<p className = 'p_vs_image'>{language_variant.desc_counters}</p>
								</div>
			return {
				selector: selector,
				description: renderToString(hintContent),
				showSkip: false,
				showNext: true,
				nextButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null;}
			}
		},
		descHandling: function(onBeforeStart) {
			let selector = '.tabs[name="other"]';
			let hintContent = 	<div className = 'hintContent'>
									<p className = 'p_vs_image'>{language_variant.desc_handling}</p>
								</div>
			return {
				selector: selector,
				description: renderToString(hintContent),
				showSkip: false,
				showNext: true,
				nextButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null;}
			}
		},
		descDigitalInputs: function(onBeforeStart) {
			let selector = '.tabs[name="digitalInputs"]';
			let hintContent = 	<div className = 'hintContent'>
									<p className = 'p_vs_image'>{language_variant.desc_digitalInputs}</p>
								</div>
			return {
				selector: selector,
				description: renderToString(hintContent),
				showSkip: false,
				showNext: true,
				nextButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null;}
			}
		},
		descControlSensors: function(onBeforeStart) {
			let selector = '.tabs[name="controlSensors"]';
			let hintContent = 	<div className = 'hintContent'>
									<p className = 'p_vs_image'>{language_variant.desc_control_of_sensor}</p>
								</div>
			return {
				selector: selector,
				description: renderToString(hintContent),
				showSkip: false,
				showNext: true,
				nextButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null;}
			}
		},
		descControlZones: function(onBeforeStart) {
			let selector = '.tabs[name="controlZones"]';
			let hintContent = 	<div className = 'hintContent'>
									<p className = 'p_vs_image'>{language_variant.desc_control_in_zones}</p>
								</div>
			return {
				selector: selector,
				description: renderToString(hintContent),
				showSkip: false,
				showNext: true,
				nextButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null;}
			}
		},
		closePopup: function(onBeforeStart) {
			let selector = '.btn_close';//
			let hintContent = <div className = 'hintContent'>
										{language_variant.closePopup}
									</div>;
			return {
				["click " + selector]: renderToString(hintContent) ,
				showSkip: false,
				showNext: false,
				nextButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null;}
			}
		},
		actionClickOnEventsFilters: function(onBeforeStart){
			let selector = ".filter_events";
			return {
				["click "+ selector]: language_variant.click_on_marker,
				showNext: true,
				showSkip: false,
				nextButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
			}
		},
		fieldForSearch: function(onBeforeStart){
			let selector = '.transport_search_form';
			return {
				selector: selector,
				event: 'next',
				description: language_variant.search_field,
				showNext: true,
				showSkip: false,
				nextButton,
				skipButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
			}
		},
		descBtnForHideTransportList: function(onBeforeStart){
			let selector = ".trigger_triangle";
			return {
				selector: selector,
				description: language_variant.click_on_btn_alow,
				showNext: true,
				showSkip: false,
				nextButton,
				skipButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
			}
		},
		descDateOfLastData: function(onBeforeStart){
			let selector = ".one_auto:nth-child(2) .update_data span:nth-child(3)";
			return {
				selector: selector,
				description: language_variant.info_about_last_data,
				showNext: true,
				showSkip: false,
				nextButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
			}
		},
		// descBtnMapView: function(onBeforeStart){
		// 	let selector = ".gmnoprint";
		// 	return {
		// 		selector: selector,
		// 		description: language_variant.button_view_ofMap,
		// 		showNext: true,
		// 		showSkip: false,
		// 		nextButton,
		// 		onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
		// 	}
		// },
		actionClickOnGroup: function(onBeforeStart){
			let selector = ".selected_value";
			return {
				["click " + selector]: language_variant.click_for_workWithCroup,
				showSkip: false,
				onBeforeStart: function(){ onBeforeStart?onBeforeStart(selector):null; }
			}
		},
		descBtnCreateNewGroup: function(onBeforeStart){
			let selector = "#btn_create_transport_group";
			return {
				event: 'click',
				["click " + selector]: language_variant.this_addNewGroup,
				showNext: false,
				showSkip: false,
				timeout: 1000,
				nextButton,
				onBeforeStart: function(){
					const callBeforeStart = () => {
						onBeforeStart?onBeforeStart(selector):null;
						let scrollPLace = ()=> document.getElementById('group_filter_for_vehicle');
						scrollPLace().querySelector(selector).scrollIntoView({block: "center", behavior: "smooth"});
					}

					setTimeout(callBeforeStart, 700)
				}
			}
		},
		descFormEditGroup: function(onBeforeStart){
			let selector = ".group_name_input";
			return {
				selector: selector,
				description: language_variant.here_can_edit_name,
				showNext: true,
				showSkip: false,
				timeout: 1000,
				nextButton,
				onBeforeStart: function(){
					const callBeforeStart = () => onBeforeStart?onBeforeStart(selector):null;
					setTimeout(callBeforeStart, 700)
				}
			}
		},
		descSearchInputInEditGroupForm: function(onBeforeStart){
			let selector = ".edit_group .transport_search";
			return {
				selector: selector,
				description: language_variant.search_by_transport,
				showNext: true,
				showSkip: false,
				timeout: 1000,
				skipButton,
				onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
			}
		},
		// desFooterEditGroupForm: function(onBeforeStart){
		// 	let selector = ".footer_edit_group";
		// 	return {
		// 		["click " + selector]: language_variant.button_instructions,
		// 		showSkip: false,
		// 		timeout: 1000,
		// 		skipButton,
		// 		onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
		// 	}
		// },
		// descClickOnGroup: function(onBeforeStart){
		// 	let selector = ".hidden_select_list>li:nth-child(2)";
		// 	let hintContent = <div className = 'hintContent'>
		// 								<div className = "hintHeader">{language_variant.onclick_inList_left}</div>
		// 								<p className = 'p_vs_image'>
		// 									<button className = 'edit_btn'></button> - {language_variant.edit}
		// 								</p>
		// 							</div>
		// 	return {
		// 		selector: selector,
		// 		description: renderToString(hintContent),
		// 		showNext: true,
		// 		showSkip: false,
		// 		timeout: 1000,
		// 		nextButton,
		// 		onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null}
		// 	}
		// },

		// example: function(onBeforeStart) {
		// 	let selector = '.vecle_notes';
		// 	let hintContent = 	<div className = 'hintContent'>

		// 						</div>
		// 	return {
		// 		selector: selector,
		// 		description: renderToString(hintContent),
		// 		showSkip: false,
		// 		showNext: true,
		// 		nextButton,
		// 		onBeforeStart: function(){onBeforeStart?onBeforeStart(selector):null;}
		// 	}
		// }
	}
}
function addVehicleForShowHint() {
	let vehiclesList = infoStore('globalR.controlR.vehiclesInfo');
	const newVehiclesList = copyOfObject(vehiclesList);
	newVehiclesList.unshift(demoVehicle)
	TransportApi.dispatchVehicleInfoToStore(newVehiclesList)
	TransportApi.setStatusForButtonFilters(newVehiclesList)
}
function removeVehicleForHint() {
	let vehiclesList = infoStore('globalR.controlR.vehiclesInfo');
	const newVehiclesList = copyOfObject(vehiclesList);
	newVehiclesList.splice(0, 1)
	TransportApi.dispatchVehicleInfoToStore(newVehiclesList)
	TransportApi.setStatusForButtonFilters(newVehiclesList)
}






export default {
	controlPageHint: controlPageHint,
	addVehicleForShowHint: addVehicleForShowHint,
	removeVehicleForHint: removeVehicleForHint,
}
