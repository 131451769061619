import LocalizedStrings from "react-localization";
import React from "react";
import wordsUA, { subCatUA } from "./vocabularyUA.jsx";
import wordsRU, { subCatRU } from "./vocabularyRU.jsx";
import wordsEN, { subCatEN } from "./vocabularyEN.jsx";
import ru from "moment/locale/ru.js";
import uk from "moment/locale/uk.js";
import en from "moment/locale/en-gb.js";
import moment from "moment";
// import VocabularyObjects from "store/fullVocabulary.js"
// const VocabularyObjects = React.lazy(() => import("store/fullVocabulary.js"));
// console.log(`%c VocabularyObjects`, "color: orange; font-weight: 400; text-transform:uppercase", VocabularyObjects)
// console.log(`%c V-`, "color: green; font-weight: 400; text-transform:uppercase", GetVocabulary)

const language_variant = new LocalizedStrings({
	get ru() { return wordsRU;  },
	get ua() { return wordsUA;  },
	get en() { return wordsEN;  },

});


function saveUserLanguage (language) {
	localStorage.setItem("lang", language);
	setMomentLocale();
}

export function getUserLanguage () {
	const savedLang = localStorage.getItem("lang");
	return savedLang ? savedLang : "ua"; //navigator.language || navigator.userLanguage;
}

export function setMomentLocale () {

	moment.locale(getUserLanguageForMoment());
}

export function getUserLanguageForMoment () {
	const locales = {
		ru: "ru",
		ua: "uk",
		en: "en-gb",
	};
	return locales[getUserLanguage()];
}

language_variant.setLanguage(getUserLanguage());
//language_variant.__language; get current language
export default language_variant;

export const d3Locale = {
	ru: {
		"dateTime": "%A, %e %B %Y г. %X",
		"date": "%d.%m.%Y",
		"time": "%H:%M:%S",
		"periods": ["AM", "PM"],
		"days": ["воскресенье", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"],
		"shortDays": ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
		"months": ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
		"shortMonths": ["янв", "фев", "мар", "апр", "май", "июн", "июл", "авг", "сен", "окт", "ноя", "дек"]
	},
	ua: {
		"dateTime": "%A, %e %B %Y г. %X",
		"date": "%d.%m.%Y",
		"time": "%H:%M:%S",
		"periods": ["AM", "PM"],
		"days": ["неділя", "понеділок", "вівторок", "середа", "четвер", "п’ятниця", "субота"],
		"shortDays": ["нд", "пн", "вт", "ср", "чт", "пт", "сб"],
		"months": ["січня", "лютого", "березня", "квітня", "травня", "червня", "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"],
		"shortMonths": ["січ", "лют", "бер", "квіт", "трав", "черв", "лип", "серп", "вер", "жовт", "лист", "груд"]
	},
	en: {
		"dateTime": "%A, %e %B %Y г. %X",
		"date": "%d.%m.%Y",
		"time": "%H:%M:%S",
		"periods": ["AM", "PM"],
		"days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
		"shortDays": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
		"months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		"shortMonths": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
	}
};

//return here in monday
// function VocabularyTransform() {

//    const mapKeysToGroup = new Map()
//    const mapKeysToLang = new Map();

//    Object.entries(subCatRU).forEach(el => {

//       const group = el[0];
//       const keysObj = Object.keys(el[1]).forEach(key => mapKeysToGroup.set(key, group));
//       // keysObj.forEach(key => mapKeysToGroup.set(key, group) );
//    })


//    console.log(`%c -`, "color: green; font-weight: 400; text-transform:uppercase", )


//    console.log(`%c mapKeysToGroup`, "color: blue; font-weight: 400; text-transform:uppercase", mapKeysToGroup)


//    ///
//    // Шаг 1: Извлекаем все ключи (названия констант) из каждого объекта
//    // В этом примере я предполагаю, что объекты subCatRU, subCatEN и subCatUA уже определены
//    const keysRU = Object.keys(subCatRU);
//    const keysEN = Object.keys(subCatEN);
//    const keysUA = Object.keys(subCatUA);


//    //get group list
//    const objGroupList = new Set([...Object.keys(subCatRU), ...Object.keys(subCatEN), ...Object.keys(subCatUA)]);
//    window.objGroupList = objGroupList;
//    console.log(`%c objGroupList`, "color: blue; font-weight: 400; text-transform:uppercase", objGroupList)

//    // get full vocabulary list keyname on first level
//    const getVocabularyForEdit = () => {
//       const finalObject = {};
//       const keyNamesObj = new Set([...Object.keys(wordsUA), ...Object.keys(wordsRU), ...Object.keys(wordsEN)]);
//       keyNamesObj.forEach(key => {
//          finalObject[key] = {
//             'ru': wordsRU[key] || '',
//             'en': wordsEN[key] || '',
//             'ua': wordsUA[key] || '',
//             'group': mapKeysToGroup.get(key) || 'Unknown'
//          };
//       });
//       return finalObject
//    };
//    console.log(`%c getVocabularyForEdit()`, "color: blue; font-weight: 400; text-transform:uppercase", getVocabularyForEdit())

//    //get vocabulary grouped by language
//    const getVocabularyGroupedByLanguage = () => {
//       const vocabularyRu = {};

//       const formatForLocalized = { ru: wordsRU, en: wordsEN, ua: wordsUA };

//       Object.defineProperty(formatForLocalized, "saveItem", {
//          value: function ({ name, ru, en, ua }) {
//             this.en[name] = en;
//             this.ru[name] = ru;
//             this.ua[name] = ua;
//          },
//          writable: false,
//          enumerable: false,
//          configurable: false,
//       });
//       Object.keys(fullVocabulary).forEach(key =>
//          formatForLocalized.saveItem({
//             ...fullVocabulary[key],
//             name: key
//          })
//       );
//       return formatForLocalized
//    };
//    console.log(`%c getVocabularyGroupedByLanguage()`, "color: blue; font-weight: 400; text-transform:uppercase", getVocabularyGroupedByLanguage())

//    const getVocabularyGroupedByComponents = (arg) => {
//       //create empty object for every group

//       const groupObjects = Object.fromEntries([...objGroupList].map(group => [group, {}]));
//       console.log(`%c groupObjects`, "color: blue; font-weight: 400; text-transform:uppercase", groupObjects)
//       const resultObject = Object.defineProperty(groupObjects,
//          "saveItem", {
//             value: function ({ key_name, keyValue }) {
//                console.log(`%c ${keyValue.group} this`,
//                   "color: blue; font-weight: 400; text-transform:uppercase",
//                   this, key_name, keyValue)
//                //create group if it's new
//                const { group } = keyValue;

//                !this[group] ? this[group] = {} : null;
//                //add keyword
//                this[group][key_name] = keyValue;
//             },
//             writable: true,
//             enumerable: false,
//             configurable: false,
//          }
//       );

//       for (const [key_name, keyValue] of Object.entries(fullVocabulary)) {
//          // console.log(`${key_name}: ${keyValue?.group}`);
//          resultObject.saveItem({ key_name, keyValue })
//       }

//       return resultObject
//    };
// }
