import React                   from 'react'
import L                       from "leaflet" //https://github.com/bbecquet/Leaflet.RotatedMarker
import { getMapTypeFromStore } from "components/leafLetsMap/LFMap.jsx"
import { renderToString }      from 'react-dom/server'
import SL                      from 'sl-library'
import {
	stringCarInfoWindow,
	customizeInfoWindow,
	listennerInstructions,
	stringTrailerInfoWindow,
	HorizontalCarWindow
}                              from './windowsOnMap.jsx'
import helpFunc                from 'components/modules/controlPage/helpFunc.js'
import { createRoot } 		   from 'react-dom/client';
import { createPortal } 	   from 'react-dom';
// import ClickOutside            from 'react-click-outside'
import "leaflet-rotatedmarker"
import 'images/mapIcon/arrow_on_map_def.svg';
import 'images/mapIcon/arrow_on_map.svg';
import 'images/mapIcon/arrow_on_map_move.svg';
import 'images/mapIcon/arrow_on_map_no_signal.svg';
import 'images/mapIcon/vehicle_on_route_icon.svg';


export function validateCoordindates(lat, lng){
	return SL.isNumeral(lat) && SL.isNumeral(lng) && !!lat && !!lng //not equal 0
}

let horizontalWindowRoot;

const MarkerDomMethods = Base => class MarkerDomMethods extends Base{
	markerRotate(bearing){
		this.marker.setRotationAngle(bearing)
    	const markerDOM = this.markerDOM;
    }
	get markerDOM() {
		if(this.marker){
			return this.marker._icon
		}else{
			return null
		}
	}
	addClass(markerDOM){
		!markerDOM.classList.contains(cssClass) ?
		markerDOM.classList.add(cssClass) :
		null
	}
	removeClass(markerDOM){
		markerDOM.classList.remove(cssClass)
	}
	updateCssClass(action){
    	const markerDOM = this.markerDOM;
    	const cssClass = 'choosedMarker';
    	const setClass = (action) => action == 'add' ? this.addClass(markerDOM) : this.removeClass(markerDOM)
	}
	getDOMCoorginates(){//not completed
		const markerIcon = this.marker.getIcon();
		let el = document.querySelector('img[src="' + markerIcon.url + '"]')
		return  el.getBoundingClientRect()
	}
}

const MarkerControlPageMethods = Base => class MarkerControlPageMethods extends Base{
	_updateLocation_(location_data) {
		// console.log(`%c location_data-`, "color: green; font-weight: 400; text-transform:uppercase", location_data)
		let { vehicleId, latitude, longitude, speed, azimuth } = location_data;
		if(this.prevLat !== latitude && this.prevLng !== longitude
		   		&& validateCoordindates(latitude, longitude)){//The crutch for situation when back end send the same coordinates what was in previous once
			this.lat = latitude;
			this.lng = longitude;
			const bearing = azimuth ? azimuth : this.getBearing(this.prevLat, this.prevLng, this.lat, this.lng);
			this.markerRotate(bearing)
			this.bearing = bearing;
			this.marker.setLatLng( L.latLng(latitude, longitude));
			this.selected ?
			this.setMarkerOnCenterMap() :
			null;
			this.prevLat = this.lat;
			this.prevLng = this.lng;
		}
		this.setNewStatusIcon;
	}
	addListeners() {
		this.marker.addEventListener('mouseover', () => {
			this.hoveredMarker()
		});
		this.marker.addEventListener('mouseout', () => {
			this.overedMarker()
		});
		this.marker.on('click', e => this.onMarkerClick())
	}
}

const MarkerRouteDetailsMethods  = Base => class MarkerRouteDetailsMethods extends Base {
	_updateLocation_(location_data) {
		let {
			currentLat,
			currentLng,
			currentDate,
			vehicleId,
			isNotSelected,
			focus_on_vehicle_marker
		} = location_data;
		if(validateCoordindates(currentLat, currentLng) && currentDate !== this.date) { //date comparing  need for prevent rotation marker if data
			this.lat = currentLat;
			this.lng = currentLng;
			this.marker.setLatLng(L.latLng(currentLat, currentLng)); //come not from route detail (component also listen redux - websocket )
			if(focus_on_vehicle_marker){//this.zoomWasUpdated && !isNotSelected
				this.setMarkerOnCenterMap();//лагает если быстро водить маркером
			}
		}
		/* //getting azimuth for marker, now it's getting from backend
			if(SL.isValidDate(currentDate) && SL.isValidDate(this.date)) {
				const bearing = this.date.getTime() < currentDate.getTime() ?
								this.getBearing(this.prevLat, this.prevLng, currentLat, currentLng) :
								this.getBearing(currentLat, currentLng, this.prevLat, this.prevLng)
				this.markerRotate(bearing);
			}
		*/
		this.date = currentDate;
		this.prevLat = currentLat;
		this.prevLng = currentLng;
	}
	setInitZoom(){
		this.itsInitZoom = true;
		this.zoomWasUpdated = false;
	}
	addListeners(){
		// this.map.addEventListener(this.map, 'zoom_changed', (e) => {
		// 		if(!this.zoomWasUpdated){
		// 			if(!this.itsInitZoom){ //first change zoom happens on redraw road
		// 				this.zoomWasUpdated = true
		// 			}
		// 		}
		// 		if(this.itsInitZoom){
		// 			this.itsInitZoom = false;
		// 		}

		// 	}
		// )
	}
}

//need refactor but no concrete task - no  sens  code refactor. may be will need rewriting)
//code change idea: info window method change - can remove droch like "this.window_view_type == 'horizontal'"

const HorizontalInfoWindow = Base => class HorizontalInfoWindow extends Base {
		get mapContainerEl(){
			return document.getElementById('map_wrapper')
		}
		get winMountPointEl(){
			return document.getElementById('mount_container_info_window')
		}
		get horizontalWindowContent(){
			// <ClickOutside onClickOutside = {removeHorizontalWindow}>
			// </ClickOutside>
			const setDefaultView = () => {//on click to btn set default window view
				this.window_view_type = 'default';
				this.removeHorizontalWindow();
				this.createInfoWindow();
			};
			return 	<HorizontalCarWindow 	{...this.state}
											onClose = 			{ () => this.removeHorizontalWindow() }
											setDefaultView = 	{ setDefaultView }/>
		}
		get updateInfoWindow(){
			// console.log("%c GET UPDATEINFOWINDOW", coCSS, this)
			if(this.window_view_type == 'horizontal' && this.horizontalWindowOpen) this.updateHorizontalWindow();
			return this._updateInfoWindow_
		}
		get closeInfoWindow(){
			// console.log("%c GET CLOSEINFOWINDOW", coCSS, this)
			if(this.window_view_type == 'horizontal' && this.horizontalWindowOpen) this.removeHorizontalWindow()
			return this._closeInfoWindow_
		}
		get openInfoWindow(){
			// console.log("%c GET OPENINFOWINDOW", coCSS, this)
			if(this.window_view_type == 'horizontal') setTimeout( () => this.createHorizontalWindow(), 100)
			// (() => {if(this.window_view_type == 'horizontal') this.createHorizontalWindow()}) ();
			return this._openInfoWindow_
		}
		get showInfoWindowWithOutMarker(){
			if(this.window_view_type == 'horizontal') setTimeout( () => this.createHorizontalWindow(), 100);
			return this._showInfoWindowWithOutMarker_
		}
		removeHorizontalWindow(){//clear mount point
			horizontalWindowRoot.render(null);
			this.winMountPointEl.style.display = 'none';
			this.horizontalWindowOpen = false;
		}
		createHorizontalWindow(){
			if(!this.winMountPointEl){
				//create DOM el for mount component
				const domEl = document.createElement('div');
				domEl.setAttribute('id', 'mount_container_info_window')
				domEl.setAttribute('class', 'mount_container_info_window');
				this.mapContainerEl.appendChild(domEl);
				//clear if root created on another map
				if(horizontalWindowRoot) {
					horizontalWindowRoot.render(null);
					horizontalWindowRoot = null;
				}
			}
			this.winMountPointEl.style.display = 'block';
			// //create component
			if(!horizontalWindowRoot) {
				horizontalWindowRoot = createRoot(this.winMountPointEl);
			}
			this.updateHorizontalWindow();
			this.horizontalWindowOpen = true;
		}
		updateHorizontalWindow(){
			horizontalWindowRoot.render(this.horizontalWindowContent);
		}
}

const InfoWindow = Base => class InfoWindow extends Base{
	_windowViewType_ = 'default';//'horizontal', 'default';
	mapInfoWindow = null; //it's used when marker is absent
	set window_view_type(value){
		localStorage.setItem('window_view_type', value);
		return this._windowViewType_ = value;
	}
	get window_view_type(){
		if(localStorage.getItem('window_view_type')){
			this._windowViewType_ = localStorage.getItem('window_view_type');
		}
		return 	this._windowViewType_;
	}
	get mapPopUpContent(){
		return 	this.stateInStore.type == 'VEHICLE' ?
				stringCarInfoWindow(this.stateInStore) :
				stringTrailerInfoWindow(this.stateInStore);
	}
	get popUp(){
		if(this.marker){
			return this.marker.getPopup();
		}
		if(this.mapInfoWindow){
			return this.mapInfoWindow.getPopup();
		}
		return null
	}
	createMapInfoWindow(){
		const popUp = new L.Popup({ autoClose: false, closeButton: true }).setContent(this.mapPopUpContent);
		this.marker.bindPopup(popUp).openPopup();
		this.addListenersForDefWindow();
	}
	createInfoWindow(){
		const vehicleId = this.vehicleId;
		if(this.window_view_type == 'horizontal'){
			this.createHorizontalWindow();
		}else{
			if(this.marker){
				this.createMapInfoWindow()
			}else{
				this._showInfoWindowWithOutMarker_()
			}

		}
	}
	_openInfoWindow_(){
		const openMapInfoWindow = () => { //crutch, because popup event "close" on click outside - closing new window)
			this.marker.openPopup();
			this.popUp.setContent(stringCarInfoWindow(this.stateInStore));
			this.addListenersForDefWindow();
		};

		if(this.popUp && !this.popUp.isOpen() && this.window_view_type == 'default') setTimeout( openMapInfoWindow,  300);
		if(!this.popUp && this.window_view_type == 'default') this.createInfoWindow();
		if(this.onOpenCarInfoWindow) this.onOpenCarInfoWindow(this.vehicleId);
	}
	_updateInfoWindow_(){
		if(this.popUp && this.popUp.isOpen() && this.window_view_type == 'default') {
			this.addListenersForDefWindow();
			this.popUp.setContent(this.mapPopUpContent);
		}

	}
	_closeInfoWindow_(){
		if(this.marker){
			this.marker.closePopup();
			return
		}
		if(this.mapInfoWindow){
			this.mapInfoWindow.remove();
			return
		}

		// this.clearPrevListenners();
	}
	_showInfoWindowWithOutMarker_(){
		if(this.window_view_type == 'default'){
			this.mapInfoWindow = new L.Popup()
								.setLatLng(this.map.getCenter())
								.setContent(this.mapPopUpContent)
								.openOn(this.map);
			this.addListenersForDefWindow();

		}

		if(this.onOpenCarInfoWindow) this.onOpenCarInfoWindow(this.vehicleId);
	}
	hideInfoWindowWithOutMarker(){
		this.closeInfoWindow()
	}
	addListenersForDefWindow(){
		setTimeout(()=> {
			listennerInstructions.vehiclesDataGpsDataDto = this.state.vehiclesDataGpsDataDto;
			listennerInstructions.setListenerForCopyBtn(listennerInstructions.funcOnCopy);

			listennerInstructions.setListenerForChangeView(() => {
				this.closeInfoWindow();
				this.window_view_type = 'horizontal';
				this.createHorizontalWindow();
			});
		}, 400)

	}
}






class VehicleMarker {
	constructor(context) {
		// if(context.vehicleId == 39187){
		// 	console.log("%c VEHICLEMARKER context", coCSS, context)
		// }

		this.context = context;
		this.state = context.state;
		this.initIconWidth = 43;
		this.initIconHeight = 43;
		this.markerInitialZindex = context.markerInitialZindex;
		this.color = context.color;
		this.map = context.map;
		this.title = context.title;
		this.marker_status  = helpFunc.getVehicleMarkerStatus(context.state||context);
		this.addMarkerToMap();
		//I don't member wtf, but markers not visible on the map without this
		this.hoveredMarker;
		this.setNormalIcon;//command
		//I don't member wtf, but markers not visible on the map without this
		this.onOpenCarInfoWindow = context.onOpenCarInfoWindow;
		this.scrollVehicleIntoView = context.scrollVehicleIntoView;
	}
	markerPresentOnMap(){
		return this.markerDOM
	}
	markerIcon(marker_status) {
		marker_status = marker_status ? marker_status : 'no_signal';
		if(this.is_route_detail_marker){
			return this.availableIcon.route_detail_marker
		}
		return this.availableIcon[marker_status]
	}
	_availableIcon_() {
		return {
			normal: {
				iconUrl: 'images/mapIcon/arrow_on_map_def.svg#' + this.vehicleId,
				status: "normal",
				...this.iconSettings
			},
			hover: {
				iconUrl: 'images/mapIcon/arrow_on_map.svg#' + this.vehicleId,
				status: "hover",
				...this.iconSettings
			},
			move: {
				iconUrl: 'images/mapIcon/arrow_on_map_move.svg#' + this.vehicleId,
				status: "move",
				...this.iconSettings
			},
			no_signal: {
				iconUrl: 'images/mapIcon/arrow_on_map_no_signal.svg#' + this.vehicleId,
				status: "no_signal",
				...this.iconSettings
			},
			route_detail_marker: {
				iconUrl: 'data:image/svg+xml;charset=UTF-8,'+ route_detail_marker(this.color) + '#' + this.vehicleId,
				status: "route_detail_marker",
				// anchor:      new google.maps.Point(this.iconWidth / 2, this.iconHeight / 2),
				iconSize:        [40, 40],
				iconAnchor:  [24, 40],
				// labelOrigin: new google.maps.Point(this.iconWidth / 2, this.iconHeight + 7),
			}
		}
	}
	_iconSettings_() {
		return {
			iconAnchor:  [this.iconWidth / 2, this.iconHeight / 2],
			iconSize:    [this.iconWidth, this.iconWidth],
			// scaledSize:  [this.iconWidth, this.iconWidth],
			labelOrigin: [this.iconWidth / 2, this.iconHeight + 7],
			// rotation:    50//this.bearing ? this.bearing : 0//don`t work with url
			// origin:   		 new google.maps.Point(21.5, 21.5)
		}
	}
	addMarkerToMap() {
		if(!!this.lat && !!this.lng && this.map && !this.marker) {
			const iconInfo = this.markerIcon(this.marker_status);
			const icon = L.icon({
				...iconInfo,
				zIndexOffset:   this.markerInitialZindex,
				date:      		this.date,
				id: 	   		this.vehicleId,
			})
			this.prevIconInfo = iconInfo;
			this.marker = L.marker(
			                       [this.lat, this.lng],
			                       {
			                       		icon: icon,
			                       		rotationOrigin: 'center center',
			                       		rotationAngle: this.bearing,
			                       		riseOnHover: true,
			                       	}).addTo(this.map);
			this.setNewStatusIcon;

			this.prevLng = this.lng;
			this.prevLat = this.lat;
			this.addListeners();
			let promiseInterval;
			if(this.marker_status !== 'route_detail_marker'){
				// if(this.vehicleId == 39187){
				// 	window.VehicleMarker = this;
				// 	window.marker = this.marker;
				// }
				const promiseRotateMarker = new Promise((resolve, reject) => {
					  promiseInterval = setInterval(() => {
					   this.markerDOM ? resolve() : null
					  }, 200);
					});

				promiseRotateMarker.then((value) => {
				  clearInterval(promiseInterval)
				  this.markerRotate(this.bearing)
				});
			}

			this.labelForAllEnabled ? this.showMarkerLabel : null;
		}
	}
	removeMarkerFromMap(){
		const removeMarker = () => {
			this.map.removeLayer(this.marker)
			// this.marker.setMap(null)
			this.marker = null;
		}
		this.marker ?  removeMarker() : null
	}
	setMarkerOnCenterMap() {
		if(validateCoordindates(this.lat, this.lng)){
			this.map.panTo({ lat: this.lat, lng: this.lng })
		}else{
			console.warn(`VehicleMarker - setMarkerOnCenterMap invalid coordinates for vehicleId ${this.vehicleId}`, this.lat, this.lng)
		}
	}
	focusOnMarker(){
		this.map.setZoom(11);
		this.setMarkerOnCenterMap();
	}
	_setNewStatusIcon_() {
		const new_status = helpFunc.getVehicleMarkerStatus(this.state);
		/*if(this.vehicleId == 39187){
			console.log("%c _SETNEWSTATUSICON_: status, this.marker_status", coCSS, helpFunc.getVehicleMarkerStatus(this.state), this.marker_status);
			console.log("%c _SETNEWSTATUSICON_ state", coCSS, this.state);
		}*/
		if(new_status !== this.marker_status) {
			const iconInfo = this.markerIcon(new_status)
			this.marker.setIcon( L.icon(iconInfo) );
			this.marker.setZIndexOffset(this.markerInitialZindex);
			this.marker_status = new_status;
			this.prevIconInfo = iconInfo;
		}
	}
	_hoveredMarker_() {
		// if(this.vehicleId == 74841)console.trace('_hoveredMarker_', new Date().toISOString())
		this.marker.setIcon(L.icon(this.markerIcon('hover')));
		this.marker.setZIndexOffset(this.markerInitialZindex * 10 + 1);
		this.labelForAllEnabled ? null : this.showMarkerLabel;
		this.markerIsHovered = true;
	}
	_overedMarker_() {
		// if(this.vehicleId == 74841)console.trace('_overedMarker_', new Date().toISOString())
		if(this.markerIsHovered) {
			this.marker.setIcon(L.icon(SL.copyOfObject(this.prevIconInfo)));
			this.marker.setZIndexOffset(this.markerInitialZindex + 1);
			this.labelForAllEnabled ? null : this.hideMarkerLabel;
			this.markerIsHovered = false;
		}
	}
	_setSelected_() {
		console.log("%c _setSelected_", coCSS, )
		this.setBigIcon;
		this.marker.setIcon(L.icon(this.prevIconInfo));
		this.openInfoWindow();
		this.setMarkerOnCenterMap()
		this.selected = true;
	}
	_setDeselected_() {
		this.setNormalIcon;
		this.marker.setIcon(L.icon(this.prevIconInfo));
		this.closeInfoWindow();
		this.selected = false;
	}
	updateTooltipColor(){
		if(this.tooltipBinded){
			if(this.marker.getTooltip()._container.classList.contains('marker_label_blue')){
				this.marker.getTooltip()._container.classList.remove('marker_label_blue')
				this.marker.getTooltip()._container.classList.add('marker_label_white')
			}else{
				this.marker.getTooltip()._container.classList.remove('marker_label_white')
				this.marker.getTooltip()._container.classList.add('marker_label_blue')
			}
		}
	}
	_showMarkerLabel_() {
		if(this.map && this.title && this.marker){//crutch periodic have a bug
			if(!this.tooltipBinded){
				this.marker.bindTooltip(this.title, {
					direction: 'bottom',
					offset: [0, 17],
					zIndex: this.markerInitialZindex,
					permanent: true,
					className: getMapTypeFromStore() == 'hybrid' ? 'marker_label_white' : 'marker_label_blue',
					interactive: false
				})
				this.tooltipBinded = true;
				this.marker.openTooltip()
			}else{
				this.marker.openTooltip()
			}
		}
		return 'marker update executed'
	}
	_hideMarkerLabel_() {
		this.marker.closeTooltip()
	}
	onMarkerClick() {
		if(!SL.getBooleanFromLocalStorage('helpActive')){
			this.openInfoWindow()
			// this.map.setZoom(9); //zoom map to marker
			// this.setMarkerOnCenterMap();
			this.scrollVehicleIntoView ? this.scrollVehicleIntoView(this.vehicleId) : null
		}
	}
	moveMarkerToRigtTopCorner(){
		this.setMarkerOnCenterMap();
		const bounds = this.map.getBounds();
		const NECorner = bounds.getNorthEast();
		const newLatCenter = this.lat - ((NECorner.lat() - this.lat)*0.8);
		const newLngCenter = this.lng - ((NECorner.lng() - this.lng)*0.8);
		this.map.setCenter({lat: newLatCenter, lng: newLngCenter})
	}
	getBearing = getBearing
	get showMarkerLabel() {
		return this.marker ? setTimeout(()=>this._showMarkerLabel_(),500) : 'marker is absent'//timeout added because tooltip binded to prev icon (I think)
	}
	get hideMarkerLabel() {
		return this.marker ? setTimeout(()=>this._hideMarkerLabel_(), 500) : 'marker is absent'
	}
	get setNewStatusIcon() {
		return this.marker ? this._setNewStatusIcon_() : () => 'marker is absent'
	}
	get overedMarker() {
		return this.marker ? this._overedMarker_ : () => 'marker is absent'
	}
	get hoveredMarker() {
		return this.marker ? this._hoveredMarker_ : () => 'marker is absent'
	}
	get availableIcon() {
		return this._availableIcon_()
	}
	set availableIcon(value) {
		return this._availableIcon_ = value
	}
	get iconSettings() {
		return this._iconSettings_()
	}
	set iconSettings(value) {
		return this._iconSettings_ = value
	}
	get setSelected() {
		return this.marker ? this._setSelected_() : "marker is absent"
	}
	get setDeselected() {
		return this.marker ? this._setDeselected_() : "marker is absent"
	}
	get setBigIcon() {
		this.iconWidth = this.initIconWidth * 1.25;
		this.iconHeight = this.initIconHeight * 1.25;
	}
	get setNormalIcon() {
		this.iconWidth = this.initIconWidth;
		this.iconHeight = this.initIconHeight;
	}
	get stateInStore() {
		return this._stateInStore_()
	}
	set stateInStore(value) {
		return this._stateInStore_ = value
	}
	get updateInfoWindow() {
		return this.marker ? this.updateInfoWindow() : "marker is absent"
	}
	get updateLocation() {
		return 	this.marker ?
				this._updateLocation_ :
				(location_data) => {
					const lat = location_data.latitude || location_data.currentLat;
					const lng = location_data.longitude || location_data.currentLng;
					if(this.map && validateCoordindates(lat, lng)) {
						let name = '',
							licensePlate = '';
						if(this.state) {
							name = this.state.name;
							licensePlate = this.state.licensePlate;
						} else {
							if(location_data){
								name = location_data.name;
								licensePlate = location_data.licensePlate;
							}

						}
						/*if(location_data.vehicleId == 39187)*/
						Object.assign(this, {
							vehicleId:     location_data.vehicleId,
							lat:           lat,
							lng:           lng,
							title:         `${name}`,
							licensePlate:  licensePlate,
							name:          name,
							marker_status: helpFunc.getVehicleMarkerStatus(this.state || location_data),

						});
						this.addMarkerToMap();
				}
			}
	}
	get labelForAllEnabled(){
    	return SL.getBooleanFromLocalStorage('labelForAllEnabled')
   	}
	set labelForAllEnabled(value){
	    return this._labelForAllEnabled_ = value
	}
	get title(){
		const vehicle_name = SL.noNull(SL.ifNoKey(this.state, 'name'));
		const license_plate = SL.ifNoKey(this.state, 'licensePlate');
		const device_id = SL.ifNoKey(this.state, 'deviceSerial') || SL.ifNoKey(this.context?.currentDeviceMapping, 'deviceSerial');
		const device_id_text = device_id ? `[${device_id}]` : '';
		return this._title_ ? this._title_ : `${device_id_text} ${vehicle_name} ${license_plate}`;
	}
	set title(value){
		return this._title_ = value;
	}
}

export function getBearing(startLat, startLong, endLat, endLong) {
    	function radians(n) {
    		return n * (Math.PI / 180);
    	}
    	function degrees(n) {
    		return n * (180 / Math.PI);
    	}
    	startLat = radians(startLat);
    	startLong = radians(startLong);
    	endLat = radians(endLat);
    	endLong = radians(endLong);
    	var dLong = endLong - startLong;
    	var dPhi = Math.log(Math.tan(endLat / 2.0 + Math.PI / 4.0) / Math.tan(startLat / 2.0 + Math.PI / 4.0));
    	if(Math.abs(dLong) > Math.PI) {
    		if(dLong > 0.0)
    			dLong = -(2.0 * Math.PI - dLong);
    		else
    			dLong = (2.0 * Math.PI + dLong);
    	}
    	return (degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;
	}


export const ControlPageMarker = class ControlPageMarker extends MarkerControlPageMethods(
	                                                                  	MarkerDomMethods(
	                                                      	                HorizontalInfoWindow(
																				InfoWindow(VehicleMarker)))){};
export const RouteDetailMarker = class ControlPageMarker extends MarkerRouteDetailsMethods(
	                                                                   	MarkerDomMethods(
	                                                       	                HorizontalInfoWindow(
																				InfoWindow(VehicleMarker)))){}

// export const RouteDetailMarker = SL.mixedClass(VehicleMarker,[VehicleMarker, InfoWindow, MarkerLocationOnRoute]);


// getMarkerPosition: function(marker){
// 	return {
// 			lat: marker.getPosition().lat(),
// 			lng: marker.getPosition().lng()
// 		}
// }
function preloadImage(url)
{
    var img = new Image();
    img.src=url;
}
preloadImage('images/mapIcon/arrow_on_map_def.svg');
preloadImage('images/mapIcon/arrow_on_map.svg');
preloadImage('images/mapIcon/arrow_on_map_move.svg');
preloadImage('images/mapIcon/arrow_on_map_no_signal.svg');


const route_detail_marker = (fillColor) => {
	const icon = <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
						 viewBox="0 0 493.5 493.5" enableBackground = "new 0 0 493.5 493.5" xmlSpace="preserve">

						<g>

							<path fill = {fillColor?fillColor:'black'} d="M246.8,461.1c-13.7,0-26.2-6.9-33.6-18.5c-54.4-86-119.2-200.4-119.2-257.5c0-84.2,68.5-152.8,152.8-152.8
								s152.8,68.5,152.8,152.8c0,56.9-64.8,171.4-119.1,257.4C273.1,454.2,260.6,461.1,246.8,461.1z M246.8,131.3
								c-33,0-59.9,26.9-59.9,59.9c0,33,26.9,59.9,59.9,59.9c33.1,0,59.9-26.9,59.9-59.9C306.7,158.2,279.8,131.3,246.8,131.3z"/>
							<path fill = 'white' d="M246.7,45.3c77.1,0,139.9,62.8,139.9,139.9c0,56.7-73.4,181.4-117.1,250.6c-4.9,7.9-13.4,12.5-22.7,12.5
								s-17.8-4.7-22.8-12.5c-43.8-69.2-117.2-193.9-117.2-250.6C106.8,108,169.6,45.3,246.7,45.3 M246.8,264.1
								c40.2,0,72.8-32.7,72.8-72.8c0-40.2-32.7-72.8-72.8-72.8c-40.2,0-72.8,32.7-72.8,72.8C174,231.4,206.7,264.1,246.8,264.1
								 M246.7,19.5c-91.3,0-165.6,74.3-165.6,165.6c0,29.4,14.7,72.7,45,132.3c24.8,48.7,55.1,98.7,76.2,132.1l0,0l0,0
								c9.8,15.3,26.4,24.5,44.5,24.5c18.3,0,34.9-9.2,44.5-24.6c21.1-33.5,51.5-83.5,76.1-132c30.3-59.6,45-102.8,45-132.2
								C412.3,93.8,338,19.5,246.7,19.5L246.7,19.5z M246.8,238.3c-25.9,0-47.1-21.1-47.1-47.1c0-25.9,21.1-47.1,47.1-47.1
								s47.1,21.1,47.1,47.1C293.8,217.2,272.7,238.3,246.8,238.3L246.8,238.3z"/>
						</g>
					</svg>;
	return encodeURIComponent(renderToString(icon))
}
