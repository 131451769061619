
export default function routeDetailR(state = {
		coordinates_route: undefined,
		vehicle_info: undefined,
		point_date:  undefined,
		previous_period: undefined,
	}, action) {
		switch (action.type) {
	    	case 'SET_ROUTE_COORDINATS':
	    	return {
	    		...state,
	    		coordinates_route: action.setCoordinates_route,
	    	};
	    	case 'SET_VEHICLE_INFO':
	    	return {
	    		...state,
	    		vehicle_info: action.setVehicle_info,
	    	};
	    	case 'POINTBYDATE':
	    	return {
	    		...state,
	    		point_date: action.setDate
	    	};
	    	case 'PREVIOUS_PERIOD':
	    	return {
	    		...state,
	    		previous_period: action.setPeriod,
	    	};
	      	default:
	         	return state;
			}
	}
routeDetailR.reducer = 'routeDetailR'