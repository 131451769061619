const trailerInfoWindow = {
    status: 'Status',
    connected: 'connected',
    disconnected: 'disconnected',
    connection_date: 'Connection date',
    disconection_date: 'Shutdown date',
};

const routeWords = {
    ...trailerInfoWindow,
    copy_coordinates:                          'Copy coordinates',
    coordinates_copied:                        'Coordinates copied',
    vehicle:                                   'Vehicle',
    current_counter_value:                     'Counter now',
    text_about_validation_counter_values:      'Initial value must be less than threshold',
    status_will_update_after:                  'Status will be updated after the device is connected',
    parameters:                                'Options',
    or_create_new:                             'or create a new one',
    disabled_receiving_data:                   'Disabled receiving data for the tracker',
    triggers_are_absent:                       'No triggers',
    total_distance:                            'Total distance',
    message_about_gps_veracity:                'GPS data is not valid!',
    ON:                                        'ON',
    OFF:                                       'OFF',
    enter_route_name:                          'Enter route name',
    task_routes:                               'Routes set',
    are_youre_sure_want_remove_route:          'Are you sure you want to remove the route?',
    shedule_route:                             'Route schedule',
    pass_order:                                'Pass order',
    regarding_activation:                      'Regarding activation',
    relative_to_day:                           'Relative to day',
    absolute_time:                             'Absolute time',
    consistently:                              'Consistently',
    arbitrarily:                               'Arbitrarily',
    with_passes:                               'With passes',
    active_tasks:                              'Active tasks',
    complete_the_task:                         'Complete the task?',
    details:                                   'Details',
    cancel_job:                                'Cancel Job',
    latest_data:                               'Latest data',
    completed_tasks:                           'Completed tasks',
    job_detail:                                'Job Details',
    quest_start:                               'Start of quest',
    quest_end:                                 'End of Quest',
    activate_task:                             'Activate task',
    scheduled_for:                             'Scheduled for',
    scheduled:                                 'Scheduled',
    scheduled_job:                             'Scheduled jobs',
    start_task:                                'Start the task?',
    list_of_scheduled_jobs_is_empty:           'the list of scheduled jobs is empty',
    route_detail:                              'Route Details',
    route_name:                                'Route Name',
    path_order_route_points:                   'Order of passage of route points',
    update_route:                              'Update route',
    save_route:                                'Save route',
    complete:                                  'Finish',
    remove_route_point:                        'Remove route point',
    show_detail:                               'Show details',
    hide_detail:                               'Hide details',
    permissible_deviation_from_arrival_time:   'Permissible deviation from arrival time',
    permissible_deviation_from_departure_time: 'Permissible deviation from departure time',
    save_point:                                'Save point',
    location_not_selected:                     'Location not selected',
    are_you_sure_want_delete_job:              'Are you sure you want to delete the job?',
    select_performer:                          'Select performer',
    performer:                                 'Performer(s)',
    no_one_is_doing_the_job:                   'No one is doing the job',
    vehicles_that_parfom_task:                 'Cars that perform the task',
    vehicles_with_task:                        'Vehicles with a task',
    available_vehicles:                        'Available vehicles',
    one_off:                                   'one-off',
    daily:                                     'daily',
    Sunday:                                    "Sunday",
    Monday:                                    'Monday',
    Tuesday:                                   'Tuesday',
    Wednesday:                                 'Wednesday',
    Thursday:                                  'Thursday',
    Friday:                                    'Friday',
    Saturday:                                  'Saturday',
    editing_task:                              'Editing task',
    create_task:                               'Create tasks',
    name_of_task:                              'Task Name',
    task_type:                                 'Task Type',
    task_description:                          'Description of the task',
    update_task:                               'Update task',
    save_task:                                 'Save task',
    task_start_date:                           'Task start date',
    task_end_date:                             'Task end date',
    tasks_days:                                'Task days',
    select_every_day:                          'Select every day',
    enter_route_name:                          'Enter route name'
};

const globalVocabulary = {
    sensors:                                            "Sensors",
    DETAILED_SPEEDING:                            'Details',
    land_cadastre:                                      "Cadastre geometry of plots",
    land_cadastre_index:                                'Cadastre index map',
    attention:                                          'Attention',
    you_donT_have_enough_rights_to_do:                  "You don't have enough rights to do this",
    logic_inputs:   'Logic inputs',
    by_number:      'By number',
    multisensor:    'Multisensor',
    cabinet:                                    'Management',

    fromDateL:                                  'from',
    toDateL:                                    'to',
    standart_view: 'Standart mode',
    for_print: 'Print mode',
    power_restored_text:                       'Board voltage restored',
    data_not_available:                        'Data not available',
    new_notifications_absent:                  'No new notifications',
    clear_blocking_date:                       'Clear blocking date',
    add:                                       'Add',
    update_blocking_date:                      'Update blocking date',
    blocking_date:                             'Blocking date',
    blocking:                                  'Blocking',
    blocked:                                   'Blocked',
    infographic:                               'Infographic',
    complete_vehicles_list:                    'Complete list of transport',
    brief_vehicles_list:                       'Brief list of transport',
    sorting:                                   'Sorting',
    by_name:                                   'By name',
    by_license_plate:                          'By licenseplate',
    by_traker_number:                          'By tracker number',
    vehicle_whith_parking:                     'Cars with parking',
    vehicle_whith_fuelling:                    'Auto with gas stations',
    vehicle_whith_defuelling:                  'Car with possible fuel drain',
    vehicle_whith_speeding:                    'Auto with excesses',
    vehicle_online:                            'Auto Online',
    vehicle_offline:                           'Auto offline',
    show_only:                                 'Show only',
    interface_and_application:                 'Interface and application',
    player:                                    'Player',
    on_calculation:                                    'Include calculations',
    off_calculation:                                   'Disable calculations',
    no_groups:                                         'no groups',
    trailer_registration:                         'Trailer operation',
    RFID_registration:                            'RFID card work',
    card:                                              'Card',
    RFID_cards:                                        'RFID cards',
    additionalColumns:                                 'Additional columns',
    message_to_many:                                   'You entered the wrong password several times in a row. wait ..',
    weak:                                              'weak',
    okay:                                              'okay',
    good:                                              'good',
    strong:                                            'strong',
    restore_pass:                                      'Restore password',
    remembered_you_pass:                               'Did remember your password?',
    entered_passwords_dont_match:                      'The passwords entered do not match',
    password_update_failed:                            'Update password failed',
    password_recovery:                                 'Password recovery',
    password_must_be:                                  "Password must contain at least 3 digits 5 letters, in total 8 characters",
    newPassMustBe:                                     "The new password must contain at least 3 digits 5 letters, in total 8 characters",
    zone_width_must_be:                                'The zone width must be greater than 0м',
    not_set_IN:                                        'Logic input "Trailer" is not assigned for report operation',
    FROM:                                              'from',
    radius:                                            'Radius',
    editVehicles:                                      'Edit vehicles',
    seeTasks:                                          'See tasks',
    select_zone_type:                                  'Select zone type',
    error_on_set_unlock:                               'An error occurred while disarming',
    error_on_set_lock:                                 'An error occurred while arming',
    rotation_in_hour:                                  'r/h',
    'r/min':                                           'r/m',
    rotation_crankshaft_amount:                        'Crankshaft amount rotation',
    crankshaft_amount_rotation:                        'Crankshaft amount rotation',
    link_to_report:                                    'Link to report',
    enter_gmail_email_for_export:                      'Enter your gmail account to export the file',
    recieved:                                          'Recieved',
    total:                                             'Total',
    data_processing_completed:                         'Data processing completed',
    request_processing_in_progress:                    'Request processing in progress',
    geofence_color:                                    'Geofence color',
    zone_width_in_m:                                   'Zone width in meters',
    move_to_group:                                     'Move to group',
    notes_name:                                        'Note title',
    notes_text:                                        'Note text',
    zone_types:                                        'Types of zones',
    select_zone_types:                                 'Select zone types',
    filter_by_zone_types:                              'Filter by zone types',
    create_geophence_from_route:                       'Create geofence from route',
    speeding:                                          'Speeding',
    connection_restored:                               'Сonnection restored',
    try_recovery_connection:                           'Trying to restore the connection. Check your internet connection',
    waypoints:                                         'Waypoints',
    INCLUDE_FIRST_SHIFT:                               'First shift',
    INCLUDE_SECOND_SHIFT:                              'Second shift',
    start_of_period:                                   'Beginning of period',
    end_of_period:                                     'End of period',
    get_data:                                          'Get data',
    offName:                                           'Enabled',
    onName:                                            'Disabled',
    notifyDeviceInControl:                             'Logic inputs triggering',
    name_new:                                          'Name',
    width:                                             'Width',
    filter_notifications:                              'Filter notifications',
    to_new:                                            'To',
    from_new:                                          'From',
    same_fuel_data_time:                               'Glue refills less',
    notes_by_vehicle:                                  'Vehicle notes',
    create_geozone:                                    'Create geofence',
    update_geozone:                                    'Refresh geofence',
    creating_geozone_from_route:                       'Create geofence from route',
    save_geozone:                                      'Save geofence',
    along_the_track:                                   'Along the track',
    squares_from_route:                                'Squares from the route',
    name_for_geozone:                                  'Geofence name',
    fill_name_of_geozone:                              'Fill in the name of the geozone',
    rename:                                            'Rename',
    add_to_group:                                      'Add to group',
    variables:                                         'Variables',
    set_control_zone:                                  'Set control zone',
    get_route:                                         'Request a route',
    import:                                            'Import',
    select_archive_zip_kmz:                            'Select zip/kmz archive or kml file',
    problems_when_generation_report:                   'There were problems when generating the report',
    for_period:                                        'For the period',
    password_was_updated:                              'Password has been updated',
    save_password:                                     'Save password',
    from_beginning_of_month:                           'From the beginning of the month',
    edit_rfid_cards:                                 'Edit RFID cards',
    user_can:                                          'User can',
    edit_user:                                         'Edit user',
    create_user:                                       'Create user',
    enter_user_login:                                  'Enter user login',
    users_management_head:                             'Users management',
    from:                                              'from',
    count_lines:                                       'Count of lines',
    trailer_type:                                      'Trailer type',
    transmit_period_night_in_seconds:                  'Transmission period night in S',
    transmit_period_in_seconds:                        'Day transfer period in S',
    trailers_width:                                    'Trailer width in meters',
    serial_number_of_trailer:                          'Serial number of the trailer',
    name_of_trailer:                                   'Name of trailer',
    editing_trailer:                                   'Editing a trailer',
    adding_trailer:                                    'Adding a trailer',
    trailers_managment:                                'Trailer control',
    enter_name_type_or_serial:                         'Enter name, type or serial number of trailer',
    get_fuel_data:                                     'Get fuel data',
    see_charts:                                        'Get charts',
    switch_device_outputs_state:                       'Switch device outputs state',
    set_lock_mode:                                     'Set protection mode',
    unset_alarm_flag:                                  'Unset alarm flag',
    edit_zones:                                        'Edit zones and reports',
    seeRoutes:                                         'Get routes and see trailers',
    see_reports:                                       'Get reports',
    see_current_vehicle_position:                      'Get vehicle tracks',
    save_user_rights:                                  'Save user rights',
    no_data_allowed_for_user:                          'No data allowed for user',
    prohibited_vehicles_of_user:                       'Prohibited vehicles of user',
    allowed_vehicles_of_user:                          'Allowed vehicles of user',
    trailers:                                          'Trailers',
    instruction_for_setup:                             'Instructions for installation',
    open_telegram:                                     'Open telegram',
    vehicleId:                                         'Vehicle ID',
    limit_sapmle_time:                                 'Limit sample time',
    message_for_user:                                  'Message for user',
    save_description:                                  'Save description',
    save_message:                                      'Save message',
    allowed_vehicles:                                  'Аllowed vehicles',
    prohibited_vehicles:                               'Prohibited vehicles',
    description:                                       'Description',
    confirm_password:                                  'Confirmation password',
    users_management:                                  'Users',
    select:                                            'Select',
    selected:                                          'Selected',
    import:                                            'Import',
    smoothed_data:                                     'Smoothed data',
    no_data_or_equipment_width:                        'No data for the selected period or the width of the attachment is not set',
    all_zone_types:                                    'All types',
    type:                                              'Type',
    comment_text:                                      'Comment text',
    comments_for:                                      'Comments for ',
    are_your_sure_want_del_comment:                    'Are your sure want delete comment?',
    edit_comment:                                      'Edit comment ',
    create_comment:                                    'Сreate comment',
    add_geofence:                                      'Add geofence',
    withOutGroups:                                     'Without groups',
    raw_data:                                          "RAW data",
    detect_parking_time_hint:                          'Stop / parking differentiation time with ignition on',
    detect_parking_time:                               'Parking with the ignition on, through',
    nothing_selected:                                  ' - нічого не вибрано',
    filter:                                            "The filter",
    all_groups:                                        'All groups',
    tracker:                                           'Tracker',
    in_system:                                         'In system',
    request_was_send_answer_in_notif:                  'The request has been sent, the response to the request will come in notifications (upper right corner, bell) ',
    ussd_send:                                         'Sending ussd',
    arming:                            'Arming',
    remove_notif_about_triggering:                     'Remove notification about alarm',
    only_from_line_all:                                'Only from the line "Total"',
    all_data:                                          'All data',
    clear_selected:                                    'Clear selected',
    Fuel_sensor_is_not_install:                        'Fuel sensor is not install',
    stopVehicle:                                       'Stop',
    period1_hint:                                      'Transmission period when driving',
    period2_hint:                                      'Transfer period when the vehicle is parked',
    ATTACHED_EQUIPMENT_WIDTH_hint:                     "Width of attachments in transport, for calculating the processing area.",
    maximum_speed_hint:                                "Transport speed above which fuel fluctuations are not unambiguously considered as refueling",
    maximum_duration_hint:                             "Parameter for fuel spike averaging",
    minimal_stop_time_hint:                            "The maximum time to determine when stopping is a fuel jump or refueling",
    maximum_allowed_speed_hint:                        "To inform about speeding in the message and reports",
    m:                                                 "m.",
    vehicle_width:                                     "Vehicle width",
    ATTACHED_EQUIPMENT_WIDTH:                          "Attached equipment width",
    distance_in_zone_no_less:                          "Mileage in zone no less than, km",
    DAILY_TRANSPORT_ACCOUNT:                           'Daily transport account',
    Yes:                                               'Yes',
    No:                                                'No',
    server_temporarily_unavailable:                    'Server is temporarily unavailable',
    admin_panel:                                       'Admin panel',
    take_off_notif_about_sos:                          'Take off notification about signal SOS',
    isNotifySosSignal:                                 'Signal SOS',
    hide_geofences:                                    'Hide geofences',
    show_geofence:                                     'Show geofences',
    labels:                                            'Labels',
    MOVEMENT_BY_DAY_REPORT:                            'Movement by day',
    MILEAGE_BY_ZONES_REPORT:                           'Mileage by zones',
    MILEAGE_BY_ZONE_TYPES_REPORT:                      'Mileage by zone types',
    SPEEDING_REPORT:                                   'Speed overing',
    ZONE_SPEEDING_REPORT:                              'Speeding in zones by types',
    ZONE_SQUARES_REPORT:                               'Zone squares',
    PARKING_WITH_IGNITION_ON_DURATION_REPORT:          'Parking with ignition on',
    DAILY_FUEL_REPORT:                                 'Daily fuel report',
    SUMMARY_FUEL_REPORT:                               'Fuel level',
    STOPS_REPORT:                                      'By stops',
    DRIVERS_WORK_WITH_VEHICLES:                        'Work drivers by vehicles',
    TRAILERS_WORK_WITH_VEHICLES:                       'Trailers work by vehicles',
    TIME_OF_GETTING_LAST_DATA:                         'Time of getting last data',
    DEVICES_ACTIVITY:                                  'Activity of devices',
    ZONE_PASSAGE:                                      'Passage of zones',
    DEVICES_IN_ZONES_NUMBER:                           'Count of devices in zones',
    STOPS_IN_ZONES:                                    'Stops in zones',
    STOPS_OUT_OF_ZONES:                                'Stops out of zones',
    USSD_REPORT:                                       'USSD report',
    WORK_TIME_STOPS_IN_ZONES_OUT_OF_ZONES_TIME:        'Work time and stops in zones and out of zones',
    DAILY_FLOW_METER_REPORT:                           'Fuel flow meter by days',
    CHANGING_LOGICAL_INPUTS:                           'Changing logical inputs',
    USER_CONNECTION_HISTORY:                           'User connection history',
    LOCK_EVENT_REPORT:                                 'Security mode',
    LIVE_REPORT:                                       'Live report',
    ACCELEROMETER_REPORT:                              'Accelerometer sensor triggering',
    PROCESSED_AREA_REPORT:                             'Process area',
    licensePlate:                                      'License plate',
    absentDataFromSomeSensors:                         "Absent data from some sensors",
    openInNewTab:                                      "Open link in new tab",
    cantSaveForThisAccount:                            "Cannot save report for this account",
    saveToGoogleDisk:                                  "Save to Google disk",
    notifySensorControl:                               "Notifications sensor control",
    isNotifyLogicalInputEngineHoursCounter:            "Counters moto hours by logic inputs",
    isNotifyEntryZone:                                 "Control zone entry",
    isNotifyExitZone:                                  "Control zone exit",
    during:                                            ' for ',
    counter_events_term_over:                          'Count events - "Over temperature"',
    counter_events_speed_over:                         'Count events - "Over speeding"',
    counter_events_voltage:                            'Count events - "Power surge"',
    counter_events_fuelling:                           'Count events - "Fueling"',
    counter_events_defuelling:                         'Count events - "Possible fuel drain',
    counter_events_parking:                            'Count events - "Parking"',
    no_coordinates:                                    "No devise coordinates data",
    status_was_updated:                                "Updated",
    antena_connected:                                  "Antenna ON",
    antena_info_absent_check_device:                   "No antenna data, check if device connected to vehicle",
    antena_info_absent:                                "Antenna status unavailable",
    antenna_was_disabled:                              "Antenna OFF",
    credibility:                                       "Credibility",
    table_SIGNALING_ALARM:                             "Alarm in guard mode!",
    SOS_ALARM:                                         "ALARM!",
    liters:                                            "L",
    on_full:                                           "Enabled",
    off_full:                                          "Disabled",
    POWER_RESTORED:                                    "Power restored",
    DEVICE_ANT_ON:                                     "Antenna ON",
    DEVICE_ANT_OFF:                                    "Antenna OFF",
    DEVICE_SHORT_MODE_ON:                              "Antenna short circuit",
    DEVICE_SHORT_MODE_OFF:                             'Antenna not shorted',
    passed:                                            'passed',
    stop:                                              'Stop',
    start:                                             'Start',
    POWER_RESTORED_START:                              "Power absent",
    POWER_RESTORED_END:                                "Power was restored",
    EXIT_CONTROL_ZONE:                                 "Exit from control zone",
    ENTRY_CONTROL_ZONE:                                "Entry to control zone",
    EngineHours_by_ignite:                             'Motohours by ignition',
    LOGICAL_INPUT_ENGINE_HOURS_COUNTER:                'Motohours counter by logic inputs',
    MOTOHOURS_CAN:                                     'Motohours CAN',

    enable_table:                                      'Enable table view',
    enable_chart:                                      'Enable chart view',
    pure_data:                                         'Pure data',
    calculation:                                       'Calculation',
    driver:                                            'Driver',
    undefined:                                         'Undefined',
    fuel_consumed:                                     'Fuel consumed',
    fuel_consumed_on_hundred:                          'Consumption fuel on 100 km ',
    fuel_consumption_in_hour:                          'Consumption fuel in a hour ',
    pure:                                              'pure',
    parking_duration:                                  'Parking duration ',
    parking_duration_table:                                  'Parking ',
    fuelled:                                           'Fuelled ',
    defuelled:                                         'Defuelled ',
    antenna_was_disabled:                              'Antenna was disabled - ',
    antenna_was_short_mode:                            'Antenna was in short mode  - ',
    today:                                             "Today",
    yesterday:                                         "Yesterday",
    last_seven_day:                                    "Last 7 Days",
    set_own:                                           "Set Own",
    saved:                                             'Saved',
    fuel_sensor_not_installed:                         'Fuel sensor not install',
    'km/h':                                            'km/h',
    managing_keys:                                     'Managing of keys',
    get_link_for_Report:                               'Get link for report',
    LONGER_THEN:                                       "Duration longer then( in s)",
    GEO_CODING:                                        "Geocoding",
    HUNDRED_MILES:                                     "Calculate fuel flow by hundred miles",
    DAILY_FLOW_METER_REPORT:                           "Daily flow meter report",
    TASK_START:                                        "Task start",
    TASK_END:                                          "Task end",
    FUELLING:                                    "Fuelling start",
    FUELLING_START:                                    "Fuelling start",
    FUELLING_END:                                      "Fuelling start",
    DEFUELLING:                                  "Defuelling start",
    DEFUELLING_START:                                  "Defuelling start",
    DEFUELLING_END:                                    "Defuelling start",
    CAUTION_EVENTS:                                    "Caution events",
    INFO_EVENTS:                                       "Info events",
    VEHICLE_OVER_SPEED:                          "Over speed",
    VEHICLE_OVER_SPEED_START:                          "Over speed",
    VEHICLE_OVER_SPEED_END:                            "Over speed",
    VEHICLE_PARKING:                             "Parking with enabled ignition",
    VEHICLE_PARKING_START:                             "Parking with enabled ignition",
    VEHICLE_PARKING_END:                               "Parking with enabled ignition",
    WORK_TIME_STOPS_IN_ZONES_OUT_OF_ZONES_TIME:        'Work time and stops in zones and out of zones',
    USSD_REPORT:                                       'USSD report',
    vehicleDidntMoveDonthaveCoordinatesForGeozone:     "Vehicle did Not Move, we haven't coordinates for geozone",
    onThisVehicleSensorAbsent:                         'On this vehicle sensor is absent',
    someProblem:                                       'Have a problem',
    ZONE_PASSAGE:                                      'Passage of zones',
    DEVICES_IN_ZONES_NUMBER:                           'Count devices in zones',
    STOPS_IN_ZONES:                                    'Stops in zones',
    STOPS_OUT_OF_ZONES:                                'Stops outside zones',
    hide_notif:                                        'Hide this notification',
    reload_page:                                       'Reload page',
    send_info:                                         'Send info',
    please_send_error:                                 'Please send info about error to support and try reload page',
    something_went_wrong:                              'Something went wrong',
    we_already_work_with_fix:                          'we already work with fix',
    notif_about_enter:                                 'Notify about enter to zone',
    notif_about_exit:                                  'Notify about enter to zone',
    control_in_zones:                                  'Control in zones',
    enter_template_name:                               'Enter template name',
    task:                                              'task',
    minimal_fuelling_volume:                           'Minimal fuelling volume',
    minimal_defuelling_volume:                         'Minimal defueling volume',
    rename_zone_type:                                  "Rename zone type",
    add_zone_type:                                     "Add zone type",
    enterZonTypeName:                                  "Enter name of type",
    save_zoneType:                                     "Save zone type",
    add_type:                                          "Add type",
    handling:                                          "Handling",
    control_of_sensor:                                 "Control of sensors",
    digitalInputs:                                     "Digital inputs",
    fuel_level:                                        "Fuel level",
    tank:                                              "Tank",
    UssdRequestForDevice:                              "Answer on ussd request:",
    send:                                              "Send",
    login_placeholder:                                 "Login",
    password_placeholder:                              "Password",
    errorauthorization:                                "Wrong login or password",
    enterToSystem:                                     "Enter",
    interfaceLanguage:                                 "Interface Language",
    profile:                                           "Profile",
    button_send:                                       "Enter system",
    control:                                           "Control",
    tasks:                                             "Tasks",
    report:                                            "Report",
    personal:                                          "Personal",
    help:                                              "Help",
    placeholder_search:                                "Search ...",
    today:                                             "Today:",
    show:                                              "Show:",
    group:                                             "Group",
    groups:                                            "Groups",
    allTransport:                                      "All transport",
    transport_search_placeholder:                      "Number or transportation brand",
    errorconnection:                                   "Connection problem ...",
    status_inmidcourse:                                "In mid course",
    parking:                                           "parking",
    on:                                                "on",
    off:                                               "off",
    kmH:                                               "km/h",
    l:                                                 "l",
    L:                                                 "L",
    min:                                               "min",
    engine:                                            "Engine",
    speed:                                             "Speed",
    showroute:                                         "Show route",
    givetask:                                          "Give task",
    exit:                                              "Exit",
    MARKER_ADMIN_WORDS:                                "____________________________________",
    clients:                                           "Сlients",
    users:                                             "Users",
    devices:                                           "Devices",
    admins:                                            "Admins",
    sensors_type:                                      "Sensors type",
    clients_list:                                      "list of clients",
    admin_list:                                        "list of Administrator",
    transports:                                        "Transports",
    nodata:                                            "No data",
    name_group:                                        "Put name group",
    groupEdit:                                         "Group edit",
    createGroup:                                       "Сreate group",
    cancel:                                            "Cancel",
    remove_group:                                      "remove group",
    save:                                              "Save",
    selectVehicles:                                    "Select vehicles",
    select_vehicle:                                    "Select vehicle",
    give_task:                                         "Give task",
    active:                                            "Active",
    planed:                                            "Planed",
    competed:                                          "Competed",
    draft:                                             "Draft",
    templates:                                         "Templates",
    template:                                          "Template",
    suitable_for_assignments:                          "Suitable for tasks that recur periodically",
    geozones_and_addresses:                            "Geozones and addresses",
    apply:                                             "Apply",
    anytime:                                           "any time",
    task_type:                                         "Task type",
    route:                                             "Route",
    date_and_time:                                     "Date and time",
    beginning_way:                                     "Beginning of the way",
    no_data:                                           "No data",
    may_be_trouble_internet:                           "Maybe you have trouble with internet connection.Try reload page",
    choose_vehicle:                                    "choose vehicle",
    one_time_assignment:                               "One time assignment",
    addPoint:                                          "Add point",
    enter_adress_or_geofence:                          "Enter address or geozone",
    date_and_time:                                     "date and time",
    today:                                             "Today",
    yesterday:                                         "Yesterday",
    last_seven_day:                                    "Last seven day",
    set_own:                                           "Set own...",
    fuel:                                              "Fuel",
    voltage:                                           "Voltage",
    speed:                                             "Speed",
    input:                                             "Input",
    support_contacts:                                  "Support service:",//"Have question? Support service:",
    stay_in_course:                                    "GPS MONITORING | FUEL CONTROL | EFFICIENT BUSINESS",
    notifications:                                     "Notifications",
    password:                                          "Password",
    recieved_sms_notif:                                "Received SMS notifications",
    recieved_email_notif:                              "Received email notifications",
    recieved_telegram_notif:                           "Received telegram notifications",
    adress:                                            "address",
    name:                                              "Name",
    setting:                                           "Setting",
    user_name:                                         "User name",
    current_pass:                                      "Current password",
    new_pass:                                          "New password",
    change:                                            "Change",
    here_you_can_change_pass:                          "Here you can change your password for enter to system.",
    in_system:                                         "In the system",
    on_email:                                          "On email",
    sms_notif:                                         "SMS-notifications",
    telegram_notif:                                    "Telegram",
    signal:                                            "Signal",
    more:                                              "more",
    fueling:                                           "Fueling",
    maybe_discharge:                                   "Possibilities discharge fuel",
    consumption_average:                               "Fuel consumption at 100 km above average",
    lack_signal:                                       "Lack of signal from vehicle",
    event_in_way:                                      "Event in way",
    stop_vs_engine_on:                                 "Stopped with engine in-gear",
    over_speed:                                        "Over speed",
    dangerous_temper:                                  "Dangerous engine temperature increase",
    dangerous_voltage:                                 "Dangerous on-board network voltage increase",
    start_task:                                        "Start of task",
    end_task:                                          "End of task",
    deviation_route:                                   "Deviation from the route",
    choose_events_what_you_want_receive_on_email:      "Select events what you want receive on email.",
    to_receive:                                        "To receive notifications on your phone number, please, ",
    indicate_your_number:                              "indicate your number ",
    and_allow:                                         "and allow SMS notification",
    settings:                                          "Settings",
    show_all:                                          "Show all",
    tuning_notif:                                      "Tuning notifications",
    select_event_if_you_want_receive:                  "Select event if you want receive notification about it",
    add_photo:                                         "Add photo",
    change_photo:                                      "Change photo",
    profile_vehicle:                                   "Vehicle profile ",
    parameters:                                        "Parameters",
    counters:                                          "Counters",
    current_level_fuel:                                "Current level fuel",
    rate_of_fuel:                                      "The rate of fuel consumption (per 100 km)",
    l:                                                 "L",
    voltage_board_network:                             "Voltage board network",
    current_voltage:                                   "Current voltage",
    normal_voltage:                                    "Normal voltage",
    v:                                                 "V",
    motor_temperature:                                 "Motor temperature",
    current_temperature:                               "Current temperature",
    normal_temperature:                                "Normal temperature",
    other_parameters:                                  "Other parameters",
    mileage:                                           "Mileage",
    mileage_counter:                                   "Mileage counter",
    km:                                                "km",
    carrying:                                          "Carrying",
    tons:                                              "tons",
    fuel_tank_volume:                                  "Fuel tank volume",
    change_of_oil:                                     "Change of oil",
    disable_counter:                                   "Disable counter",
    enable_counter:                                    "Enable counter",
    reset_counter:                                     "Reset counter",
    next_maintenance:                                  "Next maintenance",
    change_rubber:                                     "Change rubber",
    here_will_be_employee:                             "Here you will see the employee profile.",
    please_select_employe:                             "Please select an employee from the list on the left or ",
    add_new_one:                                       "add a new one",
    contacts:                                          "Contacts",
    work_time:                                         "Work time",
    vehicles:                                          "Vehicles",
    violations:                                        "Violations",
    h:                                                 "h",
    date:                                              "date",
    timestamp:                                         "date",
    location:                                          "location",
    new_employee:                                      "New employee",
    photo_helps:                                       "Photo helps you quickly find the right person",
    enter_first_name:                                  "Enter first name of the user",
    enter_last_name:                                   "Enter last name of the user",
    first_name:                                        "First name",
    last_name:                                         "Last name",
    add_another_phone:                                 "Add another phone",
    mobile_phone_number:                               "Mobile phone number",
    create_employee:                                   "Create employee",
    add_employee:                                      "Add employee",
    are_you_sure:                                      "Are you sure, you want remove ",
    this_employee:                                     "this employee",
    remove:                                            "Remove",
    deleting:                                          "deleting",
    edit_employee_profile:                             "Edit employee profile",
    update_data:                                       "Update data",
    report_type:                                       "Report type",
    here_will_be_report:                               "Here will be displayed your report.",
    create_report_on_sidebar:                          "Create report using menu on left side. In process work you will can update  report configuration in real time",
    create_own_report:                                 "Create own report",
    name_licenceplate_or_group:                        "Name, license plate vehicle or group",
    update_report:                                     "Update report",
    start_date_must_be_early:                          "Start date must be before end date",
    report_parameters:                                 "Report parameters",
    display_order:                                     "Display order",
    choose_enter_parameters:                           "Enter a name and select the options that you want to include in your report",
    report_name:                                       "Report name",
    start_motion:                                      "Start motion",
    end_motion:                                        "End motion",
    time_in_way:                                       "Time in way",
    mileage:                                           "Mileage ",
    max_speed:                                         "Max speed",
    average_speed:                                     "Average speed",
    spent_fuel:                                        "Spent fuel",
    average_spent_fuel:                                "Average spent fuel on 100 km",
    average_fuel_consumption_per_hour:                 "Average spent fuel on 1 hour",
    time_parking:                                      "Time parking",
    time_parking_ignition_on:                          "Time parking with ignition on",
    time_parking_ignition_off:                         "Time parking with ignition off",
    time_stop_in_geo:                                  "Time stop in geo",
    time_of_entry_in_geo:                              "Time of entry in geo",
    time_of_exit_in_geo:                               "Time of exit in geo",
    spent_fuel_in_geo:                                 "Spent fuel in geo",
    fuel_consumtion_in_geo:                            "Fuel consumption in geo",
    distance_about_prev_entering:                      "Distance about prev entering",
    distance_traveled:                                 "distance traveled",
    geofence:                                          "geofence",
    stop_parking:                                      "Stop/Parking",
    check_order_display:                               "Check the order of the parameters in the table and change the columns in places, if necessary.",
    over_cursor_of_interest:                           "Hover over the column you are interested in and move it to a different location.",
    create_report:                                     "Create report",
    pull_to_move:                                      "Pull to move",
    your_report_was_created:                           "Your_report_was_created!",
    now_you_can_choose:                                "Now you can choose him in field  ",
    type_of_report:                                    "“Type of report”",
    save_pdf:                                          "Save as PDF",
    print:                                             "Print",
    save_xlsx:                                         "Save as XLSX",
    save_csv:                                          "Save as CSV",
    no_data_for_period:                                "No data for the selected period",
    try_other_period:                                  "Try to choose other dates.",
    whole_period:                                      "For the whole period",
    period:                                            "Period",
    time_in_point:                                     "Time in point",
    optional:                                          "optional",
    repeatings:                                        "Repeatings",
    date_of_startTask:                                 (window.outerWidth >= 375) ? "Date of start task" : "Date of start ",
    no:                                                "no",
    before:                                            "before",
    task_created_succesfull:                           "Task created successful!",
    orienting_resource_consumption:                    "Orienting resource consumption",
    without_taking_in_geo:                             "without taking into account the work in the geofence and parking in points",
    duration_of_route:                                 "Duration of the route",
    fuel_consumption:                                  "Fuel consumption",
    duration:                                          "Duration",
    edit:                                              "Edit",
    adresses:                                          "Addresses",
    geofences:                                         "geofences",
    search_by_addresses:                               "Search by addresses",
    search_by_geofences:                               "Search by geofence",
    you_dont_have_saved_addresses:                     "You have not saved addresses yet.",
    add_often_used_addres:                             "Add addresses what you use often, using menu of right",
    you_dont_have_saved_geofences:                     "You have not saved geofence yet.",
    add_geofences_by:                                  "Add geofence, using menu of right",
    add_address:                                       "Add address",
    add_geofence:                                      "Add geofence",
    new_addres:                                        "New address",
    new_geo:                                           "New geofence",
    enter_address:                                     "Enter address",
    denotation:                                        'Denotation',
    this_address:                                      'this address',
    this_geofence:                                     'this geofence',
    addres_or_object:                                  'Can find address or object',
    circle:                                            "Circle",
    polygon:                                           "Polygon",
    color:                                             "Color",
    default:                                           "Default",
    city:                                              "City",
    cornfield:                                         "Cornfield",
    type_geofence:                                     "Type of geofence",
    search_by_templates:                               "Search by templates",
    you_dont_have_saved_templates:                     "You don't have saved templates",
    add_templates:                                     'For add template use button  "give task"',
    hide:                                              "Hide",
    show:                                              "Show",
    points:                                            {
    one:                                               "point",
    i:                                                 "points",
    ek:                                                "points",
    },
    decline_min:                                       {
    one:                                               "min.",
    i:                                                 "min.",
    ek:                                                "min.",
    },
    decline_hour:                                      {
    one:                                               "hour",
    i:                                                 "hours",
    ek:                                                'hours',
    },
    copying:                                           "Сopying",
    sure_copy_template:                                "Are you sure want copy template?",
    copy:                                              'Сopy',
    repeat:                                            "Repeat",
    every_day:                                         "Every day",
    every_week:                                        "Every week",
    every_month:                                       "Every month",
    on_weekdays:                                       "On weekdays",
    update_name:                                       "Update name",
    no_active_tasks:                                   "No active tasks.",
    no_planned_tasks:                                  "No planned task.",
    no_completed_tasks:                                "No completed tasks.",
    create_task_for_vehicles:                          "Create the tasks for comfortable monitoring self vehicles.",
    template_was_created:                              "Template created succesfull!",
    template_was_edited:                               "Template edited succesfull!",
    column_is_required:                                "The column is required and cannot be removed or dragged",
    transport_search:                                  "transport search",
    for_your_request_nodata:                           "for your request no data",
    choose_geofence_or:                                'Choose geofence or',
    create_new:                                        'Сreate a new',
    forgot_pass:                                       "Forgot password?",
    help:                                              "Help",
    nothing_choosed:                                   "Nothing choosed",
    date_of_violation:                                 "Date of the violation",
    enter_address:                                     "Enter address",
    description_of_violation:                          "Description of the violation",
    save_violation:                                    "Save violation",
    adding_violation:                                  "Adding violation",
    been_blocked:                                      "Your account has been blocked",
    find_reason:                                       "Contact:",
    or_email:                                          "or using email",
    introduced:                                        "Introduced",
    noGpsPeriod:                                       "no gps data",
    noPowerPeriod:                                     "no power",
    "daily-traffic":                                   "Daily traffic",
    "logical-inputs":                                  'Logical  output',
    "stops-report":                                    "Report of stops",
    save_and_contiue:                                  "Save and contiue",
    allotted_show_place:                               "Allotted zone will be show on place avatar",
    "daily-fuel-level":                                "fuel levels for days",
    "daily_consumption_hundred_km":                    "Consumption for 100 km ",
    "daily_fuel_consumption":                          "Fuel consumed",
    "fuel_level_end":                                  "Level fuel on end of day",
    "fuel_level_start":                                "Level fuel on start of day",
    "fuelled_at_day":                                  "Scope fuelled",
    "de_fuelled_at_day":                               "Maybe fuel drained",
    "daily_ignition_on_duration":                      "Time with work ignition",
    "daily_consumption_hour":                          "Fuel consume on hour",
    "mileage_at_day":                                  "Mileage at day",
    'from':                                            "Date",
    "summary_de_fuelled":                              "Maybe fuel drained",
    "summary_fuel_spent":                              "Scope fuel consumed",
    "summary_fuelled":                                 "Scope fuel fuelled",
    "summary_mileage":                                 "Summary mileage",
    "ignition_on_duration":                            "Summary time with work ignition",
    delta:                                             "Duration",
    dist:                                              "Distance from previous stay",
    time_begin:                                        "Start",
    time_end:                                          "End",
    arrival_time:                                      "Arrival time",
    departure_time:                                    "Departure time",
    complete_prev_step:                                "Complete the previous step",
    choose_notif:                                      "Choose notification what you want getting",
    no_valid_name:                                     'Allowed are Cyrillic and Latin characters, more two symbols',
    validationResult:                                  'Required are all field with *',
    no_valid_telegram:                                 'Your login failed verification ',
    no_valid_email:                                    'You email address failed verification',
    no_valid_phone_number:                             'The number should be at 12 digits (international format), for example +380582458919',
    textTelegramSettings:                              "For receive notifications in telegram set login yours telegram and allow notifications.",
    youCanhere:                                        "You can do it here",
    no_events:                                         "no events",
    edit_geo:                                          'Edit geofence',
    admonition:                                        "Admonition",
    not_complete_edit:                                 "You are not completed editing of geofence",
    continue:                                          'Continue',
    resume_edit:                                       'Resume edit',
    Parking:                                           "Parking",
    "zones-device-count":                              'Quantity device in zones',
    "zones-passage":                                   'Vehicle intersection zones',
    "stops-in-zones":                                  'Stops in zones',
    "stops-without-zones":                             'Stops outside zones',
    "zones-stops-and-work":                            'Time work in zones and work outside zones',
    "mileage-by-zone-types":                           'Mileage by zone types',
    prev_cur_zone_entry_duration:                      'Time out of zone',
    zone_entry_time:                                   'Time of entry into zone',
    zone_exit_time:                                    'Time of entry from zone',
    zone_name:                                         'Name of zone',
    "exit-out-of-zones":                               "Exit out of zones",
    movement_start:                                    'Movement start',
    movement_stop:                                     'Movement stop',
    time_of_working:                                   'Time of working',
    time_of_stops_in_zones:                            'Time of stops in_zones',
    time_of_stops_without_zones:                       'Time of stops without zones',
    serial_numbers:                                    'Serial numbers',
    summary_duration_out_zones:                        'Summary duration out zones',
    summary_mileage_out_zones:                         'Summary mileage outside of the zones',
    daily_mileage:                                     'Daily mileage',
    daily_zone_type_fuel_consumption:                  'Daily fuel consumption by zone type',
    daily_zone_type_mileage:                           'Daily mileage by zone type',
    zone_type:                                         'Type of zone',
    vehicle_name:                                      'Vehicle name',
    zone_exits_number:                                 'Count of out from zones',
    zones_vehicle_was_in:                              'Zone were was vehicle',
    vehicle_zone_exit_count:                           'Count of zone by vehicles',
    days_number:                                       'Count of days no exits',
    zones_with_vehicles:                               'Zones with vehicles',
    error_autorization:                                'Please check your login/password',
    stop_address:                                      "Stop address",
    longitude:                                         "Longitude",
    latitude:                                          "Latitude",
    zone_titles:                                       "Titles of zones",
    zone_ids:                                          "Ids zone",
    sound_signal:                                      "Sound of signal",
    didnt_have_vehicle:                                "You are don't have vehicles",
    youDidntsetOldPass:                                "you didn't set old password",
    passwords_do_not_match:                            "Passwords do not match",
    password_must_be:                                  "Password must be more than 7 characters and have at least three digits",
    newPassMustBe:                                     "New pass must be more than 7 characters and have at least three digits",
    yourPassWasUpdated:                                "Your password was updated",
    checkYourOldPass:                                  "Check your old password",
    horizontal_orientation_unavailable:                     "Horizontal orientation unavailable",
    available_options:                                 "Available options",
    "daily-fuel":                                      "Report of fuel",
    "zone-report":                                     "Report of zones",
    no_gps_data:                                       "No GPS",
    maximal_speed:                                     "Maximum speed",
    no_power:                                          "No power supply",
    consumption_amount:                                "Consumption amount fuel",
    fuelling_amount:                                   "Fuelling amount",
    consumption_per_hundred_km:                        "Consumption per hundred km",
    stops_in_zones_duration:                           "Duration stops in zones",
    level_at_start:                                    "Fuel level at the start",
    level_at_end:                                      "Fuel level at the end",
    defuelling_amount:                                 "Defueling",
    stops_without_zones_duration:                      "Duration  stops outside of zones",
    fueling:                                           "Fueling",
    defueling:                                         "Defueling",
    new_group:                                         'New group',
    you_dont_have_group:                               'You do not have group.',
    create_group_for_comfort:                          'Create groups of vehicles for more convenient use of the system.',
    asign_to_other_transport:                          "Assign to other transport",
    make_template:                                     "Make a template",
    print:                                             "Print",
    next:                                              "Next",
    cancel:                                            "Cancel",
    last_time_data:                                    "Last data",
    failed:                                            "Failed",
    no_failed_tasks:                                   "No failed tasks",
    after:                                             'After',
    number_device:                                     "Number of device",
    date_setup:                                        "Date of setup",
    date_disas:                                        "Date of disassembly",
    square:                                            "Square",
    hectares:                                          "ha",
    road:                                              "Road",
    enter_dates:                                       "Enter dates",
    maximum_speed:                                     "Maximum speed for check fuelings / drain",
    "m/s":                                             'M/S',
    maximum_amount:                                    'Maximum amount',
    liter:                                             'L',
    maximum_duration:                                  'Maximum duration return fuel on jumps',
    s:                                                 'S',
    tank_volume:                                       'Tank volume',
    fuel_allowable_fluctuation:                        'Allowable fuel fluctuation',
    minimal_mileage:                                   'Minimal mileage',
    minimal_stop_time:                                 'Maximum jump time of short-term fuel',
    average_fuel_consumption:                          'Average fuel consumption per 100 km',
    maximum_allowed_speed:                             "Maximum allowed speed",
    period1:                                           'Transmission data with enabled ignition',
    period2:                                           'Transmission data with disabled ignition ',
    "mileage-by-zones":                                'Mileage by zones',
    over_speed:                                        "Overspeeding",
    over_speed_by_zone_type:                           "Overspeeding by zone types",
    area_zones:                                        "Area zones",
    ignition_on_parking_time:                          "Ignition On parking time",
    "fuel-level":                                      "Fuel level",
    DAILY_FUEL_COLUMN_LEVEL_AT_START:                  "Level at start",
    FUEL_CONSUMPTION_PER_HOUR:                         "Consumption per hour",
    DAILY_IGNITION_ON_DURATION:                        'Duration of On ignition',
    DAILY_FUEL_COLUMN_DEFUELLING_AMOUNT:               'Defueling amount',
    DAILY_FUEL_COLUMN_LEVEL_AT_END:                    'Level at end',
    DAILY_TRAFFIC_COLUMN_MILEAGE:                      'Mileage',
    DAILY_FUEL_COLUMN_CONSUMPTION_AMOUNT:              'Amount of consumption',
    DAILY_FUEL_COLUMN_CONSUMPTION_PER_HUNDRED_KM:      'Consumption per hundred km',
    DAILY_FUEL_COLUMN_FUELLING_AMOUNT:                 'Fuelling amount',
    ZONE_REPORT_COLUMN_FUELED_CONSUMED_IN_ZONES:       'Consumption fuel in zones',
    ZONE_REPORT_COLUMN_MILEAGE_IN_ZONES:               'Mileage in zones',
    ZONE_SQUARE_REPORT_COLUMN:                         'Square of zone',
    PARKING_WITH_IGNITION_ON_DURATION_COLUMN:          'Duration of parking with ignition On',
    DAILY_TRAFFIC_COLUMN_TIME_IN_WAY:                  'Time in way',
    DAILY_TRAFFIC_COLUMN_START_MOTION:                 'Start of motion',
    DAILY_TRAFFIC_COLUMN_MAXIMAL_SPEED:                'Maximal speed',
    DAILY_TRAFFIC_COLUMN_NO_POWER:                     'No power',
    DAILY_TRAFFIC_COLUMN_END_MOTION:                   'End motion',
    DAILY_TRAFFIC_COLUMN_NO_GPS_DATA:                  "No GPS",
    SPEEDING_AMOUNT_REPORT_COLUMN:                     'Speeding amount',
    SPEEDING_DURATION_REPORT_COLUMN:                   'Speeding duration',
    SPEEDING_TIME_WITH_SPEED_REPORT_COLUMN:            'Duration of speeding',
    ZONE_REPORT_SPEEDING_DURATION_COLUMN:              'Speeding duration',
    ZONE_REPORT_SPEEDING_TIME_WITH_SPEED_COLUMN:       'Duration of speeding',
    ZONE_REPORT_SPEEDING_AMOUNT_COLUMN:                'Speeding amount',
    TimeoutForDevice:                                  'Vehicle under the guard',
    set_on_guard:                                      "Set vehicle on guard",
    disarm:                                            "Disarm the vehicle",
    guard_settings:                                    "Vehicle guard",
    stay_on_quard_from:                                "On guard from date",
    DAILY_TRAFFIC_COLUMN_START_MOTION:                 "Start of motion",
    DAILY_TRAFFIC_COLUMN_END_MOTION:                   "End of motion",
    DAILY_TRAFFIC_COLUMN_MILEAGE:                      "Mileage",
    DAILY_TRAFFIC_COLUMN_TIME_IN_WAY:                  "Time in way",
    DAILY_TRAFFIC_COLUMN_NO_GPS_DATA:                  "No GPS data",
    DAILY_TRAFFIC_COLUMN_NO_POWER:                     "No power",
    DAILY_TRAFFIC_COLUMN_MAXIMAL_SPEED:                "Max speed",
    DAILY_FUEL_COLUMN_CONSUMPTION_AMOUNT:              "Fuel consumption",
    ZONE_REPORT_COLUMN_MILEAGE_IN_ZONES:               "Mileage in zones",
    DAILY_TRAFFIC_COLUMN_MILEAGE:                      "All mileage",
    ZONE_REPORT_COLUMN_FUELED_CONSUMED_IN_ZONES:       "Fuel consumption in zones",
    ZONE_REPORT_COLUMN_MILEAGE_IN_ZONES:               "Mileage in zones",
    DAILY_TRAFFIC_COLUMN_MILEAGE:                      "All mileage",
    ZONE_REPORT_COLUMN_FUELED_CONSUMED_IN_ZONES:       "Fuel consumed in zones",
    SPEEDING_AMOUNT_REPORT_COLUMN:                     "Speeding amount",
    SPEEDING_DURATION_REPORT_COLUMN:                   "Duration of speeding",
    SPEEDING_TIME_WITH_SPEED_REPORT_COLUMN:            "Time with speeding",
    ZONE_REPORT_SPEEDING_AMOUNT_COLUMN:                "Speeding amount in zones",
    ZONE_REPORT_SPEEDING_DURATION_COLUMN:              "Duration of speeding in zones",
    ZONE_REPORT_SPEEDING_TIME_WITH_SPEED_COLUMN:       "Time with speeding in zones",
    ZONE_SQUARE_REPORT_COLUMN:                         "Square of zones",
    PARKING_WITH_IGNITION_ON_DURATION_COLUMN:          "Duration of parking with ignition On ",
    DAILY_FUEL_COLUMN_LEVEL_AT_START:                  "Fuel level at start of day",
    DAILY_FUEL_COLUMN_FUELLING_AMOUNT:                 "Amount of fueling",
    DAILY_FUEL_COLUMN_DEFUELLING_AMOUNT:               "Amount defueling",
    DAILY_TRAFFIC_COLUMN_MILEAGE:                      "Mileage",
    DAILY_FUEL_COLUMN_CONSUMPTION_AMOUNT:              "Amount fuel consumption",
    DAILY_FUEL_COLUMN_CONSUMPTION_PER_HUNDRED_KM:      "Amount fuel on hundred km",
    DAILY_FUEL_COLUMN_LEVEL_AT_END:                    "Fuel level on end of day",
    FUEL_CONSUMPTION_PER_HOUR:                         "Amont fuel in hour",
    DAILY_IGNITION_ON_DURATION:                        "Time with ignition On",
    CONSTANT_TIME_WHEN_FUEL_LEVEL_CHANGED:             'Time when changing fuel level',
    CONSTANT_FUEL_LEVEL_DIFFERENCE:                    "What fuel level difference",
    CONSTANT_FUEL_LEVEL_BEFORE_CHANGES:                "Level before changing",
    CONSTANT_MILEAGE_AFTER_PREVIOUS_CHANGE:            "Mileage after previous change",
    CONSTANT_FUEL_CONSUMED:                            "Fuel consumed",
    CONSTANT_CONSUMPTION_PER_HUNDRED_KM:               "Cosumtion per hundred km",
    CONSTANT_STOPS_BEGIN_COLUMN:                       "Begin of parking",
    CONSTANT_STOPS_DURATION_COLUMN:                    "Duration of parking",
    CONSTANT_STOPS_DISTANCE_FROM_PREVIOUS_COLUMN:      "Milleage between parking",
    CONSTANT_STOPS_ADDRESS_COLUMN:                     "Stops addresses",
    CONSTANT_STOPS_CURRENT_ZONE:                       "Current zone",
    CONSTANT_STOPS_SUMMARY_DISTANCE:                   "Summary distance by all stops",
    DRIVERS_WORK_START_COLUMN:                         "Time start of works",
    DRIVERS_WORK_STOP_COLUMN:                          "Time end of works",
    DRIVERS_WORK_DURATION_COLUMN:                      "Work time of driver",
    DRIVERS_WORK_SERIAL_ID_COLUMN:                     "Serial number id cards",
    TRAILERS_WORK_DURATION_COLUMN:                     "Duration trailer works",
    TRAILERS_WORK_SERIAL_ID_COLUMN:                    "Serial number of trailer",
    TRAILERS_WORK_START_COLUMN:                        "Time start of works",
    TRAILERS_WORK_STOP_COLUMN:                         "Time end of works",
    TIME_OF_GETTING_LAST_DATA_DEVICE_LAST_DATA_COLUMN: "Last data",
    TIME_OF_GETTING_LAST_DATA_DEVICE_ID_COLUMN:        "Device id",
    SIGNALING_ALARM:                                   "Signalization:",
    was_alarm_locked:                                  "Was enabled alarm, vehicle unblocked in",
    alarmDrawdown:                                     "SOS signal triggered",
    lockedAlarmTrigger:                                "Triggering locked alarm",
    noSignalInSecurity:                                "Missing signal",
    app_notif:                                         "Notifications in application",
    ignition_always_on:                                "Ignition always on",
    control_output:                                    "Control_output",
    no_data_parameters:                                "No data about parameters",
    inverse:                                           "Inverse",
    trailer:                                           "Trailer",
    deviceInNumber:                                    "Input number",
    notifyMileageCounter:                              "Notifications counter by mileage",
    notifyWorkingDaysCounter:                          "Notifications counter by days",
    notifyEngineHoursCounter:                          "Notifications counter by moto hours",
    isNotifyCanCounter:                                "Notifications counter moto hours by CAN",
    EngineHours:                                       "Engine hours",
    WorkingDays:                                       "Counter by days",
    not_connected:                                     'Not connected',
    movement:                                          'Movement',
    parking:                                           'Parking',
};

const routeDetailsPage = {
    volume:                   'volume',
    year:                     'year',
    days:                     'days',
    month:                    'month',
    hour:                     'h',
    min:                      'min',
    second:                   'seconds',
    consider_ignition:        'Consider ignition',
    ignore_ignition:          'Ignore ignition',
    haversin:                 'Haversin',
    filtered_fuel_events:     'Fuel filter events',
    do_not_smooth:            'Do not smooth',
    do_smooth:                'Do smooth',
    show_pure_data:           'Show clean data',
    hide_pure_data:           'Hide clean data',
    show_raw_data:            'Show RAW',
    hide_raw_data:            'Hide RAW',
    ruler:                    'Ruler',
    link_copied_to_clipboard: 'Link copied to clipboard',
    validity:                 'Credibility',
    Smoothed:                 'Smoothed',
    unfiltered:               'Unfiltered',
    unfiltered_data:          'Unfiltered',
    only_valid_data:          'Only valid data',
    table_view:               'Table mode',
    focus_on_vehicle_marker:  'Follow the car',

};


const transportProfile = {
    counter:           'Counter',

    create_counter:    'Creating a counter',
    edit_counter:      'Editing the counter',
    counter_name:      'Counter name',
    counter_type:      'Counter type',
    start_value:       'Initial value',
    threshold:          'Threshold',
    left:              'Left',
    created:           'Created',

    are_you_sure_you_want_to_delete_the_trigger: 'Are you sure you want to delete the trigger?',
    trigger_creatting:                           'Creating a trigger',
    trigger_editing:                             'Editing the trigger',
    trigger_name:                                'Name',
    type:                                        'Type',
    logic_input:                                 'Logic input',
    condition:                                   'Condition',
    enabled:                                     'Enabled',
    disabled:                                    'Disabled',
    available:                                   'Available',
    notification_text:                           'Notification text',
    more_than:                                   'More Than',
    less_than:                                   'Less Than',
    quals:                                       'Quals',
    remaining_to_work: 'Until it works',
    will_work_when_value: 'Will work when the sensor value',
    will_work_when:         'Will work when',
    creating_control_zone:  'Creating a control zone',
    editing_control_zone:   'Editing zone',
    choose_zone:            'Choose a zone',
}

const rfidCardsPage = {
    phone_number:                                       'Phone number',
    enter_number_or_driver_name:                        'Enter driver number or name',
    add_card:                                           'Add a card',
    update_card:                                        'Refresh map',
    delete_card:                                        'Delete card',
    add_new:                                            'add new',
    here_will_bew_card_info:                            'Information about the card will be displayed here',
    please_select_card_or:                              'Please select a card from the list on the left or',
    driver_name:                                        'Driver name',
    are_you_sure_want_remove_card:                      'Are you sure you want to remove the card?',
    editing_rfid_card:                                  'RFID card editing',
    creating_rfid_card:                                 'RFID card creation',
};

const infoGraphicControl = {
    CONNECTED: 'In touch',
    DISCONNECTED: 'Disabled',
    NO_DATA: 'There is no data',
    NO_DEVICE: 'No device',
    coordinates_absent: 'No coordinates',
    data_reception_disabled: 'Data reception disabled',
    parking_with_ignition_on: "Parking with the ignition on",
};


const hints = {
pressToChooseOnVehicle:                             "Click to choose the vehicle you want the report to be build for. You can also use this field to search for a certain vehicle or a vehicle group.",
closePopup:                                         "Close the pop-up",
vehicle_stopped:                                    "Vehicle stopped",
selected_vehicle:                                   "Vehicle is selected",
vehicle_moving:                                     "Vehicle moving",
vehicle_not_connected:                              "Vehicle not connected",
marker_hint:                                        "Marker indicate the vehicle position and direction of move.",
clickOnMarker:                                      "Click on the marker opens a window with information on the car",
event_what_happened_today:                          "Today`s vehicle events",
on_hover_visible_counter:                           "if you place the cursor over the icon you will see total events number",
parking_vs_engintOn:                                "Parking with ignition on for more than 5 minutes",
fuelling:                                           "Fueling",
fuel_drain:                                         "Estimated fuel drain",
on_hover_visible_event_date:                        "When you hover, the time of the event is visible",
vehicle_is_on_quard:                                "Security mode on",
quard_is_triggered:                                 "Alarm triggered",
on_hover_visible_event_date:                        "if cursor placed over the icon you will see the event date information",
info_about_curent_status:                           "Vehicle status information",
antena_is_ok:                                       "Antenna is connected",
end_education:                                      "Finish training",
antena_icons:                                       "Information on when the latest data was received",
click_on_star:                                      'Clicking on an star allows you to pin an element at the top of the list so that it is easier to find',
click_on_one_of_list:                               "Click any vehicle from the list (empty space)",
desc_selected_vehicle:                              "Now this vehicle is in the «selected mode» and:",
if_vehicle_has_coordinates:                         "if selected vehicle has device coordinates, the map shifted to the vehicle marker",
if_veicle_move:                                     "if the vehicle is moving, the map follows it",
also_available_btns:                                "also became available buttons: 'route planning' and 'problem statement'",
click_by_this_btn_call_hint:                        "Clicking on this button causes training by page elements",
navigation_by_vehicle_settings:                     "Vehicle settings for notifications and reports navigation",
vehicleId_in_our_system:                            "Vehicle id inside our system",
deviceId:                                           "Installed on the vehicle device serial number ",
settings_for_notif:                                 "Default parameters and transmission periods",
desc_counters:                                      "Counter settings. For example, you can set the number of machine hours and receive a notification when it will be worked out.",
desc_handling:                                      "Security mode on / off and USSD-requests sending",
desc_digitalInputs:                                 "Digital outputs settings",
desc_control_of_sensor:                             "Trigger settings and creating triggers to receive sensor value notifications. For example, you can set the condition such as: «If fuel level sensor value is below 20, send me the following notification … »",
desc_control_in_zones:                              "Entering and leaving zone notifications management",
click_for_workWithCroup:                            "Click to open vehicle groups menu",
onclick_inList_left:                                "When you click in the list of vehicles, only the vehicle that belongs to this group will remain",
this_addNewGroup:                                   "Click to create a new group",
click_on_marker:                                    "Click event icon to hide not interesting for you events type in the vehicle list. If the cursor looks disabled, it means there are no such event type notifications inside system.",
search_field:                                       'Search field to find a vehicle in the list',
click_on_btn_alow:                                  'Click the button to show or hide the vehicle list',
click_on_star:                                      "Click on star allow fix element on top of list for quickly search him",
click_btn_showRoute:                                'On click to the button you can building route of vehicle',
click_btn_setTask:                                  'On click to the button you can set task for vehicle',
click_nameOfVehicle:                                'Click vehicle name to open vehicle profile with its settings',
here_you_can_look:                                  "Here you can see the statistics, and also configure the sensors on your car",
in_this_block:                                      "In this block, the events that occurred with the car for today are displayed. If you move the cursor over the icon, you can see its name",
antena_icons:                                       "The badge informs you about vehicle antenna status. The crossed out icon means there are some problems with vehicle antenna.",
info_about_last_data:                               "Last data from vehicle date information",
click_to_workWithCroup:                             "Click to work with transport groups",
here_can_edit_name:                                 "Here you can edit the group name",
vehicle_of_groupCheckbox:                           "Transport which belongs to the group is marked with a check mark. Clicking on the transport can add it to the group or exclude it.",
search_by_transport:                                "Search field will help you find the vehicle you are interested in",
button_instructions:                                'Clicking on the "Save" button allows you to save the changes made in the group, "Cancel" - cancel changes, "Delete group" - delete the existing group, transport will not go anywhere',
info_about_curent:                                  "Information about the vehicle current state, ignition, fuel level, etc.",
button_view_ofMap:                                  "Buttons allow you to select the appearance of the map",
marker_of_place:                                    "Indicates the location of the transport, the blue marker indicates that this machine is active, when hovering over the marker, a hint with the machine name appears. To see more information click on the marker",
pressToChoose:                                      'Click to select report type',
also_you_can_call:                                  'You can also create your own report with the report constructor',
pressToChoose:                                      'Click to choose the report type',
pressToChooseThisVehicle:                           "Click to select the vehicle. Already selected vehicles are in bold type.",
forDeletePress_x:                                   "Click the cross to remove vehicle from the list.",
date_start_of_period:                               "Date start of the period, you can enter as from the keyboard, in dd.mm.year format, or by clicking on the icon to display the calendar",
timeReportFrom:                                     "You can input the period the report will be built for by yourself in format 20:00 or select it from the list",
buttonWillActiveAfter:                              "The button is active if all the required fields are filled in. Click it to update the report",
buttonAlloswChangeSize:                             "Click the button to change the report display area size",
buttonsForExport:                                   "Buttons for exporting to different formats",
drawNameOfReport:                                   'Type the report name (this field is required)',
chooseFieldWhatWillInRep:                           'Select the fields to be displayed in the report',
pressForSetting:                                    'Click to setup the report columns order',
pinchMouse:                                         'Hold the mouse pointer and drag the column to the desired location',
pressForCreate:                                     'Click to create your personal report',
holdMouseButton:                                    'Hold the mouse pointer and drag the column to the desired location',
clickForCreateCustomRep:                            'Click to create your personal report',
clickAddEmployee:                                   'Click to add an employee',
listOfYourEmployee:                                 'List of yours employees',
clickForChooseEmploye:                              'Click to choose employee',
onMapMarkedCurrent:                                 'The current location of the employee is indicated on the map',
clickOnElement:                                     'Clicking on this element will cause a popup where you can edit the current information',
clickOnElementAlowDelete:                           'Click on an item to delete an employee',
here_can_change_period:                             "Here you can choose the period a route with the chart to be build for",
click_for_showTable:                                'Click the button to include or exclude information to / from the chart',
sensrosButtons_block:                               'You can see all available on the vehicle sensors inside of this block. Place the cursor over the sensor icon to see its name.',
addGraph:                                           'Click the button to see all the vehicle information in a table form',
graph_triger:                                       'Hold left mouse button on the trigger to see information about vehicle at certain chart point. The trigger is movable by mouse and by left / right keyboard buttons',
here_valueSensors:                                  'Here, the readings of the sensors are displayed at the time which is determined by the position of the trigger',
time_format:                                        'Timeline in 24-hour format',
line_of_progress:                                   'The progress line fits the timeline and displays the task execution and vehicle route events. Event duration is highleted by its icon color. Click event icon to move marker to the map point where it occured.',
marker_show_currentPlace:                           'Vehicle marker, shows vehicle position on map and its movement direction',
blue_color_route:                                   'The vehicle route for chosen time period is drawn on the map in blue. Click the waypoint to move the trigger or the chosen table row to the according timestamp - it will allow you to see event time and vehicle data at this point.',
zoom_instruction:                                   'Use this control element to change data chart scale. You can change chart scale by using slider, pressing + / - buttons or scrolling the mouse wheel while it focused on the graph.',
startTask_instr:                                    "Decide on the task, if this task will be periodically turned around, then choose a template. If the task is for one time, then select a one-time job",
available_vehicle_instr:                            "Clicking on this item will display a list of available transport",
button_for_name:                                    "Click on the radio button to fill out the template name",
name_is_required:                                   "The name is a required field, please fill in to proceed to the next step.",
pressToNextStep:                                    "Click to go to the next step",
control_points_instr:                               "The route can contain both control points and geofences",
clickForList:                                       "Click on an item to list your list of geofences",
start_enterAdress_instr:                            "Start typing the name of the address so that prompts appear. Then press Tab",
forChooseAddres:                                    "To select a hint, use ↑ ↓ to select an address, press Enter, the selected address will be marked on the map with a marker",
clickForCopy:                                       "Click to copy to clipboard",
clickToClear:                                       "Click to clear",
clickToAdd:                                         "Click to add another address",
clickToDelete:                                      "Button allows you to delete an address from a route",
afterClick_instr:                                   "After clicking the button, the route is built and the next step is taken, note that in order to build a route, a minimum of 2 points",
understand:                                         "I understand!",
enterDate_instr:                                    "Enter the start date of the job in dd.mm.year format or select from the calendar to invoke the icon in the corner of the image",
listControlPoint:                                   "Route checkpoints list",
timeCheckpoint:                                     "The time to which the vehicle needs to come to a point, the system will take this time into account when tracking the task. You can enter the time in the 24-hour format (hh: mm) or select it from the list",
ifThenYouCanChange:                                 "If you do not like the optimal route laid by the system, then you can edit it by changing the choice of the road between the points. To do this, hold down the left mouse button on the road section, and then by dragging, change the route",
clickToReturn:                                      'Clicking on this button will allow you to go back one step',
clickToNextStep:                                    'Press to go to the next step',
pressToSaveTemplate:                                "Click to save template",
pressToDrawOnMap:                                   "Click on the template so that the route of the template was drawn on the map",
clickToView:                                        "Click to view full information about the template",
clickToSetTask:                                     "Click to put transport this template in the task",
clickToDeleteTemplete:                              "Click to delete template",
clickToCopyTemplate:                                "Click to copy the template",
clickToViwAllLIst:                                  "Click to see the full list of points",
clickToViewPointOnMap:                              "Click to see where the point is on the map",
clickToEdit:                                        "Click to go to edit",
clictToGeoDraw:                                     "Click on the name so that the geofence is drawn on the map",
clickToDeleteElem:                                  "Click to delete this item",
enterAddresForMove:                                 "Enter the address for the card to move to the specified address",
chooseTypeColor:                                    "Select the type of circle and its color, then by clicking on the map you will be able to build the figure",
whitePoints:                                        "White dots are the control elements of the figure, that in the center of the figure allows you to change its location, the rest to change its size or shape.The button next to the figure with the arrow allows you to undo the last",
hereCalcSquare:                                     "It calculates the area of the figure in Ga",
deleteBtnGeo:                                       "If you are somewhere wrong and want to start over, this button you can delete the figure",
fillNameGeo:                                        "Fill in the name of the geofence by which you will later find it, the field is mandatory",
clickToSaveGeo:                                     "Click to save geofence",
};

const errorTexts = {
    something_went_wrong:                               'Something went wrong',
    message_sent:                                       'Message sent successfully',
    request_limit_exceeded:                             "Request limit exceeded, please try again in a minute",
    notify_support:                                     'Notify Support',
    not_enough_data_to_build_geofence:                  'Not enough data for building a geofence',
    only_file_types_allowed:                            'Only zip / kmz or kml files are allowed',
    is_wrong:                                           'is wrong',
    Account:                                            'Account ',
    absent_in_gmail:                                    ' absent in gmail',
    rfidCard_number:                                    'RFID сard number ',
    already_exists:                                     ' already exists',
    expiry_date:                                        'Expiry date: ',
    cant_be_in_the_past:                                ' can not be in the past',
    vehicle_with_id:                                    'Vehicle with id: ',
    belongs_to_the_group_already:                       'belongs to the group already',
    does_not_belong_to_the_group:                       'does not belong to the group',
    No_required_parameter_GeoCoding:                    "No required parameter - Geo coding",
    geoCoding_cant_be_null:                             'Geo coding cant be null',
    equipmentWidth_cant_be_null:                        'Equipment width can not be a null',
    longerThen_cant_be_null:                            '"Longer then" cant be null ',
    ZoneType_cant_be_null:                              'Zone type can not be a null ',
    not_exist:                                          ' not exist',
    zone_type_with_id:                                  'Zone type with id ',
    Impossible_create_zone_from_square_with_coordinate: 'Impossible create zone from square with coordinate < 1',
    not_exist_device_in_with_id:                        'Not exist device in with id: ',
    tankVolumeStart_cant_be_more_tankVolume:            'Tank volume on start can not be more then tank volume',
    fuel_volume_exceeded_tank_volume:                   'Fuel volume exceeded tank volume',
    Cant_find_FUEL_LEVEL_BEFORE_EVENT:                  "Can't to find FUEL LEVEL BEFORE EVENT, because tank volume start can't be determine!",
    Rfid_card_can_not_be_empty:                         'RFID card can not be empty',
    Rfid_card_id_can_not_be_null:                       'RFID card id can not be a null',
    No_actual_device_mapping_for_control_entity:        'The tracker is not installed on the car in the requested period',
    Current_vehicl_with_id:                             'Current vehicl with id: ',
    does_not_have_current_device_mapping:               'Does not have current device mapping',
    Vehicle_has_incorrect_width:                        'Vehicle has incorrect width',
    trailer_serial_allready_exist:                      'A trailer with this serial number is already in the system',
    user_with_such_login_already_exists:                'User with such login already exists',
    impossible_get_square_zone_from_coordinates:        'Impossible get square zone from coordinates: points <=1',
    absent_data_for_report:                             'Missing information for report',
    There_is_no_information_from_the_fuel_sensor:       'There is no information from the fuel sensor',
    The_tracker_is_missing_on_the_car:                  'The tracker is missing on the car',

};

const lostedWords = {
    scheduled: 'scheduled',
    scheduled_job: 'scheduled job',
    scheduled_for: 'scheduled_for',

    list_of_scheduled_jobs_is_empty: 'list of scheduled jobs is empty',
    Tuesday: 'Tuesday'
};


const words = Object.assign({},
    globalVocabulary,
    hints,
    errorTexts,
    rfidCardsPage,
    infoGraphicControl,
    routeWords,
    routeDetailsPage,
    lostedWords,
    transportProfile,
)
export const subCatEN = {
    globalVocabulary: globalVocabulary,
    hints,
    errorTexts,
    rfidCardsPage,
    infoGraphicControl,
    routeWords,
    routeDetailsPage,
    lostedWords,
    transportProfile,
}
export default words
