const domainKey = "domainDEV";

function getDomainKey()    {
	if(DEVELOPMENT)   return 'domainPROD';//comment it if want DEV server
	if(DEVELOPMENT)   return 'domainDEV';
	if(DEV_SERVER)    return 'domainDEV';
	if(MASTER_SERVER) return 'domainMASTER';
	if(PROD_SERVER)   return 'domainPROD';
	if(TEST_SERVER)	return 'domainTEST'
}
const domainSettings = {
	domainDEV:    "k8t-api.elsyton.com",//"k8t-api.elsyton.com",//"dev.elsyton.com",
	domainMASTER: "gl21.elsyton.com",
	domainPROD:   "pro-api.elsyton.com",
	domainTEST:   "pro-api.elsyton.com"
}
const urlsAdminPanels = {
	domainDEV:    'https://dev-admin.skt-globus.com.ua',//'https://dev-admin.skt-globus.com.ua/',
	domainMASTER: 'https://admctl.skt-globus.com.ua',
	domainPROD:   'https://proctl.skt-globus.com.ua',
	domainTEST:   'https://proctl.skt-globus.com.ua'
}
const telegramBotNames = {
	domainDEV:    "dev_control_test_bot",
	domainMASTER: "globus_control_bot",
	domainPROD:   "globus_sender_bot",
	domainTEST:   'dev_control_test_bot'
}
const initialState = {
	login:              getValue(localStorage.getItem("login")),
	kindUser:           getValue(localStorage.getItem("kindUser")),
	ownerLogin: 		getValue(localStorage.getItem("ownerLogin")),
	token:              getValue(localStorage.getItem("sid")),
	language:           getValue(localStorage.getItem("lang")) ? getValue(localStorage.getItem("lang")) : 'ru',
	user_permission:    getValue(localStorage.getItem("user_permission")),
	viewedLastUpdate:   getValue(localStorage.getItem("ReadStatusForLastUpdate")),
	user_info:          getValue(localStorage.getItem("profileSettings")),
	admin_panel_url:    urlsAdminPanels[domainKey],
	base_url:           "https://" + domainSettings[domainKey],
	websocket_url:      "wss://" + domainSettings[domainKey] + "/ws/v2/client",
	botLink:            "tg://telegram.me/" + telegramBotNames[domainKey],
	botName: 			telegramBotNames[domainKey],
	bonus_url_device:   "/api/device/v2/",
	bonus_url_api:      "/api/v2/",
	imagesPath:         "/images/",
	port_profile:       "", //8084  //not used now
	port_one:           "", //:8081  //not used now
	port_notif:         "", //:8084  //not used now
	socket:             undefined,
	messageStatus:      undefined,
	errorStatus:        undefined,
	message:            undefined,
	messageFromAdmin:   undefined,
	blocked:            false,
	urlbeforedep:       undefined,
	store:              "",
	saveSession:        "",
	happened:           undefined,
	okeyDanila:         true,
	triggerReload:      false,
	answerForUser:      "",
	text_notif:         null,
	messageAboutNetwork: null,
	notif_in_OS_enabled: getValue('notif_in_OS_enabled'),
}

function authorizationR(state = initialState, action) {
	switch (action.type) {
		case "FORM_AUTH_LOGIN_UPDATE":
			return {
				...state,
				login: action.setLogin
			};
		case "OWNER_LOGIN_UPDATE":
			return {
				...state,
				ownerLogin: action.setLogin
			};
		case "USER_INFO":
			return {
				...state,
				user_info: action.setUserInfo,
			};
		case "KOSTUL":
			return {
				...state,
				okeyDanila: action.setOkeyDanila
			};
		case "FORM_AUTH_FAILED":
			return {
				...state,
				answerForUser: action.answerForUser
			};
		case "RELOAD_FORM":
			return {
				...state,
				triggerReload: action.setTrigger
			};
		case "USER_PREMISSION":
			return {
				...state,
				user_permission: action.getpremission
			};
		case "SESSION_TOKEN":
			return {
				...state,
				token: action.savetoken
			};
		case "KIND_USER":
			return {
				...state,
				kindUser: action.savekindUser
			};
		case "MESSAGE_STATUS":
			return {
				...state,
				messageStatus: action.setstatus
			};
		case "ADMIN_MESSAGE":
			return {
				...state,
				messageFromAdmin: action.setAdminMessage
			};
		case "MESSAGE":
			return {
				...state,
				message: action.setMessage
			};
		case "HAPENS":
			return {
				...state,
				happened: action.setHappened,
			};
		case "URL_BEFORE_DEPORTATION":
			return {
				...state,
				urlbeforedep: action.setURL
			};
		case "START_SAVE_SESSION":
			return {
				...state,
				save_session: action.save,
			};
		case "SOUND_NOTIF":
			return {
				...state,
				sound_status: action.setSatatus,
			};
		case "NOTIFINPOPUP":
			return {
				...state,
				text_notif: action.setTextNotif,
			}
		case "WEBSOCKET":
			return {
				...state,
				socket: action.saveSocket
			};
		case "BLOCK":
			return {
				...state,
				blocked: action.saveStatusBlock
			};
		case "LANGUAGE_UPDATE":
			return {
				...state,
				language: action.setLanguage,
			};
		case "API_ERROR":
			return {
				...state,
				errorStatus: action.updateErrorStatus,
			}
		case "NOTIF_WHAT_IS_NEW":
			return {
				...state,
				viewedLastUpdate: action.setStatusAboutViewdLastUpdate,
			}
		case "SAVE_NOTIF_IN_OS": {
			localStorage.setItem("notif_in_OS_enabled", action.setSatatus);
			return {
				...state,
				notif_in_OS_enabled: action.setSatatus,
			}
		};
		case "NETWORK_PROBLEM":
			return {
				...state,
				messageAboutNetwork: action.setNetworkProblemMessage,
			}
		default:
			return state;
	}
}
authorizationR.reducer = "authorizationR";
export default authorizationR;

function tryParse(data) {
	let parsed;
	try {
		JSON.parse(data);
	} catch (e) {
		return false;
	}
	return true
}

export function getValue(data) {
	return tryParse(data) ? JSON.parse(data) : data
}
