import axios                       from 'axios'
import store                       from 'store/store.js'
import SL, { ifNoKey, tryStringify }   from 'sl-library'
import infoStore                   from './infoStore.js'
import authActions                 from './authActions.js'
import { sessionIsOver, isOrigin } from 'components/AppDoorMan.jsx'
import language_variant            from 'store/languages.jsx'



export default function bugReport(error, request, name) {
	// saveUrlBeforeDeportation();//I think it's doesn't matter
	const infoAboutError = collectInfoForMessageAboutError(error, request, name);
	const errorStatus = ifNoKey(infoAboutError, "status");
	if(errorStatus == 422 || errorStatus == 403){
		rulesForError_422_433(ifNoKey(infoAboutError.errorInfo, 'data'), errorStatus)
		return
	}
	if(errorStatus == 429){
		setNotifText(language_variant.request_limit_exceeded)
		return
	}
	if(!sessionIsOver(errorStatus)) {
		if(error == 'Error: Network Error' || errorStatus == 502) {
			if(!isOrigin()){
				authActions.setMessageNetworkProblem(language_variant.server_temporarily_unavailable);
			}
		} else {
			 (infoAboutError)
		}
	}else{
		!DEVELOPMENT ? authActions.logOut() : console.log("%c app want do logout", coCSS,)
	}
	setErrorStatus(errorStatus)
}


export function rulesForError_422_433(errorText) {

	if(typeof errorText == 'string'){
		const text = new getСoincidenceText(errorText);
		if(text.compareText("No gmail account for")){// "No gmail account for " + gmailAddress
			setNotifText(language_variant.Account + text.variable + language_variant.absent_in_gmail);
			return
		}
		if(text.compareText("rfidCard number:")){// "rfidCard number: " + getNumber + " already exist"
			setNotifText(language_variant.rfidCard_number + text.variable + language_variant.already_exists);
			return
		}
		if(text.compareText("Expiry date:")){// "Expiry date: " + getExpiryDate + " can'n be in past"
			setNotifText(language_variant.expiry_date + text.variable + language_variant.cant_be_in_the_past);
			return
		}
		if(text.compareText("Vehicle with id:", 'belongs to the group already')){// "Vehicle with id " + vehicleId + " belongs to the group already."
			setNotifText(language_variant.vehicle_with_id + text.variable + language_variant.belongs_to_the_group_already);
			return
		}
		if(text.compareText("Vehicle with id:", 'does not belong to the group')){// "Vehicle with id " + vehicleId + " does not belong to the group."
			setNotifText(language_variant.vehicle_with_id + text.variable + language_variant.does_not_belong_to_the_group);
			return
		}
		if(text.compareText("No required parameter GeoCoding")){// "No required parameter GeoCoding"
			setNotifText(language_variant.No_required_parameter_GeoCoding);
			return
		}
		if(text.compareText("geoCoding can not be null")){// "No required parameter GeoCoding"
			setNotifText(language_variant.geoCoding_cant_be_null);
			return
		}
		if(text.compareText("equipmentWidth can not be null")){// "equipmentWidth can not be null"
			setNotifText(language_variant.equipmentWidth_cant_be_null);
			return
		}
		if(text.compareText("longerThen can not be null")){// "longerThen can not be null " + longerThen
			setNotifText(language_variant.longerThen_cant_be_null + text.variable);
			return
		}
		if(text.compareText("ZoneType can not be null")){// "ZoneType can not be null " + zoneTypesId
			setNotifText(language_variant.ZoneType_cant_be_null + text.variable);
			return
		}
		if(text.compareText("zone type with id:")){// "zone type with id: " + getZoneTypeId + " not exist"
			setNotifText(language_variant.zone_type_with_id + text.variable + language_variant.not_exist);
			return
		}
		if(text.compareText("Impossible create zone from square with coordinate <=1")){// "Impossible create zone from square with coordinate <=1"
			setNotifText(language_variant.Impossible_create_zone_from_square_with_coordinate);
			return
		}
		if(text.compareText("not exist device in with id:")){// "not exist device in with id: " + getDeviceId
			setNotifText(language_variant.not_exist_device_in_with_id + text.variable);
			return
		}
		if(text.compareText("Invalid device type:")){// "Invalid device type: " + deviceType
			setNotifText(language_variant.not_exist_device_in_with_id + text.variable);
			return
		}
		if(text.compareText("tankVolumeStart can'n be more than tankVolume")){// "tankVolumeStart can'n be more than tankVolume"
			setNotifText(language_variant.tankVolumeStart_cant_be_more_tankVolume);
			return
		}
		if(text.compareText("fuel volume exceeded tank volume")){// "fuel volume exceeded tank volume"
			setNotifText(language_variant.fuel_volume_exceeded_tank_volume);
			return
		}
		if(text.compareText("Can't to find FUEL LEVEL BEFORE EVENT, because tank volume start can't be determine!")){// "Can't to find FUEL LEVEL BEFORE EVENT, because tank volume start can't be determine!"
			setNotifText(language_variant.Cant_find_FUEL_LEVEL_BEFORE_EVENT);
			return
		}
		if(text.compareText("Rfid card can not be empty")){// "Rfid card can not be empty"
			setNotifText(language_variant.Rfid_card_can_not_be_empty);
			return
		}
		if(text.compareText("Rfid card id")){// "Rfid card id: " + rfidCardId + " can not be null"
			setNotifText(language_variant.Rfid_card_id_can_not_be_null);
			return
		}
		if(text.compareText("No actual device mapping for control entity with id:")){// "No actual device mapping for control entity with id: " + getId
			setNotifText(language_variant.No_actual_device_mapping_for_control_entity + text.variable);
			return
		}
		if(text.compareText("Current vehicle with id:")){// "Current vehicle with id: " + vehicleId + " does not have current device mapping"
			setNotifText(language_variant.Current_vehicl_with_id + text.variable  + language_variant.does_not_have_current_device_mapping);
			return
		}
		if(text.compareText("Vehicle has incorrect width:")){// "Current vehicle with id: " + vehicleId + " does not have current device mapping"
			setNotifText(language_variant.Vehicle_has_incorrect_width + text.variable);
			return
		}
		if(text.compareText("Trailer serial id:")){// "Trailer serial id: " + idSerial + " is duplicated"
			setNotifText(language_variant.trailer_serial_allready_exist + ' ' + text.variable);
			return
		}
		if(text.compareText("No active device in for vehicle with id:")){
			setNotifText(errorText)//нужно переводы
			return
		}
		if(text.compareText("User with such login already exists")){
			setNotifText(language_variant.user_with_such_login_already_exists)//нужно переводы
			return
		}
		if(text.compareText("impossible get square zone from coordinates: point <= 1")){
			setNotifText(language_variant.user_with_such_login_already_exists)//нужно переводы
			return
		}
		if(text.compareText("Task route not exist")){
			setNotifText('Такого маршрута не существует')//нужно переводы
			return
		}
		if(text.compareText("Route id can not be null")){
			setNotifText('Должен быть выбран маршрут для задания')//нужно переводы
			return
		}
		if(text.compareText("Geozone id can not be null")){
			setNotifText('Не выбран адрес для контрольной точки')//нужно переводы
			return
		}
		if(text.compareText("Task route point position must be uniq")){
			setNotifText('Конфликт в порядке прохождения точек')//нужно переводы
			return
		}
		if(text.compareText("Task route point not valid by time activation")){
			setNotifText('Временной конфликт  в точке маршрута и времени начала задания')//нужно переводы
			return
		}
		if(text.compareText("Task route point not valid time by day")){
			setNotifText('Не валидное время в точке относительно начала дня')//нужно переводы
			return
		}
		if(text.compareText("Task route point not valid by time absolute")){
			setNotifText('Не валидное время в точке')//нужно переводы
			return
		}
		if(text.compareText("Current task route point time by activation can not be less or equal than previous")){
			setNotifText('Не валидное время в точке, время следующей точки не может быть раншьше предыдущей')//нужно переводы
			return
		}
		if(text.compareText("task rout with id", ' was not deleted')){
			setNotifText('Не валидное время в точке или  конфликт времени прохождения')//нужно переводы
			return
		}
		if(text.compareText("Report template", ' already exists')){
			setNotifText('Отчёт с именем ' + text.variable  + ' уже существует')//нужно переводы
			return
		}
		if(text.compareText("Current password:", ' is not correct')){
			setNotifText(language_variant.current_pass + ': ' + text.variable  + ' ' + language_variant.is_wrong)
			return
		}
		if(text.compareText('Invalid number of points, must be >= 4')){
			setNotifText(language_variant.not_enough_data_to_build_geofence)
			return
		}
		if(text.compareText('Vehicle with ID = ', ' does not belong to user test')){
			setNotifText(`Это транспортное средство вам не доступно`)
			return
		}
		if(text.compareText("Report template", ' already exists')){
			setNotifText('Отчёт с именем ' + text.variable  + ' уже существует')//нужно переводы
			return
		}
		setNotifText(errorText);
	}else{
		if(errorStatus == 403){
			setNotifText(language_variant.you_donT_have_enough_rights_to_do);//language_variant.data_not_available
		}
		if(errorStatus == 422){
			setNotifText(language_variant.data_not_available);
		}
		console.warn("rulesForError_422_433 - errorText not a string", errorText)
	}



	// "Vehicle has incorrect width: " + getWidth
}

export function getСoincidenceText(errorText) {
		this.partOfMessage = null;
		this.variable = null;

		this.compareText = function(partOfMessage, secondPartOfMessage) {
			this.partOfMessage = partOfMessage;
			let result = errorText.indexOf(partOfMessage) !== -1;
			result ? this.getVariable() : null;
			if(secondPartOfMessage){
				let secondResult = errorText.indexOf(secondPartOfMessage) !== -1;
				if(secondResult && result){
					return true
				}else{
					return false
				}
			}
			return result
		}
		this.getVariable = function(){
			const regExpForFilter = new RegExp('(?<=\\b' + this.partOfMessage + '\\s).*?(?=\\s|$)', 'g');
			console.log("%c errorText, errorText.match(regExpForFilter)", coCSS, errorText, errorText.match(regExpForFilter))
			this.variable = errorText.match(regExpForFilter)
		}
}

function saveUrlBeforeDeportation(){
	store.dispatch({
		type: 'URL_BEFORE_DEPORTATION',
		setURL: window.location.hash,
	})
}

export function setHappens(value) {
	store.dispatch({
		type: 'HAPENS',
		setHappened: value
	})
}

export function setNotifText(text) {
	store.dispatch({
		type: 'NOTIFINPOPUP',
		setTextNotif: text
	})
}

export function setErrorStatus(errorCode) {
	store.dispatch({
		type: 'API_ERROR',
		updateErrorStatus: errorCode,
	})
}

export function checkSIDForAccess(tokenIsActual, tokenFailed) {
	let token = infoStore().token;
	let login = infoStore().login;
	let requestData = {
		url: infoStore().base_url + "/api/check-token-for-expiration",
		method: 'POST',
		headers: {
			authorization: infoStore().token
		},
		withCredentials: true,
		params: {
			token: infoStore().token
		},
	};
	if(!token && !login){
		tokenFailed?tokenFailed():null
		return
	}
	axios(requestData)
	.then( answer  => tokenIsActual ? tokenIsActual() : null)
	.catch( answer => {
		if(tokenFailed) tokenFailed()
		bugReport(answer, requestData, "checkSIDForAccess")
	})
}

function collectInfoForMessageAboutError(error, request, name) {
	console.log("%c error", coCSS, error.response, error )
	// console.log("%c error.response.message", coCSS, error.response.message)
	// console.log("%c error.response.status", coCSS, error.response.status)
	const errorInfo = error.response ? error.response : error.message;
	return {
		nameAction: name,
		place: window.location.href,
		userLogin: infoStore().login,
		errorInfo: errorInfo,
		version_app: APP_VERSION,
		...request,
	}
}

function consoleLogError(error){
	console.log("%c error", coCSS, error)
	console.log("%c .status", coCSS, error.status)
	console.log("%c .message", coCSS, error.message)
	console.log("%c .code", coCSS, error.code)
	console.log("%c .stack", coCSS, error.stack)
}

export function requestToSlack(login, message, callback) {
	const requestInfo = {
        url: "https://hooks.slack.com/services/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",
        method: 'POST',
        data: JSON.stringify({
            "icon_emoji": ":gorilla:",
            "userName": login,
            "text": '*GLOBUS API bug*' + '\n' + '```\n' + message + ' \n```',
            "channel": "C01C9QCG3K8",//"YOUR_CHANNEL_ID",
        })
    };
    function sendMessage() {
    	const ifRequestBlocked = error => {
    		const eInfo = collectInfoForMessageAboutError(error, {}, 'send message to support');
   			consoleLogError(error);
   			authActions.setMessageFromAdmin(`Не вдалося надіслати повідомлення, причина: ${SL.tryStringify(eInfo.errorInfo)}`)
   			callback();
   		}
   		axios(requestInfo)
   		.then(()=> callback())
   		.catch(ifRequestBlocked)
   	};
    setTimeout(sendMessage,  1000)
}
window.requestToSlack = requestToSlack;
// https://skt-globus.slack.com/archives/C01C9QCG3K8
// https://join.slack.com/share/zt-ic7fhx9v-33CezWkx8PWW9ALMdHpk6A