import axios                     from 'axios'
import bugReport                 from './bugReport.js'
import SL 						 from 'sl-library';
import language_variant          from "store/languages.jsx"
import infoStore                 from './infoStore.js'
import store                     from 'store/store.js'
import Notif                     from 'actions/notifRequest'
import ProfileAction             from 'actions/profile_events.js'
import WebsocketActions          from "actions/Websocket.js"
import transportApi              from "actions/transportApi.js"
import geoRequest 				 from "actions/geoRequest.js"

const authActions = {
	authFailedAnswer:           authFailedAnswer,
	setMessageFromAdmin:        setMessageFromAdmin,
	setMessage:                 setMessage,
	reloadForm:                 reloadForm,
	loginUpdate:                loginUpdate,
	setPremission:              setPremission,
	savetoken:                  savetoken,
	savekindUser:               savekindUser,
	showBlockingMess:           showBlockingMess,
	submitAuth:                 submitAuth,
	logOut:                     logOut,
	clearAuthInfo:              clearAuthInfo,
	setReadStatusForLastUpdate: setReadStatusForLastUpdate,
	setAuthFromAdmin:           setAuthFromAdmin,
	savePremission: 			savePremission,
	saveOwnerLogin: 			saveOwnerLogin,
	setMessageNetworkProblem: 	setMessageNetworkProblem,
	sendRestoreRequest: 		sendRestoreRequest,
	checkingVersionApp: 		checkingVersionApp,
	showScenario: 				showScenario,
	get isAuthFromAdminPanel(){
		return SL.getBooleanFromLocalStorage('authFromAdminPanel')
	}
}


export default authActions;

function showScenario(userLogin) {
    return  typeof userLogin == 'string' ?
            !window.presentationWasCompleted && userLogin == 'demo' :
            false
}

function setMessageNetworkProblem(text) {
	store.dispatch({
		type: 'NETWORK_PROBLEM',
		setNetworkProblemMessage: text
	})
}

function nospace(str) {
	var VRegExp, VResult;
	if(!!str) {
		VRegExp = new RegExp(/^(\s|\u00A0)+/g);
		VResult = str.replace(/\s/g, '');
	} else {
		VResult = str;
	}
	return VResult
}

function authFailedAnswer(answerText) {
	store.dispatch({
		type: 'FORM_AUTH_FAILED',
		answerForUser: answerText
	})
}

function setMessageFromAdmin(messageFroUser) {
	store.dispatch({
		type: 'ADMIN_MESSAGE',
		setAdminMessage: messageFroUser
	})
}

function setMessage(messageText) {
	store.dispatch({
		type: 'MESSAGE',
		setMessage: messageText
	})
}

function reloadForm(value) {
	store.dispatch({
		type: 'RELOAD_FORM',
		setTrigger: value
	})
}

function loginUpdate(userLogin) {
	localStorage.setItem('login', nospace(userLogin));
	store.dispatch({
		type: 'FORM_AUTH_LOGIN_UPDATE',
		setLogin: nospace(userLogin)
	});
}

function setPremission(serveranswer) {
	const capabilities = SL.ifNoKey(serveranswer, 'capabilities');
	savePremission(capabilities)
}

function savePremission(capabilities) {
	localStorage.setItem('user_permission', SL.tryStringify(capabilities))
	store.dispatch({
		type: 'USER_PREMISSION',
		getpremission: capabilities
	})
}

function savetoken(token) {
	localStorage.setItem('sid', token); //need if user reload page
	store.dispatch({
		type: 'SESSION_TOKEN',
		savetoken: token
	})
}

function savekindUser(kindUser) {
	localStorage.setItem('kindUser', kindUser);
	store.dispatch({
		type: 'KIND_USER',
		savekindUser: kindUser
	})
}

function saveOwnerLogin(ownerLogin) {
	localStorage.setItem('ownerLogin', ownerLogin);
	store.dispatch({
		type: 'OWNER_LOGIN_UPDATE',
		setLogin: ownerLogin
	})
}

function showBlockingMess(value) {
	store.dispatch({
		type: 'BLOCK',
		saveStatusBlock: value,
	})
}

function setReadStatusForLastUpdate(readStatus) {
	localStorage.setItem('ReadStatusForLastUpdate', readStatus);
	store.dispatch({
		type: 'NOTIF_WHAT_IS_NEW',
		setStatusAboutViewdLastUpdate: readStatus,
	})
}

function clearGeofencesList() {
	geoRequest.saveGeofenceListToStore(null)
	geoRequest.saveFullGeofenceListToStore(null)
	store.dispatch({
		type: 'ZONE_TYPES',
		setZoneTypes: null,
	})
	store.dispatch({
		type: 'SELECTED_ZONE_TYPES',
		setSelectedZoneTypes: [],
	})
	store.dispatch({
		type: 'GEOFENCES_LIST',
		setGeofencesList: null,
	})
	store.dispatch({
		type: 'GEOFENCES_FOR_VIEW',
		setListForView: null,
	})
}

function clearNotifList() {
	store.dispatch({
		type: 'ALL_NOTIF',
		setAllNotif: null,
	})
	store.dispatch({
		type: 'NOTIF',
		setNotif_list: null,
	})
}

function checkingVersionApp() {
	const dataForSend = {
		baseURL:         window.location.origin,
		url:             "version/",
		method:          'GET',
		headers:         { "Content-Type": "text/html", },
		withCredentials: true,
	};
	axios(dataForSend)
	.then((answer) => {
		const versionAppOnHosting = answer.data;
		const scriptVersion = APP_VERSION;
		console.log("%c CHECKINGVERSIONAPP answer", coCSS, answer)
		console.log("%c scriptVersion", coCSS, scriptVersion)
		console.log("%c versionAppOnHosting", coCSS, versionAppOnHosting)
		console.log("%c scriptVersion !== versionAppOnHosting", coCSS, scriptVersion !== versionAppOnHosting)
		if(versionAppOnHosting && scriptVersion !== versionAppOnHosting){
			window.location.reload()
		}

	})
	.catch(e=>console.log("%c unvailable app version", coCSS, e))
}

window.checkingVersionApp = checkingVersionApp;
function submitAuth(login, password) {
	let page_after_login = "/control";
	let authInfo = new FormData();
	authInfo.append("username", nospace(login))
	authInfo.append("password", nospace(password))
	const dataForSend = {
		baseURL:         infoStore().base_url,
		url:             "/api/auth/v2/login",
		method:          'POST',
		headers:         { "Content-Type": "multipart/form-data", },
		withCredentials: true,
		data:            authInfo,
	};

	axios(dataForSend).then((answer) => {
		let urlbeforedep = infoStore('globalR.authorizationR.urlbeforedep');
		if(!!urlbeforedep && urlbeforedep !== window.location + '/#/') {
			page_after_login = urlbeforedep;
		}
		savetoken(answer.headers.authorization)
		loginUpdate(nospace(answer.data.login))
		setAuthFromAdmin(false);
		authFailedAnswer('');
		setPremission(answer.data);
		savekindUser(answer.data.kind);
		saveOwnerLogin(answer.data.belongsTo);
		setMessageFromAdmin(answer.data.message);
		setReadStatusForLastUpdate(answer.data.lastUpdateRead);
		ProfileAction.setProfileSettings(answer.data.profileDto);
		Notif.getNotifications(answer.data.login);
		setTimeout(() => {
			document.location.hash = page_after_login;
		}, 1000)
	}).catch((answer) => {
		console.log("%c catch", 'color: green; font-weight: bold;',  answer)
		catchAurorization(answer, dataForSend, "submitAuth")
	})
}

function sendRestoreRequest(login, callbackOk, callbackProblem) {
	const dataForSend = {
			baseURL:         infoStore().base_url,
			url:             "/api/auth/v2/user/" + login + "/reset-password",
			method:          'POST',
			headers:         { "Content-Type": "multipart/form-data", },
			withCredentials: true,
		};
		axios(dataForSend)
		.then( answer => callbackOk('Инструкции по восстановлению отправлены на ваш имейл'))
		.catch( answer => {
			const errorInfo = answer.response ? answer.response : answer.message;
			const status = SL.ifNoKey(answer, "status");
			console.log("%c status", coCSS, status, errorInfo, answer)
			if(errorInfo == 'Error: Network Error') {
				callbackProblem(language_variant.errorconnection)
				return
			}
			if(status == 404 && errorInfo.data == "User with such login does not exist") {
				callbackProblem('Пользователь с таким логином не найден')
				return
			}

			if(status == 422 && errorInfo.data == "User email is empty"){
				callbackProblem('Имейл пользователя не найден, обратитесь в техподдержку')
				return
			}

			// Может быть 404(User with such login does not exist) если пользователя с таким логином нет в системе или 422(User email is empty) если у пользователя нет почты
			// catchAurorization(answer, dataForSend, "sendRestoreRequest")
		})
}

function clearAuthInfo(){
	WebsocketActions.clearWebSocket();

	loginUpdate('');
	saveOwnerLogin('');
	savetoken('');
	clearNotifList();
	clearGeofencesList();
	setMessageNetworkProblem('');
}

function catchAurorization(answer, request, name) {
	let answertext;
	let status = SL.ifNoKey(answer, "status");
	let error = SL.ifNoKey(answer, "error");
	let errorData = answer.response ? answer.response.data : null
	let message = SL.ifNoKey(errorData, 'message');
	console.log("%c message", coCSS, message)
	console.log("%c errorData", coCSS, errorData)
	if(status == 401 && error == "Unauthorized") {
		answertext = language_variant.error_autorization
		clearAuthInfo()
	}
	if(status == 404 || answer == 'Error: Network Error') {
		answertext = language_variant.errorconnection;
		bugReport(answer, request, name);
		clearAuthInfo()
	}
	if(status == 403 && message == 'Too many login failures'){
		answertext = language_variant.message_to_many
	}
	if(status == 403 && message == 'Wrong username or password') {
		answertext = language_variant.errorauthorization;
	}
	if(status == 423) {
		showBlockingMess(true)
		savetoken(null);
	}
	authFailedAnswer(answertext);
}

function logOut(callback) {
	const requestInfo = {
		method:          'GET', // "PUT",//,
		baseURL:         infoStore().base_url,
		url:             infoStore().bonus_url_api + "logout",
		dataType:        "JSON", //формат
		withCredentials: true,
		headers:         {
									'authorization': infoStore().token
							  },
	};

	axios(requestInfo).then((answer) => {
		clearAuthInfo();
		setTimeout(redirectToLoginForm, 500);//AppDoorMan check on old props if time out absent(appeared after update react version)
		transportApi.dispatchVehicleInfoToStore(null);
		localStorage.setItem('presentationWasCompleted', false)
	}).catch((serveranswer) => {
		clearAuthInfo()
		setTimeout(redirectToLoginForm, 500);//AppDoorMan check on old props if time out absent(appeared after update react version)
		transportApi.dispatchVehicleInfoToStore(null);
		localStorage.setItem('presentationWasCompleted', false)
		// bugReport(serveranswer, requestInfo, 'logOut')
	})
}

function redirectToLoginForm() {
	window.location = '/';
}

function setAuthFromAdmin(value) {
	localStorage.setItem('authFromAdminPanel', value);//using for different logic for user and admin
}
