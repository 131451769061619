
export default function controlR(state = {
							vehiclesInfo:   null,
							fix:            '',
							updateprops:    '',
							transport_list: undefined,
							vehicleGroups: 	null,
							newData:        '',
							location:       '',
							ignition:       '',
							onMouse:        '',
							goto:           '',
							moving:         { id: undefined, move: false, },
							markersList:    [],
							choosedVehicle: null,
							simpleListViewEnabled: localStorage.getItem('simpleListViewEnabled') == 'true' ? true : false,
						}, action) {
						switch (action.type) {
							case 'SIMPLE_LIST_VIEW':
								localStorage.setItem('simpleListViewEnabled', action.setStatusSimpleList);
								action.setStatusSimpleList ? localStorage.setItem('sideBarStatus', 'shorted') : null;
								return {
									...state,
									simpleListViewEnabled: action.setStatusSimpleList
								}
							case 'VEHICLE_GROUPS':
								return {
									...state,
									vehicleGroups: action.setVehicleGroups
								}
							case 'CHOOSED':
								return {
									...state,
									choosedVehicle: action.setChoosedVehicle,
								}
							case 'ADD_MARKER':
								return {
									...state,
									markersList: action.setMarkersList,
								}
							case 'GET_transport':
								return {
									...state,
									vehiclesInfo: action.setVehiclesInfo
								};
							case 'LOCATION':
								return {
									...state,
									location: action.up_location
								};
							case 'GET_NEWDATA':
								return {
									...state,
									newData: action.set_newdata,
								};
							case 'HOVER':
								return {
									...state,
									onMouse: action.hovered
								};
							case 'GOTO':
								return {
									...state,
									goto: action.setgoto
								};
							case 'MOVEUP':
								return {
									...state,
									moving: action.moveUp,
								};
							default:
								return state;
						}
}
controlR.reducer = 'controlR'
