import EnjoyHintLibr from "lodash";

EnjoyHintLibr.forEach(key=>appendElement(key.tagName, key.attributes))

function appendElement(elName, attributes) {
	const element = document.createElement(elName);
	for(let attributeName in attributes) {
		element[attributeName] = attributes[attributeName];
	}
	document.body.appendChild(element);
}

