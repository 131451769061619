import store 		from 'store/store.js'
import bugReport 	from './bugReport'
import axios 		from 'axios'
import infoStore		from './infoStore.js'
import {arrayIsEmpty, copyOfObject} 		from 'sl-library'
const Notif = {
	getNotifications:                getNotifications,
	getAllNotifications:             getAllNotifications,
	getTopNotifications:             getTopNotifications,
	setRead:                         setRead,
	setCount:                        setCount,
	getNotifVSfilter:                getNotifVSfilter,
	clearTextNotif:                  clearTextNotif,
	getUpdatesNotification:          getUpdatesNotification,
	setStatusLastUpdatesNotifViewed: setStatusLastUpdatesNotifViewed,
	setAllNotifReaded:               setAllNotifReaded,
	saveCount:                       saveCount,
	getNotifForVehicle:              getNotifForVehicle,
	getNewNotificationForVehicle: 	 getNewNotificationForVehicle,
	setNotifForVehicleReaded: 			 setNotifForVehicleReaded
}

export default Notif;

export function getNotifications(login) {
	let requestData = {
		method: 'GET',
		baseURL: infoStore().base_url + infoStore('globalR.authorizationR.port_notif'), //this was in the start
		url: infoStore().bonus_url_api + 'ns/' + login + '/unread',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
		.then(answer => {
			store.dispatch({
				type: "NOTIF",
				setNotif_list: answer.data
			})
			setCount(answer.data)
		})
		.catch(serveranswer => bugReport(serveranswer, requestData, "getNotifications"))
}

export function setCount(data, obj, action, newVal) {
	let state = store.getState();
	let list = state.globalR.eventsR.notif_list;
	if(!!obj) {
		saveCount(obj.number)
		list[obj.index].readStatus = true;
		store.dispatch({
			type: "NOTIF",
			setNotif_list: list
		})
	} else if(Array.isArray(data)) {
		saveCount(data.length)
	}
	if(action == 'plus' && !!newVal) {
		let count = (!!state.globalR.eventsR.count) ? state.globalR.eventsR.count : 0;
		saveCount(count + 1)
		if(!arrayIsEmpty(list)) {
			list = [];
		}
		list.unshift(newVal);
		store.dispatch({
			type: "NOTIF",
			setNotif_list: copyOfObject(list)
		})
	}
}

function saveCount(count) {
	store.dispatch({
		type: "COUNT",
		setCount: count
	})
}

export function getAllNotifications(login) {
	let requestData = {
		method: 'GET',
		baseURL: infoStore().base_url + infoStore('globalR.authorizationR.port_notif'), //this was in the start
		url: infoStore().bonus_url_api + 'ns/' + login + '/read',
		headers: { 'authorization': infoStore().token },
		withCredentials: true,
	};
	axios(requestData)
		.then(answer => {
			store.dispatch({
				type: "ALL_NOTIF",
				setAllNotif: answer.data
			})
		})
		.catch(serveranswer => bugReport(serveranswer, requestData, 'getAllNotifications'))
}

export function getTopNotifications(login) {
	let requestData = {
		method: 'GET',
		baseURL: infoStore().base_url + infoStore('globalR.authorizationR.port_notif'), //this was in the start
		url: infoStore().bonus_url_api + 'ns/' + login + '/top',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
		.then((answer) => {
			console.log(answer.data)
			store.dispatch({
				type: "TOP_NOTIF",
				setTopNotif: answer.data
			})
		})
		.catch((serveranswer) => bugReport(serveranswer, requestData, 'getTopNotifications'))
	// }
}

export function setRead(data) {
	let requestData = {
		method: 'PUT',
		baseURL: infoStore().base_url, //this was in the start
		url: infoStore().bonus_url_api + 'ns/set',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: data
	}
	axios(requestData)
		.then((answer) => {
			// console.log('setRead',answer.data)
		})
		.catch(serveranswer => bugReport(serveranswer, requestData, "setRead"))
	// }
}

function clearTextNotif() {
	store.dispatch({
		type: 'NOTIFINPOPUP',
		setTextNotif: ''
	})
}

function setAllNotifReaded(callback) {
	let requestData = {
		method: 'PUT',
		baseURL: infoStore().base_url, //this was in the start
		url: infoStore().bonus_url_api + 'ns/set/all/' + infoStore().login,
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
		.then((answer) => {
			console.log('setAllNotifReaded', answer.data)
			if(callback) callback()
		})
		.catch(serveranswer => bugReport(serveranswer, requestData, "setAllNotifReaded"))
}

function setNotifForVehicleReaded({login, vehicleId, callback}) {
	let requestData = {
		method: 'PUT',
		baseURL: infoStore().base_url, //this was in the start
		url: `${infoStore().bonus_url_api}ns/set/${login}/${vehicleId}`,
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
		.then((answer) => {
			console.log('setNotifForVehicleReaded', answer.data)
			if(callback) callback()
		})
		.catch(serveranswer => bugReport(serveranswer, requestData, "setNotifForVehicleReaded"))	
}

window.setAllNotifReaded = () => setAllNotifReaded(localStorage.getItem('login'))

function generateQueryString(descriptions) {
  return descriptions.map(description => `descriptions=${description}`).join('&') + '&';
}

function getNotifForVehicle({login, vehicleId, params, callback}) {
	const description = generateQueryString(params.descriptions);
	delete params.description;
	let requestData = {
		method: 'GET',
		baseURL: infoStore().base_url, //this was in the start
		url: 		`${infoStore().bonus_url_api}ns/${login}/${vehicleId}/filters?${description}`,
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: 	params,
	}
	axios(requestData)
		.then(answer => {
			callback(answer.data)
		})
		.catch(serveranswer => bugReport(serveranswer, requestData, "getNotifForVehicle"))
}

function getNewNotificationForVehicle({login, vehicleId, callback}) {
	let requestData = {
		method: 'GET',
		baseURL: infoStore().base_url, //this was in the start
		url: 		`${infoStore().bonus_url_api}ns/${login}/unread/${vehicleId}`,
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
		.then(answer => callback(answer.data))
		.catch(serveranswer => bugReport(serveranswer, requestData, "getNewNotificationForVehicle"))
}

function getNotifVSfilter(requestJson, callback) {
	let requestData = {
		method: 'PUT',
		baseURL: infoStore().base_url, //this was in the start
		url: infoStore().bonus_url_api + 'ns/' + infoStore().login + '/filters',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: requestJson
	}
	axios(requestData)
		.then((answer) => {
			if(!!callback) callback(answer.data)
		})
		.catch(serveranswer => bugReport(serveranswer, requestData, "getNotifVSfilter"))
}

function getUpdatesNotification(callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'updating_notification',
		dataType: "JSON",
		method: 'GET',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'getUpdatesNotification'))
}

function setStatusLastUpdatesNotifViewed(callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'user/last_update_read',
		dataType: "JSON",
		method: 'POST',
		withCredentials: true,
		headers: { "Content-Type": "application/json", 'authorization': infoStore().token },
		data: infoStore().login
	}
	axios(dataForRequest).then((answer) => {
		callback()
	}).catch((answer) => bugReport(answer, dataForRequest, 'getUpdatesNotification'))
}

