import React, { Component, PureComponent } from 'react';
import {checkSIDForAccess, setHappens}         	from 'actions/bugReport'
import {connect}                        		from 'react-redux';
import authActions                      		from 'actions/authActions.js'
import {setErrorStatus, setMessageForUser}      from 'actions/bugReport.js'

class AppDoorMan extends PureComponent {
	UNSAFE_componentWillMount() {
		let { login, token, errorStatus, blocked } = this.props;
		let url = window.location.hash;
		let authToDemoAccount = url.indexOf('demo_account') >= 0;
		let restorePassword = url.indexOf('change-password') >= 0
		if(isOrigin()) {
			let tokenIsActual = redirectFromAuthForm,
				ifUnAuthorized = null;
			isUserAuthorized(login, token, tokenIsActual, ifUnAuthorized)
		} else if(!authToDemoAccount && !restorePassword) {
			let tokenIsActual = null;
			let unAuthorized = () => {
				authActions.clearAuthInfo();
				if(!isOrigin()) {
					authActions.setMessage('Ваша сессия истекла, повторите авторизацию!')
				}
				redirectToLoginForm();
			};
			isUserAuthorized(login, token, tokenIsActual, unAuthorized)
		}
		window.addEventListener('onhashchange', () => {
			if(!authToDemoAccount) {
				checkSidAndLoginValue(this.getLogin(), this.getToken())
			}
		}, false)
		if(!DEVELOPMENT) setInterval(authActions.checkingVersionApp, 300000) //5 minutes
		window.addEventListener('popstate', (event) => {
			if(this.getToken() && this.getLogin() && isOrigin()) {
				redirectFromAuthForm()
			}
		});
	}
	componentDidMount() {
		console.log("%c APP_VERSION", coCSS, APP_VERSION, BUILD_DATE);
	}
   	getToken = () => this.props.token
   	getLogin = () => this.props.login
	render() {
      	let url = window.location.hash;
      	let authToDemoAccount = url.indexOf('demo_account') >= 0;
      	let restorePassword = url.indexOf('change-password') >= 0
		let {login, token, errorStatus, blocked} = this.props;
		if(blocked){
			redirectToLoginForm();
			authActions.clearAuthInfo();
		}else{
			if(sessionIsOver(errorStatus) && !DEVELOPMENT){
				if(!isOrigin() && !authToDemoAccount && !restorePassword) {
					authActions.setMessage('Ваша сессия истекла, повторите авторизацию')
					redirectToLoginForm();
					authActions.clearAuthInfo();
				}
			}
		}

		return null
	}
}


export default connect(
      state => ({
            errorStatus: 	state.globalR.authorizationR.errorStatus,
            login:          state.globalR.authorizationR.login,
            token:          state.globalR.authorizationR.token,
            blocked:        state.globalR.authorizationR.blocked,
      })
)(AppDoorMan)

function checkSidAndLoginValue(login, sid){
    if(!sid || !login){
        if(!isOrigin()){
            authActions.clearAuthInfo();
            redirectToLoginForm();
        }
    }

}

export function sessionIsOver(answerStatus) {
    if(answerStatus == 401 || answerStatus == 1008 || answerStatus == 1001) {
        return true
    } else return false
}

export function isOrigin() {
   return window.location.href == window.location.origin + '/#/' || window.location.href == window.location.origin
}

export function isRestorePassword() {
	return window.location.hash.indexOf('change-password') >= 0
}
function redirectToLoginForm(){
  console.log("%c redirectToLoginForm", coCSS, )
  if(!DEVELOPMENT){
        setErrorStatus(null);
    window.location.hash = '/';
  }

}

function redirectFromAuthForm(){
	console.trace()
    window.location.hash = 'control'
}

function isUserAuthorized(login, sid, funcIfIsAuthorized, funcIfIsUnAuthorized) {
   let url = window.location.hash;
   let authFromAdminPanel = url.indexOf('commingFromAdmin') >= 0;
   if(authFromAdminPanel) {
      return funcIfIsAuthorized ? funcIfIsAuthorized() : null;
   } else {
      if(!login || !sid) {
         return funcIfIsUnAuthorized ? funcIfIsUnAuthorized() : null;
      }
      if(login && sid) {
         return checkSIDForAccess(funcIfIsAuthorized, funcIfIsUnAuthorized)
      }
   }
}


