import { combineReducers } from 'redux'
import authorizationR      from './authorization_reducer.js';
import controlR            from './controlPage_reducer.js'
import tasksR              from './tasks_reducer.js'
import eventsR             from './events_reducer.js'
import reportR             from './report_reducer.js'
import routeDetailR        from './routeDetail_reducer.js'
import geoR                from "./geo_reducer.js"

export default {
  globalR: combineReducers({
				  authorizationR,
				  controlR,
				  eventsR,
				  reportR,
				  tasksR,
				  routeDetailR,
              geoR
				})
}

