//demo()
export default function tasksR(state = {
		task_routes: 		null,
		tasks_list: 		null,


		templates: 			undefined,
		template_for_edit: 	JSON.parse(localStorage.getItem('tempTemplate')),//undefined,
		active_tasks: 		undefined,
		planned_tasks: 		undefined,
		completed_tasks: 	undefined,
		failed_tasks: 		undefined,
	}, action) {
		switch (action.type) {
			case ('SET_TASK_ROUTES'):
			return {
				...state,
				task_routes: action.setTaskRoutes,
			};
			case ('SET_TASKS'):
			return {
				...state,
				tasks_list: action.setTasksList,
			};


	    	case ('SET_TEMPLATES'):
	    	return {
	    		...state,
	    		templates: action.setTemplates,
	    	};
	    	case ('EDIT_TEMPLATE'):
	    	return {
	    		...state,
	    		template_for_edit: action.setEditTemplate,
	    	};
	    	case ('ACTIVE_TASKS'):
	    	return {
	    		...state,
	    		active_tasks: 	action.setActiveTasks,
	    	};
	    	case ('PLANNED_TASKS'):
	    	return {
	    		...state,
	    		planned_tasks: action.setPlannedTasks,
	    	};
	    	case ('COMPLETED_TASKS'):
	    	return {
	    		...state,
	    		completed_tasks: action.setCompletedTasks,
	    	};
	    	case ('FAILED_TASKS'):
	    	return {
	    		...state,
				failed_tasks: 	action.setFailedTasks,

	    	}
	      	default:
	         	return state;
			}
	}
tasksR.reducer = 'tasksR'
