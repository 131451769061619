import infoStore            from 'actions/infoStore.js'
import helpFunc             from 'components/modules/controlPage/helpFunc.js'
import { ControlPageMarker, validateCoordindates} from "components/leafLetsMap/VehicleMarker.jsx"
import {
	ifNoKey,
	arrayIsEmpty,
	isValidDate,
	isNumeral,
	mixedClass,
	copyOfObject
}                           from 'sl-library'
import L  from "leaflet"


class Vehicle extends ControlPageMarker {
	constructor(context){
		super(context);
		this.setState =              context.functionForUpdateState;
		this.state =                 context.functionForGetState;
		this.vehicleId =             context.vehicleId;
		this.labelForAllEnabled =    context.labelForAllEnabled;
		this.scrollVehicleIntoView = context.scrollVehicleIntoView;
		this.map =                   context.map;
		this.onOpenCarInfoWindow = 	 context.onOpenCarInfoWindow;
		this.clearOpennedInfoWindow = context.clearOpennedInfoWindow;
		this.markerInitialZindex = 200500 + this.indexInStore;//next level xz
		this.getStateInStore = function(){
			const storeState = infoStore('globalR.controlR.vehiclesInfo');
			return storeState[this.indexInStore]
		}
		this.map?
		this.initMarker :
		null
	}
	get indexInStore(){
		return this.getIndexVehicleInStore(this.vehicleId)
	}
	_initMarker_() {
		// if(this.vehicleId == 39187){
		// 	console.log("%c _INITMARKER_ this.state", coCSS, this.state)
		// }
		const vehiclesDataGpsDataDto = ifNoKey(this.state, 'vehiclesDataGpsDataDto');
		const lat = ifNoKey(vehiclesDataGpsDataDto, 'latitude');
		const lng = ifNoKey(vehiclesDataGpsDataDto, 'longitude');
		const bearing = this.state.azimuth;
		if(this.map && validateCoordindates(lat, lng) && !this.marker) {
			Object.assign(this, {
				vehicleId:    this.vehicleId,
				lat:          lat,
				lng:          lng,
				licensePlate: this.state.licensePlate,
				name:         this.state.name,
				bearing: 	  bearing,
				stateInStore: () => this.getStateInStore(),
			});
			this.addMarkerToMap()
		}
		Object.assign(this, { stateInStore: () => this.getStateInStore()})
		return this.marker
	}
    getIndexVehicleInStore(vehicleId){
		const storeState = infoStore('globalR.controlR.vehiclesInfo');
		return 	arrayIsEmpty(storeState) ?
				storeState.findIndex(key => key.vehicleId == vehicleId) :
				null
    }
	context(){
		return this
	}
	get map(){
		return this._map_()
	}
	set map(value){
		return this._map_ = value
	}
	get select(){
		this.setState({'choosed': true}, () => this.marker ? this.setSelected : this.showInfoWindowWithOutMarker());
	}
	get deselect(){
		this.setState({'choosed': false}, () => this.marker ? this.setDeselected : this.hideInfoWindowWithOutMarker());
	}
	get DOMCoordinates(){
		return this.getDOMCoorginates()
	}
	get initMarker(){
		return this._initMarker_()
	}
	get state() {
        return 	this._state_ ?
        		this._state_() :
        		'access to state unavailable, check OneVehicle UNSAFE_componentWillMount addAccesToVehicleState'
    }
	set state(val) {
		this._state_ = val;
	}
}

class VehiclesList {
	constructor(context){
		this.setInitialList()
	}
	setInitialList() {
		this.vehiclesList = new Object()
	}
	addAccesToVehicleState(vehicleId, functionForUpdateState, functionForGetState, islastInList) {
		// if(vehicleId == 39187){
		// 	console.log("%c addAccesToVehicleState", coCSS, functionForGetState())
		// }

		const init = () => {
			if(!this.vehiclesList[vehicleId] || !this.vehiclesList[vehicleId].markerPresentOnMap()){//|| !this.vehiclesList[vehicleId].markerP resentOnMap()
				// if(vehicleId == 39187) console.log("%c this.vehiclesList", coCSS, this.vehiclesList)
				this.vehiclesList[vehicleId] = new Vehicle({
															vehicleId:              vehicleId,
															functionForUpdateState: functionForUpdateState,
															functionForGetState:    functionForGetState,
															map:                   	() => this.map,
															labelForAllEnabled: 	this.labelForAllEnabled,
															scrollVehicleIntoView: 	vehicleId => this.scrollVehicleIntoView(vehicleId),
															onOpenCarInfoWindow: 	infoWindowVehicleId => this.onOpenCarInfoWindow(infoWindowVehicleId),
															clearOpennedInfoWindow: () => this.infoWindowVehicleId = null
															});
				if(islastInList){
					this.setMarkersIntoView();
				}
				if(this.labelForAllEnabled){
					this.setLabelForAllMarkers()
				}
			}

		}
		if(!this.map){//wait map if vehicle info was receive early
			const checkMap = () => {
				if(this.map){
					init();
					clearInterval(interval);
				}
			};
			const interval = setInterval(checkMap, 1000)
		}else{
			init();
		}
	}
	removeVehicle(vehicleId){
		if(this.vehiclesList[vehicleId]) {
			this.vehiclesList[vehicleId].removeMarkerFromMap()
			delete this.vehiclesList[vehicleId]
		}
	}
	updateInfoWindow(vehicleId) {
		return this.vehiclesList[vehicleId].updateInfoWindow()
	}
	setMarkers() {
		if(this.map){
			for(let vehicle in this.vehiclesList) {
				this.vehiclesList[vehicle].initMarker
			}
			this.setMarkersIntoView()
		}
		return this
	}
	_overedMarker_(vehicleId) {
		return this.vehiclesList[vehicleId] ? this.vehiclesList[vehicleId].overedMarker() : null
	}
	_hoveredMarker_(vehicleId) {
		return this.vehiclesList[vehicleId] ? this.vehiclesList[vehicleId].hoveredMarker() : null
	}
	deselect() {
		this.selected ? this.selected.deselect : null;
		this.selected = null;
	}
	select(vehicleId) {
		this.deselect();
		this.vehiclesList[vehicleId].select;
		this.selected = this.vehiclesList[vehicleId];
	}
	remove(vehicleId) {
		delete this.vehiclesList[vehicleId]
	}
	setMarkersIntoView() { //here do bound map where we can see all
		let latVSLng = []
		for(let el in this.vehiclesList) {
			if(this.vehiclesList[el].marker) {
				latVSLng.push(L.latLng(this.vehiclesList[el].lat, this.vehiclesList[el].lng) );
			}
		}
		if(arrayIsEmpty(latVSLng)){
			let bounds = new L.LatLngBounds(latVSLng)
			this.map.fitBounds(bounds);
		}
	}
	updateMarkerLocation(newLocation) {
		const { vehicleId } = newLocation;
		return this.vehiclesList[vehicleId].updateLocation(newLocation)
	}
	updateMarkerStatus(vehicleId){
		return this.vehiclesList[vehicleId].setNewStatusIcon
	}
	setLabelForAllMarkers() {
		for(let el in this.vehiclesList) {
			this.vehiclesList[el].showMarkerLabel
		}
		this.setLabelStatus(true)
	}
	toogleLabelColor() {
		for(let el in this.vehiclesList) {
			this.vehiclesList[el].updateTooltipColor()
		}

	}
	removeLabelForAllMarkers() {
		for(let el in this.vehiclesList) {
			this.vehiclesList[el].hideMarkerLabel
		}
		this.setLabelStatus(false)
	}
	onOpenCarInfoWindow(infoWindowVehicleId){//close previous opened info window
		if(this.infoWindowVehicleId !== infoWindowVehicleId && this.vehiclesList[this.infoWindowVehicleId]){
			this.vehiclesList[this.infoWindowVehicleId].closeInfoWindow()
		}
		this.infoWindowVehicleId = infoWindowVehicleId;
	}
}

export default VehiclesList
