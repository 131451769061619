import "core-js/stable";
import React from "react";
import { HashRouter } from "react-router-dom";
// import { render }      from 'react-dom'
import ReactDOM from "react-dom/client";
import store from "./store";
import { Provider } from "react-redux";
import App from "components/app";
import { setMomentLocale } from "./store/languages.jsx";
window.coCSS = "color: green; font-weight: bold;";
window.pCSS = "color: purple; font-weight: bold;";
window.bCSS = "color: blue; font-weight: bold;";
window.rCSS = "color: red; font-weight: bold;";
if(!window.location.origin) {//crutch for development process, maybe not actual now
	window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
}
setMomentLocale();
crutchForHeightInMoblie();
const root = ReactDOM.createRoot(document.getElementById("app_mountpoint"));
root.render(
	<Provider store={store}>
		<HashRouter>
			<App />
		</HashRouter>
	</Provider>,
);
hideHtmlPreloader();

function hideHtmlPreloader() {
	let preloaderImg = document.getElementById("first_pre");
	preloaderImg ?
		preloaderImg.style.display = "none" :
		console.warn("can't hide preloader. preloaderImg -", preloaderImg);
}

function crutchForHeightInMoblie() {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
	document.documentElement.setAttribute("data-crutch_info", "this need for header in mobile chrome(header can be or not) func name - crutchForHeightInMoblie");
}
window.addEventListener("resize", crutchForHeightInMoblie);
